import React, { FC } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import {
  Stack,
  StackProps,
  Text,
  useTableSorters
} from '../../../components/common';
import { useHistory } from 'react-router-dom';
import { CustomerWithNumCases } from '../types';
import utils from '../../../utils/utils';

const TableHeaderCells = [
  { id: 'accountName', label: 'Customer', width: '25%', hasFilter: true },
  { id: 'numCases', label: 'No of Cases', width: '25%', hasFilter: true }
];

type Props = {
  data: CustomerWithNumCases[];
  title: string;
  color: string;
} & StackProps;

export const CustomerCasesTable: FC<Props> = (props) => {
  const { data, title, color, ...stackProps } = props;
  const history = useHistory();
  const { onSort, filterData, sortColumn, sortOrder } = useTableSorters({
    turnOffPagination: true
  });
  const handleRowClick = (rowData: CustomerWithNumCases) => {
    history.push(utils.getAccountHealthRoute(rowData._id.AccountName));
  };
  return (
    <Stack gap={20} direction="vertical" {...stackProps}>
      <Text
        variant="p4"
        weight="bold"
        color="dark"
        style={{ marginTop: '16px', marginBottom: '0px' }}
      >
        {title}
      </Text>
      <Card
        style={{
          // padding: '30px 15px',
          borderRadius: 0,
          boxShadow: 'none'
        }}
      >
        <TableContainer>
          <Table size="small" aria-label="Customer Cases Table">
            <TableHeadSorting
              order={sortOrder}
              orderByColumn={sortColumn}
              onRequestSort={(_, column) => onSort(column)}
              headCells={TableHeaderCells}
            />
            <TableBody>
              {filterData(data).map((row, index) => (
                <TableRow key={index} onClick={() => handleRowClick(row)}>
                  <TableCell variant="body">
                    <Text
                      variant="p1"
                      style={{ color: '#5E81F4' }}
                      weight="semi-bolder"
                    >
                      {row._id.AccountName}
                    </Text>
                  </TableCell>
                  <TableCell variant="body">
                    <Text variant="p2" weight="semi-bold" color="dark">
                      {row.numCases}
                    </Text>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Stack>
  );
};
