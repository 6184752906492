import React, { FC } from 'react';
import { Text } from './Text';

export const Tag: FC = (props) => {
  return (
    <div
      style={{
        color: '#fff',
        background: 'linear-gradient(92.31deg, #2BCDFF 0.71%, #22D6BA 100%)',
        borderRadius: 30,
        // padding: '2px 6px',
        paddingLeft: 8,
        paddingRight: 8,
        height: 22
      }}
    >
      <Text
        variant="p1"
        weight="semi-bolder"
        style={{ color: 'white', verticalAlign: 'super' }}
      >
        {props.children}
      </Text>
    </div>
  );
};
