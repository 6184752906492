import React, { Component } from 'react';
import './FreeTrialExpired.scss';
import IrisPortalService from '../../services/IrisPortalService';
import Utils from '../../utils/utils';
import { Button } from '../../components/home/Button';

class FreeTrialExpired extends Component<{}, any> {
  componentDidMount() {
    let user = Utils.getObjectItem('user');
    if (!Object.keys(user).length) {
      IrisPortalService.handleError(403); // Unathorized user (User not logged)
    }
  }

  render() {
    const message =
      'Uh Oh! Your free trial has expired. Please contact us to upgrade and re-enable your account.';
    const link =
      'https://calendly.com/palak-iris/upgrade-your-irisagent-account';
    const buttonTitle = 'Upgrade Now';
    return (
      <div style={{ height: '100%', width: '100%', paddingTop: '5%' }}>
        <div
          style={{
            width: '40%',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: 10
          }}
        >
          <div
            style={{
              color: '#8181A5',
              fontWeight: 'bold',
              paddingBottom: '3%'
            }}
          >
            {message}
          </div>
          <Button
            href={link}
            variant="outlined"
            style={{ backgroundColor: '#0fb2a9', color: 'white' }}
          >
            {buttonTitle}
          </Button>
        </div>
      </div>
    );
  }
}

export default FreeTrialExpired;
