import React from 'react';
import { Stack, Text } from '../../components/common';
import Utils from '../../utils/utils';
import { Badge } from '@material-ui/core';
import _ from 'lodash';
import "./ChatbotActivityCard.scss"

export interface IrisCardProps {
  background?: string;
  style?: object;

  userName: string;
  message: string;
  timestamp: string;
}

export const ChatbotActivityCard: React.FC<IrisCardProps> = (props) => {
  const { background = '#F7F9FE', style, userName, message, timestamp } = props;

  return (
    <Stack direction="horizontal" gap={10}>
      <div
        style={{
          display: 'flex',
          width: '40px',
          height: '40px',
          backgroundColor: background,
          borderRadius: '50%'
        }}
      >
        <p style={{ margin: 'auto' }}>
          <Text variant="p1" weight="semi-bolder" color={'dark'}>
            {_.capitalize(_.first(userName))}
          </Text>
        </p>
      </div>
      <div
        style={{
          flex: 1,
          borderRadius: 10,
          padding: 12,
          background,
          width: '100%',
          ...style
        }}
      >
          <Stack direction="vertical" gap={10}>
              <Stack direction="horizontal" align={'center'}>
                  <Text style={{fontSize: 14}} weight={'bold'}>
                      {userName}
                  </Text>{' '}
                  <Badge
                      color="primary"
                      variant="dot"
                      style={{
                          marginRight: '10px',
                          marginLeft: '10px'
                      }}
                  />{' '}
                  <Text>{Utils.formatDate(new Date(timestamp), 'lll')}</Text>
              </Stack>
              <Text className={"chatbot-message"} dangerouslySetInnerHTML={{__html: message}}/>
          </Stack>
      </div>
    </Stack>
  );
};
