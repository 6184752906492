import React, { ReactElement } from 'react';

import { RulesRowManager } from '../../../utils/RulesRowManager';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as UserPlus } from '../../../../../assets/userPlus.svg';
import '../styles.scss';
import {
  formatPrerequisitesData,
  useTriggersPrerequisites
} from '../../../utils';
import SearchWidget from '../../../../categories/components/SearchWidget';

/**
 * Row manager for Assign Agent
 */
export class AssignAgentRowManager extends RulesRowManager {
  constructor(
    options: Option[],
    actionValue: any,
    actionOperator: any,
    type: string
  ) {
    super(options, actionValue, actionOperator, type);
    this.action.type = 'change_case_assignee';
  }
  setDefaultActionValue(): void {
    this.action.value = '';
  }
  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    const prerequesitesData = useTriggersPrerequisites();
    const agentOptions = formatPrerequisitesData(
      prerequesitesData.triggersPrerequesites?.agents || []
    ).map((agent) => {
      return { name: agent.name.id, label: agent.name.name };
    });
    let searchVal = '';
    if (this.action.value) {
      const agentObj = agentOptions.filter(
        (opt) => opt.name === this.action.value
      );
      if (agentObj && agentObj.length > 0) {
        searchVal = agentObj[0]['label'];
      }
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <UserPlus />
          <p className="title">Assign Agent</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <p className="operator-text">Select agent/team member</p>
          <div style={{ width: '20em' }}>
            <SearchWidget
              isCustom={false}
              searchValue={searchVal}
              dataList={agentOptions.map((cat) => {
                return cat.label;
              })}
              onInputChange={(input: string) => {
                const agentObj = agentOptions.filter(
                  (opt) => opt.label === input
                );
                if (agentObj && agentObj.length > 0) {
                  this.action.value = agentObj[0]['name'];
                } else {
                  this.action.value = '';
                }
                searchVal = input;
                handleRowComponent(this.action);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
