import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './LoginButton.scss';

interface Props {
  svgIcon: string;
  account: string;
  onClick: any;
  title: string;
}

class LoginButton extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { svgIcon, account, onClick, title } = this.props;

    return (
      <div className="btnContainer">
        <div className="iconButton">
          <img alt="" className="icon" src={svgIcon} />
        </div>
        <Button value={account} onClick={onClick} className="button">
          {title}
        </Button>
      </div>
    );
  }
}

export default LoginButton;
