import React, { FC, useEffect, useState } from 'react';
import { Stack, Text } from '../common';
import { DateRangePicker } from '../../routes/incidents/date-picker-component/DatePickerComponent';
import { Button } from './Button';
import ReactTooltip from 'react-tooltip';
import { NoCategoriesFoundCmp } from './CategoriesByMostCases';
import { CategoryBar } from '../../routes/categories/types';
import { Loader } from '../common/blocks/Loader';
import { useDateRangeQuery } from '../../routes/categories/utils';
import { CategoryTypeSelection } from '../../routes/categories/components/categoryTypeComponent/CategoryTypeSelection';
import { Switch } from '../switch/Switch';
import { TablePaper } from '../common/blocks/TablePaper';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../utils/constants';

type CategoriesByMostMttrData = {
  inDashboardTab?: boolean;
};

type CategoryBarChartData = {
  dataTop: CategoryBar[];
};

const ORDER = ['L2H', 'H2L'];

export const CategoriesByMostMttr: FC<CategoriesByMostMttrData> = (props) => {
  const { inDashboardTab = true } = props;

  const itemsCount = 5;

  const [dateRangeByMostMttr, setDateRangeByMostMttr] = useState<[Date, Date]>([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE
  ]);
  const [filterField, setFilterField] = useState('CASES');

  const {
    data: categoriesDataByMostMttr,
    status: statusByMostMttr
  } = useDateRangeQuery(dateRangeByMostMttr);

  const categoriesDataByMostMttrFiltered = categoriesDataByMostMttr?.caseCategoriesData.filter(
    (item) => item.name !== 'Uncategorized'
  );

  const top5CategoriesByMttr = categoriesDataByMostMttrFiltered
    ?.sort((cat1, cat2) =>
      (cat1.mttr.mttr || 0) - (cat2.mttr.mttr || 0) > 0 ? -1 : 1
    )
    .slice(0, itemsCount);
  const top5CategoriesByComments = categoriesDataByMostMttrFiltered
    ?.sort((cat1, cat2) =>
      (cat1.averageNumberOfComments || 0) -
        (cat2.averageNumberOfComments || 0) >
      0
        ? -1
        : 1
    )
    .slice(0, itemsCount);
  const top5CategoriesByCases = categoriesDataByMostMttrFiltered
    ?.sort((cat1, cat2) => ((cat1.count || 0) - (cat2.count || 0) > 0 ? -1 : 1))
    .slice(0, itemsCount);
  const top5CategoriesByCsatH2L = categoriesDataByMostMttrFiltered
    ?.sort((cat1, cat2) =>
      (cat1.csat.score || 0) - (cat2.csat.score || 0) > 0 ? -1 : 1
    )
    .slice(0, itemsCount);

  const top5CategoriesByCsatL2H = categoriesDataByMostMttrFiltered
    ?.sort((cat1, cat2) =>
      (cat2.csat.score || 0) - (cat1.csat.score || 0) > 0 ? -1 : 1
    )
    .slice(0, itemsCount);

  const top5CategoriesBySentimentH2L = categoriesDataByMostMttrFiltered
    ?.sort((cat1, cat2) =>
      (cat1.averageSentimentScore || 0) - (cat2.averageSentimentScore || 0) > 0
        ? -1
        : 1
    )
    .slice(0, itemsCount);

  const top5CategoriesBySentimentL2H = categoriesDataByMostMttrFiltered
    ?.sort((cat1, cat2) =>
      (cat2.averageSentimentScore || 0) - (cat1.averageSentimentScore || 0) > 0
        ? -1
        : 1
    )
    .slice(0, itemsCount);

  const [top5Categories, setTop5Categories] = useState(top5CategoriesByCases);

  useEffect(() => {
    setTop5Categories(top5CategoriesByCases);
    setFilterField('CASES');
    // eslint-disable-next-line
  }, [categoriesDataByMostMttr]);

  const onChangeType = (type: string, localOrder?: string) => {
    setFilterField(type);
    switch (type) {
      case 'MTTR':
        setTop5Categories(top5CategoriesByMttr);
        break;
      case 'COMMENTS':
        setTop5Categories(top5CategoriesByComments);
        break;
      case 'CSAT':
        if (localOrder === 'H2L') {
          setTop5Categories(top5CategoriesByCsatH2L);
        } else {
          setTop5Categories(top5CategoriesByCsatL2H);
        }
        break;
      case 'CASES':
        setTop5Categories(top5CategoriesByCases);
        break;
      case 'SENTIMENT':
        if (localOrder === 'H2L') {
          setTop5Categories(top5CategoriesBySentimentH2L);
        } else {
          setTop5Categories(top5CategoriesBySentimentL2H);
        }
        break;
      default:
        setTop5Categories(top5CategoriesByCases);
    }
  };

  const showNoCategoriesMessage = () => {
    return !top5Categories?.length;
  };

  const formatDataBarChart = top5Categories?.map((cate: any, index: any) => ({
    name: cate.name,
    count: cate.count,
    color: CATEGORIES_COLORS[index]
  }));

  const getFilterData = (category: any) => {
    switch (filterField) {
      case 'MTTR':
        return category.mttr.mttr
          ? `${category.mttr.mttr.toFixed(0)} Hrs`
          : '-';
      case 'COMMENTS':
        return category.averageNumberOfComments
          ? `${category.averageNumberOfComments}`
          : '-';
      case 'CSAT':
        return category.csat.score ? `${category.csat.score}%` : '0%';
      case 'CASES':
        return category.count ? `${category.count}` : '-';
      case 'SENTIMENT':
        return category.averageSentimentScore
          ? `${Math.round(category.averageSentimentScore * 100)}%`
          : '0';
      default:
        return category.averageNumberOfComments
          ? `${category.averageNumberOfComments}`
          : '0';
    }
  };
  const buttonLabels = ['CASES', 'COMMENTS', 'MTTR', 'CSAT', 'SENTIMENT'];
  const changeLocalOrder = (id: any) => {
    onChangeType(filterField, ORDER[id]);
  };

  return (
    <TablePaper
      style={{
        flex: 1,
        height: '100%',
        marginTop: inDashboardTab ? '2%' : '0'
      }}
    >
      <Stack direction="vertical" gap={16} style={{ paddingTop: 16 }}>
        {inDashboardTab ? (
          <Stack direction="horizontal" justify="space-between" align="center">
            <Text variant="p4" weight="bold" color="dark">
              Top 5 Categories by
            </Text>
            <CategoryTypeSelection
              onChangeType={onChangeType}
              selectedType={filterField}
              buttonLabels={buttonLabels}
            ></CategoryTypeSelection>
            <DateRangePicker
              value={dateRangeByMostMttr}
              onChange={setDateRangeByMostMttr}
            />
          </Stack>
        ) : (
          <Stack direction="vertical">
            <Stack
              direction="horizontal"
              justify="space-between"
              align="center"
            >
              <Text variant="p4" weight="bold" color="dark">
                Top 5 Categories by
              </Text>
              <DateRangePicker
                compact={true}
                value={dateRangeByMostMttr}
                onChange={setDateRangeByMostMttr}
              />
            </Stack>
            <div style={{ marginLeft: -20, marginTop: '2%' }}>
              <CategoryTypeSelection
                onChangeType={onChangeType}
                selectedType={filterField}
                buttonLabels={buttonLabels}
              ></CategoryTypeSelection>
            </div>
          </Stack>
        )}

        {statusByMostMttr === 'loading' && (
          <div style={{ marginTop: '-5%' }}>
            <Loader center />
          </div>
        )}
        {showNoCategoriesMessage() ? (
          <NoCategoriesFoundCmp />
        ) : (
          <Stack
            direction={inDashboardTab ? 'horizontal' : 'vertical'}
            gap={30}
          >
            <Stack
              style={{
                flexBasis: '35%',
                height: '200px',
                padding: 20,
                alignSelf: 'center'
              }}
            >
              {top5Categories && (
                <TopCategoriesBubbleChart
                  dateRange={dateRangeByMostMttr}
                  categories={top5Categories.map((category: any) => ({
                    name: category.name,
                    count: category.count,
                    caseChangePercentage: category.casesChangePercent,
                    mttr: category.mttr.mttr
                  }))}
                />
              )}
            </Stack>
            <Stack style={{ flexBasis: '65%' }} direction="vertical" gap={16}>
              <Stack direction="vertical" gap={16}>
                <CategoryBarChart dataTop={formatDataBarChart || []} />
              </Stack>
              <Stack direction="vertical" gap={16}>
                <Stack
                  direction="horizontal"
                  style={{
                    background: 'rgba(245, 245, 250, 0.4)',
                    padding: '15px 24px'
                  }}
                >
                  <Text
                    style={{ flexBasis: '50%', color: '#8181A5' }}
                    variant="p0"
                    weight="semi-bolder"
                  >
                    Top 5 categories
                  </Text>

                  {filterField === 'SENTIMENT' && (
                    <Switch
                      buttons={['Low', 'High']}
                      onChange={changeLocalOrder}
                      defaultSelection={0}
                    />
                  )}
                  {filterField === 'CSAT' && (
                    <Switch
                      buttons={['Low', 'High']}
                      onChange={changeLocalOrder}
                      defaultSelection={0}
                    />
                  )}
                  <Text
                    style={{ flexBasis: '25%', color: '#8181A5' }}
                    variant="p0"
                    weight="semi-bolder"
                  >
                    {filterField !== 'CASES'
                      ? filterField !== 'CSAT' && filterField !== 'MTTR'
                        ? filterField.charAt(0) +
                          filterField
                            .substring(1, filterField.length)
                            ?.toLowerCase()
                        : filterField
                      : ''}
                  </Text>

                  <Text
                    style={{
                      flexBasis: '25%',
                      color: '#8181A5'
                    }}
                    variant="p0"
                    weight="semi-bolder"
                  >
                    Cases
                  </Text>
                </Stack>
                <Stack direction="vertical" gap={20}>
                  {top5Categories?.map((category: any, index) => (
                    <Stack direction="horizontal" gap={30}>
                      <Stack
                        style={{ flexBasis: '50%' }}
                        direction="horizontal"
                        gap={15}
                      >
                        <Stack
                          style={{
                            backgroundColor: CATEGORIES_COLORS[index],
                            width: 20,
                            height: 20,
                            borderRadius: '50%'
                          }}
                        />
                        <Text style={{ color: '#8181A5' }} maxWidth="200px">
                          {category.name}
                        </Text>
                      </Stack>

                      <Stack
                        style={{ flexBasis: '25%' }}
                        direction="horizontal"
                        gap={7}
                      >
                        {filterField !== 'CASES' && (
                          <Text
                            color="dark"
                            weight="semi-bolder"
                            style={{ width: '33%' }}
                          >
                            {getFilterData(category)}
                          </Text>
                        )}
                        {filterField === 'CSAT' && (
                          <Text
                            variant="p0"
                            weight="regular"
                            color="green"
                            style={{
                              marginInlineStart: '2px',
                              alignSelf: 'center'
                            }}
                          >
                            {category.csat.totalSurveyCount
                              ? category.csat.totalSurveyCount + ' Surveys'
                              : ' '}
                          </Text>
                        )}
                        {/* <ChangeIndicator
                          dateRange={dateRangeByMostMttr}
                          value={category.mttrChangePercent}
                        /> */}
                      </Stack>

                      <Stack
                        style={{ flexBasis: '25%', alignItems: 'center' }}
                        direction="horizontal"
                        gap={7}
                      >
                        <Text
                          color="dark"
                          weight="semi-bolder"
                          style={{ width: '20%' }}
                        >
                          {category.count}
                        </Text>
                        {/* <ChangeIndicator
                          dateRange={dateRangeByMostMttr}
                          value={category.escalatedCasesChangePercent}
                        /> */}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Stack
                style={{ marginTop: 25 }}
                direction="horizontal"
                align="center"
                justify="center"
              >
                {inDashboardTab && (
                  <Button href="/overview" variant="outlined">
                    View all Categories
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </TablePaper>
  );
};

const CATEGORIES_COLORS = [
  '#E6EBFF',
  '#E4FAEE',
  '#FDF5E5',
  '#FFECEC',
  '#D5FAFF'
];

type CategoryBubbleData = {
  name: string;
  caseChangePercentage: number | null;
  count: number;
  mttr: number | null;
};

const TopCategoriesBubbleChart: FC<{
  categories: CategoryBubbleData[];
  dateRange: [Date, Date];
}> = (props) => {
  const { categories, dateRange } = props;
  const categoriesSortedByCasesCount = categories
    .map((cat, index) => ({ ...cat, color: CATEGORIES_COLORS[index] }))
    .sort((cat1, cat2) => (cat1.count - cat2.count > 0 ? -1 : 1));
  return (
    <div style={{ position: 'relative', width: 400, height: 'inherit' }}>
      {categoriesSortedByCasesCount.map((category, index) => (
        <CategoryBubble
          index={index}
          category={category}
          dateRange={dateRange}
        />
      ))}
    </div>
  );
};

const CategoryBubble: FC<{
  index: number;
  category: CategoryBubbleData & { color: string };
  dateRange: [Date, Date];
}> = (props) => {
  const { index, category } = props;
  const radii = [70, 60, 50, 40, 30];
  const radius = radii[index];
  const color = category.color;
  const top = [
    `calc(50% - ${radius}px)`,
    `calc(80% - ${radius}px)`,
    `calc(70% - ${radius}px)`,
    `calc(20% - ${radius}px)`,
    `calc(25% - ${radius}px)`
  ];
  const left = [
    `calc(50% - ${radius}px)`,
    `calc(70% - ${radius}px)`,
    `calc(25% - ${radius}px)`,
    `calc(29% - ${radius}px)`,
    `calc(65% - ${radius}px)`
  ];
  const font = [
    [36, 47],
    [32, 42],
    [24, 31],
    [20, 26],
    [18, 23]
  ];
  return (
    <>
      <Stack
        data-tip
        data-for={category.name}
        align="center"
        justify="center"
        style={{
          width: radius * 2,
          height: radius * 2,
          borderRadius: '50%',
          background: color,
          position: 'absolute',
          top: top[index],
          left: left[index],
          border: `${index * 2}px solid white`
        }}
      >
        <Text
          style={{
            fontSize: font[index][0],
            lineHeight: `${font[index][1]}px`,
            color: '#1F3271'
          }}
        >
          {category.count}
        </Text>
      </Stack>
      <ReactTooltip
        className="custom-tooltip-styles"
        id={category.name}
        place="top"
        backgroundColor="transparent"
        effect="solid"
      >
        <Stack
          direction="vertical"
          style={{
            boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.1)',
            padding: 15,
            border: '1px solid #D7D7D7',
            background: 'white'
          }}
          gap={10}
        >
          <Text>{category.name}</Text>
          <Stack direction="horizontal" gap={10}>
            <Text weight="semi-bolder">{category.count} Cases</Text>
            {/* <ChangeIndicator
              dateRange={dateRange}
              value={category.caseChangePercentage}
            /> */}
          </Stack>
        </Stack>
      </ReactTooltip>
    </>
  );
};

const CategoryBarChart: FC<CategoryBarChartData> = (props) => {
  const { dataTop } = props;
  let totalCountTopCategories = 0;
  const categoriesSortedByCasesCount = dataTop
    .map((cat: any, index: any) => {
      totalCountTopCategories = totalCountTopCategories + cat.count;
      return { ...cat, color: CATEGORIES_COLORS[index] };
    })
    .sort((cat1: any, cat2: any) => (cat1.count - cat2.count > 0 ? -1 : 1));
  return (
    <div style={{ width: '90%', display: 'flex' }}>
      {categoriesSortedByCasesCount?.map((category, index) => (
        <div
          key={index}
          style={{
            background: category.color,
            height: 7,
            marginRight: 2,
            width: `${(
              (category.count / totalCountTopCategories) *
              100
            ).toFixed(2)}%`
          }}
        >
          {/* {category.count} */}
        </div>
      ))}
    </div>
  );
};
