import {
  CustomersWithLowestHealthScores,
  DashboardSummary,
  ImportantSupportMetricsData,
  RevenueImpact
} from '../../components/home/types';
import MockData from '../../utils/mockData';
import Utils from '../../utils/utils';
import {handleError, isMocKData} from './utils';
import _ from 'lodash';
import {CustomerHealthDataV2} from '../../routes/customerHealth/types';


type AttributeCaseDriversInfo = {
  show: boolean;
  attribute: string;
  caseCounts: {
    name: string;
    count: number;
  }[];
};
export const getAttributeValueCaseDrivers = async (
  startDate: Date,
  endDate: Date
): Promise<AttributeCaseDriversInfo> => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return { show: false, attribute: 'test', caseCounts: [] };
  }

  const params = new URLSearchParams({
    customerId: user.customerId,
    startTime: Utils.formatDateToUTC(startDate.toString()),
    endTime: Utils.formatDateToUTC(endDate.toString())
  });
  const url = `${process.env.REACT_APP_BACKEND_URL}/dashboard/getAttributeCaseDrivers?${params}`;

  const response = await fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`
    })
  });

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Unhandled response code!');
  }

  return await response.json();
};

export const getDashboardSummary = async (
  startDate: Date,
  endDate: Date
): Promise<DashboardSummary> => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return MockData.getDashboardSummaryMockData();
  }

  const params = new URLSearchParams({
    customerId: user.customerId,
    startTime: Utils.formatDateToUTC(startDate.toString()),
    endTime: Utils.formatDateToUTC(endDate.toString())
  });
  const url = `${process.env.REACT_APP_BACKEND_URL}/dashboard/v2/summary?${params}`;

  const response = await fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`
    })
  });

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Unhandled response code!');
  }

  const body: DashboardSummary = await response.json();
  return body;
};

export const getCustomersWithLowestHealthScores = async (
  limit?: number
): Promise<CustomersWithLowestHealthScores> => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  if (user.email === 'dmitry@irisagent.com') {
    return _getCustomersWithLowestHealthScores(limit);
  }

  if (isMocKData) {
    return MockData.getCustomersWithLowestHealthScoresMockData();
  }

  const params = new URLSearchParams({
    customerId: user.customerId
  });
  if (!_.isNil(limit)) {
    params.set('limit', _.toString(limit));
  }

  const url = `${process.env.REACT_APP_BACKEND_URL}/dashboard/v2/accounts/health/lowest?${params}`;

  const response = await fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`
    })
  });

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Unhandled response code!');
  }

  const body: CustomersWithLowestHealthScores = await response.json();
  return body;
};

export const _getCustomersWithLowestHealthScores = async (
  limit?: number
): Promise<CustomersWithLowestHealthScores> => {
  // There is an existing API endpoint with the same data, but with a different element name.
  // Existing endpoint contains multiple elements, but we only need one. There are no issues for reusing it for now.

  if (isMocKData) {
    return MockData.getCustomersWithLowestHealthScoresMockData();
  }

  const tokenExpress = Utils.getObjectItem('token_express');

  const params = new URLSearchParams({});

  if (!_.isNil(limit)) {
    params.set('limit', _.toString(limit));
  }

  let endpoint = `${process.env.REACT_APP_APISERVER_URL}/v1/customer/health?${params}`;

  const response = await fetch(endpoint, {
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`
    })
  });

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Unhandled response code!');
  }

  const body: CustomerHealthDataV2 = await response.json();

  function convert(
    from: CustomerHealthDataV2
  ): CustomersWithLowestHealthScores {
    if (
      from &&
      from.customerHealth &&
      from.customerHealth.customerLowestHealthScores.length > 0
    ) {
      return {
        customerAccounts: from.customerHealth.customerLowestHealthScores
      };
    }

    return { customerAccounts: [] };
  }

  return convert(body);
};

export const getImportantSupportMetrics = async (
  startTime: any,
  endTime: any,
  attributeFilter?: {}
) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  const startTimeUTC = Utils.formatDateToUTC(startTime);
  const endTimeUTC = Utils.formatDateToUTC(endTime);

  if (isMocKData) {
    return MockData.getImportantSupportMetricsMockData();
  }
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/dashboard/v2/important-support-metrics?customerId=${user.customerId}&&startTime=${startTimeUTC}&&endTime=${endTimeUTC}`,
    {
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`
      })
    }
  );

  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }

  const body: ImportantSupportMetricsData = await response.json();
  return body;
};

export const getRevenueImpact = async (
  startTime: any,
  endTime: any,
  orderBy: string
) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  const startTimeUTC = Utils.formatDateToUTC(startTime);
  const endTimeUTC = Utils.formatDateToUTC(endTime);

  if (isMocKData) {
    return MockData.getRevenueImpactMockData(orderBy);
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/dashboard/v2/impact/revenue?customerId=${user.customerId}&&startTime=${startTimeUTC}&&endTime=${endTimeUTC}&by=${orderBy}`,
    {
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`
      })
    }
  );

  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (response.status === 400) {
    // const resp = await response.json();
    // throw new Error();
    const emptyObj: RevenueImpact = {
      impact: {
        graph: [],
        cumulative: {}
      }
    };
    return emptyObj;
  }
  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }

  const body: RevenueImpact = await response.json();
  return body;
  // return responseInterceptor(body);
};
