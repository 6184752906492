import React, { useEffect, useState } from 'react';
import './Tool.scss';
import { Tool } from './Tool';
import { ReactComponent as SuggestedResolution } from '../../../../assets/checkCircle.svg';
import { ReactComponent as Bell } from '../../../../assets/bell.svg';
import { ReactComponent as UserPlus } from '../../../../assets/userPlus.svg';
import { ReactComponent as AssignTeam } from '../../../../assets/AssignTeam.svg';
import { ReactComponent as Trello } from '../../../../assets/trello.svg';
import { ReactComponent as Action } from '../../../../assets/mousePointer.svg';
import { ReactComponent as Priority } from '../../../../assets/priority.svg';
import { ReactComponent as AutoRespond } from '../../../../assets/auto_respond.svg';
import { ReactComponent as AddInternalNote } from '../../../../assets/addInternalNote.svg';
import { ReactComponent as TagSvg } from '../../../../assets/tag.svg';
import { ReactComponent as CustomField } from '../../../../assets/CustomField.svg';
import { Option } from '../../types';
import Utils from '../../../../utils/utils';

type Props = {
  conditionToolClicked: (condition: string) => void;
  toolItems: Option[];
};

export const ActionTool: React.FC<Props> = (props) => {
  const [filteredActionRules, setFilteredActionRules] = useState(
    props.toolItems
  );
  const headerIcon = () => {
    return <Action stroke="#F2F2F2" />;
  };
  const getIcon = (iconName: string) => {
    switch (iconName) {
      case 'suggested_resolution':
        return <SuggestedResolution />;
      case 'alert':
        return <Bell />;
      case 'change_case_assignee':
        return <UserPlus />;
      case 'change_case_assignee_team':
        return <AssignTeam />;
      case 'change_case_status':
        return <Trello />;
      case 'change_case_priority':
        return <Priority />;
      case 'reply_to_case':
        return <AutoRespond />;
      case 'write_case_tags':
        return <TagSvg />;
      case 'write_case_custom_field':
        return <CustomField />;
      case 'add_internal_note':
        return <AddInternalNote />;
      default:
        return;
    }
  };

  useEffect(() => {
    const user = Utils.getObjectItem('user');
    if (user) {
      setFilteredActionRules(
        props.toolItems.filter((rule: Option) => {
          if (rule.hidden && rule.hidden.length > 0) {
            if (rule.hidden?.includes(user.sourceName) === false) {
              return rule;
            }
            return null;
          } else {
            return rule;
          }
        })
      );
    }
  }, [props.toolItems]);

  return (
    <Tool
      headerColor="#EF5DA8"
      heading="Actions"
      toolItems={filteredActionRules}
      getIcon={getIcon}
      headerIcon={headerIcon}
      conditionToolClicked={props.conditionToolClicked}
    ></Tool>
  );
};
