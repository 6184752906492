import React, { Component } from 'react';
import './ReportingService.scss';
import SimpleTimeline from '../../../components/simple-timeline/SimpleTimeline';
import Constants from '../../../utils/constants';
import { logEvent } from '../../../utils/amplitude';
import Utils from '../../../utils/utils';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { Stack, Text } from '../../../components/common';

interface Props {
  data: any;
  goBack: any;
}

class ReportingService extends Component<Props, any> {
  onClickLink = (url: string, reportingData: any) => {
    console.log('link: ', url);
    if (url) {
      // Only open new Tab when url is not empty
      let user = Utils.getObjectItem('user');
      // When user makes click in "Source" value
      logEvent('Go on Incident Details', {
        email: user.email,
        organizationId: user.sourceOrganizationId,
        incidentName: reportingData.data.title,
        // TODO: Is this ok?
        incidentType: reportingData.data.incidentSource
      });
    }
  };

  render() {
    const { data, goBack } = this.props;
    const rowData = data.data;
    const imagesMap: { [key: string]: any } = Constants.incidentImagesMap;
    let imageKey = rowData.incidentSource;
    const imageWidth =
      rowData.incidentSource === Constants.JIRA_KEY ? '75px' : '115px';
    let title =
      rowData.incidentSource === Constants.JIRA_KEY
        ? `${rowData.issueKey}${
            rowData.summary || rowData.description
              ? `: ${rowData.summary || rowData.description}`
              : ''
          }`
        : rowData.title; // Use JIRA issueKey as title if a jira issue is being rendered
    if (rowData.incidentNumber > 0) {
      title = `${rowData.incidentNumber}: ${rowData.title}`;
    }
    let conditionalCategoryTitle;
    if (rowData.category && rowData.category.length > 0) {
      conditionalCategoryTitle = 'Category';
    }
    console.log(conditionalCategoryTitle);

    return (
      <Stack direction="vertical" gap={5}>
        <Stack align="center" gap={20}>
          <div
            onClick={goBack}
            style={{
              cursor: 'pointer',
              position: 'relative',
              width: 48,
              height: 48,
              borderRadius: 4,
              backgroundColor: '#5E81F4'
            }}
          >
            <ArrowBackOutlinedIcon
              style={{
                color: 'white',
                transform: 'translate(0%, 50%)'
              }}
            />
          </div>
          <a
            href={rowData.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', textAlign: 'left' }}
          >
            <Text
              onClick={() => this.onClickLink(rowData.link, data)}
              style={{
                cursor:
                  rowData.incidentSource === 'IrisAgent' ? 'default' : 'pointer'
              }}
              variant="p4"
              weight="semi-bolder"
              color="dark"
            >
              {title}
            </Text>
          </a>
          <a
            href={rowData.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Stack
              gap={5}
              align="center"
              onClick={() => this.onClickLink(rowData.link, data)}
              style={{
                background:
                  'linear-gradient(0deg, rgba(94, 129, 244, 0.05), rgba(94, 129, 244, 0.05)), #FFFFFF',
                borderRadius: '64px',
                padding: '10px',
                cursor:
                  rowData.incidentSource === 'IrisAgent' ? 'default' : 'pointer'
              }}
            >
              <Text variant="p0" weight="regular" color="lighter">
                Source
              </Text>
              <img alt="" src={imagesMap[imageKey]} width={imageWidth} />
            </Stack>
          </a>
        </Stack>
        <SimpleTimeline data={rowData} />
      </Stack>
    );
  }
}

export default ReportingService;
