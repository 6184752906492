import React from 'react';
import { Loader } from '../../components/common/blocks/Loader';
import { Stack, Text } from '../../components/common';
import AccountSearch from '../customerHealth/components/AccountSearch';
import { CACHE_KEYS, CACHE_TIME } from '../../utils/cacheUtils';
import IrisPortalService from '../../services/IrisPortalService';
import { useQuery } from 'react-query';
import Utils from '../../utils/utils';
import { useHistory } from 'react-router';

export const CustomerAccountSearch: React.FC = (props) => {
  const history = useHistory();

  const { data: customers, isLoading } = useQuery(
    [CACHE_KEYS.CUSTOMERS],
    () => IrisPortalService.getAllAccountNames(),
    {
      ...CACHE_TIME.get(CACHE_KEYS.CUSTOMERS)
    }
  );

  const [
    formattedCustomerAccounts,
    setFormattedCustomerAccounts
  ] = React.useState<any[]>();

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    setFormattedCustomerAccounts(
      customers?.allAccountNames?.map((nameCustomer: any) => ({
        name: nameCustomer
      }))
    );
  }, [isLoading, customers, setFormattedCustomerAccounts]);

  return (
    <React.Fragment>
      {!formattedCustomerAccounts ? (
        <Loader center />
      ) : (
        <div>
          <Stack
            direction="horizontal"
            align="center"
            style={{ backgroundColor: 'white', padding: '16px' }}
          >
            <Text
              variant="p4"
              weight="bold"
              color="dark"
              style={{ marginRight: '20px' }}
            >
              Search By Customer Name
            </Text>
            <AccountSearch
              deactivateDropDown={false}
              placeholder="Type here"
              deactivateTitle={true}
              withoutBorder={true}
              dataListSearch={formattedCustomerAccounts}
              dataList={customers?.allAccountNames || []}
              showListSearch={false}
              onlyTextItems={true}
              getOptionSelected={handleSelectTextSearch}
            />
          </Stack>
        </div>
      )}
    </React.Fragment>
  );

  function handleSelectTextSearch(optionSelected: string) {
    const toGo = Utils.getAccountHealthRoute(optionSelected);
    history.replace(toGo);
  }
};
