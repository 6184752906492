import React, { Component } from 'react';
import './KnowledgeBaseArticlesTable.scss';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import IrisPortalService from '../../../services/IrisPortalService';
import { Heading, IssueId, Stack } from '../../../components/common';
import { TablePaper } from '../../../components/common/blocks/TablePaper';

interface Props {
  data: any;
}

class KnowledgeBaseArticlesTable extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      articlesList: null
    };
  }

  async getKnowledgeBaseArticles(casesList: any) {
    const caseIdsList = casesList.map((caseObj: any) => caseObj.databaseId);
    const kbArticles = await IrisPortalService.getKnowledgeBaseArticles(
      caseIdsList
    );
    this.setState({ articlesList: kbArticles, isLoading: false });
  }

  componentWillMount() {
    const { data } = this.props;
    this.getKnowledgeBaseArticles(data);
  }

  render() {
    const { articlesList, isLoading } = this.state;
    let areAllSnippetsNull = false;
    if (articlesList) {
      let allSnippets = articlesList.map((article: any) => article.summary);
      areAllSnippetsNull = allSnippets.every(function (snippet: any) {
        return snippet === null || snippet.length === 0;
      });
    }

    return (
      <React.Fragment>
        {isLoading && (
          <div>
            <img
              className="loading"
              alt=""
              width="55px"
              src="/images/spinner.gif"
            />
          </div>
        )}
        {!isLoading && articlesList.length > 0 && (
          <TablePaper>
            <Stack direction="vertical" gap={20} style={{ marginTop: 16 }}>
              <Heading>Relevant knowledge base articles</Heading>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow className="table-row-sorting">
                      <TableCell
                        align="left"
                        width={'20%'}
                        style={{
                          opacity: 0.6,
                          fontSize: '12px'
                        }}
                      >
                        Article ID
                      </TableCell>

                      <TableCell
                        align="left"
                        width={'30%'}
                        style={{
                          opacity: 0.6,
                          fontSize: '12px'
                        }}
                      >
                        Title
                      </TableCell>

                      {!areAllSnippetsNull && (
                        <TableCell
                          align="left"
                          width={'50%'}
                          style={{
                            opacity: 0.6,
                            fontSize: '12px'
                          }}
                        >
                          Preview
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {articlesList.map((row: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell>
                          <a
                            href={row.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IssueId color="#58508D">{row.articleId}</IssueId>
                          </a>
                        </TableCell>
                        <TableCell>{row.title}</TableCell>
                        {!areAllSnippetsNull && (
                          <TableCell>{row.summary}</TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </TablePaper>
        )}
      </React.Fragment>
    );
  }
}

export default KnowledgeBaseArticlesTable;
