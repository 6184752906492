import React, { FC, useCallback } from 'react';
// import { RulesTrigger } from '../RulesTrigger';
import './styles.scss';
import ArrowRight from '@material-ui/icons/ArrowRightOutlined';
import Constants from '../../../../utils/constants';
import { useHistory } from 'react-router-dom';

type Template = {
  img: any;
  name: string;
};

export const Template: FC<Template> = (props) => {
  const { img, name } = props;
  const history = useHistory();
  const goToActionsTrigger = useCallback(() => {
    history.push(Constants.routes.TRIGGERS_DETAILS + '?templateId=' + name);
  }, [history, name]);

  return (
    <div className="template">
      <div onClick={goToActionsTrigger} style={{ cursor: 'pointer' }}>
        {img}
      </div>
      <div className="template-footer" onClick={goToActionsTrigger}>
        <p style={{ cursor: 'pointer' }}>Get Started</p>
        <ArrowRight style={{ alignSelf: 'center', cursor: 'pointer' }} />
      </div>
    </div>
  );
};
