import React, { useCallback } from 'react';

import { Box, Input, Tooltip } from '@material-ui/core';

import { Text } from '../../../../../components/common';

import { ReactComponent as ActiveStatus } from '../../../../../assets/active-status.svg';
import { ReactComponent as InactiveStatus } from '../../../../../assets/inactive-status.svg';

import {
  DetectedIncidentsSetting,
  DetectedIncidentsSettingStatus
} from '../types';

import './styles.scss';
import _ from 'lodash';
import SearchWidget from '../../../../categories/components/SearchWidget';
import { useQuery } from 'react-query';
import { CACHE_KEYS, CACHE_TIME } from '../../../../../utils/cacheUtils';
import IrisPortalService from '../../../../../services/IrisPortalService';
import { Button } from '../../../../../components/home/Button';
import Utils from '../../../../../utils/utils';

interface IDetectedIncidentsManagementTableItemProps {
  item?: DetectedIncidentsSetting;
  onSuccess: Function;
}

export const DetectedIncidentsSettingsInputForm: React.FC<IDetectedIncidentsManagementTableItemProps> = ({
  onSuccess,
  item
}) => {
  const [inputCategoryState, setInputCategoryState] = React.useState(
    item ? item?.settings.category : ''
  );
  const [inputTimeIntervalState, setInputTimeIntervalState] = React.useState(0);
  const [inputCaseThresholdState, setInputCaseThresholdState] = React.useState(
    0
  );

  const { data, isLoading } = useQuery(
    [CACHE_KEYS.DETECTED_INCIDENTS_SETTINGS_PREREQUISITES],
    () => IrisPortalService.getDetectedIncidentsSettingsPrerequisites(),
    {
      ...CACHE_TIME[CACHE_KEYS.DETECTED_INCIDENTS_SETTINGS_PREREQUISITES]
    }
  );

  const upsertDetectedIncidentSetting = useCallback(async () => {
    const getSettingsObj = (
      category: string,
      caseThreshold: number,
      timeInterval: number
    ) => {
      return {
        category: inputCategoryState,
        case_threshold: inputCaseThresholdState,
        time_interval: inputTimeIntervalState
      };
    };

    if (_.isNil(item)) {
      // Create
      const settingObj = {
        name: inputCategoryState,
        status: DetectedIncidentsSettingStatus.ENABLED,
        settings: getSettingsObj(
          inputCategoryState,
          inputCaseThresholdState,
          inputTimeIntervalState
        )
      };
      try {
        await IrisPortalService.createDetectedIncidentSetting(settingObj);
        Utils.showNotify('Create Success');
        onSuccess();
      } catch (e) {
        Utils.showNotify(`Something went wrong while creating setting.`);
      }
    } else {
      // Update
      const updatedObj = { ...item };
      updatedObj['settings'] = getSettingsObj(
        inputCategoryState,
        inputCaseThresholdState,
        inputTimeIntervalState
      );
      try {
        await IrisPortalService.editDetectedIncidentSetting(updatedObj);
        Utils.showNotify('Update Success');
        onSuccess();
      } catch (e) {
        Utils.showNotify(`Something went wrong while updating setting.`);
      }
    }
  }, [
    item,
    inputCategoryState,
    inputTimeIntervalState,
    onSuccess,
    inputCaseThresholdState
  ]);

  return (
    <React.Fragment>
      {isLoading && (
        <img
          className="loading"
          alt=""
          width="55px"
          src="/images/spinner.gif"
        />
      )}
      {!isLoading && (
        <div>
          <Box
            bgcolor={`rgba(159, 161, 255, 0.05)`}
            padding={'20px'}
            marginBottom={'20px'}
          >
            <Text>
              Create a trending incident when the number of cases matching the
              below Category/Tag
            </Text>
            <Tooltip
              title={'detected-incidents=category-search'}
              placement="top"
            >
              <div className={'search-selector'}>
                <SearchWidget
                  isCustom={false}
                  searchValue={inputCategoryState}
                  dataList={data.categories}
                  onInputChange={(input: string) => {
                    setInputCategoryState(input);
                  }}
                />
              </div>
            </Tooltip>
            <br />
            <br />
            <Text>exceed the threshold </Text>
            <br />
            <Input
              onChange={(e) => {
                setInputCaseThresholdState(parseInt(e.target.value));
              }}
              defaultValue={item?.settings.case_threshold}
              type="number"
            ></Input>
            <br />
            <br />
            <br />
            <Text>within a time interval of </Text>
            <br />
            <Input
              onChange={(e) => {
                setInputTimeIntervalState(parseFloat(e.target.value));
              }}
              defaultValue={item?.settings.time_interval}
              type="number"
            ></Input>
            <Text> days</Text>

            <div
              style={{
                marginTop: '2em'
              }}
            >
              {(_.isEqual(
                item?.status,
                DetectedIncidentsSettingStatus.ENABLED
              ) && <ActiveStatus />) ||
                (_.isEqual(
                  item?.status,
                  DetectedIncidentsSettingStatus.DISABLED
                ) && <InactiveStatus />)}
            </div>

            <div>
              <div className={'ml-auto width-fc'}>
                <Button
                  className="button_details"
                  variant="contained"
                  onClick={() => upsertDetectedIncidentSetting()}
                >
                  {item ? 'Update Incident' : 'Create Incident'}
                </Button>
              </div>
            </div>
          </Box>
        </div>
      )}
    </React.Fragment>
  );
};
