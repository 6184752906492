import Utils from '../../utils/utils';
import { Source } from '../../utils/constants';

export const onLoginSalesforce = async (sandbox: boolean) => {
  const loginMode = sandbox ? 'sandbox' : 'production';
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/oauth/salesforce?loginMode=${loginMode}`
  );
  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  window.open(body.uri, '_self');
};

export const onLoginZendesk = async (authorization_code: string) => {
  let zendeskSubdomain = Utils.getObjectItem('zendeskSubdomain');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      zendesk_subdomain: zendeskSubdomain,
      authorization_code: authorization_code
    }),
    credentials: 'include'
  } as RequestInit;

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/login/zendesk`,
    requestOptions
  );
  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
};

export const onLoginGoogle = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/oauth/google`,
    {
      credentials: 'include'
    } as RequestInit
  );
  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  window.open(body.uri, '_self');
};

export const onLoginMicrosoft = async () => {
  window.open(`${process.env.REACT_APP_BACKEND_URL}/oauth/microsoft`, '_self');
};

export const getUserInfo = async (sourceName: any) => {
  let user = Utils.getObjectItem('user');
  let data: any = {};

  let url = `${process.env.REACT_APP_BACKEND_URL}/user-info`;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  } as RequestInit;

  if (sourceName === 'Zendesk') {
    let zendeskSubdomain = Utils.getObjectItem('zendeskSubdomain');
    data.zendesk_subdomain = zendeskSubdomain;
    data.google_email = user.data.email;
  } else if (sourceName === 'ZendeskChat') {
    let zendeskChatSubdomain = Utils.getObjectItem('zendeskChatSubdomain');
    data.zendesk_chat_subdomain = zendeskChatSubdomain;
    data.google_email = user.data.email;
  } else if (sourceName === 'Freshdesk') {
    let freshdeskSubdomain = Utils.getObjectItem('freshdeskSubdomain');
    data.freshdeskSubdomain = freshdeskSubdomain;
    data.google_email = user.data.email;
  } else if (sourceName === 'Zoho') {
    data.google_email = user.data.email;
  } else if (sourceName === 'Jira') {
    data.google_email = user.data.email;
  } else if (sourceName === 'Intercom') {
    data.google_email = user.data.email;
  } else if (sourceName === Source.IRIS_GPT) {
    data.google_email = user.data.email;
  }

  requestOptions.credentials = 'include' as RequestCredentials;
  requestOptions.body = JSON.stringify(data);
  const response = await fetch(
    `${url}?sourceName=${sourceName}`,
    requestOptions
  );
  const userInfo = await response.json();
  if (response.status !== 200) throw Error(userInfo.message);
  return userInfo;
};
