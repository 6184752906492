import { makeStyles } from '@material-ui/core/styles';

export const useSelectStyles = makeStyles((theme) => ({
  placeholder: {
    color: '#000'
  },
  select: {
    minWidth: 195
  }
}));
