import React, { Component } from 'react';
import './IncidentsDetails.scss';
import IrisPortalService from '../../../services/IrisPortalService';
import BadgeInformation from '../badge-information/BadgeInformation';
import ReportingService from '../reporting-service/ReportingService';
import NegativeScoreTable from '../negative-score-table/NegativeScoreTable';
import ThumbsUpDownComponent from '../thumbs-up-down-component/ThumbsUpDownComponent';
import { Button, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Utils from '../../../utils/utils';
import Constants from '../../../utils/constants';
import KnowledgeBaseArticlesTable from '../knowledge-base-articles-table/KnowledgeBaseArticlesTable';
import { RouteComponentProps } from 'react-router';
import { IncidentCasesDistribution } from '../../../components/incidentCasesDistributionGraph/IncidentCasesDistribution';
import { PageContainer } from '../../../components/common/blocks/PageContainer';
import { Stack } from '../../../components/common';
import { scrollToTop } from '../../../utils/scrollToTop';

class IncidentsDetails extends Component<
  RouteComponentProps<{ incidentSource: string; incidentId: string }>,
  any
> {
  constructor(props: any) {
    super(props);
    let user = Utils.getObjectItem('user');
    this.state = {
      detailsData: {},
      sourceName: user.sourceName,
      acceptanceStatus: null,
      isLoading: false
    };
  }

  async componentDidMount() {
    // Scroll to the top of the page, whenever navigated back to it
    scrollToTop();

    const { incidentSource, incidentId } = this.props.match.params;
    const casesType = new URLSearchParams(this.props.location.search).get(
      Constants.CASES_TYPE_QUERY_PARAM
    );

    this.setState({ isLoading: true });
    try {
      const apiPromise =
        incidentSource === Constants.JIRA_KEY
          ? IrisPortalService.getJIRAIssueDetails(
              incidentId,
              casesType || undefined
            )
          : IrisPortalService.getIncidentDetails(incidentSource, incidentId);
      const detailsData = { data: await apiPromise };
      const acceptanceStatus =
        Constants.DETECTED_INCIDENTS_KEY === incidentSource
          ? (detailsData.data || {}).acceptanceStatus?.toLowerCase()
          : null; // lowercase acceptanceStatus value

      if (incidentSource === Constants.JIRA_KEY) {
        detailsData.data.incidentSource = Constants.JIRA_KEY;
        detailsData.data.link = detailsData.data.url;
      }

      this.setState({
        detailsData: detailsData,
        acceptanceStatus: acceptanceStatus
      });
    } catch (e) {
      console.log('Incident Details Page Error: ', e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  goBackToIncidents = () => {
    this.props.history.goBack();
  };

  thumbsImplementation = async (newAcceptanceStatus: string) => {
    let finalAcceptanceStatus,
      currentAcceptanceStatus = this.state.acceptanceStatus;
    finalAcceptanceStatus =
      newAcceptanceStatus === currentAcceptanceStatus
        ? Constants.ACCEPTANCE_STATUS_PENDING
        : newAcceptanceStatus;
    this.setState({ acceptanceStatus: finalAcceptanceStatus });
    await IrisPortalService.updateAcceptanceStatus(
      this.state.detailsData.data.incidentId,
      finalAcceptanceStatus
    );
  };

  async createProblemTicket(data: any, incidentSource: string) {
    var affectedCaseNumbers = '';
    data.cases.filter(Boolean).forEach((c: any) => {
      affectedCaseNumbers += `${c.Id},`;
    });
    let problemTicketResponse = await IrisPortalService.createProblemTicket(
      data.incidentId,
      incidentSource,
      data.title,
      data.serviceSummary,
      data.created_at,
      affectedCaseNumbers
    );
    let detailsData = this.state.detailsData;
    detailsData.data.problemTicket = {
      ticketId: problemTicketResponse.ticketId,
      ticketUrl: problemTicketResponse.ticketUrl
    };
    this.setState({ detailsData: detailsData });
  }

  renderProblemTicketHeader = (detailsData: any) => {
    // Render create/display problem ticket header only for Zendesk customers
    if (this.state.sourceName !== 'Zendesk') {
      return;
    }
    let data = detailsData.data || {};
    let disableCreateProblemTicket =
      !!data.problemTicket && !!data.problemTicket.ticketId;
    return disableCreateProblemTicket ? (
      <a
        href={data.problemTicket.ticketUrl}
        className="problem-ticket-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Problem Ticket #{data.problemTicket.ticketId}
      </a>
    ) : (
      <Button
        onClick={this.createProblemTicket.bind(
          this,
          data,
          detailsData.data.incidentSource
        )}
        variant="contained"
        className="basic-button problem-ticket-container"
        style={{ backgroundColor: '#5D5FEF', color: '#F2F2F2' }}
        startIcon={<AddIcon />}
      >
        Create Problem Ticket
      </Button>
    );
  };

  render() {
    const { detailsData, acceptanceStatus, isLoading } = this.state;
    const isJiraIssueData =
      detailsData.data?.incidentSource === Constants.JIRA_KEY;
    if (isJiraIssueData) {
      // For JIRA issues, set timeOfOccurrence field equal to timeOfCreation
      detailsData.data.timeOfOccurrence = detailsData.data.timeOfCreation;
    }

    // Filtering out the null cases,
    // since in case of Jira Issue details
    // there might be `null` in the cases/allCasesWithinTimeline array
    // because of the deleted cases from the cases collection
    // but the Id in the jiraIssues collection still remains
    let nullFilteredCases = detailsData.data?.cases?.filter(Boolean) || [];
    // const priority = detailsData.data?.priority || '';
    nullFilteredCases = nullFilteredCases.map((obj: any) => {
      return {
        ...obj
      };
    });
    const revenueData = detailsData.data?.totalRevenue;

    const showProductDeployed = detailsData.data?.showProductDeployed || false;

    return (
      <div className="incidents-details-container">
        {isLoading && (
          <div>
            <img
              className="loading"
              alt=""
              width="55px"
              src="/images/spinner.gif"
            />
          </div>
        )}
        {detailsData.data ? (
          <>
            <Paper style={{ padding: '40px' }}>
              <Stack direction="vertical">
                <Stack align="center" justify="space-between">
                  <ReportingService
                    data={detailsData}
                    goBack={this.goBackToIncidents}
                  />
                  <div className="incident-details-subheader-container-right">
                    {Constants.DETECTED_INCIDENTS_KEY ===
                      detailsData.data.incidentSource && (
                      <div className="thumbs-implementation-container">
                        <ThumbsUpDownComponent
                          onClickThumbs={this.thumbsImplementation}
                          acceptanceStatus={acceptanceStatus}
                          title="Is this incident relevant?"
                        />
                      </div>
                    )}
                    {detailsData.data &&
                      this.renderProblemTicketHeader(detailsData)}
                  </div>
                </Stack>
                <BadgeInformation
                  data={nullFilteredCases}
                  revenueData={revenueData}
                />
              </Stack>
            </Paper>
            <PageContainer className="tab-container">
              <Stack direction="vertical" gap={20}>
                <NegativeScoreTable
                  data={nullFilteredCases}
                  showProductDeployed={showProductDeployed}
                />
                <KnowledgeBaseArticlesTable data={nullFilteredCases} />

                {nullFilteredCases.length ? (
                  <IncidentCasesDistribution
                    causedCases={nullFilteredCases}
                    incidentId={
                      detailsData.data.incidentNumber ||
                      detailsData.data.issueKey
                    }
                    isJiraIssue={isJiraIssueData}
                  />
                ) : null}
              </Stack>
            </PageContainer>
          </>
        ) : null}
      </div>
    );
  }
}

export default IncidentsDetails;
