/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import { FC } from 'react';
import { THEME } from '../theme';
import { Stack } from './Stack';
import { Text } from './Text';

export const IssueId: FC<{ color?: string }> = (props) => {
  const { color = THEME.primaryColor } = props;
  return (
    <ClassNames>
      {({ css }) => (
        <Stack
          gap={0}
          align="center"
          direction="vertical"
          className={css`
            background: ${color}1A;
            border-radius: 100px;
            padding: 4px 20px;
          `}
        >
          <Text variant="p2" weight="semi-bold" style={{ color }}>
            {props.children}
          </Text>
        </Stack>
      )}
    </ClassNames>
  );
};
