import React, { FC, useEffect, useState } from 'react';
import { Stack, Text, useTableSorters } from '../../../components/common';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector
} from 'recharts';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import randomColor from 'randomcolor';
import Utils from '../../../utils/utils';
import { useHistory } from 'react-router-dom';
import { logEventWithUserInfo } from '../../../utils/amplitude';

const TableHeaderCells = [
  { id: 'colorDot', label: '', width: '10%' },
  { id: 'name', label: 'Secondary Categories', width: '30%' },
  { id: 'value', label: '# Cases', width: '20%', hasFilter: true }
];
// TODO: Confirm if we need to show uncategorized cases also?
export const SecondaryCategoryDistribution: FC<any> = (props) => {
  const { style } = props;
  const [pieDataBin, setPieDataBin] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [total, setTotal] = useState(0);
  const [colors, setColors] = useState<string[]>([]);
  const history = useHistory();

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };
  const onPieLeave = (_: any) => {
    setActiveIndex(-1);
  };

  useEffect(() => {
    if (props.data) {
      const newPieDataBin: any = [];
      const newColors: string[] = [];
      let totalCases = 0;
      Object.keys(props.data).map((cat) => {
        newPieDataBin.push({
          name: cat,
          value: props.data[cat]
        });
        totalCases += props.data[cat];
        newColors.push(randomColor({ luminosity: 'dark' }));
        return cat;
      });
      setColors(newColors);
      setTotal(totalCases);
      setPieDataBin(newPieDataBin);
    }
  }, [props.data]);
  const { onSort, sortColumn, sortOrder } = useTableSorters({
    turnOffPagination: true
  });

  const navigateToCategory = (categoryName: string) => {
    Utils.saveObjectItem('categoryDetailedView', { current: categoryName });
    history.replace(`/detailed-view#${categoryName}`);
  };

  return (
    // <TablePaper style={{ ...style, marginTop: 16 }}>
    <Stack
      direction="vertical"
      style={{ ...style, marginTop: 16, marginBottom: 16 }}
    >
      <Text variant="p4" weight="bold" color="dark">
        Secondary category distribution
      </Text>
      <Stack direction="horizontal">
        <ResponsiveContainer
          className="graph-container"
          height={350}
          width={329}
        >
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={pieDataBin?.filter((ent: any) => ent.hide !== true)}
              cx="50%"
              cy="50%"
              innerRadius={100}
              outerRadius={140}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={onPieEnter}
              onMouseLeave={onPieLeave}
            >
              {activeIndex === -1 && (
                <>
                  <Label
                    // offset={0}
                    dy={-12}
                    position="center"
                    style={{ fontSize: '32px' }}
                  >
                    {total}
                  </Label>
                  <Label
                    dy={20}
                    position="center"
                    fill="#A0A0A0"
                    style={{ fontSize: '20px', fontFamily: 'DM Sans' }}
                  >
                    {total === 1 ? 'Case' : 'Cases'}
                  </Label>
                </>
              )}

              {pieDataBin
                ?.filter((ent: any) => ent.hide !== true)
                .map((entry: any, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
            </Pie>
            {/* <Tooltip content={<AdditionalDataTooltip lines={pies} />} /> */}
          </PieChart>
        </ResponsiveContainer>
        <TableContainer style={{ maxHeight: 400, marginTop: '16px' }}>
          <Table size="small" stickyHeader>
            <TableHeadSorting
              order={sortOrder}
              orderByColumn={sortColumn}
              onRequestSort={(_, column) => onSort(column)}
              headCells={TableHeaderCells}
            />
            <TableBody>
              {pieDataBin
                .sort(
                  (caseItem1: any, caseItem2: any) =>
                    caseItem2.value - caseItem1.value
                )
                .map((legend: any, index: number) => (
                  <TableRow
                    key={legend.name}
                    onClick={() => {
                      logEventWithUserInfo('Category clicked', {
                        categoryName: legend.name
                      });
                      navigateToCategory(legend.name);
                    }}
                  >
                    <TableCell>
                      <span
                        style={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          backgroundColor: colors[index % colors.length],
                          display: 'inline-block'
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Text
                        variant="p1"
                        weight="semi-bold"
                        lineClamp={2}
                        style={{
                          textOverflow: 'ellipsis',
                          display: 'block',
                          color: '#1C1D21'
                        }}
                      >
                        {legend.name}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text
                        variant="p1"
                        weight="semi-bold"
                        style={{ color: '#1C1D21' }}
                      >
                        {legend.value}
                      </Text>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
    // </TablePaper>
  );
};

const renderActiveShape = (props: any) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    value
  } = props;
  return (
    <g>
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        fill={fill}
        style={{ fontSize: '32px' }}
      >
        {value}
      </text>
      <text
        x={cx}
        y={cy}
        dy={25}
        textAnchor="middle"
        fill="#A0A0A0"
        style={{ fontSize: '20px', fontFamily: 'DM Sans' }}
      >
        {value === 1 ? 'Case' : 'Cases'}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 10}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};
