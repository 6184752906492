import { SuggestedResolutionRowManager } from './components/RowManagers/SuggestedResolutionRowManager';
import { CategoriesRowManager } from './components/RowManagers/CategoriesRowManager';
import { TagsRowManager } from './components/RowManagers/TagsRowManager';
import { SubjectRowManager } from './components/RowManagers/SubjectRowManager';
import { TicketContentRowManager } from './components/RowManagers/TicketContentRowManager';
import { LinkedJiraIssueRowManager } from './components/RowManagers/LinkedJiraIssueRowManager';
import { AccountNameRowManager } from './components/RowManagers/AccountNameRowManager';
import { Action, Option } from './types';
import { AlertRowManager } from './components/RowManagers/AlertRowManager';
import { AssignAgentRowManager } from './components/RowManagers/AssignAgentRowManager';
import { ChangeCaseStatusRowManager } from './components/RowManagers/ChangeCaseStatusRowManager';
import { TrendingIncidentsRowManager } from './components/RowManagers/TrendingIncidentsRowManager';
import { ChangeCasePriorityRowManager } from './components/RowManagers/ChangeCasePriorityRowManager';
import { AutoRespondRowManager } from './components/RowManagers/AutoRespondRowManager';
import { AddInternalNoteRowManager } from './components/RowManagers/AddInternalNoteRowManager';
import { CustomerHealthRowManager } from './components/RowManagers/CustomerHealthRowManager';
import { CustomFieldRowManager } from './components/RowManagers/CustomFieldRowManager';
import { AssignTeamRowManager } from './components/RowManagers/AssignTeamRowManager';
import { CaseRequesterEmailRowManager } from './components/RowManagers/CaseRequesterEmailRowManager';
import { WriteCaseTagsRowManager } from "./components/RowManagers/WriteCaseTagsRowManager";
import { WriteCaseCustomFieldRowManager } from "./components/RowManagers/WriteCaseCustomFieldRowManager";
import {PriorityRowManager} from "./components/RowManagers/PrioritiesRowManager";


export const CONDITIONS_LABEL: any = {
  categories: 'Categories',
  tags: 'Tags',
  ticket_priority: 'Ticket Priority',
  custom_field: 'Custom Field',
  subject: 'Subject',
  ticket_content: 'Ticket Content',
  account_name: 'Account Name',
  linked_jira_status: 'Linked Jira Issue',
  trending_incident_case_count: 'Trending Incident',
  customer_health: 'Customer health',
  requester_emails: 'Cases raised by email addresses'
};

export const operators: string[] = ['and', 'or'];

export const CONDITIONS_RULE: Option[] = [
  {
    name: 'categories',
    label: CONDITIONS_LABEL['categories'],
    keyName: 'category',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new CategoriesRowManager(options, action, operatorValue)
  },
  {
    name: 'tags',
    label: CONDITIONS_LABEL['tags'],
    keyName: 'tags',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new TagsRowManager(options, action, operatorValue)
  },
  {
    name: 'custom_field',
    label: CONDITIONS_LABEL['custom_field'],
    keyName: 'custom_field',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new CustomFieldRowManager(options, action, operatorValue)
  },
  {
    name: 'subject',
    label: CONDITIONS_LABEL['subject'],
    keyName: 'subject',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new SubjectRowManager(options, action, operatorValue)
  },
  {
    name: 'ticket_content',
    label: CONDITIONS_LABEL['ticket_content'],
    keyName: 'ticket_content',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new TicketContentRowManager(options, action, operatorValue)
  },
  {
    name: 'ticket_priority',
    label: CONDITIONS_LABEL['ticket_priority'],
    keyName: 'ticket_priority',
    rowFactoryFn: (
        options: Option[],
        action?: Action,
        operatorValue?: string
    ) => new PriorityRowManager(options, action, operatorValue)
  },
  {
    name: 'requester_emails',
    label: CONDITIONS_LABEL['requester_emails'],
    keyName: 'requester_emails',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new CaseRequesterEmailRowManager(options, action, operatorValue)
  },
  {
    name: 'account_name',
    label: CONDITIONS_LABEL['account_name'],
    keyName: 'account_name',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new AccountNameRowManager(options, action, operatorValue)
  },
  {
    name: 'linked_jira_status',
    label: CONDITIONS_LABEL['linked_jira_status'],
    keyName: 'linked_jira_status',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) =>
      new LinkedJiraIssueRowManager(options, action, operatorValue, 'trigger')
  },
  {
    name: 'trending_incident_case_count',
    label: CONDITIONS_LABEL['trending_incident_case_count'],
    keyName: 'trending_incident_case_count',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new TrendingIncidentsRowManager(options, action, operatorValue)
  },
  {
    name: 'customer_health',
    label: CONDITIONS_LABEL['customer_health'],
    keyName: 'customer_health',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new CustomerHealthRowManager(options, action, operatorValue)
  }
];

export const ACTIONS_RULE: Option[] = [
  {
    name: 'suggested_resolution',
    label: 'Suggested resolution',
    keyName: 'suggested_resolution',
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new SuggestedResolutionRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'alert',
    label: 'Send alert',
    keyName: 'alert', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new AlertRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'change_case_assignee',
    label: 'Assign agent',
    keyName: 'change_case_assignee', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new AssignAgentRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'change_case_assignee_team',
    label: 'Assign team',
    keyName: 'change_case_assignee_team', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new AssignTeamRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'change_case_status',
    label: 'Change case status',
    keyName: 'change_case_status', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new ChangeCaseStatusRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'change_case_priority',
    label: 'Change priority of case',
    keyName: 'change_case_priority', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new ChangeCasePriorityRowManager(options, value, operatorValue, 'action'),
    hidden: ['Intercom']
  },
  {
    name: 'reply_to_case',
    label: 'Auto-respond to customer',
    keyName: 'reply_to_case', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new AutoRespondRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'write_case_tags',
    label: 'Write tags to case',
    keyName: 'write_case_tags', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
        new WriteCaseTagsRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'write_case_custom_field',
    label: 'Write custom field to case',
    keyName: 'write_case_custom_field', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
        new WriteCaseCustomFieldRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'add_internal_note',
    label: 'Add an internal note to ticket',
    keyName: 'add_internal_note', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new AddInternalNoteRowManager(options, value, operatorValue, 'action')
  }
];
