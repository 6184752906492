import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { FC } from 'react';
import {
  IssueId,
  Stack,
  Text,
  useTableSorters
} from '../../../components/common';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import { CaseType } from '../types';
import { SentimentLabel } from '../../customerHealth/components/SentimentLabel';
import { ReactComponent as ExternalLink } from '../../../assets/external-link.svg';
import { ReactComponent as ExternalLinkGrey } from '../../../assets/external-link-grey.svg';

export const CasesTable: FC<{
  title: string;
  cases: CaseType[];
  footerText?: string;
}> = (props) => {
  const { cases, title, footerText } = props;
  const {
    onSort,
    filterData,
    sortColumn,
    sortOrder,
    onPageChange,
    currentPage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    pageSize,
    ROWS_PER_PAGE_OPTIONS,
  } = useTableSorters({});
  return (
    <Stack gap={20} direction="vertical" align="flex-start">
      <Text
        variant="p4"
        weight="bold"
        color="dark"
        style={{ display: 'flex', alignItems: 'center', width: '100%' }}
      >
        <img
          src="/images/rectangle_table.png"
          alt="Platform"
          style={{ paddingRight: '2%' }}
        />
        {title}
      </Text>
      <TableContainer>
        <Table>
          <TableHeadSorting
            order={sortOrder}
            orderByColumn={sortColumn}
            onRequestSort={(_, column) => onSort(column)}
            headCells={TableHeaderCells}
          />
          <TableBody>
            {filterData(cases).length === 0 && (
              <TableRow>
                <TableCell colSpan={100} align="center">
                  <Text>No Data</Text>
                </TableCell>
              </TableRow>
            )}
            {filterData(cases).map((recentCase) => (
              <TableRow key={recentCase.Id}>
                <TableCell variant="body">
                  <IssueId color="#5E81F4">{recentCase.CaseNumber}</IssueId>
                </TableCell>
                <Tooltip title={recentCase.Subject} placement="top">
                  <TableCell variant="body">
                    <Text
                      variant="p2"
                      weight="semi-bold"
                      color="dark"
                      lineClamp={4}
                    >
                      {recentCase.Subject}
                    </Text>
                  </TableCell>
                </Tooltip>
                <TableCell variant="body">
                  <Text variant="p2" weight="medium">
                    {format(
                      new Date(recentCase.timeOfCreation),
                      'MMM dd, yyyy'
                    )}
                  </Text>
                </TableCell>
                <TableCell variant="body">
                  <SentimentLabel
                    sentimentScore={recentCase.caseSentiment?.score}
                  />
                </TableCell>
                <Tooltip
                  title={
                    recentCase.secondaryCategories
                      ? recentCase.secondaryCategories.toString()
                      : ''
                  }
                  placement="top"
                >
                  <TableCell variant="body">
                    <Text variant="p2" color="dark" lineClamp={4}>
                      {recentCase.secondaryCategories
                        ? recentCase.secondaryCategories.toString()
                        : '-'}
                    </Text>
                  </TableCell>
                </Tooltip>
                <Tooltip title={recentCase.Description} placement="top">
                  <TableCell variant="body">
                    <Text variant="p2" color="dark" lineClamp={4}>
                      {recentCase.Description}
                    </Text>
                  </TableCell>
                </Tooltip>
                <TableCell variant="body" style={{ padding: 0 }}>
                  {recentCase.InstanceURL ? (
                    <a
                      href={recentCase.InstanceURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ExternalLink />
                    </a>
                  ) : (
                    <ExternalLinkGrey />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {cases.length ? (
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={cases.length}
            rowsPerPage={pageSize}
            page={currentPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
            onChangePage={(_, page) => onPageChange(page)}
          />
        ) : null}
        {footerText && (
          <TableFooter
            style={{
              fontSize: 12,
              // To position footer on same vertical level as of the pagination
              position: 'relative',
              top: '-35px'
            }}
            component="div"
          >
            {footerText}
          </TableFooter>
        )}
      </TableContainer>
    </Stack>
  );
};

const TableHeaderCells = [
  {
    id: 'CaseNumber',
    label: 'CASE ID',
    width: '23%',
    hasFilter: true
  },
  {
    id: 'Subject',
    label: 'SUBJECT',
    width: '15%',
    hasFilter: true
  },
  {
    id: 'timeOfCreation',
    label: 'CREATED AT',
    width: '14%',
    hasFilter: true
  },
  {
    id: 'caseSentiment.score',
    label: 'SENTIMENT SCORE',
    width: '13%',
    hasFilter: true
  },
  {
    id: 'category',
    label: 'SECONDARY CATEGORY',
    width: '15%',
    hasFilter: true
  },
  {
    id: 'Description',
    label: 'DESCRIPTION',
    width: '20%',
    hasFilter: false
  },
  { id: 'InstanceURL', label: '', width: '5%', hasFilter: false }
];
