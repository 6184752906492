import { CaseDistributionOptionStyle, DistributionColorStyle } from './types';

export const GreenDistributionColor: DistributionColorStyle = {
  color: '#219653',
  background: '#F0FAF6',
  gradientColor: {
    first: '#219653',
    last: '#03BB73'
  }
};

export const YellowDistributionColor: DistributionColorStyle = {
  color: '#F3C94C',
  background: '#E7EAE8',
  gradientColor: { first: '#F3C94C', last: '#E7EAE8' }
};

export const AgentDistributionLabels: CaseDistributionOptionStyle[] = [
  {
    category_key: 'resolvedCases',
    category_name: 'Number of Cases',
    category_name_format: 'Number of Cases',
    colors: YellowDistributionColor,
    status: true
  }
];
