import _ from 'lodash';

const MINS_20 = 20 * 60 * 1000;
const MINS_10 = 10 * 60 * 1000;

export const CACHE_KEYS = {
  CATEGORIES_DATA: 'categories-data',
  AGENT_ANALYTICS_DATA: 'agent_analytics-data',
  AGENT_SINGLE_ANALYTICS_DATA: 'agent_single_analytics-data',
  CATEGORY_FILTERS: 'category-filters',
  CUSTOMER_HEALTH: 'customer-health',
  CUSTOMER_HEALTH_V2: 'customer-health-v2',
  CUSTOMER_HEALTH_EXTRAS: 'customer-health-extras',
  CUSTOMERS: 'customers',
  USER: 'user',
  ACCOUNT_HEALTH: 'account-health',
  ACCOUNT_RECENT_CASES: 'account-recent-cases',
  INCIDENT_SOURCES_DATA: 'incident-sources-data',
  AI_RECOMMENDED_CASES: 'ai-recommended-cases',
  HOME_PAGE_STATS: 'home-page-stats',
  DASHBOARD_SUMMARY: 'dashboard-summary',
  CUSTOMER_LOWEST_HEALTH: 'customer-lowest-health',
  IMPORTANT_SUPPORT_METRICS_DATA: 'important-support-metrics-data',
  REVENUE_IMPACT_DATA: 'revenue-impact-data',
  REVENUE_IMPACT_ESCALATIONS_DATA: 'revenue-impact-escalations-data',
  REVENUE_IMPACT_CATEGORIES_DATA: 'revenue-impact-categories-data',
  REVENUE_IMPACT_CASE_PRIORITY_DATA: 'revenue-impact-case-priority-data',
  REVENUE_IMPACT_CASE_STATUS_DATA: 'revenue-impact-case-status-data',
  MANAGE_USERS: 'manage_users',
  ENTITY_ANALYTICS: 'entity-analytics',
  TRIGGER_DATA: 'trigger_data',
  TRIGGERS_DATA: 'triggers_data',
  TRIGGERS_PREREQUISITES: 'triggers_prerequisites',
  RULE_DETAILS: 'rule_details',
  TRIGGERS_OVERALL_IMPACT: 'triggers-overall-impact',
  TRIGGERS_OVERALL_IMPACT_TREND: 'triggers-overall-impact-trend',
  ARTICLE_METRICS: 'article-metrics',
  ATLASSIAN_CONFIG: 'atlassian-config',
  TRIGGER_AR_CASES: 'trigger-auto-respond-cases',
  ROUTING_AGENTS: 'routing-agents',
  CUSTOMER_PROVIDED_TAGS: 'category-intents',
  DETECTED_INCIDENTS_SETTINGS_PREREQUISITES:
    'detected-incidents-settings-prerequisites',
  CHATBOT_ACTIVITY: 'chatbot-activity'
};
export const CACHE_TIME = {
  [CACHE_KEYS.CATEGORIES_DATA]: {
    staleTime: MINS_10,
    cacheTime: MINS_20
  },
  [CACHE_KEYS.CUSTOMER_HEALTH]: {
    staleTime: MINS_10,
    cacheTime: MINS_20
  },
  [CACHE_KEYS.CUSTOMERS]: {
    staleTime: MINS_10,
    cacheTime: MINS_20
  },
  [CACHE_KEYS.ACCOUNT_HEALTH]: {
    staleTime: MINS_10,
    cacheTime: MINS_20
  },
  [CACHE_KEYS.ACCOUNT_RECENT_CASES]: {
    staleTime: MINS_10,
    cacheTime: MINS_20
  },
  [CACHE_KEYS.INCIDENT_SOURCES_DATA]: {
    staleTime: MINS_10,
    cacheTime: MINS_20
  },
  [CACHE_KEYS.HOME_PAGE_STATS]: {
    staleTime: MINS_10,
    cacheTime: MINS_20
  },
  [CACHE_KEYS.AI_RECOMMENDED_CASES]: {
    staleTime: MINS_10,
    cacheTime: MINS_20
  },
  [CACHE_KEYS.AGENT_ANALYTICS_DATA]: {
    staleTime: MINS_10,
    cacheTime: MINS_20
  },
  [CACHE_KEYS.CHATBOT_ACTIVITY]: {
    staleTime: MINS_10,
    cacheTime: MINS_20
  },
  get: function (key: string) {
    if (_.has(this, key)) {
      return _.get(this, key);
    }

    return {
      staleTime: MINS_10,
      cacheTime: MINS_20
    };
  }
};
