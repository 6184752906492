import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { handleError, isMocKData } from './utils';
import { DetectedIncidentsSetting } from '../../routes/incidents/trending-incidents-management/DetectedIncidentsManagementTable/types';

export const getDetectedIncidentSettings = async () => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.geDetectedIncidentSettingsMockData();
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/detected-incidents-settings?customerId=${user.customerId}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`
      })
    }
  );
  handleError(response.status);
  if (response.status === 403) {
    return [];
  }
  if (!response.ok) {
    throw new Error(
      `getRules: Unhandled error status code!: ${response.status}`
    );
  }
  return response.json();
};

export const getDetectedIncidentsSettingsPrerequisites = async () => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getDetectedIncidentsSettingsPrerequisitesMockData();
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/detected-incidents-settings/prerequisites?customerId=${user.customerId}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`
      })
    }
  );
  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error(
      `getDetectedIncidentsSettingsPrerequisites: Unhandled error status code!: ${response.status}`
    );
  }

  return response.json();
};

export const createDetectedIncidentSetting = async (
  newSetting: DetectedIncidentsSetting
) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({ setting: newSetting })
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/detected-incidents-settings?customerId=${user.customerId}`,
    requestOptions
  );

  handleError(response.status);

  const body = await response.json();
  if (response.status !== 200) {
    console.log('body:', JSON.stringify(newSetting, null, 2));
    console.log('response:', response.statusText);
    console.log(`Body received = ${JSON.stringify(body)}`);
    Utils.showError(body.message);
    throw Error(body.message);
  }
  return { body, response };
};

export const editDetectedIncidentSetting = async (updatedSetting: any) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  const requestOptions = {
    method: 'PUT',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({ setting: updatedSetting })
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/detected-incidents-settings/${updatedSetting._id}/?customerId=${user.customerId}`,
    requestOptions
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }

  return response;
};

export const deleteDetectedIncidentSetting = async (settingId: any) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  const requestOptions = {
    method: 'DELETE',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    })
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/detected-incidents-settings/${settingId}?customerId=${user.customerId}`,
    requestOptions
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }

  return response;
};
