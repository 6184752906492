import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';

import './DetectedIncidentsSettingsHolder.scss';
import { Box, Typography } from '@material-ui/core';
import { DetectedIncidentsSetting } from '../types';
import { DetectedIncidentsSettingsInputForm } from '../DetectedIncidentsSettingsInputForm';
import { DetectedIncidentsSettingsTableDrawerWithHeaderFooter } from './DetectedIncidentsSettingsTableDrawerWithHeaderFooter';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '../../../../../components/IconButton';

export interface DetectedIncidentsInputFormHolderProps {
  item?: DetectedIncidentsSetting;
  buttonComponent: React.ReactElement;
  onSuccess: Function;
}

export const DetectedIncidentsInputFormHolder: React.FC<DetectedIncidentsInputFormHolderProps> = (
  props
) => {
  const [state, setState] = React.useState({
    showDrawer: false
  });

  return (
    <React.Fragment key="right">
      <div
        onClick={() => {
          console.log('Button set state true');
          setState({ showDrawer: true });
        }}
        style={{
          width: 'fit-content'
        }}
      >
        {props.buttonComponent}
      </div>
      <DetectedIncidentsSettingsTableDrawerWithHeaderFooter
        headerComponent={
          <Box
            style={{ marginBottom: '64px', alignItems: 'center' }}
            role="presentation"
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              <IconButton
                className="back_button_details"
                icon={ArrowBackIcon}
                onClick={() => {
                  setState({ showDrawer: false });
                }}
                backgroundColor="#5e81f4"
                size="sm"
              ></IconButton>
              <Typography
                variant="h5"
                display="block"
                align={'left'}
                // className={'mt-0'}
                style={{
                  fontWeight: 'bold'
                }}
              >
                Create a new configuration for trending incidents
              </Typography>
            </div>
          </Box>
        }
        footerComponent={<div></div>}
        showDrawer={state.showDrawer}
        classes={{
          root: 'page-container'
        }}
      >
        <div>
          <DetectedIncidentsSettingsInputForm
            item={props.item}
            onSuccess={() => {
              setState({ showDrawer: false });
              props.onSuccess();
            }}
          />
        </div>
      </DetectedIncidentsSettingsTableDrawerWithHeaderFooter>
    </React.Fragment>
  );
};
