import React, { Component } from 'react';
import './TableHeadSorting.scss';
import {
  SvgIcon,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import Constants from '../../utils/constants';
import { ReactComponent as HelpSvg } from '../../assets/Help.svg';
import { DarkTooltip } from '../../routes/incidents/incident-list/DarkTooltip';
import { ReactComponent as SortDown } from '../../assets/sortDown.svg';
import { ReactComponent as SortUp } from '../../assets/sortUp.svg';
import { ReactComponent as SortNumberDown } from '../../assets/sortNumberDown.svg';
import { ReactComponent as SortNumberUp } from '../../assets/sortNumberUp.svg';

interface Props {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: any;
  orderByColumn: string;
  headCells: any;
  headerStyle?: React.CSSProperties;
}

const AscIcon = () => (
  <SvgIcon className="sortIcon">
    <SortDown />
  </SvgIcon>
);
const AscNumberIcon = () => (
  <SvgIcon className="sortIcon">
    <SortNumberDown />
  </SvgIcon>
);
const DescIcon = () => (
  <SvgIcon className="sortIcon">
    <SortUp />
  </SvgIcon>
);
const DescNumberIcon = () => (
  <SvgIcon className="sortIcon">
    <SortNumberUp />
  </SvgIcon>
);
class TableHeadSorting extends Component<Props, any> {
  render() {
    let { order, orderByColumn, onRequestSort, headCells } = this.props;
    const createSortHandler = (property: string) => (
      event: React.MouseEvent<unknown>
    ) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow className="table-row-sorting">
          {headCells.map((headCell: any, index: number) => (
            <TableCell
              key={headCell.id}
              width={headCell.width}
              className={'table-header ' + (headCell.classNames || '')}
              style={{
                height: '33px',
                padding: '0px',
                paddingLeft: index === 0 ? '25px' : '5px',
                ...this.props.headerStyle
              }}
            >
              {headCell.hasFilter ? (
                <TableSortLabel
                  active={true}
                  IconComponent={
                    order === 'asc' && orderByColumn === headCell.id
                      ? headCell.type === 'number'
                        ? AscNumberIcon
                        : AscIcon
                      : order === 'desc' && orderByColumn === headCell.id
                      ? headCell.type === 'number'
                        ? DescNumberIcon
                        : DescIcon
                      : headCell.type === 'number'
                      ? AscNumberIcon
                      : AscIcon
                  }
                  direction={
                    orderByColumn === headCell.id
                      ? order
                      : Constants.ASCENDING_SORTING
                  }
                  onClick={createSortHandler(headCell.id)}
                  className={
                    orderByColumn === headCell.id
                      ? 'activeSortIcon'
                      : 'inactiveSortIcon'
                  }
                >
                  {headCell.label}
                  {headCell.label === 'ESCALATED CASES' ? (
                    <DarkTooltip title="Shows number of cases escalated to engineering via Jira issue. Connect with Jira to see results.">
                      <HelpSvg />
                    </DarkTooltip>
                  ) : null}
                </TableSortLabel>
              ) : (
                <React.Fragment>
                  <p
                    style={{
                      opacity: 0.6,
                      fontSize: '12px'
                    }}
                  >
                    {headCell.label}
                  </p>
                </React.Fragment>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export default TableHeadSorting;
