import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export function useURLState<T>(
  key: string,
  defaultValue: T,
  parser: (paramValue: string) => T
): [T, (newValue: string, extraParams?: Record<string, string>) => void] {
  const history = useHistory();
  const location = useLocation();

  const valueFromURL = queryString.parse(location.search)[key]?.toString();
  const value = valueFromURL ? parser(valueFromURL) : defaultValue;

  const handleUpdateValue = useCallback(
    (value: string, extraParams?: Record<string, string>) => {
      const existingParams = queryString.parse(location.search);
      history.replace({
        search: queryString.stringify({
          ...existingParams,
          ...extraParams,
          [key]: value
        })
      });
    },
    [history, key, location.search]
  );

  return [value, handleUpdateValue];
}
