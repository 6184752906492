import React, { FC } from 'react';
import { Stack } from '../../../components/common';
import { Agent, DataMetric } from '../types';
import {
  formatAvgTime,
  formatCSATScore,
  formatOpenCloseCases,
  useDateRangeQueryForAgentData
} from '../utils';
import { Loader } from '../../../components/common/blocks/Loader';
import AccountSearch from '../../customerHealth/components/AccountSearch';
import { useHistory } from 'react-router-dom';
import Constants from '../../../utils/constants';
import { MetricsRow } from './MetricsRow';

export const AgentMetrics: FC<{
  dateRange: Date[];
  agentId: string;
  setAgentName: (agentName: string) => void;
}> = ({ dateRange, agentId, setAgentName }) => {
  const history = useHistory();
  const handleSelectOption = (optionSelected: any) => {
    history.replace(
      `${Constants.routes.AGENT_DETAILS}?${Constants.AGENT_ID_SEARCH_PARAM}=${optionSelected}`
    );
  };

  const { data: agentAnalyticsData } = useDateRangeQueryForAgentData(dateRange);

  const agent = (agentAnalyticsData?.agents as Agent[])?.find(
    (agent: any) => agent.id === agentId
  );
  setAgentName(agent?.name ? agent?.name : '');

  const data: DataMetric[] = [
    {
      metric: formatAvgTime(agent?.mttrInSecs),
      description: 'Avg. Resolution time'
    },
    {
      metric: formatOpenCloseCases(agent?.numOpenCases, agent?.numClosedCases),
      description: 'Total open cases vs Closed cases'
    },
    { metric: formatCSATScore(agent?.csat), description: 'CSAT Score' }
  ];

  const filterData = agentAnalyticsData?.agents.map((agent: any) => {
    return agent.name;
  });

  if (agentAnalyticsData === undefined) {
    return <Loader center />;
  }

  return (
    <>
      <AccountSearch
        withoutBorder={true}
        dataList={filterData}
        dataListSearch={agentAnalyticsData?.agents}
        value={agent?.name}
        deactivateTitle={true}
        withoutBorderInput={true}
        getOptionSelected={handleSelectOption}
        redirectWithId={true}
      />
      <Stack
        direction="vertical"
        gap={15}
        align="flex-start"
        style={{ marginTop: 30 }}
      >
        <MetricsRow data={data} />
      </Stack>
    </>
  );
};
