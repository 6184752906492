import React from 'react';

import { Tooltip } from '../Tooltip';

import './styles.scss';

type IconButtonSize = 'sm' | 'md' | 'lg';

interface IIconProps {
  width?: string | number;
  height?: string | number;
}

type HTMLButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'title' | 'className'
>;

interface IIconButtonProps extends HTMLButtonProps {
  backgroundColor?: string;
  className?: string;
  color?: string;
  icon: React.ComponentType<IIconProps>;
  iconContainerClassName?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  size?: IconButtonSize;
  title?: string;
}

const iconSize: Record<IconButtonSize, IIconProps> = {
  sm: { width: 22, height: 22 },
  md: { width: 22, height: 22 },
  lg: { width: 22, height: 22 }
};

export const IconButton: React.FC<IIconButtonProps> = ({
  className = '',
  backgroundColor = '',
  icon: Icon,
  iconContainerClassName = '',
  onClick,
  size = 'md',
  title = '',
  ...rest
}) => (
  <Tooltip title={title}>
    <div
      style={{
        ...(backgroundColor && { backgroundColor })
      }}
      className={['app-icon-button-container', size, className]
        .filter(Boolean)
        .join(' ')}
    >
      <button
        className={['app-icon-button', iconContainerClassName]
          .filter(Boolean)
          .join(' ')}
        onClick={onClick}
        {...rest}
      >
        <Icon {...(iconSize[size] || {})} />
      </button>
    </div>
  </Tooltip>
);
