import {
  createStyles,
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableContainer,
  TableHead,
  TableRow as MUITableRow,
  withStyles
} from '@material-ui/core';
import React, { FC } from 'react';
import { Stack, Text } from '../../../components/common';
import { UserType } from '../types';
import _ from "lodash";
import {Role} from "../../../services/types";

const VIEWER_ROLE_ALIAS = "non-admin"

export const ManageUsersTable: FC<{
  title: string;
  cases: UserType[];
  footerText?: string;
}> = (props) => {
  const { title, cases } = props;

  return (
    <Stack gap={20} direction="vertical" align="flex-start">
      <Text
        variant="p4"
        weight="semi-bolder"
        color="dark"
        style={{ float: 'left' }}
      >
        {title}
      </Text>
      <TableContainer style={{ maxHeight: 420, overflowY: 'scroll' }}>
        <Table>
          <TableHead>
            <TableRow
              style={{
                width: '1152px',
                height: '48px',
                background: 'rgba(245, 245, 250, 0.4)'
              }}
            >
              {headCells.map((headCell: any, index) => (
                <TableCell
                  key={index}
                  width={headCell.width}
                  style={{ borderBottom: 'none' }}
                >
                  <Text variant="p0" weight="semi-bolder" color="gray">
                    {headCell.label}
                  </Text>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {cases.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={100}
                  align="center"
                  style={{ border: 'none' }}
                >
                  <Stack
                    align="center"
                    direction="vertical"
                    style={{ paddingTop: 50 }}
                    gap={40}
                  >
                    <img src="/images/users_no_data.png" alt="users" />
                    <Text variant="p3" weight="semi-bolder" color="dark">
                      You haven’t invited any user to platform
                    </Text>
                  </Stack>
                </TableCell>
              </TableRow>
            )}
            {cases.map((recentCase, index) => (
              <TableRow key={index}>
                <TableCell variant="body" style={{ borderBottom: 'none' }}>
                  <Stack align="center" direction="horizontal" gap={20}>
                    <div
                      style={{
                        display: 'flex',
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#F7F7F7',
                        borderRadius: '50%'
                      }}
                    >
                      <p style={{ margin: 'auto' }}>
                        <Text variant="p1" weight="semi-bolder" color={'dark'}>
                          {recentCase.name === null
                            ? ''
                            : recentCase.name.charAt(0).toUpperCase()}
                        </Text>
                      </p>
                    </div>
                    <Text variant="p2" weight="semi-bolder" color={'dark'}>
                      {recentCase.name === null ? '' : recentCase.name}
                    </Text>
                  </Stack>
                </TableCell>
                <TableCell variant="body" style={{ borderBottom: 'none' }}>
                  <Text variant="p1" weight="regular" color={'light'}>
                    {recentCase.email}
                  </Text>
                </TableCell>
                <TableCell variant="body" style={{ borderBottom: 'none' }}>
                  <Text variant="p1" weight="regular" color={'light'}>
                    {recentCase.roles.map(role => _.isEqual(role, Role.VIEWER) ? VIEWER_ROLE_ALIAS : role).join(",")}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

const headCells = [
  {
    id: 'UserName',
    label: 'USER',
    width: '50%',
    hasFilter: true
  },
  {
    id: 'UserMail',
    label: 'EMAIL ID',
    width: '30%',
    hasFilter: true
  },
  {
    id: 'Role',
    label: 'ROLE',
    width: '20%',
    hasFilter: true
  }
];

const TableCell = withStyles(createStyles({}))(MUITableCell);
const TableRow = withStyles(
  createStyles({
    root: {
      '&:hover': {
        background: '#FBFBFD'
      }
    }
  })
)(MUITableRow);
