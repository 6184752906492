import React, { FC } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import Utils from '../../../utils/utils';
import Constants from '../../../utils/constants';
import { Link } from 'react-router-dom';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import {
  Heading,
  Stack,
  Text,
  useTableSorters
} from '../../../components/common';
import './MaxSupportImpactIncidents.scss';
import { Tag } from '../../../components/common/blocks/Tag';
import { DarkTooltip } from '../incident-list/DarkTooltip';
import { ReactComponent as HelpSvg } from '../../../assets/Help.svg';
import { IncidentsNotifications } from './IncidentsNotifications';

const PAGE_SIZE = 3;
const user = Utils.getObjectItem('user');
const starterCustomerId = Utils.getStarterCustomerId();

export const MaxImpactSkeleton: FC<
  MaxImpactSkeletonProps & {
    defaultSortOrder?: keyof MaxImpactSkeletonProps['data'][0];
  }
> = (props) => {
  const {
    heading,
    timeline,
    data,
    defaultSortOrder = 'casesCount',
    tagText = '',
    iconCmp,
    tooltipText,
    enabledButtonNotification = false,
    rightAlignedComponents = []
  } = props;

  const customerIdValidation = starterCustomerId === user.customerId;
  const { filterData, onPageChange, currentPage } = useTableSorters({
    pageSize: PAGE_SIZE,
    defaultSortColumn: defaultSortOrder,
    defaultSortOrder: Constants.DESCENDENT_SORTING
  });
  const getIncidentDetailsLink = (item: MaxImpactSkeletonProps['data'][0]) => {
    return `/incidents-details/${item.incidentSource}/${item.id}`;
  };

  return (
    <div className="max-support-container">
      <div className="max-support-header-container">
        <div className="left-container">
          <Stack direction="horizontal" gap={10} align="center">
            {iconCmp}
            <Heading>{heading}</Heading>
            {tagText && <Tag>{tagText}</Tag>}
            {tooltipText && (
              <DarkTooltip title={tooltipText}>
                <HelpSvg />
              </DarkTooltip>
            )}
          </Stack>
        </div>
        {enabledButtonNotification && customerIdValidation && (
          <IncidentsNotifications />
        )}
        <div
          className={'left-margin'}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          {rightAlignedComponents}
          <span className="sub-title-table">{timeline}</span>
        </div>
      </div>
      <Grid className="incidents-container" container spacing={1}>
        {filterData(data).map((item) => (
          <Grid key={item.id} item xs={4}>
            <a
              style={{ textDecoration: 'none' }}
              rel="noopener noreferrer"
              href={getIncidentDetailsLink(item)}
              onClick={(e) => {
                e.stopPropagation();
                logEventWithUserInfo(heading, {
                  id: item.id
                });
              }}
            >
              <div className="incident-container" style={{ cursor: 'pointer' }}>
                <div className="incident-header">
                  <div
                    className="incident-header-left"
                    style={{ width: '85%' }}
                  >
                    <div className="incident-id">
                      <label className="incident-id-value">
                        {item.identifier}
                      </label>
                    </div>
                    <Tooltip title={item.title}>
                      <div className="incident-title">{item.title}</div>
                    </Tooltip>
                  </div>
                  <Stack
                    direction="vertical"
                    gap={5}
                    align="end"
                    style={{ width: '15%' }}
                  >
                    <Text variant="p1">CASES</Text>
                    <Text
                      style={{
                        fontSize: 28,
                        color: '#F57070',
                        fontWeight: 'bold',
                        lineHeight: '21px'
                      }}
                    >
                      {item.casesCount}
                    </Text>
                  </Stack>
                </div>
                <div className="incident-date">
                  {Utils.formatDistanceToNowStrict(
                    new Date(item.timeOfCreation || '')
                  )}
                </div>
                <TableContainer
                  className="cases-table-container"
                  style={{ minHeight: 90, maxHeight: 90 }}
                >
                  <Table stickyHeader>
                    <TableBody>
                      {item.cases.map((caseElement, caseIndex) => (
                        <Tooltip placement="top" title={caseElement.subject}>
                          <TableRow
                            className={
                              caseIndex === 1 ? 'second-row' : 'normal-row'
                            }
                            key={caseIndex}
                          >
                            <TableCell
                              className="cases-table-cell case-number"
                              width="35%"
                            >
                              <a
                                className="casesLink"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={caseElement.url}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {caseElement.caseNumber}
                              </a>
                            </TableCell>
                            <TableCell
                              className="cases-table-cell subject"
                              width="65%"
                            >
                              <span className="case-ellipsis">
                                {caseElement.subject}
                              </span>
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="cases-footer-container">
                  <img
                    alt=""
                    src={
                      (Constants.incidentImagesMap as Record<string, string>)[
                        item.incidentSource
                      ]
                    }
                    height="20px"
                  />
                  <Link
                    to={getIncidentDetailsLink(item)}
                    className="view-details-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      logEventWithUserInfo(heading, {
                        id: item.id
                      });
                    }}
                  >
                    <u>VIEW DETAILS</u>
                  </Link>
                </div>
              </div>
            </a>
          </Grid>
        ))}
      </Grid>
      <TablePagination
        count={data.length}
        rowsPerPage={PAGE_SIZE}
        component="div"
        rowsPerPageOptions={[PAGE_SIZE]}
        page={currentPage}
        onChangePage={(_, page) => onPageChange(page)}
        style={{ marginTop: 10 }}
      />
    </div>
  );
};
export type MaxImpactSkeletonProps = {
  heading: string;
  tagText?: string;
  iconCmp?: React.ReactNode;
  tooltipText?: string;
  timeline: string;
  countText: string;
  data: Array<{
    timeOfCreation: string;
    id: string;
    identifier: string;
    title: string;
    incidentSource: string;
    casesCount: number;
    cases: Array<{
      id: string; // Check if it will be there?
      Id: string;
      caseNumber: string;
      subject: string;
      url: string;
      timeOfCreation: string;
    }>;
  }>;
  enabledButtonNotification?: boolean;
  rightAlignedComponents?: React.ReactElement[];
};
