import React, { FC, useCallback, useState } from 'react';
import { Stack } from '../../../components/common';
import { AgentCaseData } from '../types';
import CloseIcon from '@material-ui/icons/Close';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Rating from '@material-ui/lab/Rating';
import {
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  Button
} from '@material-ui/core';
import { TextArea } from '../../../components/TextArea';
import './FeedbackForm.scss';

export const FeedbackForm: FC<{
  caseData: AgentCaseData;
  open: boolean;
  setShowFeedbackDialog: (arg0: boolean) => void;
  setValue: (arg0: number) => void;
  ratingSelected: number;
  view: boolean;
}> = (props) => {
  const {
    caseData,
    setShowFeedbackDialog,
    ratingSelected,
    setValue,
    view
  } = props;
  const [isOpen, setIsOpen] = useState(props.open);
  // const [newComment, setNewComment] = useState('');
  const [viewOnly, setViewOnly] = useState(view);
  // const [hasError, setHasError] = useState<boolean>(false);
  // const [errorMessage, setErrorMessage] = useState<string>('');
  const closeModal = useCallback(() => {
    setIsOpen(false);
    setShowFeedbackDialog(false);
  }, [setShowFeedbackDialog]);

  //   const toggleModal = useCallback(() => {
  //     setIsOpen((oldState) => !oldState);
  //   }, []);

  const handleConfirm = useCallback(() => {
    //   onConfirm(data);
    closeModal();
  }, [closeModal]);

  const handleCancel = useCallback(() => {
    //   onCancel(data);
    closeModal();
  }, [closeModal]);

  const handleEdit = useCallback(() => {
    //   onCancel(data);
    setViewOnly(!viewOnly);
  }, [viewOnly]);

  return (
    <div>
      <Dialog
        //   style={{ borderRadius: '10px' }}
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <h3 className="feedback-form-title">Case: {caseData.Id}</h3>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          style={{
            position: 'absolute',
            right: 8,
            top: 8
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          style={{ width: 600, pointerEvents: viewOnly ? 'none' : 'auto' }}
        >
          <div>
            <Stack direction="vertical">
              <Stack
                align="center"
                justify="flex-end"
                style={{ width: 'fit-content', flex: 'auto' }}
                // className="created"
              >
                <p
                  style={{
                    marginRight: 16,
                    fontWeight: 'bold',
                    color: 'black',
                    fontSize: 28
                  }}
                >
                  {ratingSelected}
                </p>
                <Rating
                  value={ratingSelected}
                  // size="small"
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  precision={0.1}
                  onChange={(event, newValue) => {
                    caseData.feedbackRating = newValue ? newValue : 0;
                    setValue(newValue ? newValue : 0);
                  }}
                />
              </Stack>
              <TextArea
                style={{ width: '100%' }}
                className="comment-box"
                placeholder={
                  viewOnly && caseData.feedbackComment
                    ? caseData.feedbackComment[0]
                    : 'Write a comment'
                }
                onChange={(event) => {
                  caseData.feedbackComment = [event.target.value];
                }}
              />
            </Stack>
          </div>
        </DialogContent>
        {viewOnly && (
          <DialogActions style={{ marginTop: 66 }}>
            <Button
              onClick={handleEdit}
              color="primary"
              className="comment-button"
            >
              Edit
            </Button>
          </DialogActions>
        )}
        {!viewOnly && (
          <DialogActions style={{ marginTop: 66 }}>
            <Button
              onClick={handleCancel}
              color="primary"
              className="cancel-comment-button"
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              autoFocus
              className="comment-button"
            >
              Comment
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
