import React, { ReactElement, useState } from 'react';

import { FormControl, Typography } from '@material-ui/core';

import { RulesRowManager } from '../../../utils/RulesRowManager';
import { Chips } from 'primereact/chips';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as EditIcon } from '../../../../../assets/Edit.svg';

import '../styles.scss';

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';

//core
import 'primereact/resources/primereact.min.css';

/**
 * Row manager for Ticket Content
 */
export class CaseRequesterEmailRowManager extends RulesRowManager {
  constructor(options: Option[], actionValue: any, actionOperator: any) {
    super(options, actionValue, actionOperator);
    this.action.type = 'requester_emails';
    this.action.operator = actionOperator === undefined ? 'in' : actionOperator;
  }

  setDefaultActionValue(): void {
    this.action.value = '';
  }
  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    if (this.action.value === undefined) this.setDefaultActionValue();
    const valueInitialState = this.action.value ? this.action.value : [];
    const [value, setValue] = useState(valueInitialState);
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <EditIcon />
          <p className="title">Case Requester Email</p>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <p className="operator-text">is</p>
          <div>
            <FormControl>
              <Chips
                value={value}
                onChange={(e: any) => {
                  e.value = e.value.map(function (x: string) {
                    return x.trim();
                  }); // Trim spaces
                  setValue(e.value);
                  this.action.value = e.value;
                  handleRowComponent(this.action);
                }}
                separator=","
              />
            </FormControl>
          </div>
        </div>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          align={'left'}
        >
          <p
            style={{
              textAlign: 'left'
            }}
          >
            <br />
            You can enter or paste comma-separated values here. Each value will
            be auto-parsed into individual entries.
            <br />
            Use backspace or the "x" icon on each label to delete entries.
            <br />
            Please make sure all email addresses are valid and are separated by
            commas.
          </p>
        </Typography>
      </div>
    );
  }
}
