import React from "react";
import {PageContainer} from "../../components/common/blocks/PageContainer";
import {TablePaper} from "../../components/common/blocks/TablePaper";
import Utils from "../../utils/utils";
import {Loader} from "../../components/common/blocks/Loader";
import {Stack, Text} from "../../components/common";

export default function ArticleSearch() {
    const {
        data: customer,
        isLoading,
    } = Utils.useCustomerQuery();

    if ( isLoading ) {
        return <Loader />;
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/zendesk/zendesk-ticket-form-searchbar/getZendeskTicketFormSearchBar?businessId=${customer?.businessId}`;

    return <PageContainer className="tab-container">
        <TablePaper
            style={{
                borderBottomLeftRadius: '0 !important',
                borderBottomRightRadius: '0 !important'
            }}
        >
            <Stack gap={20} direction={'vertical'}>
                <Text
                    variant="p4"
                    weight="bold"
                    color="dark"
                    style={{ marginTop: '16px', marginBottom: '0px' }}
                >
                    Intelligent Search on your knowledge articles
                </Text>
                <iframe src={url} title={"Search"} className={"w-100 p-0o5 border-0"} style={{height: "85vh"}}></iframe>
            </Stack>
        </TablePaper>
    </PageContainer>;
}
