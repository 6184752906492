import React, { FC } from 'react';

export type PaperProps = {
  padding?: string | number;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const Paper: FC<PaperProps> = (props) => {
  return (
    <div
      {...props}
      style={{
        ...props.style,
        background: props.color ? props.color : 'white',
        borderRadius: 10,
        padding: props.padding ? props.padding : 24
      }}
    />
  );
};
