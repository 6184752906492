import React, { FC, useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import {
  HorizontalGauge,
  Stack,
  TableCell,
  TableRow,
  Text,
  useTableSorters
} from '../../../components/common';
import { ReactComponent as Closed } from '../../../assets/Closed.svg';
import { ReactComponent as MeanTime } from '../../../assets/MeanTime.svg';
import { ReactComponent as Open } from '../../../assets/Open.svg';
import { formatDistance } from 'date-fns';
import { Paper } from '../../../components/home/Paper';
import { Case, LinkedJiraIssue } from '../types';
import { HealthScore } from '../../../routes/customerHealth/types';
import './CaseMetrics.scss';
import _, { toNumber } from 'lodash';
// import constants from '../../../utils/constants';
import { buckets, getEmoji, getSentiment } from '../../categories/utils';

import Utils from '../../../utils/utils';
import { getScoreColor, StatItem } from './StatItem';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import {
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  Tab,
  Tabs,
  Button
} from '@material-ui/core';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';

import { RecentCasesTable } from './RecentCasesTable';
import { TasksTable } from './TasksTable';
import { NewTask } from './NewTask';

export const AverageSentiment: FC<{
  value: number | null;
  style?: React.CSSProperties;
}> = (props) => {
  const { value, style } = props;
  return (
    <Paper
      style={{
        ...style
      }}
    >
      <Stack direction="vertical" gap={30} align="center" justify="center">
        <Stack direction="vertical" align="center" gap={0}>
          <Text variant="p1" weight="medium">
            Average Sentiment
          </Text>
          {value === null ? (
            '--'
          ) : (
            <HorizontalGauge
              width="300px"
              buckets={buckets}
              value={value / 100}
            />
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};
export const CasesMetrics: FC<{
  closedCases: number;
  mttr: number;
  totalCases: number;
  style?: React.CSSProperties;
}> = (props) => {
  const { closedCases, totalCases, mttr, style } = props;
  const metrics = [
    {
      icon: <MeanTime />,
      title: 'Mean Time to Resolve',
      metric: mttr ? `${formatDistance(0, mttr * 60 * 60 * 1000)}` : '-'
    },
    {
      icon: <Open />,
      title: 'Total Cases',
      metric: totalCases
    },
    {
      icon: <Closed />,
      title: 'Closed Cases',
      metric: closedCases
    }
  ];
  return (
    <Paper
      style={{
        ...style
      }}
    >
      <Stack gap={15} justify="space-between">
        {metrics.map((metric) => (
          <Stack
            key={metric.title}
            direction="vertical"
            gap={30}
            align="center"
          >
            {metric.icon}
            <Stack direction="vertical" align="center" gap={15}>
              <Text
                variant="p1"
                weight="medium"
                style={{ textAlign: 'center' }}
              >
                {metric.title}
              </Text>
              <Text
                style={{
                  fontSize: 30,
                  fontWeight: 500,
                  color: '#58508D',
                  lineHeight: '45px'
                }}
              >
                {metric.metric}
              </Text>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Paper>
  );
};

const TabPanel = (props: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const tasksData = [
  {
    taskId: 'ID-25334',
    description: 'Customer data Syncing with dashboard',
    status: 'Open',
    timeOfCreation: '17/12/2021',
    owner: 'Alex'
  },
  {
    taskId: 'ID-55334',
    description: 'Render Frontend for customer',
    status: 'New',
    timeOfCreation: '17/12/2022',
    owner: 'Liam'
  },
  {
    taskId: 'ID-26334',
    description: 'Setup dashboard and their features for customer',
    status: 'Open',
    timeOfCreation: '17/12/2020',
    owner: 'Oliver'
  },
  {
    taskId: 'ID-25336',
    description: 'New customer message on January 31, 2021 at 11:25 am	',
    status: 'Closed',
    timeOfCreation: '17/12/2020',
    owner: 'Cooper'
  },
  {
    taskId: 'ID-25358',
    description: 'Setup a new account for the customer',
    status: 'Closed',
    timeOfCreation: '17/12/2020',
    owner: 'Cooper'
  }
];

export const LinkedJiraIssues: FC<{
  jiraIssues: LinkedJiraIssue[];
  cases?: Case[];
  style?: React.CSSProperties;
}> = (props) => {
  const { jiraIssues, style, cases } = props;
  const [showNewTaskDialog, setShowNewTaskDialog] = useState(false);

  const headCells = [
    {
      id: 'issueKey',
      label: 'Issue ID',
      width: '15%',
      hasFilter: true
    },
    {
      id: 'summary',
      label: 'Summary',
      width: '35%',
      hasFilter: true
    },

    {
      id: 'issueStatus',
      label: 'Status',
      width: '15%',
      hasFilter: true
    },
    {
      id: 'priority',
      label: 'Priority',
      width: '15%',
      hasFilter: true
    },
    {
      id: 'assignee',
      label: 'Assignee',
      width: '20%',
      hasFilter: true
    }
  ];
  const isMockData = process.env.REACT_APP_MOCKDATA === 'true' ? true : false;

  const {
    onSort,
    filterData,
    sortColumn,
    sortOrder,
    onPageChange,
    currentPage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    pageSize,
    ROWS_PER_PAGE_OPTIONS,
  } = useTableSorters({});
  const [tabNumber, setTabNumber] = useState(0);
  return (
    <TablePaper
      style={{
        ...style
      }}
    >
      <div style={{ display: 'flex' }}>
        <Tabs
          value={tabNumber}
          onChange={(event: any, newValue: number) => {
            setTabNumber(newValue);
          }}
          className="tabs"
        >
          <Tab
            className="tab"
            label={
              <Stack direction="horizontal" gap={15} align="center">
                <span className="heading">Recent Cases</span>
              </Stack>
            }
          />

          <Tab
            className="tab"
            label={
              <Stack direction="horizontal" gap={15} align="center">
                <span className="heading">Open bug escalations</span>
              </Stack>
            }
          />

          {isMockData === true && (
            <Tab
              className="tab"
              label={
                <Stack direction="horizontal" gap={15} align="center">
                  <span className="heading">All Tasks</span>
                </Stack>
              }
            />
          )}
        </Tabs>
        {tabNumber === 2 && (
          <Button
            variant="outlined"
            onClick={() => {
              setShowNewTaskDialog(true);
            }}
            style={{
              marginRight: '16px',
              marginLeft: 'auto',
              marginTop: '8px',
              marginBottom: '8px',
              color: '#F2F2F2',
              background: '#5D5FEF'
            }}
          >
            CREATE NEW TASK
          </Button>
        )}
      </div>

      <TabPanel value={tabNumber} index={1}>
        <Stack
          gap={5}
          justify="left"
          direction="horizontal"
          style={{ marginTop: 16 }}
        >
          <TableContainer style={{ maxHeight: 1000 }}>
            <Table size="small">
              <TableHeadSorting
                order={sortOrder}
                orderByColumn={sortColumn}
                onRequestSort={(_, column) => onSort(column)}
                headCells={headCells}
              />
              <TableBody>
                {filterData(jiraIssues).length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={100}
                      align="center"
                      style={{ border: 'none' }}
                    >
                      <Stack
                        align="center"
                        direction="vertical"
                        style={{ paddingTop: 50 }}
                        gap={40}
                      >
                        <Text variant="p3" weight="semi-bolder" color="dark">
                          No data
                        </Text>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
                {filterData(jiraIssues).map((issue: any, index: any) => (
                  <TableRow
                    key={index}
                    className="agent-row"
                    // onClick={() => handleRowClick(agent.id)}
                  >
                    <TableCell variant="body" style={{ borderBottom: 'none' }}>
                      <Text
                        variant="p1"
                        weight="semi-bolder"
                        style={{ color: '#5E81F4' }}
                      >
                        {issue.issueKey === null ? '' : issue.issueKey}
                      </Text>
                    </TableCell>

                    <TableCell variant="body" style={{ borderBottom: 'none' }}>
                      <Text variant="p1">
                        {issue.summary === null ? '' : issue.summary}
                      </Text>
                    </TableCell>
                    <TableCell variant="body" style={{ borderBottom: 'none' }}>
                      <Text variant="p1">
                        {issue.issueStatus === null ? '' : issue.issueStatus}
                      </Text>
                    </TableCell>
                    <TableCell variant="body" style={{ borderBottom: 'none' }}>
                      <Text variant="p1">
                        {issue.priority === null ? '' : issue.priority}
                      </Text>
                    </TableCell>
                    <TableCell variant="body" style={{ borderBottom: 'none' }}>
                      <Text variant="p1">
                        {issue.assignee === null ? '' : issue.assignee}
                      </Text>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={jiraIssues.length}
              rowsPerPage={pageSize}
              page={currentPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelDisplayedRows={labelDisplayedRows}
              onChangePage={(_, page) => onPageChange(page)}
              // classes={{
              //   caption: classes.caption,
              //   actions: classes.actions
              // }}
            />
          </TableContainer>
        </Stack>
      </TabPanel>
      <TabPanel value={tabNumber} index={0}>
        {cases && <RecentCasesTable cases={cases} tabView={true} />}
      </TabPanel>
      <TabPanel value={tabNumber} index={2}>
        <TasksTable tasks={tasksData} />
      </TabPanel>
      {showNewTaskDialog && (
        <NewTask
          task=""
          open={showNewTaskDialog}
          setShowNewTaskDialog={setShowNewTaskDialog}
        />
      )}
    </TablePaper>
  );
};
export const DetailedAccountMetrics: FC<{
  granularDetails: HealthScore;
  closedCases: number;
  mttr: number;
  totalCases: number;
  satisfactionRatingScore: number;
  satisfactionRatingSurveyCount: number;
  averageSentiment: number;
  style?: React.CSSProperties;
  healthScore: number;
  healthBucket: any;
  annualContractValue: any;
  currency?: string;
}> = (props) => {
  const {
    closedCases,
    totalCases,
    mttr,
    satisfactionRatingScore,
    satisfactionRatingSurveyCount,
    averageSentiment,
    healthScore = 0,
    granularDetails,
    healthBucket,
    annualContractValue,
    currency
  } = props;

  // const ColorHealthRating = constants.ColorRating;

  // const customData = () => {
  //   let defaultCustomValue = {
  //     score: NaN,
  //     parameters: [
  //       {
  //         type: 'ttr',
  //         name: 'Time to Resolve',
  //         score: NaN,
  //         healthBucket: {
  //           id: 'bad',
  //           title: 'Bad',
  //           color: ColorHealthRating.bad
  //         },
  //         subtitle: ''
  //       },
  //       {
  //         type: 'csat',
  //         name: 'CSAT',
  //         score: NaN,
  //         healthBucket: {
  //           id: 'bad',
  //           title: 'Bad',
  //           color: ColorHealthRating.bad
  //         },
  //         subtitle: ''
  //       },
  //       {
  //         type: 'numCases',
  //         name: 'Number of Cases',
  //         score: NaN,
  //         healthBucket: {
  //           id: 'bad',
  //           title: 'Bad',
  //           color: ColorHealthRating.bad
  //         },
  //         subtitle: ''
  //       },
  //       {
  //         type: 'caseSentimentScore',
  //         name: 'Case Sentiment Score',
  //         score: NaN,
  //         healthBucket: {
  //           id: 'bad',
  //           title: 'Bad',
  //           color: ColorHealthRating.bad
  //         },
  //         subtitle: ''
  //       },
  //       {
  //         type: 'priority',
  //         name: 'Case Priority',
  //         score: NaN,
  //         healthBucket: {
  //           id: 'bad',
  //           title: 'Bad',
  //           color: ColorHealthRating.bad
  //         },
  //         subtitle: ''
  //       },
  //       {
  //         type: 'acv',
  //         name: 'Annual Contract Value',
  //         score: NaN,
  //         healthBucket: {
  //           id: 'bad',
  //           title: 'Bad',
  //           color: ColorHealthRating.bad
  //         },
  //         subtitle: ''
  //       },
  //       {
  //         type: 'numEscalatedCases',
  //         name: 'Number of Escalated Cases',
  //         score: NaN,
  //         healthBucket: {
  //           id: 'bad',
  //           title: 'Bad',
  // color: ColorHealthRating.bad
  //         },
  //         subtitle: ''
  //       }
  //     ]
  //   };
  //   if (!_.isEmpty(granularDetails)) {
  //     defaultCustomValue.score = granularDetails.score;
  //     defaultCustomValue.parameters?.map((defaultValue) => {
  //       const tempValue = granularDetails.parameters?.find(
  //         (value) => value.type === defaultValue.type
  //       );
  //       if (_.isNil(tempValue)) {
  //         return;
  //       }

  //       defaultValue.score = _.isNil(tempValue?.score) ? NaN : tempValue?.score;
  //       defaultValue.healthBucket.id =
  //         tempValue?.healthBucket?.id.toString() || 'bad';
  //       defaultValue.healthBucket.title =
  //         tempValue?.healthBucket?.title.toString() || 'Bad';
  //       defaultValue.healthBucket.color =
  //         ColorHealthRating[defaultValue.healthBucket.id];

  //       defaultValue.subtitle = tempValue.subtitle;
  //     });
  //   }
  //   defaultCustomValue.parameters = _.orderBy(
  //     defaultCustomValue.parameters.filter(
  //       (value: any) => !_.isNaN(value.score)
  //     ),
  //     ['score'],
  //     ['desc']
  //   );
  //   return defaultCustomValue;
  // };

  // const refillDetails = customData();
  // const getScoreBucket = (healthScore: Number) => {
  //   if (healthScore <= 40) {
  //     return 'Bad';
  //   } else if (healthScore <= 70) {
  //     return 'Average';
  //   } else {
  //     return 'Good';
  //   }
  // };

  // const gapBetweenScoreTypeAndValue = 7;
  const convertHourstoDays = (numberOfHours: number) => {
    var Days = Math.round(numberOfHours / 24);
    return Days;
  };

  const granularParameterMap = _.chain(granularDetails.parameters)
    .map('type')
    .keyBy(_.identity)
    .value();

  let contractExpiryDateFormatted = null;
  if (granularDetails?.parameters) {
    const contractExpiryValue = granularDetails.parameters.filter(
      (par) => par.type === 'contractExpiry'
    )[0]?.value;
    if (contractExpiryValue) {
      contractExpiryDateFormatted = new Date(
        contractExpiryValue
      ).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short'
      });
    }
  }

  return (
    <TablePaper>
      <Stack direction="vertical" gap={25} style={{ marginTop: 16 }}>
        <Text variant="p4" weight="semi-bolder" style={{ color: '#4F4F4F' }}>
          Important Stats
        </Text>
        {healthScore > 0 ? (
          <Stack direction="horizontal" style={{ marginLeft: '-20px' }}>
            {healthScore >= 0 && (
              <Stack
                style={{ alignSelf: 'center' }}
                // className="health-score-highlight-box"
                direction="vertical"
                align="center"
                // gap={gapBetweenScoreTypeAndValue}
              >
                {/* <Stack gap={7} align="center" style={{ marginLeft: '100px' }}> */}
                {healthScore !== 0 ? (
                  <GaugeChart
                    id="gauge-chart5"
                    nrOfLevels={100}
                    arcsLength={[0.4, 0.3, 0.3]}
                    colors={['#EB5757', '#F2C94C', '#219653']}
                    percent={toNumber('' + healthScore) / 100}
                    arcPadding={0.02}
                    cornerRadius={3}
                    textColor="#000"
                    formatTextValue={(value) => value + '/100'}
                  />
                ) : (
                  <Text variant="p4" weight="bold">
                    -
                  </Text>
                )}
                <Text
                  variant="p1"
                  weight="regular"
                  style={{ textAlign: 'center', color: '#4F4F4F' }}
                >
                  Your health score is{' '}
                  <span
                    style={{
                      background: getScoreColor(healthBucket?.title, 0.1),
                      color: getScoreColor(healthBucket?.title, 1),
                      paddingTop: '8px',
                      paddingBottom: '8px',
                      paddingLeft: '14px',
                      paddingRight: '14px'
                    }}
                  >
                    {healthBucket?.title}
                  </span>
                </Text>
              </Stack>
            )}
            <Stack direction="vertical" style={{ width: '100%' }}>
              <Stack
                direction="horizontal"
                align="center"
                style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  columnGap: '20px',
                  gridTemplateColumns: '1fr 1fr 1fr'
                }}
                // gap={gapBetweenScoreTypeAndValue}
              >
                {_.has(granularParameterMap, 'acv') && (
                  <StatItem
                    score={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'acv'
                      )[0]?.score
                    }
                    type="annualContractValue"
                    value={Utils.nFormatter(annualContractValue, 1, currency)}
                    label="Annual Contract Value"
                    bucket={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'acv'
                      )[0]?.healthBucket?.title
                    }
                  />
                )}

                {_.has(granularParameterMap, 'ttr') && (
                  <StatItem
                    score={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'ttr'
                      )[0]?.score
                    }
                    type="mttr"
                    value={mttr ? convertHourstoDays(mttr) + ' days' : '-'}
                    label="Mean time to resolve"
                    bucket={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'ttr'
                      )[0].healthBucket?.title
                    }
                  />
                )}

                {_.has(granularParameterMap, 'priority') && (
                  <StatItem
                    // value={}
                    score={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'priority'
                      )[0]?.score
                    }
                    type="priority"
                    label="Case Priority"
                    bucket={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'priority'
                      )[0].healthBucket?.title
                    }
                  />
                )}
              </Stack>
              <Stack
                direction="horizontal"
                align="center"
                style={{
                  marginTop: '24px',
                  display: 'grid',
                  gridAutoFlow: 'column',
                  columnGap: '20px',
                  gridTemplateColumns: '1fr 1fr 1fr'
                }}

                // gap={gapBetweenScoreTypeAndValue}
              >
                {_.has(granularParameterMap, 'numCases') && (
                  <StatItem
                    score={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'numCases'
                      )[0]?.score
                    }
                    type="totalCases"
                    value={totalCases}
                    label="Number of Cases"
                    bucket={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'numCases'
                      )[0].healthBucket?.title
                    }
                  />
                )}

                {_.has(granularParameterMap, 'csat') && (
                  <StatItem
                    score={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'csat'
                      )[0]?.score
                    }
                    type="csat"
                    value={
                      satisfactionRatingSurveyCount
                        ? satisfactionRatingScore + '%'
                        : '-'
                    }
                    additionalValue={
                      satisfactionRatingSurveyCount
                        ? satisfactionRatingSurveyCount + ' Surveys'
                        : ' '
                    }
                    label="CSAT Score"
                    bucket={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'csat'
                      )[0]?.healthBucket?.title
                    }
                  />
                )}

                <StatItem
                  type="closedCases"
                  value={closedCases}
                  label="Closed Cases"
                />
              </Stack>
              <Stack
                direction="horizontal"
                align="center"
                style={{
                  marginTop: '24px',
                  display: 'grid',
                  gridAutoFlow: 'column',
                  columnGap: '20px',
                  gridTemplateColumns: '1fr 1fr 1fr'
                }}
                // gap={gapBetweenScoreTypeAndValue}
              >
                {_.has(granularParameterMap, 'numEscalatedCases') && (
                  <StatItem
                    score={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'numEscalatedCases'
                      )[0]?.score
                    }
                    type="numEscalatedCases"
                    value={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'numEscalatedCases'
                      )[0]?.value
                    }
                    label="# of Escalated Cases"
                    // additionalValue={getSentiment(buckets, averageSentiment / 100)}
                    bucket={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'numEscalatedCases'
                      )[0]?.healthBucket?.title
                    }
                  />
                )}

                {_.has(granularParameterMap, 'caseSentimentScore') && (
                  <StatItem
                    score={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'caseSentimentScore'
                      )[0]?.score
                    }
                    type="caseSentimentScore"
                    value={getEmoji(
                      getSentiment(buckets, averageSentiment / 100)
                    )}
                    label="Case Avg Sentiment"
                    additionalValue={getSentiment(
                      buckets,
                      averageSentiment / 100
                    )}
                    bucket={
                      granularDetails.parameters?.filter(
                        (par) => par.type === 'caseSentimentScore'
                      )[0].healthBucket?.title
                    }
                  />
                )}

                {_.has(granularParameterMap, 'contractExpiry') &&
                  contractExpiryDateFormatted && (
                    <StatItem
                      score={
                        granularDetails.parameters?.filter(
                          (par) => par.type === 'contractExpiry'
                        )[0]?.score
                      }
                      type="contractExpiry"
                      value={contractExpiryDateFormatted}
                      label="Contract Expiry"
                      // additionalValue={getSentiment(buckets, averageSentiment / 100)}
                      bucket={
                        granularDetails.parameters?.filter(
                          (par) => par.type === 'contractExpiry'
                        )[0]?.healthBucket?.title
                      }
                    />
                  )}
              </Stack>
            </Stack>
          </Stack>
        ) : (
          `No cases in the last month`
        )}
      </Stack>
    </TablePaper>
  );
};
