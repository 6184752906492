import React, { Component } from 'react';
import './ThumbsUpDownComponent.scss';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import Constants from '../../../utils/constants';

interface Props {
  acceptanceStatus: any;
  onClickThumbs: any;
  title: string;
}

class ThumbsUpDownComponent extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  onClickThumbs = (new_acceptance_status: any) => {
    this.props.onClickThumbs(new_acceptance_status);
  };

  thumbIconClasses = (icon: string) => {
    let classNames = 'thumb-icon';
    let acceptanceStatus = this.props.acceptanceStatus;
    classNames +=
      icon === Constants.THUMBS_UP ? ' thumb-up-icon' : ' thumb-down-icon'; // Up/Down Icon

    if (
      (acceptanceStatus === Constants.ACCEPTANCE_STATUS_IGNORED &&
        icon === Constants.THUMBS_DOWN) ||
      (acceptanceStatus === Constants.ACCEPTANCE_STATUS_ACCEPTED &&
        icon === Constants.THUMBS_UP)
    ) {
      // Selected/Unselected Icon
      classNames += ' thumb-icon-selected';
    } else {
      classNames += ' thumb-icon-unselected';
    }

    return classNames;
  };

  render() {
    const { title } = this.props;
    return (
      <React.Fragment>
        <div className="thumbs-implementation-label">{title}</div>
        <div className="thumbs-container">
          <ThumbDownAltIcon
            onClick={this.onClickThumbs.bind(
              this,
              Constants.ACCEPTANCE_STATUS_IGNORED
            )}
            className={this.thumbIconClasses(Constants.THUMBS_DOWN)}
          />
          <ThumbUpAltIcon
            onClick={this.onClickThumbs.bind(
              this,
              Constants.ACCEPTANCE_STATUS_ACCEPTED
            )}
            className={this.thumbIconClasses(Constants.THUMBS_UP)}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ThumbsUpDownComponent;
