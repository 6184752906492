import React, { ReactElement } from 'react';

import { RulesRowManager } from '../../../utils';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as AddInternalNote } from '../../../../../assets/addInternalNote.svg';
import { ReactComponent as Tick } from '../../../../../assets/tick.svg';

import '../styles.scss';

/**
 * Row manager for Suggested Resolution
 */
export class AddInternalNoteRowManager extends RulesRowManager {
  constructor(
    options: Option[],
    actionValue: any,
    actionOperator: any,
    type: string
  ) {
    super(options, actionValue, actionOperator, type);
    this.action.type = 'add_internal_note';
  }

  setDefaultActionValue(): void {
    this.action.value = '';
  }

  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <AddInternalNote />
          <p className="title">Add an internal note to ticket</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <Tick
            style={{ alignSelf: 'center', marginRight: 10, marginLeft: 5 }}
          />
          <div style={{ fontWeight: 'normal', color: '#4F4F4F' }}>
            <p>
              New comments on Jira issue will be added as internal note to
              linked tickets
            </p>
          </div>
        </div>
      </div>
    );
  }
}
