import React from 'react';
import { TablePaper } from '../../components/common/blocks/TablePaper';
import Utils from '../../utils/utils';
import { Card, CardContent } from '@material-ui/core';
import { PageContainer } from '../../components/common/blocks/PageContainer';
import Grid from '@material-ui/core/Grid';
import { Loader } from '../../components/common/blocks/Loader';
import IrisPortalService from '../../services/IrisPortalService';
import _ from 'lodash';
import { ChatbotActivityCard } from './ChatbotActivityCard';
import { Stack, Text } from '../../components/common';
import { useQuery } from 'react-query';
import { CACHE_KEYS, CACHE_TIME } from '../../utils/cacheUtils';

export default function ChatbotActivityDashboard() {
  const [currentConversationId, setCurrentConversationId] = React.useState<
    string | undefined
  >(undefined);

  const { data: chatbotActivityData, isLoading } = useQuery(
    [CACHE_KEYS.CHATBOT_ACTIVITY],
    () => IrisPortalService.getChatbotActivity(),
    {
      ...CACHE_TIME[CACHE_KEYS.CHATBOT_ACTIVITY]
    }
  );

  if (isLoading) {
    return <Loader />;
  }

  const selectedChatbotActivity = _.find(
    chatbotActivityData,
    (chatbotActivity) =>
      _.isEqual(chatbotActivity.chatIdentifier, currentConversationId)
  );

  const highlightedBgColor = `#F7F9FE`;
  const borderStyle = `1px solid #F6F6F6`;

  return (
    <React.Fragment>
      <PageContainer className="tab-container">
        <TablePaper
          style={{
            fontFamily: 'inherit',
            minHeight: '97vh'
          }}
        >
          {
              _.isEmpty(chatbotActivityData) ?
                  <div style={{textAlign: 'center', marginTop: '10%'}}>
                      <Text weight={'bold'}>
                          No conversations to show yet.
                          <br/>
                          Please make sure that you have deployed IrisGPT bot on your website using steps on the "Configure" page.
                      </Text>
                  </div>
                  :
            <Grid
              container
              spacing={0}
              style={{
                marginTop: '24px',
                minHeight: '100%'
              }}
            >
              <Grid
                item
                lg={5}
                md={5}
                sm={12}
                xs={12}
                style={{
                  borderLeft: borderStyle,
                  borderRight: borderStyle,
                  minHeight: '97vh'
                }}
              >
                {chatbotActivityData?.map((chatbotActivity) => {
                  const conversation = chatbotActivity.conversation;
                  const userName = _.first(conversation)?.entity;
                  const shortMsg = _.first(conversation)?.message;
                  return (
                    <Card
                      variant={'outlined'}
                      onClick={() =>
                        setCurrentConversationId(chatbotActivity.chatIdentifier)
                      }
                      style={{
                        border: borderStyle,
                        borderRadius: '0px',
                        backgroundColor: _.isEqual(
                          currentConversationId,
                          chatbotActivity.chatIdentifier
                        )
                          ? highlightedBgColor
                          : '#FFFFFF'
                      }}
                    >
                      <CardContent>
                        <Stack direction="vertical" gap={10}>
                          <Stack direction="horizontal" align={'center'}>
                            <Text style={{ fontSize: 14 }} weight={'bold'}>
                              {userName}
                            </Text>
                            <Text className={'ml-auto'}>
                              {Utils.formatDate(
                                new Date(chatbotActivity.createdAt),
                                'll'
                              )}
                            </Text>
                          </Stack>
                          <Text style={{ fontSize: 14 }}>
                            {shortMsg ? shortMsg : ''}
                          </Text>
                        </Stack>
                      </CardContent>
                    </Card>
                  );
                })}
              </Grid>

              <Grid
                item
                lg={7}
                md={7}
                sm={12}
                xs={12}
                style={{
                  paddingLeft: '24px'
                }}
              >
                {!_.isNil(selectedChatbotActivity) &&
                  selectedChatbotActivity.conversation.map((conversation) => {
                    return (
                      <div>
                        <ChatbotActivityCard
                          userName={conversation.entity}
                          message={conversation.message}
                          timestamp={conversation.createdAt}
                        />
                        <br />
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
          }
        </TablePaper>
      </PageContainer>
    </React.Fragment>
  );
}
