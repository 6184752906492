import React, { FC, useMemo } from 'react';
import { Stack, Text } from '../../../components/common';
import { DetailedAccountMetrics } from './CaseMetrics';
import { CategoryDistributionChart } from './CategoryDistributionChart';
import { CaseTimeDistribution } from './CaseTimeDistribution';
import { SentimentDistribution } from './SentimentDistribution';
import { AccountHealth, HealthScore } from '../types';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import Utils from '../../../utils/utils';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import classNames from 'classnames';

export const RevenueDetail: FC<{
  accountHealth: AccountHealth;
}> = (props) => {
  const { accountHealth } = props;

  const useStyles = makeStyles((theme) => ({
    box: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'minmax(256px, 1fr)',
      height: '52px',
      borderRadius: '10px',
      backgroundColor: '#FFFFFF'
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.box}>
      <Stack align="center" style={{ padding: 10 }}>
        {accountHealth.accountDetails !== null &&
        !_.isNil(accountHealth.accountDetails.annualContractValue) ? (
          <Text
            variant="p2"
            weight="regular"
            style={{
              padding: 15,
              color: '#8181A5'
            }}
          >
            Annual Revenue{' '}
            <Text variant="p3" weight="bold" color="dark">
              {Utils.nFormatter(
                accountHealth.accountDetails.annualContractValue,
                1,
                accountHealth.accountDetails.currency
              )}
            </Text>
          </Text>
        ) : (
          ''
        )}
      </Stack>
    </div>
  );
};

export const ImportantStats: FC<{
  accountHealth: AccountHealth;
  accountName: string;
}> = (props) => {
  const { accountHealth } = props;
  var emptyHealthData = {} as HealthScore;
  const healthData =
    accountHealth.accountDetails && accountHealth.accountDetails.health
      ? accountHealth.accountDetails.health
      : emptyHealthData;

  return (
    <Stack direction="vertical" gap={20}>
      <DetailedAccountMetrics
        granularDetails={healthData}
        closedCases={accountHealth.closedCases}
        mttr={accountHealth.mttr}
        totalCases={accountHealth.totalCases}
        satisfactionRatingScore={accountHealth.satisfactionRatingsData.score}
        satisfactionRatingSurveyCount={
          accountHealth.satisfactionRatingsData.surveyCount
        }
        averageSentiment={accountHealth.averageSentimentScore}
        healthScore={accountHealth.accountDetails?.health?.score}
        healthBucket={accountHealth.accountDetails?.healthBucket}
        annualContractValue={accountHealth.accountDetails?.annualContractValue}
        currency={accountHealth.accountDetails?.currency}
        style={{ flexBasis: '100%' }}
      />
    </Stack>
  );
};

export const StatsGraphsCharts: FC<{
  accountHealth: AccountHealth;
  accountName: string;
}> = (props) => {
  const { accountHealth } = props;

  const categoryDistribution = useMemo(() => {
    if (!accountHealth) {
      return [];
    } else {
      return Object.keys(accountHealth.categoryCaseDistribution)
        .map((category) => ({
          name: category,
          value: Math.round(
            (accountHealth.categoryCaseDistribution[category] /
              accountHealth.totalCases) *
              100
          )
        }))
        .filter((data) => data.value !== 0);
    }
  }, [accountHealth]);

  const rhsClassName = classNames({
    'w-50': categoryDistribution.length,
    'w-100': !categoryDistribution.length
  });

  const rhsItemClassName = classNames({
    'w-50': !categoryDistribution.length,
    'w-100': categoryDistribution.length
  });

  return (
    <Stack direction="vertical" gap={15}>
      <Stack direction="vertical" gap={20}>
        <Stack direction="vertical" gap={40}>
          <Stack gap={30}>
            {categoryDistribution.length ? (
              <TablePaper style={{ width: '50%' }}>
                <CategoryDistributionChart
                  // style={{ flexBasis: '33%', flexGrow: 1 }}
                  data={categoryDistribution}
                  totalCases={accountHealth.totalCases}
                />
              </TablePaper>
            ) : null}
            {Object.keys(accountHealth.caseTimeDistribution).length ? (
              <Stack
                direction={
                  categoryDistribution.length ? 'vertical' : 'horizontal'
                }
                gap={20}
                className={rhsClassName}
              >
                <SentimentDistribution
                  data={accountHealth.caseTimeDistribution}
                  classes={{
                    root: rhsItemClassName
                  }}
                />
                <CaseTimeDistribution
                  data={accountHealth.caseTimeDistribution}
                  classes={{
                    root: rhsItemClassName
                  }}
                />
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
