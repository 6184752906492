import { Tooltip, withStyles } from '@material-ui/core';

export const DarkTooltip = withStyles(() => ({
  tooltip: {
    background: '#303030',
    color: '#fff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    maxWidth: 250,
    padding: '22px',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '21px'
  },
  arrow: {
    color: '#303030'
  }
}))(Tooltip);
