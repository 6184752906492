import React, { FC } from 'react';

import { Stack } from '../../../common';
import { DashboardSummary } from '../../types';
import './Summary.scss';

interface Props {
  data?: DashboardSummary;
}

export const SummaryItem: FC<Props> = (props) => {
  const data = props.data?.summary;

  return (
    <Stack direction="horizontal">
      <div className="item">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="text">{data?.caseCount}</p>
        </div>
        <p className="label">Overall ticket volume</p>
      </div>
      <div className="item">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="text">{data?.escalationCount}</p>
        </div>
        <p className="label">Escalations</p>
      </div>
      <div className="item">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="text">{data?.csat.score ? data?.csat.score : 0}%</p>
          <p className="content">{data?.csat.totalSurveyCount} Survey</p>
        </div>
        <p className="label">Overall CSAT score</p>
      </div>
      <div className="item">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="text">
            {Math.round(data?.mttr.mttr ? data?.mttr.mttr : 0)} min
          </p>
        </div>
        <p className="label">Overall MTTR</p>
      </div>
    </Stack>
  );
};
