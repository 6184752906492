import React from 'react';
import { Stack, Text } from './common';

export interface IrisCardProps {
  background?: string;
  style?: object;
  description: string;
}

export const IrisCardHighlightText: React.FC<{ text: any }> = (props) => {
  return (
    <React.Fragment>
      <Text
        weight="semi-bolder"
        style={{
          color: '#1C1D21',
          alignSelf: 'center',
          fontSize: 20
        }}
      >
        {props.text}
      </Text>
    </React.Fragment>
  );
};

export const IrisCard: React.FC<IrisCardProps> = (props) => {
  const { background = '#F7F9FE', description } = props;

  return (
    <div
      style={{
        flex: 1,
        borderRadius: 10,
        padding: 12,
        background,
        maxWidth: 'fit-content',
        ...props.style
      }}
    >
      <Stack direction="vertical" gap={10}>
        <Stack direction="horizontal" align={'center'}>
          {props.children}
        </Stack>
        <Text style={{ fontSize: 14 }}>{description}</Text>
      </Stack>
    </div>
  );
};
