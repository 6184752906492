import React, { ReactElement } from 'react';

import { FormControl } from '@material-ui/core';

import { RulesNativeSelect } from '../../RulesNativeSelect';

import { RulesRowManager } from '../../../utils/RulesRowManager';

import { HandleRowComponentType, Option } from '../../../types';
import { TextArea } from '../../../../../components/TextArea';
import { ReactComponent as BookOpen } from '../../../../../assets/bookOpen.svg';
import '../styles.scss';

/**
 * Row manager for Subject
 */
export class SubjectRowManager extends RulesRowManager {
  subjectOptions: Option[] = [
    { name: 'contains', label: 'Contains the following text' },
    { name: 'doesNotContain', label: 'Does not contain the follwing text' }
  ];

  constructor(options: Option[], actionValue: any, actionOperator: any) {
    super(options, actionValue, actionOperator);
    this.action.type = 'subject';
    this.action.operator =
      actionOperator === undefined ? 'contains' : actionOperator;
  }

  setDefaultActionValue(): void {
    this.action.value = '';
  }

  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    if (this.action.value === undefined) this.setDefaultActionValue();
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <BookOpen />
          <p className="title">Subject</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <div className={'fact-selector'}>
            <RulesNativeSelect
              options={this.subjectOptions}
              defaultValue={this.action.operator}
              onChange={(e) => {
                this.action.operator = e.target.value;
                handleRowComponent(this.action);
              }}
            />
          </div>
          <div className={'input-selector'}>
            <FormControl>
              <TextArea
                onChange={(e) => {
                  this.action.value = e.target.value;
                  handleRowComponent(this.action);
                }}
                placeholder="Write it here (Max 200 characters)"
                defaultValue={this.action.value}
                rowsMin={1}
                className="text-area"
              ></TextArea>
            </FormControl>
          </div>
        </div>
      </div>
    );
  }
}
