import React, { FC } from 'react';
import { useQuery } from 'react-query';
import IrisPortalService from '../../../services/IrisPortalService';
import Constants, {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE
} from '../../../utils/constants';
import { CACHE_KEYS, CACHE_TIME } from '../../../utils/cacheUtils';
import {
  MaxImpactSkeleton,
  MaxImpactSkeletonProps
} from '../max-support-impact/MaxImpactSkeleton';
import { IncidentSourcesData } from '../../../services/types';
import { Loader } from '../../../components/common/blocks/Loader';
import FireImage from '../../../assets/FireImage.svg';
import { DetectedIncidentsSettingsHolder } from '../trending-incidents-management/DetectedIncidentsManagementTable/DetectedIncidentsSettingsHolder/DetectedIncidentsSettingsHolder';

export const DetectedIncidentsTable: FC = (props) => {
  const { data: incidents, isLoading } = useQuery(
    [CACHE_KEYS.INCIDENT_SOURCES_DATA, DEFAULT_START_DATE, DEFAULT_END_DATE],
    () =>
      IrisPortalService.getIncidentSourcesData(
        DEFAULT_START_DATE,
        DEFAULT_END_DATE
      ),
    {
      ...CACHE_TIME[CACHE_KEYS.INCIDENT_SOURCES_DATA]
    }
  );
  const detectedIncidents =
    incidents?.filter(
      (incident) => incident.incidentSource === Constants.DETECTED_INCIDENTS_KEY
    ) || [];

  if (isLoading) {
    return <Loader center />;
  }

  const skeletonProps = prepareIncidents(detectedIncidents);
  return (
    <div>
      <div style={{ backgroundColor: 'white', padding: 18, borderRadius: 10 }}>
        <MaxImpactSkeleton
          {...skeletonProps}
          defaultSortOrder="timeOfCreation"
          enabledButtonNotification={true}
        />
      </div>
    </div>
  );
};

function prepareIncidents(data: IncidentSourcesData[]): MaxImpactSkeletonProps {
  return {
    heading: 'Trending incidents created from similar cases',
    tooltipText:
      'Our Early Warning Engine identifies trending problems by grouping similar cases created because of a common reason',
    iconCmp: <img src={FireImage} alt="fire" />,
    tagText: 'AI Recommended',
    timeline: 'Past two weeks',
    countText: `Total ${data.length} incidents`,
    data: data.map((incident) => ({
      timeOfCreation: incident.timeOfOccurrence,
      id: incident.incidentId,
      identifier: `ID ${incident.incidentNumber || 0}`,
      title: incident.title,
      incidentSource: incident.incidentSource,
      casesCount: incident.cases.length,
      cases: incident.cases.map((caseItem: any) => ({
        id: caseItem.databaseId,
        caseNumber: caseItem.caseNumber,
        Id: caseItem.Id,
        subject: caseItem.subject,
        url: caseItem.url,
        timeOfCreation: caseItem.timeOfCreation
      }))
    })),
    rightAlignedComponents: [<DetectedIncidentsSettingsHolder />]
  };
}
