import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { handleError, isMocKData } from './utils';

export const getKnowledgeBaseArticles = async (caseIds: any) => {
  if (!caseIds || caseIds.length === 0) {
    return [];
  }

  // let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return MockData.getKnowledgeBaseArticlesMockData();
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/dashboard/getKnowledgeBaseArticlesForCases`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token_express}`
      }),
      body: JSON.stringify({ caseIds: caseIds })
    }
  );
  handleError(response.status);

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
};
