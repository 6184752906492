import React, { FC, useEffect, useState } from 'react';
import './Switch.scss';
import _ from 'lodash';
import classNames from 'classnames';

export interface SwitchClasses {
  root?: string;
  button?: string;
  buttonClicked?: string;
  buttonParagraph?: string;
}

export const Switch: FC<{
  buttons: Array<string | React.ReactElement>;
  onChange: (id: any) => void;
  defaultSelection?: number;
  classes?: SwitchClasses;
}> = (props) => {
  const [clickedId, setClickedId] = useState<number>();
  useEffect(() => {
    if (_.isNumber(props.defaultSelection)) {
      setClickedId(props.defaultSelection);
    }
  }, [props.defaultSelection]);

  const handleClick = (id: any) => {
    if (id === clickedId) return;
    setClickedId(id);

    props.onChange(id);
  };

  const {
    classes: {
      root = '',
      button = '',
      buttonParagraph = '',
      buttonClicked = ''
    } = {}
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        marginRight: '8px',
        height: '21px',
        marginTop: '-2px'
      }}
      className={root}
    >
      {props.buttons.map((buttonLabel: any, i: any) => {
        const buttonClasses = classNames('button', button, {
          [buttonClicked]: _.isEqual(clickedId, i)
        });

        return (
          <button
            key={i}
            name={i}
            onClick={() => handleClick(i)}
            className={buttonClasses}
            style={
              clickedId === i
                ? i === 0
                  ? { background: '#5E81F4', borderRadius: '4px 0px 0px 4px' }
                  : { background: '#5E81F4', borderRadius: '0px 4px 4px 0px' }
                : {
                    background:
                      'linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #FFFFFF'
                  }
            }
          >
            <p
              style={
                clickedId === i ? { color: 'white' } : { color: '#5E81F4' }
              }
              className={`button-text ${buttonParagraph}`}
            >
              {buttonLabel}
            </p>
          </button>
        );
      })}
    </div>
  );
};
