import React, { ReactElement } from 'react';

import { RulesRowManager } from '../../../utils';
import CKEditorIris from '../../../../../CKEditorIris';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as SuggestedResolution } from '../../../../../assets/checkCircle.svg';
import '../styles.scss';
import _ from 'lodash';
import Constants from '../../../../../utils/constants';
import { Typography } from '@material-ui/core';

/**
 * Row manager for Suggested Resolution
 */
export class SuggestedResolutionRowManager extends RulesRowManager {
  constructor(
    options: Option[],
    actionValue: any,
    actionOperator: any,
    type: string
  ) {
    super(options, actionValue, actionOperator, type);
    this.action.type = 'suggested_resolution';
  }

  setDefaultActionValue(): void {
    this.action.value = '';
  }

  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <SuggestedResolution />
          <p className="title">Suggested Resolution</p>
        </div>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          align={'left'}
        >
          {Constants.TRIGGER_FORMAT_NUDGE}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <div style={{ fontWeight: 'normal', width: '99%' }}>
            <CKEditorIris
              defaultValue={this.action.value}
              onChange={_.debounce(
                (event: any, editor: { getData: () => any }) => {
                  const data = editor.getData();
                  this.action.value = data;
                  handleRowComponent(this.action);

                  // console.log(`Suggested resolution editor value changed to : ${data}`);
                },
                300
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
