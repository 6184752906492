import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { Paper } from '../../../components/home/Paper';
import { PageContainer } from '../../../components/common/blocks/PageContainer';
import { Stack } from '../../../components/common';
import { Loader } from '../../../components/common/blocks/Loader';
import {
  IModalConfirmationRef,
  ModalConfirmation
} from '../../../components/ModalConfirmation';
import { RulesTriggersTable } from './RulesTriggersTable';

// import Constants from '../../../utils/constants';
import IrisPortalService from '../../../services/IrisPortalService';
import Utils from '../../../utils/utils';

import { updateIndexCurrentTriggers, useTriggers } from '../utils';

import { Trigger, TriggerStatus, TriggerSystemType } from '../types';

import './Rules.scss';
import { useResetPage } from '../../../hooks/useResetPage';
import { TriggersImpactNumbers } from '../../../components/home/TriggersImpactNumbers';
import { TemplatesOverview } from './TriggerTemplate/TemplatesOverview';
import Constants from '../../../utils/constants';
import { useURLSearchParams } from '../../../utils/useURLSearchParams';
import _ from 'lodash';

export const Rules: React.FC<RouteComponentProps> = () => {
  // const history = useHistory();
  const { triggersData, isFetching, refetch } = useTriggers();

  const isAdmin = Utils.amIAdmin();

  const modalConfirmationRef = useRef<IModalConfirmationRef>(null);

  const formatDatatriggerTable = useCallback((dataTriggers: any) => {
    return dataTriggers?.map((trigger: Trigger) => {
      return {
        id: trigger.id,
        name: trigger.name,
        createdBy: trigger.creatorUser.email,
        status: trigger.status,
        raw: trigger
      };
    });
  }, []);

  const [dataTriggersFormat, setDataTriggersFormat] = useState(
    formatDatatriggerTable(triggersData?.trigger)
  );

  // const goToActionsTrigger = useCallback(() => {
  //   history.push(Constants.routes.TRIGGERS_DETAILS);
  // }, [history]);

  const handleOptionDisableEnabled = useCallback(
    async (triggerDE: Trigger) => {
      let statusUpdated = { field: 'status', value: 'disabled' };
      const triggerId = triggerDE.id || '';
      if (triggerDE.status === TriggerStatus.ENABLED) {
        statusUpdated.value = TriggerStatus.DISABLED;
      } else {
        statusUpdated.value = TriggerStatus.ENABLED;
      }
      const statusUpdate = await IrisPortalService.updateStatusTrigger(
        triggerId,
        statusUpdated
      );

      if (statusUpdate.ok) {
        updateIndexCurrentTriggers();
        Utils.showNotify('Trigger Status was Updated');
        const newTriggers = dataTriggersFormat.map((trigger: Trigger) => {
          if (trigger.id === triggerId) {
            trigger.status = statusUpdated.value as TriggerStatus;
          }

          return trigger;
        });
        setDataTriggersFormat(newTriggers);
      }
    },
    [dataTriggersFormat]
  );

  const updateTriggersList = useCallback(
    (newTriggerId: string, trigger: Trigger) => {
      let newTriggerList = [...dataTriggersFormat];
      trigger.id = newTriggerId;
      const [formatTrigger] = formatDatatriggerTable([trigger]);

      newTriggerList.unshift(formatTrigger);
      setDataTriggersFormat(newTriggerList);
    },
    [dataTriggersFormat, formatDatatriggerTable]
  );

  const handleOptionClone = useCallback(
    async (newNameTrigger: string, trigger: Trigger) => {
      delete trigger.id;
      trigger.name = newNameTrigger;
      try {
        const statusNew = await IrisPortalService.createTrigger(trigger);

        Utils.showNotify('Trigger Cloned');
        updateIndexCurrentTriggers();
        updateTriggersList(statusNew.body.id, trigger);
      } catch (error) {
        Utils.showNotify(
          'An error ocurred while cloning the trigger, please try again later'
        );
      }
    },
    [updateTriggersList]
  );

  const actionTrigger = useCallback(
    (actionType: string, trigger: any) => {
      if (actionType === 'enabled' || actionType === 'disabled') {
        handleOptionDisableEnabled(trigger);
      } else if (actionType === 'clone') {
        const newNameTrigger = trigger.name.concat(' ', 'Clone');
        const checkTrigger = dataTriggersFormat.find(
          (trigger: any) => trigger.name === newNameTrigger
        );
        if (checkTrigger === undefined) {
          handleOptionClone(newNameTrigger, trigger);
        } else {
          Utils.showNotify(
            'The trigger was cloned or exist some with same name'
          );
        }
      } else if (actionType === 'delete') {
        Utils.saveObjectItem('triggerToDelete', { current: trigger.id });

        modalConfirmationRef.current?.openModal();
      }
    },
    [dataTriggersFormat, handleOptionClone, handleOptionDisableEnabled]
  );

  const deleteCurrentTrigger = useCallback(
    async (triigerId: string) => {
      const statusDelete = await IrisPortalService.deleteTrigger(triigerId);
      if (statusDelete.ok) {
        updateIndexCurrentTriggers();
        Utils.showNotify('Trigger Deleted');
        const newTriggers = dataTriggersFormat.filter(
          (trigger: any) => trigger.id !== triigerId
        );
        setDataTriggersFormat(newTriggers);
      }
    },
    [dataTriggersFormat]
  );

  const handleOnModalConfirmTriggerDelete = useCallback(() => {
    const triggerIdCurrent = Utils.getObjectItem('triggerToDelete').current;
    deleteCurrentTrigger(triggerIdCurrent);

    Utils.removeObjectItem('triggerToDelete');
  }, [deleteCurrentTrigger]);

  useResetPage();

  useEffect(() => {
    setDataTriggersFormat(formatDatatriggerTable(triggersData?.triggers));
  }, [formatDatatriggerTable, triggersData]);

  const query = useURLSearchParams();
  const guide = query.get(Constants.TRIGGER_GUIDE) || undefined;

  return (
    <PageContainer>
      <div>
        {dataTriggersFormat && isAdmin && (
          <TemplatesOverview
            guide={guide}
            hasCaseDeflection={dataTriggersFormat.some(
              (trigger: any) =>
                trigger.raw.isSystem &&
                _.isEqual(
                  trigger.raw.system?.type,
                  TriggerSystemType.CASE_DEFLECTION
                ) &&
                _.isEqual(trigger.raw.status, TriggerStatus.ENABLED)
            )}
            triggerRefetch={refetch}
          />
        )}
      </div>
      <TriggersImpactNumbers invertColor={true} size={'sm'} />
      <Paper>
        <Stack direction="vertical">
          <Stack direction="vertical">
            {isFetching || !dataTriggersFormat ? (
              <Loader />
            ) : (
              <RulesTriggersTable
                data={dataTriggersFormat}
                getTypeActionItem={actionTrigger}
              />
            )}
          </Stack>
        </Stack>
        <ModalConfirmation
          ref={modalConfirmationRef}
          title={'Are you sure delete this Trigger?'}
          onConfirm={handleOnModalConfirmTriggerDelete}
          acceptButtonLabel={'Yes'}
          cancelButtonLabel={'No'}
        />
      </Paper>
    </PageContainer>
  );
};
