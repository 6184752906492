import React, { FC, useEffect } from 'react';
import './Agent.scss';
import { PageContainer } from '../../../components/common/blocks/PageContainer';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import { AgentsSummarySection } from './AgentsSummarySection';
import { AgentsTotalSection } from './AgentsTotalSection';
import { TopAgentsSection } from './TopAgentsSection';
import { useResetPage } from '../../../hooks/useResetPage';
import { Stack, Text } from '../../../components/common';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import { HighPriorityScoreCases } from '../../customerHealth/components/HighPriorityScoreCases';
import AccountSearch from '../../customerHealth/components/AccountSearch';
import Constants, {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE
} from '../../../utils/constants';
import { useHistory } from 'react-router-dom';
import {
  useDateRangeQueryForAgentData
} from '../utils';
import { Loader } from '../../../components/common/blocks/Loader';
import _ from 'lodash';

export const AgentOverview: FC = (props) => {
  useResetPage();

  useEffect(() => {
    logEventWithUserInfo('Agent View');
  }, []);

  const { data: agentAnalyticsData } = useDateRangeQueryForAgentData([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE
  ]);

  const history = useHistory();

  const agentNames = _.map(agentAnalyticsData?.agents, 'name');
  const agentNameToObjMap = _.keyBy(agentAnalyticsData?.agents, 'name');

  const handleSelectOption = (optionSelected: any) => {
    const agentId = agentNameToObjMap[optionSelected]?.id;
    history.replace(
      `${Constants.routes.AGENT_DETAILS}?${Constants.AGENT_ID_SEARCH_PARAM}=${agentId}`
    );
  };

  return (
    <div>
      <PageContainer className="manage-user-container">
        {/* {!isLoading && ( */}

        <div className="agent-searchBar">
          <div className="agent-searchBar-content">
            {agentAnalyticsData === undefined ? (
              <Loader />
            ) : (
              <div>
                <Stack
                  direction="horizontal"
                  align="center"
                  style={{ backgroundColor: 'white', padding: '16px' }}
                >
                  <Text
                    variant="p4"
                    weight="bold"
                    color="dark"
                    style={{ marginRight: '20px' }}
                  >
                    Search By Agent Name
                  </Text>
                  <AccountSearch
                    defaultSelection={undefined}
                    deactivateDropDown={false}
                    placeholder="Type here"
                    deactivateTitle={true}
                    withoutBorder={true}
                    dataList={agentNames}
                    redirectWithId={false}
                    getOptionSelected={handleSelectOption}
                  />
                </Stack>
              </div>
            )}
          </div>
        </div>

        <TablePaper>
          <AgentsSummarySection />
        </TablePaper>

        <Stack
          style={{
            padding: '0px',
            paddingBottom: '5px',
            background: 'white',
            borderRadius: '10px',
            marginTop: '2%'
          }}
        >
          <AgentsTotalSection />
        </Stack>

        <TablePaper style={{ marginTop: '2%' }}>
          <TopAgentsSection />
        </TablePaper>
        <TablePaper style={{ marginTop: '2%' }}>
          <HighPriorityScoreCases setHighPriorityCasesCount={() => {}} />
        </TablePaper>
        {/* )} */}
      </PageContainer>
    </div>
  );
};

export default AgentOverview;
