import React, { FC, useState } from 'react';
import {
  Stack,
  TableCell,
  TableRow,
  Text,
  ViewDetailsButton
} from '../../../components/common';
import { AiRecommendedCases } from '../../../services/types';
import { PriorityTooltip } from './types';
import queryString from 'query-string';
import Constants from '../../../utils/constants';
import { Link, useHistory } from 'react-router-dom';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import Utils from '../../../utils/utils';
import './AiRecommendedCaseRow.scss';
import { PriorityCell } from './PriorityCell';
import { ReactComponent as ExternalLink } from '../../../assets/external-link.svg';
import { ReactComponent as ExternalLinkGrey } from '../../../assets/external-link-grey.svg';

export const AiRecommendedCaseRow: FC<{
  caseItem: AiRecommendedCases;
  alwaysShowViewDetails?: boolean;
  hideLinkedIcon?: boolean;
}> = (props) => {
  const { caseItem, alwaysShowViewDetails, hideLinkedIcon = false } = props;
  const [showViewDetailsButton, setShowViewDetailsButton] = useState(false);
  const priorityItem = CreatePriority(caseItem);
  const history = useHistory();
  const getDetailsPageLink = () => {
    const params = queryString.stringify({
      [Constants.CASES_TYPE_QUERY_PARAM]:
        caseItem.incidentSource === Constants.JIRA_KEY && caseItem.suggestedByMl
          ? Constants.SUGGESTED_JIRA_QUERY_PARAM
          : undefined
    });
    return `/incidents-details/${caseItem.incidentSource}/${caseItem.incidentId}?${params}`;
  };

  return (
    <TableRow
      onMouseEnter={() => setShowViewDetailsButton(true)}
      onMouseLeave={() => setShowViewDetailsButton(false)}
      onClick={() => history.push(getDetailsPageLink())}
      style={{ cursor: 'pointer' }}
    >
      <TableCell variant="body">
        <PriorityCell priorityTooltipItem={priorityItem} type="" />
      </TableCell>
      <TableCell variant="body">
        {caseItem.caseLink ? (
          <a
            href={caseItem.caseLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            onClick={(e) => e.stopPropagation()}
          >
            <CaseNumberCell Id={caseItem.CaseNumber} />
          </a>
        ) : (
          <CaseNumberCell Id={caseItem.CaseNumber} />
        )}
      </TableCell>
      <TableCell variant="body">
        <Stack direction="horizontal" gap={10} justify="space-between">
          <Text lineClamp={2} variant="p2" weight="semi-bold" color="light">
            {caseItem.caseSubject}
          </Text>
        </Stack>
      </TableCell>
      <TableCell variant="body">
        <Text variant="p1" weight="medium" color="lighter">
          {Utils.formatDistanceToNowStrict(
            new Date(caseItem.caseTimeOfCreation),
            {
              addSuffix: true
            }
          )}
        </Text>
      </TableCell>
      <TableCell variant="body">
        <Stack direction="vertical">
          <Stack direction="horizontal" gap={10} align="start">
            <Text variant="p1" weight="regular" color="dark" lineClamp={2}>
              {caseItem.incidentTitle}
            </Text>
            {!caseItem.suggestedByMl && !hideLinkedIcon && (
              <img alt="" src="/images/link.png" width="20px" />
            )}
          </Stack>
          <Stack direction="horizontal" gap={10}>
            <Text variant="p2" weight="medium" color="lighter">
              {caseItem.incidentSource}
            </Text>
            {caseItem.incidentLink ? (
              <a
                href={caseItem.incidentLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <ExternalLink style={{ padding: 0 }} />
              </a>
            ) : (
              <ExternalLinkGrey />
            )}
          </Stack>
        </Stack>
      </TableCell>
      <TableCell align="right" padding="none" style={{ paddingRight: 10 }}>
        {alwaysShowViewDetails || showViewDetailsButton ? (
          <Link
            style={{ textDecoration: 'none' }}
            onClick={(e) => e.stopPropagation()}
            to={getDetailsPageLink()}
          >
            <ViewDetailsButton
              onClick={() => {
                logEventWithUserInfo('View Incident Details', {
                  incidentId: caseItem.incidentId,
                  incidentType: caseItem.incidentSource
                });
              }}
            />
          </Link>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

const CaseNumberCell: FC<{ Id: string }> = (props) => {
  return (
    <Text variant="p2" weight="semi-bold" style={{ color: '#30AABC' }}>
      {props.Id}
    </Text>
  );
};
export const CreatePriority = (caseItem: AiRecommendedCases) => {
  let priorityTooltipItem: PriorityTooltip = {
    Priority: caseItem.Priority,
    priorityScore: caseItem.priorityScore,
    caseSentimentBucket: caseItem.caseSentimentBucket,
    openTimeSecs: caseItem.openTimeSecs,
    commentsLength: caseItem.commentsLength,
    annualContractValue: caseItem.annualContractValue
  };
  return priorityTooltipItem;
};
