import React, {useCallback, useLayoutEffect, useRef} from 'react';

import {Multiselect} from 'multiselect-react-dropdown';

import {ReactComponent as ChipCloseIcon} from '../../assets/ChipCloseIcon.svg';

import './styles.scss';

type MultiselectProps = Omit<
    Multiselect['props'],
    'children' | 'options' | 'onSelect'
>;

export type MultiSelectInputOption = {
    label: string;
    [key: string]: any;
};

interface IMultiSelectInputProps extends MultiselectProps {
    options?: Array<MultiSelectInputOption>;
    valuesSelected?: any;
    onChange?: (value: Array<MultiSelectInputOption>) => void;
}

export const MultiSelectInput: React.FC<IMultiSelectInputProps> = ({
                                                                       onChange = () => undefined,
                                                                       options = [],
                                                                       valuesSelected = [],
                                                                       ...rest
                                                                   }) => {
    const multiselectContainerRef = useRef<HTMLDivElement>(null);
    const multiselectRef = useRef<Multiselect>(null);

    const handleSelect = useCallback(
        (selectedOptions: Array<MultiSelectInputOption>) => {
            onChange(selectedOptions);
        },
        [onChange],
    );

    useLayoutEffect(() => {
        const multiselectOptions = multiselectContainerRef.current?.getElementsByTagName(
            'li',
        );

        if (multiselectOptions) {
            for (let i = 0; i < multiselectOptions.length; i++) {
                const option = multiselectOptions.item(i) as HTMLLIElement;

                // Check that options are even present, without this check if we click on a cross, it will crash.
                if (options != undefined && options[i] != undefined) {
                    option.title = String(options[i].label).split(/\s/g).filter(Boolean).join(' ');
                }
            }
        }
    }, [options]);

    return (
        <div
            className="app-multiselect-input-container"
            title="wawa"
            ref={multiselectContainerRef}
        >
            <Multiselect
                displayValue="label"
                options={options.map((opt) => ({
                    ...opt,
                    // Ensure that label is a string, otherwise this flow crashes.
                    label: String(opt.label).split(/\s/g).filter(Boolean).join(' '),
                }))}
                onSelect={handleSelect}
                selectedValues={valuesSelected}
                customCloseIcon={<ChipCloseIcon/>}
                ref={multiselectRef}
                showCheckbox
                showArrow
                {...rest}
            />
        </div>
    );
};
