import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import {handleError, isMocKData} from './utils';
import qs from 'query-string';
import {AiRecommendedCases, IncidentSourcesData} from '../types';

export const getIncidentSourcesData = async (startTime: any, endTime: any) => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');
  let url;

  if (isMocKData) {
    return MockData.getIncidentSourcesMockData() as IncidentSourcesData[];
  }

  if (startTime && endTime) {
    let startTimeUTC = Utils.formatDateToUTC(startTime);
    let endTimeUTC = Utils.formatDateToUTC(endTime);
    url = `${process.env.REACT_APP_BACKEND_URL}/dashboard/getIncidentSourcesData?customerId=${user.customerId}&&startTime=${startTimeUTC}&&endTime=${endTimeUTC}`;
  } else {
    url = `${process.env.REACT_APP_BACKEND_URL}/dashboard/getIncidentSourcesData?customerId=${user.customerId}`;
  }

  const response = await fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${token_express}`
    })
  });

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Unhandled response code!');
  }

  const body: IncidentSourcesData[] = await response.json();
  return body;
};

export const getIncidentDetails = async (
  incidentSource: string,
  incidentId: string
) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  // TODO: Create a new mock functions when the data returned from the list API differs from the details API
  if (isMocKData) {
    return MockData.getIncidentSourcesMockData().find(
      (incident) => incident.incidentId === incidentId
    );
  }

  const url = `${process.env.REACT_APP_BACKEND_URL}/dashboard/getIncidentDetails?customerId=${user.customerId}&incidentSource=${incidentSource}&incidentId=${incidentId}`;
  const response = await fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`
    })
  });

  handleError(response.status);

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
};

export const updateAcceptanceStatus = async (
  incidentId: string,
  acceptanceStatus: string
) => {
  let token_express = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return '';
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token_express}`
    },
    body: JSON.stringify({
      incidentId: incidentId,
      acceptanceStatus: acceptanceStatus
    })
  };

  console.log('requestOptions ', requestOptions);
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/detectedIncident/updateAcceptanceStatus`,
    requestOptions
  );
  handleError(response.status);

  if (response.status !== 200) throw Error('response');
};

export const getAiRecommendedCases = async (startTime: any, endTime: any) => {
  const user = Utils.getObjectItem('user');
  const token = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return MockData.getAiRecommendedCases();
  }

  const params = qs.stringify({
    customerId: user.customerId,
    startTime: startTime ? Utils.formatDateToUTC(startTime) : undefined,
    endTime: startTime ? Utils.formatDateToUTC(endTime) : undefined
  });
  const url = `${process.env.REACT_APP_BACKEND_URL}/dashboard/getIncidentsDataForCases?${params}`;

  const response = await fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${token}`
    })
  });

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Unhandled response code!');
  }

  const responseJSON: AiRecommendedCases[] = await response.json();
  return responseJSON;
};

export const subscribeToDetectedIncidents = async () => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/dashboard/incidents/subscribe/email?customerId=${user.customerId}`,
    {
      body: JSON.stringify({
        email: user.email
      }),
      redirect: 'follow',
      method: 'PATCH',
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`,
        'Content-Type': 'application/json'
      })
    }
  );
  handleError(response.status);
  if (response.status !== 200) throw Error('response');
};
