import React, { ReactElement } from 'react';

import { RulesRowManager } from '../../../utils/RulesRowManager';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as TagSvg } from '../../../../../assets/tag.svg';
import '../styles.scss';
import {
  formatPrerequisitesData,
  useTriggersPrerequisites
} from '../../../utils';
import SearchWidget from '../../../../categories/components/SearchWidget';

/**
 * Row manager for Writing Tags
 */
export class WriteCaseTagsRowManager extends RulesRowManager {
  constructor(
    options: Option[],
    actionValue: any,
    actionOperator: any,
    type: string
  ) {
    super(options, actionValue, actionOperator, type);
    this.action.type = 'write_case_tags';
  }
  setDefaultActionValue(): void {
    this.action.value = '';
  }
  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    const prerequesitesData = useTriggersPrerequisites();
    const tagOptions = formatPrerequisitesData(
      prerequesitesData.triggersPrerequesites?.tags || []
    );
    let searchVal = '';
    if (this.action.value) {
      const tagObj = tagOptions.filter(
        (opt) => opt.name === this.action.value
      );
      if (tagObj && tagObj.length > 0) {
        searchVal = tagObj[0].label;
      }
    }
    return (
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row'}>
          <TagSvg />
          <p className="title">Add tag to case</p>
        </div>
        <div className={'d-flex flex-row'} style={{ marginTop: 8 }}>
          <p className="operator-text">Select tag to add</p>
          <div style={{ width: '20em' }}>
            <SearchWidget
              isCustom={false}
              searchValue={searchVal}
              dataList={tagOptions.map((tagValue) => {
                return tagValue.label;
              })}
              onInputChange={(input: string) => {
                const tagObj = tagOptions.filter(
                  (opt) => opt.label === input
                );
                if (tagObj && tagObj.length > 0) {
                  this.action.value = tagObj[0]['name'];
                } else {
                  this.action.value = '';
                }
                searchVal = input;
                handleRowComponent(this.action);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
