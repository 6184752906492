import React, { useState } from 'react';
import { useQuery } from 'react-query';
import IrisPortalService from '../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../utils/cacheUtils';
import { Loader } from '../../components/common/blocks/Loader';
import { Widget } from '../../components/common/Widget';
import Grid from '@material-ui/core/Grid';
import Constants, { TriggerAction } from '../../utils/constants';
import _ from 'lodash';
import { LineGraph } from '../../components/home/components/Graphs/LineGraph';

interface Chart {
  title: string;
  bins: any[];
}

export const AutomationTrend: React.FC = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date()
  ]);
  const [enabled, setEnabled] = React.useState<boolean>(false);

  const { data: trend, isFetching } = useQuery(
    [CACHE_KEYS.TRIGGERS_OVERALL_IMPACT_TREND, ...dateRange],
    () => IrisPortalService.getTriggersOverallImpactTrendData(...dateRange),
    {
      enabled,
      ...CACHE_TIME.get(CACHE_KEYS.TRIGGERS_OVERALL_IMPACT_TREND)
    }
  );

  const charts = React.useMemo<Chart[]>(() => {
    if (isFetching) {
      return [];
    }

    const actionToDataMap = _.chain(trend?.impactData)
      .map((impactDataTimeRange) => {
        const actionToCountMap = _.chain(impactDataTimeRange.data)
          .keyBy(_.first)
          .mapValues(_.last)
          .value();

        return _.chain(TriggerAction)
          .values()
          .map((action) => {
            return {
              date: impactDataTimeRange.timeRange.startTime,
              count: _.get(actionToCountMap, action, 0),
              action
            };
          })
          .value();
      })
      .flatten()
      .groupBy('action')
      .value();

    return Constants.TRIGGER_ACTION_GROUPS.map((actionGroup) => {
      const actions = actionGroup.actions;
      const bins = _.chain(actionToDataMap)
        .pick(...actions)
        .values()
        .flatten()
        .groupBy('date')
        .mapValues((values) => _.chain(values).map('count').sum().value())
        .toPairs()
        .map((pair) => {
          return {
            date: pair[0],
            count: pair[1]
          };
        })
        .value();

      return {
        title: actionGroup.description,
        bins
      };
    }).filter((chart) =>
      _.chain(chart.bins)
        .reject((bin) => _.isEqual(bin.count, 0))
        .isEmpty()
        .thru((result) => !result)
        .value()
    );
  }, [isFetching, trend]);

  return (
    <React.Fragment>
      {isFetching ? (
        <Loader />
      ) : (
        <Widget
          title={'Automation trend over time'}
          hasDateRange={true}
          onDateRangeChange={onDateRangeChange}
        >
          <Grid container spacing={1}>
            {charts
              .filter((chart) => !_.isEmpty(chart.bins))
              .map((chart) => {
                return (
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <div
                      className={'line-graph-container'}
                      style={{
                        width: '98%',
                        height: '98%'
                      }}
                    >
                      <LineGraph
                        title={chart.title}
                        distribution={chart.bins}
                        height={260}
                        lines={[
                          {
                            name: 'count',
                            label: 'Total Cases',
                            color: '#5E81F4'
                          }
                        ]}
                      />
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </Widget>
      )}
    </React.Fragment>
  );

  function onDateRangeChange(dateRange: [Date, Date]) {
    setEnabled(true);
    setDateRange(dateRange);
  }
};
