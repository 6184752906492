import React, { FC, useEffect } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { format } from 'date-fns';
import AdditionalDataTooltip from './AdditionalDataTooltip';
import './Graph.scss';

const COLORS = [
  '#EF5DA8',
  '#F2C94C',
  '#9F72FE',
  '#F2994A',
  '#219653',
  '#2E5BFF',
  '#8AF1B9',
  '#56CCF2'
];
export const BarGraph: FC<{
  distribution: any[];
  bars: any[];
  title: string;
  customTooltip?: boolean;
  additionalTipOrder?: number;
  yaxisFormatter?: (tick: any) => string;
  height?: number;
}> = (props) => {
  const {
    distribution,
    bars,
    title,
    customTooltip,
    additionalTipOrder,
    yaxisFormatter,
    height
  } = props;
  useEffect(() => {
    if (distribution.length > 0) {
      distribution.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );
    }
  }, [distribution]);

  return (
    <>
      {title && (
        <h2
          style={{
            marginTop: '25px',
            marginLeft: '25px',
            textAlign: 'left',
            height: 'fit-content'
          }}
        >
          {title}
        </h2>
      )}
      {distribution.length > 0 && (
        <ResponsiveContainer
          className="graph-container"
          height={height ? height : 350}
          width="100%"
        >
          <BarChart
            data={distribution}
            margin={{ top: 8, left: 0, right: 2, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="2 2" horizontal={false} />
            <XAxis
              dataKey="date"
              tickFormatter={(value: string) => {
                return value ? format(new Date(value), 'dd MMM') : '';
              }}
              tickSize={12}
            />
            <YAxis
              type="number"
              domain={[
                (dataMin: number) => Math.min(dataMin, dataMin * 0.5),
                (dataMax: number) => dataMax * 1.2
              ]}
              tickFormatter={(tick) => {
                return yaxisFormatter
                  ? yaxisFormatter(tick)
                  : new Intl.NumberFormat('en', {
                      style: 'currency',
                      currency: 'USD',
                      //@ts-ignore
                      notation: 'compact'
                    })
                      .format(tick)
                      .substring(1);
              }}
            />
            <Legend
              color="black"
              payload={bars
                .filter((it) => {
                  return it.hide !== true ? it : null;
                })
                .map((item, index) => ({
                  id: item.name,
                  type: 'circle',
                  value: item.label,
                  color: item.color,
                  formatter: (value, entry, index) => {
                    return (
                      <span
                        style={{
                          color: '#8798AD',
                          fontSize: '12px',
                          lineHeight: '10px',
                          font: 'DM Sans'
                        }}
                      >
                        {value}
                      </span>
                    );
                  }
                }))}
            />
            {bars.map((val: any, index: number) => {
              return (
                <Bar
                  dataKey={val.name}
                  fill={val.color ? val.color : COLORS[index % COLORS.length]}
                  hide={val.hide ? val.hide : false}
                  barSize={20}
                  style={{ margin: '20px' }}
                  // style={{ stroke: '#fff', strokeWidth: 1 }}
                />
              );
            })}
            {customTooltip && (
              <Tooltip
                cursor={{ fill: 'transparent' }}
                content={
                  <AdditionalDataTooltip
                    lines={bars}
                    additionalTipOrder={
                      additionalTipOrder !== undefined ? additionalTipOrder : 1
                    }
                  />
                }
              />
            )}
            {!customTooltip && (
              <Tooltip
                cursor={{ fill: 'transparent' }}
                labelFormatter={(label) => {
                  return <span>{format(new Date(label), 'dd MMM')}</span>;
                }}
                formatter={(value: string, name: string) => {
                  const bar = bars.filter((bar) => {
                    return bar.name === name;
                  })[0];

                  return [value, bar.label];
                }}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
