import React, { FC, useEffect } from 'react';
import { useMutation } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { Stack } from '../components/common';
import { Loader } from '../components/common/blocks/Loader';
import IrisPortalService from '../services/IrisPortalService';
import Constants from '../utils/constants';
import Utils from '../utils/utils';

export const GetSlackToken: FC<RouteComponentProps> = (props) => {
  const { history, location } = props;
  const params = new URLSearchParams(location.search);

  const { mutate: verifyToken, isLoading } = useMutation(
    IrisPortalService.getSlackToken
  );

  useEffect(() => {
    verifyToken(
      {
        code: params.get('code')!,
        state: params.get('state')!
      },
      {
        onSuccess: (response) => {
          Utils.showNotify(response.message);
          history.replace(Constants.routes.MANAGE_ACCOUNTS);
        },
        onError: (e) => {
          const error = e as any;
          console.log('VerifySlack Error');
          console.table({
            message: error.message,
            slackErrorCode: error.slackErrorCode
          });
          Utils.showError(error.message);
          history.replace(Constants.routes.MANAGE_ACCOUNTS);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack style={{ minHeight: '100vh' }} justify="center">
      {isLoading && <Loader center />}
    </Stack>
  );
};
