import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';

import { Text } from '../../../../components/common/blocks/Text';
import {
  IRulesTriggersTableItemData,
  RulesTriggersTableItem
} from './RulesTriggersTableItem';
import { RuleTriggersTableHeadButton } from './RulesTriggersTableHeadButton';

import './styles.scss';
import { useTableSorters } from '../../../../components/common';
import SearchWidget from '../../../categories/components/SearchWidget';

type TableState = 'all' | 'enabled' | 'disabled';

interface ITableStateFilter {
  (item: IRulesTriggersTableItemData): boolean;
}

interface IChangePageEventCallback {
  (event: React.MouseEvent<HTMLButtonElement> | null, page: number): void;
}

export type RulesTriggersTableData = Array<IRulesTriggersTableItemData>;

interface IRulesTriggersTableProps {
  data: RulesTriggersTableData;
  getTypeActionItem: (action: string, item: any) => void;
}

const getTableStatePredicate = (
  _state: ITableStateFilter,
  action: TableState
): ITableStateFilter => {
  switch (action) {
    default:
    case 'all':
      return (_rule) => true;
    case 'enabled':
      return (rule) => rule.status === 'enabled';
    case 'disabled':
      return (rule) => rule.status === 'disabled';
  }
};

// TODO(sarath): Migrate this component to use the new TableWithTabs component
export const RulesTriggersTable: React.FC<IRulesTriggersTableProps> = ({
  data,
  getTypeActionItem = () => {}
}) => {
  const [tablePredicate, dispatchTablePredicate] = useReducer(
    getTableStatePredicate,
    (_rule) => true
  );
  const { filterData, onPageChange, currentPage } = useTableSorters({
    pageSize: 10
  });
  const [tableState, setTableState] = useState<TableState>('all');

  const [triggerTitles, setTriggerTitles] = useState<string[]>([]);

  const [filteredData, setFilteredData] = useState(data.filter(tablePredicate));

  const { current: tablePaginationOptions } = useRef({
    component: 'div' as React.ElementType,
    rowsPerPage: 10,
    rowsPerPageOptions: [10]
  });

  const handleChangeTableState = useCallback((newState: TableState) => {
    setTableState(newState);
    dispatchTablePredicate(newState);
  }, []);

  useEffect(() => {
    setFilteredData(data.filter(tablePredicate));
    const titles: string[] = [];
    data.filter(tablePredicate).map((item: any) => {
      titles.push(item.name);
      return item;
    });
    setTriggerTitles(titles);
  }, [tablePredicate, data]);
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    if (searchValue === '') {
      setFilteredData(data.filter(tablePredicate));
    } else {
      const wordsInput = searchValue.split(/_|-| /).filter((val: any) => val);
      const newData = data.filter(tablePredicate).filter((item) => {
        return (
          wordsInput.filter(
            (word: any) =>
              item.name
                .split('_')
                .join('')
                .toLowerCase()
                ?.indexOf(word.toLowerCase().trim()) > -1
          ).length === wordsInput.length
        );
      });
      setFilteredData(newData);
      // setFilteredData(
      //   data.filter(tablePredicate).filter((item) => {
      //     return item.name.toLowerCase === searchValue.toLowerCase;
      //   })
      // );
    }
  }, [searchValue, data, tablePredicate]);
  return (
    <>
      <div
        className="rules-trigger-table-head-title"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <Text variant="p4" weight="semi-bolder" color="dark">
          Triggers
        </Text>
        <div
          style={{
            float: 'right',
            marginLeft: 'auto'
          }}
        >
          <div
            style={{
              width: '300px',
              height: '40px',
              marginRight: '16px'
            }}
          >
            <SearchWidget
              showDropdown={false}
              placeholder="Search"
              isCustom={true}
              searchValue={searchValue}
              dataList={triggerTitles}
              onInputChange={(input: string) => {
                setSearchValue(input);
              }}
            />
          </div>
        </div>
      </div>
      <TableContainer className="rules-trigger-table-container">
        <Table>
          <TableHead>
            <div className="rules-trigger-table-head-content">
              <div className="rules-trigger-table-head-buttons">
                <RuleTriggersTableHeadButton
                  subTitle="All"
                  onClick={() => handleChangeTableState('all')}
                  isSelected={tableState === 'all'}
                  title={`${
                    data.filter(getTableStatePredicate(() => true, 'all'))
                      .length
                  }`}
                />
                <RuleTriggersTableHeadButton
                  subTitle="Active"
                  onClick={() => handleChangeTableState('enabled')}
                  isSelected={tableState === 'enabled'}
                  title={`${
                    data.filter(getTableStatePredicate(() => true, 'enabled'))
                      .length
                  }`}
                />
                <RuleTriggersTableHeadButton
                  subTitle="Inactive"
                  onClick={() => handleChangeTableState('disabled')}
                  isSelected={tableState === 'disabled'}
                  title={`${
                    data.filter(getTableStatePredicate(() => true, 'disabled'))
                      .length
                  }`}
                />
              </div>
            </div>
            <TableRow>
              <TableCell align="left">NAME</TableCell>
              <TableCell align="left">CREATED BY</TableCell>
              <TableCell align="left"># CASES IMPACTED</TableCell>
              <TableCell align="left">ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterData(filteredData).map((item, index) => (
              <RulesTriggersTableItem
                key={`${item.id}.${index}`}
                item={item}
                getTypeActionItem={getTypeActionItem}
              />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={filteredData.length}
          onChangePage={(_, page) => onPageChange(page)}
          page={currentPage}
          {...tablePaginationOptions}
        />
      </TableContainer>
    </>
  );
};
