import React, { FC, ReactElement, useCallback, useState } from 'react';

import { Button, FormControl, MenuItem, Select } from '@material-ui/core';

import { Option } from '../../types';

import './styles.scss';
import { ReactComponent as PlusWhite } from '../../../../assets/plus-white.svg';

export const RulesAddButton: FC<{
  onChange: (value: string) => void;
  options: Option[];
  children: string | ReactElement | ReactElement[];
}> = ({ onChange, options, children }) => {
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const handleClose = useCallback(() => {
    setShowSelect(false);
  }, []);

  const handleOpen = useCallback(() => {
    setShowSelect(true);
  }, []);

  return (
    <>
      {showSelect ? (
        <FormControl
          style={{
            minWidth: 60
          }}
        >
          <Select
            style={{
              width: '80px',
              height: '20px',
              marginLeft: '12px',
              marginBottom: '12px'
            }}
            variant="outlined"
            value=""
            defaultValue=""
            open={showSelect}
            onOpen={handleOpen}
            onClose={handleClose}
            onChange={(e) => {
              onChange(e.target.value as string);
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              getContentAnchorEl: null
            }}
          >
            {options.map(({ name, label }, index) => (
              <MenuItem
                value={name}
                key={index}
                disabled={options[index].disabled}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Button
          style={{
            backgroundColor: '#5E81F4',
            height: '20px',
            marginLeft: '12px',
            marginBottom: '12px',
            marginTop: '-2px',
            width: 'max-content'
          }}
          startIcon={<PlusWhite stroke="white" strokeWidth="2px" />}
          onClick={handleOpen}
        >
          {children}
        </Button>
      )}
    </>
  );
};
