import React, { Component } from 'react';
import './ProgressBar.scss';
import {Box, LinearProgress, LinearProgressProps, Typography} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

export const LinearProgressBar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
      width: '100%'
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
      borderRadius: 5
    }
  })
)(LinearProgress);

export function LinearProgressBarWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgressBar variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

interface Props {
  sentimentScore: any;
  decimalQuantity: number;
}

class ProgressBar extends Component<Props, any> {
  render() {
    const { sentimentScore, decimalQuantity } = this.props;
    let sentimentScoreFormated = sentimentScore;
    let isPositive = true;
    sentimentScoreFormated =
      sentimentScoreFormated === null || sentimentScoreFormated === undefined
        ? 0
        : sentimentScoreFormated;
    if (sentimentScoreFormated < 0) {
      sentimentScoreFormated = sentimentScoreFormated * -1;
      isPositive = false;
    }
    if (sentimentScoreFormated > 0 && sentimentScoreFormated < 1) {
      sentimentScoreFormated = sentimentScoreFormated * 100;
    }
    sentimentScoreFormated = parseFloat(sentimentScoreFormated).toFixed(
      decimalQuantity
    );

    let BarContainerClasses = isPositive
      ? 'progress-bar-container positive-status'
      : 'progress-bar-container negative-status';
    let LinearProgressBarClass = isPositive ? 'positive-bar' : 'negative-bar';
    let sentimentScoreLabel = isPositive
      ? sentimentScoreFormated
      : sentimentScoreFormated * -1;

    return (
      <div className={BarContainerClasses}>
        -ve&nbsp;{' '}
        <LinearProgressBar
          className={LinearProgressBarClass}
          variant="determinate"
          value={sentimentScoreFormated}
        />
        {sentimentScoreLabel}%
      </div>
    );
  }
}

export default ProgressBar;
