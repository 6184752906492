import _ from 'lodash';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import IrisPortalService from '../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../utils/cacheUtils';
import Utils from '../../utils/utils';
import { Bucket } from '../../components/common';

export const useNavigateToCategory = () => {
  const history = useHistory();
  const navigate = useCallback(
    (category: string) => {
      history.replace({ hash: category });
    },
    [history]
  );

  return navigate;
};

export const useCurrentCategory = () => {
  const { hash } = useLocation();
  return hash.slice(1);
};

export function useDateRangeQuery(dateRange: Date[], attributeFilter = {}) {
  return useQuery(
    [dateRange, attributeFilter],
    () =>
      IrisPortalService.getCaseCategoriesData(
        dateRange[0],
        dateRange[1],
        RECENT_CASE_LIMIT,
        _.isEmpty(attributeFilter) ? undefined : attributeFilter
      ),
    {
      refetchOnWindowFocus: false,
      onError: (e) => {
        Utils.showError(String(e));
      },
      onSuccess: (caseCategories) => {},
      ...CACHE_TIME.get(CACHE_KEYS.CATEGORIES_DATA)
    }
  );
}

export const RECENT_CASE_LIMIT = 100;
export const SCROLL_LIMIT = 10;
export const buckets: Bucket[] = [
  {
    title: 'Negative',
    range: [-1, 0],
    color: '#FF9C06',
    includeRight: false,
    includeLeft: false
  },
  {
    title: 'Neutral',
    range: [0, 0],
    color: '#E8E36E',
    includeRight: true,
    includeLeft: true
  },
  {
    title: 'Positive',
    range: [0, 1],
    color: '#75E44E',
    includeRight: false,
    includeLeft: false
  }
];
export const getSentiment = (buckets: Bucket[], sentimentValue: number) => {
  for (var i = 0; i < buckets.length; i++) {
    var condition1;
    var condition2;
    if (buckets[i].includeLeft) {
      condition1 = buckets[i].range[0] <= sentimentValue;
    } else {
      condition1 = buckets[i].range[0] < sentimentValue;
    }
    if (buckets[i].includeRight) {
      condition2 = buckets[i].range[1] >= sentimentValue;
    } else {
      condition2 = buckets[i].range[1] > sentimentValue;
    }
    if (condition1 && condition2) {
      return buckets[i].title;
    }
  }
};
export const getEmoji = (sentiment: string | undefined) => {
  if (sentiment === 'Positive') {
    return String.fromCodePoint(0x1f60a);
  } else if (sentiment === 'Neutral') {
    return String.fromCodePoint(0x1f610);
  } else {
    return String.fromCodePoint(0x1f620);
  }
};
