import { RouteComponentProps } from 'react-router';
import { Page } from '../Page';
import { CategoriesOverview } from '../categories/components/CategoriesOverview';
import React from 'react';

export const AutomationCategories: React.FC<RouteComponentProps> = () => {
  return (
    <Page>
      <CategoriesOverview />
    </Page>
  );
};
