import React, { FC } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { ClassNames } from '@emotion/react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export const ViewDetailsButton: FC<ButtonProps> = (props) => {
  const { className, ...restButtonProps } = props;
  return (
    <ClassNames>
      {({ css, cx }) => (
        <Button
          {...restButtonProps}
          className={cx(
            className,
            css`
              width: 150px;
              border-radius: 100px;
              border: solid 1px #1aabd8;
              color: #1aabd8;
              font-weight: bold;
              .view-details-button-arrow {
                font-size: 16px;
              }
            `
          )}
        >
          View Details
          <ArrowForwardIosIcon className="view-details-button-arrow" />
        </Button>
      )}
    </ClassNames>
  );
};
