import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import IrisPortalService from '../../../services/IrisPortalService';
import { CACHE_KEYS } from '../../../utils/cacheUtils';
import { CACHE_TIME } from './../../../utils/cacheUtils';

export function useTriggerRuleDetailsState(ruleId?: string) {
  const [ruleDetail, setRuleDetail] = useState<any>({
    name: '',
    rule: {
      type: 'compound',
      operator: 'and',
      operands: [
        {
          type: 'compound',
          operator: 'and',
          operands: []
        },
        {
          type: 'compound',
          operator: 'or',
          operands: []
        }
      ]
    },
    actions: []
  });

  const { status, data, isFetching, refetch } = useQuery(
    [`${CACHE_KEYS.TRIGGER_DATA}${ruleId ? `/${ruleId}` : ''}`],
    () => IrisPortalService.getTrigger(ruleId),
    {
      ...CACHE_TIME[CACHE_KEYS.TRIGGER_DATA]
    }
  );

  const setRuleDetailFormatted = useCallback((trigger) => {
    if (!trigger) {
      return;
    }

    const triggerOperands = trigger.rule.operands.reduce(
      (prev: any, cur: any) => {
        const andOperands = cur.operator === 'and' ? cur.operands : [];
        const orOperands = cur.operator === 'or' ? cur.operands : [];

        const oldPrevious = [...prev];

        oldPrevious[0].operands.push(...andOperands);
        oldPrevious[1].operands.push(...orOperands);

        return oldPrevious;
      },
      [
        {
          type: 'compound',
          operator: 'and',
          operands: []
        },
        {
          type: 'compound',
          operator: 'or',
          operands: []
        }
      ]
    );

    setRuleDetail({
      ...trigger,
      rule: {
        type: 'compound',
        operator: 'and',
        operands: triggerOperands
      },
      actions: trigger.actions.map((action: any) => ({
        id: action._id,
        type: action.type,
        value: action.data.value
      }))
    });
  }, []);

  useEffect(() => {
    if (ruleId && status === 'success') {
      setRuleDetailFormatted(data.trigger);
    }
  }, [data, isFetching, ruleId, setRuleDetailFormatted, status]);

  return [ruleDetail, setRuleDetail, refetch];
}
