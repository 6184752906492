import React from 'react';

import type { SelectProps } from '@material-ui/core';
import { FormControl, Select } from '@material-ui/core';

import { Option } from '../../types';

interface RulesNativeSelectProps extends SelectProps {
  options: Option[];
  defaultValue: string;
  onChange?: (e: any) => void;
}
export const RulesNativeSelect: React.FC<RulesNativeSelectProps> = ({
  options = [],
  onChange = (e) => {},
  defaultValue,
  open = false,
  value,
  ...rest
}) => {
  return (
    <FormControl>
      <Select
        {...rest}
        style={{ height: 36, fontSize: '14px' }}
        native
        variant="outlined"
        defaultValue={defaultValue}
        onChange={onChange}
      >
        <option disabled value="" selected={'' === defaultValue}>
          Choose option
        </option>

        {options.map((selectOption, index) => (
          <option
            key={`${selectOption.name}-${index}`}
            value={selectOption.name}
            selected={selectOption.name === defaultValue}
          >
            {selectOption.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
