import React, { FC, useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { makeStyles } from '@material-ui/core';
import { Heading, Stack, Text } from '../../../components/common';
import { format } from 'date-fns';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { AgentDistributionData, CaseDistributionOptionStyle } from '../types';
import { AgentDistributionLabels } from '../constants';
import { formatAgentDistributionData, useSingleAgentQuery } from '../utils';
import { Loader } from '../../../components/common/blocks/Loader';

type Props = {
  heading: string;
  subHeading: string;
  agentId: string;
  waitFor: any;
};

export const AgentDistribution: FC<Props> = (props) => {
  const useStyles = makeStyles(() => ({
    box: {
      width: 147,
      height: 42,
      color: '#8181A5',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '17px',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column'
    }
  }));
  const classes = useStyles();

  const { heading, subHeading, agentId, waitFor } = props;

  const { data: agentSingleAnalyticsData } = useSingleAgentQuery(agentId);

  const [dataIntentFull, setDataIntentFull] = useState<AgentDistributionData[]>(
    []
  );
  const [originalData, setOriginalData] = useState<AgentDistributionData[]>([]);

  useEffect(() => {
    const data: AgentDistributionData[] = formatAgentDistributionData(
      agentSingleAnalyticsData?.resolvedCasesPerDay
    );
    data.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    setOriginalData(data);
    setDataIntentFull(data);
  }, [agentSingleAnalyticsData]);

  const dataList: CaseDistributionOptionStyle[] = AgentDistributionLabels;

  const [categoriesDataListSelected, setCategoriesDataListSelected] = useState(
    dataList
  );

  const updateIntentSelected = (
    intentStatus: boolean,
    intentKey: string
  ) => () => {
    const categoriesDataUpdate = categoriesDataListSelected.map((intent) => {
      if (intent.category_key === intentKey) {
        intent.status = !intentStatus;
      }
      return intent;
    });
    let copyData: any = [];

    copyData = originalData.map((objectI: any) => {
      return { ...objectI };
    });
    setCategoriesDataListSelected(categoriesDataUpdate);
    // eslint-disable-next-line array-callback-return
    categoriesDataListSelected.map((intent: any) => {
      // eslint-disable-next-line array-callback-return
      copyData.map((intentData: any) => {
        if (!intent.status) {
          delete intentData[intent.category_key];
        }
      });
    });
    setDataIntentFull(copyData);
  };

  const CustomTooltip = (dataValues: any) => {
    const { active, payload, label } = dataValues;
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            border: '1px solid gray',
            fontFamily: `"DM Sans", sans-serif`,
            fontStyle: 'normal',
            fontSize: 14,
            padding: 6
          }}
        >
          <p style={{ color: 'black', margin: '0 auto', textAlign: 'left' }}>
            {format(new Date(label), 'MMM dd, yyyy')}
          </p>
          {payload.map((intent: any, index: any) => (
            <p
              key={index}
              style={{
                color: intent.stroke,
                margin: '0 auto',
                textAlign: 'left'
              }}
            >{`${intent.dataKey}: ${intent.value}`}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ padding: '25px 0' }}>
      <Stack direction="vertical" gap={30} align="flex-start">
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Heading>{heading}</Heading>
          <div className={classes.box}>{subHeading}</div>
        </div>
        {agentSingleAnalyticsData === undefined || waitFor === undefined ? (
          <div style={{ width: '100%' }}>
            <Loader center />
          </div>
        ) : dataIntentFull.length === 0 ? (
          <div style={{ marginBottom: '2%', width: '100%' }}>
            <Text style={{ fontSize: 17, fontWeight: 600, color: 'black' }}>
              No Data
            </Text>
          </div>
        ) : (
          <div
            style={{
              display: 'inline',
              width: '100%',
              border: '1px solid rgba(46, 91, 255, 0.08)'
            }}
          >
            <div
              style={{
                display: 'flex',
                marginLeft: '5%',
                marginTop: '3%',
                marginBottom: '2%'
              }}
            >
              {categoriesDataListSelected.map((intent: any, index: any) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    marginLeft: '2%',
                    cursor: 'pointer'
                  }}
                  onClick={updateIntentSelected(
                    intent.status,
                    intent.category_key
                  )}
                >
                  <div
                    style={{
                      backgroundColor: intent.status
                        ? intent.colors.color
                        : 'gray',
                      borderRadius: 15,
                      width: 21,
                      height: 20,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <VisibilityOutlinedIcon
                      fontSize="small"
                      style={{
                        color: 'white',
                        backgroundColor: intent.status
                          ? intent.colors.color
                          : 'gray',
                        borderRadius: 12,
                        width: 15,
                        height: 15,
                        marginLeft: '14%'
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: '#8798AD',
                      marginLeft: 10,
                      fontFamily: `'DM Sans', sans-serif`,
                      fontStyle: 'normal',
                      fontSize: 15,
                      fontWeight: 400
                    }}
                  >
                    {intent.category_name_format}
                  </span>
                </div>
              ))}
            </div>
            <div style={{ marginLeft: '4%' }}>
              <ResponsiveContainer height={300} width="100%">
                <AreaChart
                  style={{ marginLeft: '-25px' }}
                  data={dataIntentFull}
                >
                  {categoriesDataListSelected?.map(
                    (category: any, index: any) => (
                      <defs key={`color_${index}`}>
                        <linearGradient
                          id={category.category_key}
                          x1={0.5}
                          x2={0.5}
                          y2={1}
                        >
                          <stop
                            stopColor={category.colors.gradientColor.first}
                            stopOpacity={0.3}
                          />
                          <stop
                            offset={1}
                            stopColor={category.colors.gradientColor.last}
                            stopOpacity={0.01}
                          />
                        </linearGradient>
                      </defs>
                    )
                  )}
                  <XAxis
                    dataKey="date"
                    tickFormatter={(value: string) =>
                      format(new Date(value), 'MMM dd')
                    }
                    minTickGap={25}
                    tickSize={12}
                    fontSize={15}
                    fontWeight={400}
                    color="#B0BAC9"
                    fontFamily='"DM Sans", sans-serif'
                    fontStyle="normal"
                    label={'Case Date of Creation'}
                  />
                  <YAxis
                    tickSize={0}
                    tickMargin={20}
                    fontSize={15}
                    fontWeight={400}
                    color="#B0BAC9"
                    fontFamily='"DM Sans", sans-serif'
                    fontStyle="normal"
                  />
                  <CartesianGrid strokeDasharray="3 3" />
                  {categoriesDataListSelected?.map(
                    (category: any, index: any) => (
                      <Area
                        key={index}
                        dataKey={category.category_key}
                        stroke={category.colors.color}
                        fill={`url(#${category.category_key}`}
                        strokeWidth={2}
                        type="monotone"
                        animationDuration={6000}
                      />
                    )
                  )}
                  <Tooltip content={<CustomTooltip />} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
      </Stack>
    </div>
  );
};
