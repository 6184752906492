import React, { FC } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { format } from 'date-fns';
import { Heading, Stack, Text } from '../common';
import { CausedCase } from './types';
import { createGraphData, getTimeRange } from './utils';
import { TimeRange } from './TimeRange';
import { TablePaper } from '../common/blocks/TablePaper';

export const IncidentCasesDistribution: FC<{
  causedCases: any;
  incidentId: any;
  isJiraIssue: boolean;
}> = (props) => {
  const { causedCases: _causedCases, incidentId, isJiraIssue } = props;
  const casesCausedByIncidents = _causedCases as CausedCase[];
  const graphData = createGraphData(casesCausedByIncidents);
  const timeRange = getTimeRange(graphData.map((data) => data.timeOfCreation));
  const label = isJiraIssue ? 'Issue Key' : 'Incident Number';
  return (
    <TablePaper>
      <Stack direction="vertical" gap={30} align="flex-start">
        <Stack direction="vertical" gap={50} style={{ width: '100%' }}>
          <Stack justify="space-between" style={{ marginTop: 16 }}>
            <Heading>
              Total cases caused by {label} {incidentId}
            </Heading>
            <Text>{timeRange ? <TimeRange {...timeRange} /> : null}</Text>
          </Stack>
        </Stack>

        <Text style={{ marginBottom: -20 }}>Total Cases per day</Text>
        <ResponsiveContainer height={300} width="100%">
          <AreaChart data={graphData}>
            <defs>
              <linearGradient id="prefix__a" x1={0.5} x2={0.5} y2={1}>
                <stop stopColor="#5F83FA" stopOpacity={0.3} />
                <stop offset={1} stopColor="#FFFFFF" stopOpacity={0.01} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="timeOfCreation"
              tickFormatter={(value: string) =>
                format(new Date(value), 'MMM dd, yyyy')
              }
              tickSize={12}
            />
            <YAxis tickSize={0} tickMargin={20} />
            <CartesianGrid strokeDasharray="3 3" />
            <Area
              dataKey="causedCasesCount"
              stroke="#5F83FA"
              fill="url(#prefix__a)"
              name="Cases Caused By Incident"
            />
            <Tooltip
              labelFormatter={(label) => {
                return <span>{format(new Date(label), 'MMM dd, yyyy')}</span>;
              }}
              formatter={(value: string) => {
                return [value, 'Cases'];
              }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Stack>
    </TablePaper>
  );
};
