import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Summary } from '../../components/home/components/Summary/Summary';
import { ImportantEscalationSupportMetrics } from './ImportantEscalationSupportMetrics';
import { Page } from '../Page';
import Utils from '../../utils/utils';
import Constants from '../../utils/constants';
import { DetectedIncidentsTable } from '../incidents/incident-list/DetectedIncidentsTable';
import { HighPriorityScoreCases } from '../customerHealth/components/HighPriorityScoreCases';
import { PrioritizedEngineeringEscalations } from '../../components/PrioritizedEngineeringEscalations';
import '../incidents/incident-list/RecentIncidents.scss';
import '../incidents/max-support-impact/MaxSupportImpactIncidents.scss';

export const Escalations: React.FC<RouteComponentProps> = () => {
  const isOnboarded = Utils.checkOnboardedValue();

  return (
    <React.Fragment>
      <Page
        classes={{
          root: 'tab-container'
        }}
      >
        <div>
          <Summary />
        </div>

        <div>
          <ImportantEscalationSupportMetrics />
        </div>

        <HighPriorityScoreCases />

        <PrioritizedEngineeringEscalations />

        {isOnboarded && (
          <div
            style={{ marginTop: '2%' }}
            id={Constants.incidents_sections.trending_incidents}
          >
            <DetectedIncidentsTable />
          </div>
        )}
      </Page>
    </React.Fragment>
  );
};
