import React from 'react';
import {Typography} from '@material-ui/core';
import {PageContainer} from '../../components/common/blocks/PageContainer';
import {Stack, Text} from '../../components/common';
import IrisPortalService from '../../services/IrisPortalService';
import './IrisGPT.scss';
import {IndexData} from '../../services/IrisPortalService/irisGPT';
import Utils from '../../utils/utils';
import {Loader} from '../../components/common/blocks/Loader';
import {Redirect} from 'react-router-dom';
import Constants from '../../utils/constants';
import {TablePaper} from '../../components/common/blocks/TablePaper';
import {useQuery} from 'react-query';
import {CACHE_TIME} from '../../utils/cacheUtils';
import {Button} from '../../components/home/Button';
import * as useHooks from "@uidotdev/usehooks";
import _ from "lodash";

export default function IrisGPT() {
    const [isOperational] = React.useState<boolean>(true);

    const callAPI = Utils.isLoggedIn() || Utils.isDemo();

    const {data: customer, isLoading, status: customerQueryStatus} = Utils.useCustomerQuery(callAPI);

    let user = Utils.getObjectItem('user');

    const {
        data: indexData,
        isLoading: isIndexDataLoading
    } = useQuery<IndexData>(
        [Constants.STORAGE_KEY.TRIGGER_INDEX_DATA],
        () => IrisPortalService.getIndexData(),
        {
            refetchOnWindowFocus: false,
            onError: (e) => {
            },
            onSuccess: (caseCategories) => {
            },
            ...CACHE_TIME.get('')
        }
    );

    const chatBotBundleScriptStatus = useHooks.useScript(`${Utils.getFrontendAPIServerUrl()}/v1/chatbot/bundle`);

    const onChatBotBundleLoad = React.useCallback(
        () => {
            if (_.isNil(customer)) {
                return;
            }
            console.log("onChatBotBundleLoad")

            window.initIrisAgentChatbot(customer?.externalIdentifier, {
                width: "50%",
                height: "60vh",
            });
        },
        [customer],
    );

    React.useEffect(
        () => {
            if (!_.isEqual(customerQueryStatus, "success")) {
                return;
            }
            if (_.isEqual(chatBotBundleScriptStatus, "ready")) {
                onChatBotBundleLoad();
            }
        },
        [chatBotBundleScriptStatus, customerQueryStatus, onChatBotBundleLoad],
    );

    React.useEffect(
        () => {
            return () => {
                // window.removeIrisAgentChatbot();
                document.getElementById("iris-agent-chatbot-div")?.remove();
            }
        },
        []
    )

    if (isLoading) {
        return <Loader/>;
    } else if (!customer?.irisGptEnabled) {
        return <Redirect to={Constants.routes.ARTICLE_SEARCH}/>;
    }

    return (
        <React.Fragment>
            <PageContainer className="tab-container">
                <TablePaper
                    style={{
                        borderBottomLeftRadius: '0 !important',
                        borderBottomRightRadius: '0 !important'
                    }}
                >
                    <Stack gap={20} direction={'vertical'}>
                        <Stack
                            gap={10}
                            direction={'horizontal'}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Text
                                variant="p4"
                                weight="bold"
                                color="dark"
                                style={{marginTop: '16px', marginBottom: '0px'}}
                            >
                                IrisGPT: AI bot trained on{' '}
                                {Utils.isDemo() ? "Walmart's" : 'your'} help center articles
                            </Text>

                            <a
                                href={`${process.env.REACT_APP_BACKEND_URL}/getArticlesCsv?customerId=${user.customerId}`}
                            >
                                <Button
                                    variant={'outlined'}
                                    style={{
                                        width: '200px',
                                        marginTop: '16px'
                                    }}
                                >
                                    Export articles to CSV
                                </Button>
                            </a>
                        </Stack>

                        {isOperational && (
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                align={'left'}
                                style={{
                                    fontSize: 15,
                                    marginTop: 0
                                }}
                            >
                                IrisGPT is a GPT-powered large language model that can answer
                                questions from your help center articles.<br/>
                                If you like the bot, <a href="https://web.irisagent.com/manage-chatbot"
                                                        style={{color: 'blue'}}>get started</a> by embedding
                                it on your website or help center.
                                <br/>
                                {!isIndexDataLoading && indexData && (
                                    <span
                                        dangerouslySetInnerHTML={{__html: indexData?.subtitle}}
                                    />
                                )}
                            </Typography>
                        )}
                    </Stack>
                </TablePaper>

                {isOperational ? (
                    <React.Fragment>
                        {/*<ChatBox />*/}
                    </React.Fragment>
                ) : (
                    <strong>
                        IrisGPT is currently down. Apologies for the inconvenience. Please
                        check back in some time.
                    </strong>
                )}
            </PageContainer>
        </React.Fragment>
    );
}
