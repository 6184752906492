import { endOfDay, startOfDay } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import { Heading, Stack } from '../../../components/common';
import { Loader } from '../../../components/common/blocks/Loader';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import { DateRangePicker } from '../../incidents/date-picker-component/DatePickerComponent';
import { useDateRangeQuery } from '../utils';
import { KnowledgeArticlesAndBugsTable } from './KnowledgeArticlesAndBugsTable';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../../utils/constants';

export const KnowledgeArticlesAndBugs: FC<{}> = (props) => {
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE
  ]);
  const { data: content } = useDateRangeQuery(dateRange);
  const [modifiedData, setmodifiedData] = useState<any[]>([]);
  const handleDateRangeChange = (date: [Date, Date]) => {
    logEventWithUserInfo('Category Date Filter Change', {
      startDate: date[0].toString(),
      endDate: date[1].toString()
    }).then();
    setDateRange([startOfDay(date[0]), endOfDay(date[1])]);
  };
  useEffect(() => {
    let tempData = content?.caseCategoriesData.map((item: any) => {
      item.articlesCount = item.articles?.length || 0;
      item.jiraIssuesCount = item.jiraIssues?.length || 0;
      return item;
    });
    setmodifiedData(tempData ? tempData : []);
  }, [content]);

  return (
    <TablePaper>
      {/* Header */}
      <Stack
        gap={20}
        justify="space-between"
        align="center"
        style={{ paddingTop: 16 }}
      >
        {/* <Stack gap={5} align="baseline"> */}
        <div style={{ textAlign: 'left' }}>
          <Heading>Knowledge articles </Heading>
          <br />
          <Heading>and bugs by Category</Heading>
          {/* </Stack> */}
        </div>
        <DateRangePicker
          value={dateRange}
          onChange={handleDateRangeChange}
          compact={true}
        />
      </Stack>
      {content ? (
        <div style={{ marginTop: 22 }}>
          <KnowledgeArticlesAndBugsTable
            dateRange={dateRange}
            data={modifiedData}
          />
        </div>
      ) : (
        <Loader center />
      )}
    </TablePaper>
  );
};
