import React, { Component } from 'react';
import './SimpleTimeline.scss';
import Utils from '../../utils/utils';
import { Stack, Text } from '../common';

interface Props {
  data: any;
}

class SimpleTimeline extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  getTimelineData(data: any) {
    // Order cases by Date

    // Filtering for truthy values
    // since cases can be `null` at times
    let orderedCases = data.cases
      .filter(Boolean)
      .sort((a: any, b: any) => (a.timeOfCreation > b.timeOfCreation ? 1 : -1));

    let middleDate = orderedCases.length
      ? Utils.formatDate(orderedCases[0].timeOfCreation, 'Do MMM')
      : '';
    let lastDate = orderedCases.length
      ? Utils.formatDate(
          orderedCases[orderedCases.length - 1].timeOfCreation,
          'Do MMM'
        )
      : '';

    let firstDate;
    if (data.timeOfOccurrence) {
      firstDate = Utils.formatDate(data.timeOfOccurrence, 'Do MMM');
    } else {
      // detected incidents don't have a timeOfOccurence so set the date of first case
      firstDate = middleDate;
    }

    return {
      first: { value: 'Incident Created', date: firstDate },
      middle: { value: 'First ticket added', date: middleDate },
      last: { value: 'Last ticket added', date: lastDate }
    };
  }

  render() {
    const { data } = this.props;
    let timelineData = this.getTimelineData(data);

    return (
      <Stack className="simple-timeline" gap={0}>
        <Stack direction="vertical">
          <div className="timeline-node">
            <div className="timeline-dot-container"></div>
            <div className="timeline-connector-container"></div>
          </div>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.first.value}
          </Text>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.first.date}
          </Text>
        </Stack>
        <Stack direction="vertical">
          <div className="timeline-node">
            <div className="timeline-dot-container"></div>
            <div className="timeline-connector-container"></div>
          </div>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.middle.value}
          </Text>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.middle.date}
          </Text>
        </Stack>
        <Stack direction="vertical">
          <div className="timeline-node">
            <div className="timeline-dot-container"></div>
          </div>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.last.value}
          </Text>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.last.date}
          </Text>
        </Stack>
      </Stack>
    );
  }
}

export default SimpleTimeline;
