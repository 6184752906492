import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const isMocKData = process.env.REACT_APP_MOCKDATA === 'true' ? true : false;

class CKEditorIris extends Component {
  render() {
    const { defaultValue, onChange } = this.props;

    // const CKEDITOR_TOKEN_URL = process.env.REACT_APP_CKEDITOR_TOKEN_URL; // Use the development token url if you are doing extensive testing. It needs to be defined in your env file.
    const CKEDITOR_TOKEN_URL =
      process.env.REACT_APP_BACKEND_URL + '/auth/ckeditor';
    const CKEDITOR_UPLOAD_URL = process.env.REACT_APP_CKEDITOR_UPLOAD_URL;

    const configDict = {
      placeholder: 'Write here',
      link: {
        addTargetToExternalLinks: true
      }
    };
    if (!isMocKData) {
      // Define the cloudservices url only in non-mock mode as it needs
      // to make a call to the BE server to get a jwt token
      configDict['cloudServices'] = {
        tokenUrl: CKEDITOR_TOKEN_URL,
        uploadUrl: CKEDITOR_UPLOAD_URL
      };
    }

    return (
      <div className="CKEditorIris">
        <CKEditor
          editor={ClassicEditor}
          config={configDict}
          data={defaultValue}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log('Editor is ready to use!', editor);
          }}
          onChange={onChange}
          // onChange={(event, editor) => {
          //   const data = editor.getData();
          //   console.log({ event, editor, data });
          // }}
          onBlur={(event, editor) => {
            // console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            // console.log('Focus.', editor);
          }}
        />
      </div>
    );
  }
}

export default CKEditorIris;
