import * as EmailValidator from 'email-validator';
import _ from 'lodash';
import {TriggerFact} from "../../../utils/constants";

export function validateTriggerData(
  triggerData: any,
  customSimpleTriggerFact: boolean = false
): boolean {
  const possibleSimpleTriggerOperators = [
    'eq',
    'ne',
    'contains',
    'doesNotContain',
    'in',
    'nin',
    'geq',
    'value_changed',
    'value_changed_to'
  ];
  const possibleCompoundTriggerOperators = ['and', 'or'];

  const possibleSimpleTriggerFacts = [
    'category',
    'tags',
    'subject',
    'ticket_content',
    TriggerFact.TICKET_PRIORITY,
    'linked_jira_status',
    'trending_incident_case_count',
    'account_name',
    'requester_emails'
  ];

  const triggerOperatorsByTriggerFact: Record<string, any[]> = {
    category: ['in'],
    account_name: ['eq'],
    requester_emails: ['in'],
    tags: ['in', 'nin'],
    [TriggerFact.TICKET_PRIORITY]: ['in', 'nin'],
    subject: ['contains', 'doesNotContain'],
    ticket_content: ['contains', 'doesNotContain'],
    linked_jira_status: ['value_changed', 'value_changed_to'],
    trending_incident_case_count: ['geq']
  };

  if (_.isEqual(triggerData.type, 'simple')) {
    if (!possibleSimpleTriggerOperators.includes(triggerData.operator)) {
      return false;
    }

    if (
      !customSimpleTriggerFact &&
      !possibleSimpleTriggerFacts.includes(triggerData.fact)
    ) {
      return false;
    }

    if (
      !customSimpleTriggerFact &&
      !triggerOperatorsByTriggerFact[triggerData.fact].includes(
        triggerData.operator
      )
    ) {
      return false;
    }

    if (
      _.isEqual(triggerData.fact, 'tags') &&
      !Array.isArray(triggerData.value)
    ) {
      return false;
    }

    if (_.isEqual(triggerData.fact, 'requester_emails')) {
      const value = triggerData.value; // Should be an array of email addresses
      if (!Array.isArray(value)) {
        return false;
      }

      for (const email_str of value) {
        const isValid = EmailValidator.validate(email_str);
        if (!isValid) {
          console.log(
            `Invalid email found: "${email_str}" isValid: ${isValid}`
          );
          return false;
        }
      }
    }

    return true;
  }

  if (_.isEqual(triggerData.type, 'compound')) {
    if (!possibleCompoundTriggerOperators.includes(triggerData.operator)) {
      return false;
    }

    if (!Array.isArray(triggerData.operands)) {
      return false;
    }

    for (const triggerOperand of triggerData.operands) {
      if (!validateTriggerData(triggerOperand)) {
        return false;
      }
    }

    return true;
  }

  if (_.isEqual(triggerData.type, 'complex')) {
    return validateTriggerData(triggerData.value, true);
  }

  return false;
}
