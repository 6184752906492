import React, { ReactElement } from 'react';

import { RulesRowManager } from '../../../utils/RulesRowManager';

import { HandleRowComponentType } from '../../../types';

/**
 * Row manager for not implemented options (it can be removed)
 */
export class NotImplementedRowManager extends RulesRowManager {
  setDefaultActionValue(): void {}
  createItems(_handleRowComponent: HandleRowComponentType): ReactElement {
    return <p>Not implemented yet</p>;
  }
}
