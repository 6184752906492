import React, { FC, useEffect, useState } from 'react';
import { DateRangePicker } from '../incidents/date-picker-component/DatePickerComponent';
import Utils from '../../utils/utils';
import { Stack } from '../../components/common';
import { Loader } from '../../components/common/blocks/Loader';
import { LineGraph } from '../../components/home/components/Graphs/LineGraph';
import '../../components/home/components/ImportantSupportMetrics/ImportantSupportMetrics.scss';
import { Paper } from '@material-ui/core';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../utils/constants';

export const ImportantEscalationSupportMetrics: FC = () => {
  const [dateRangeForMetrics, setDateRangeForMetrics] = useState<[Date, Date]>(
    () => {
      return [DEFAULT_START_DATE, DEFAULT_END_DATE];
    }
  );

  const [casesBins, setCasesBins] = useState<any[]>([]);
  const [mttrBins, setMttrBins] = useState<any[]>([]);

  const {
    importantSupportMetrics,
    isLoading
  } = Utils.getImportantSupportMetrics(dateRangeForMetrics);

  useEffect(() => {
    if (importantSupportMetrics) {
      const newCasesBins: any = [],
        newMttrBins: any = [];

      importantSupportMetrics?.importantSupportMetrics.map((ism) => {
        newCasesBins.push({
          date: ism.startTime,
          caseCount: ism.data.caseCount,
          openCaseCount: ism.data.openCaseCount
        });
        newMttrBins.push({
          date: ism.startTime,
          mttr: Math.round(ism.data.mttr.mttr)
        });
      });
      setCasesBins(newCasesBins);
      setMttrBins(newMttrBins);
    }
  }, [importantSupportMetrics]);

  return (
    <>
      {isLoading || !importantSupportMetrics ? (
        <Paper style={{ flex: 1, marginBottom: '40px' }}>
          <Loader center />
        </Paper>
      ) : (
        <Stack className={'w-100'}>
          <div className="ism-container">
            {/* <div> */}
            <Stack
              direction="horizontal"
              justify="space-between"
              align="center"
              style={{ marginBottom: '16px' }}
            >
              <h3 className="heading">Important Support Metrics</h3>
              <DateRangePicker
                value={dateRangeForMetrics}
                onChange={setDateRangeForMetrics}
              />
            </Stack>

            <Stack direction="horizontal" className={'gap'}>
              <Stack
                direction="vertical"
                gap={30}
                align="flex-start"
                className="line-graph-container"
              >
                <LineGraph
                  title="Cases"
                  distribution={casesBins}
                  height={260}
                  lines={[
                    {
                      name: 'caseCount',
                      label: 'Total cases',
                      color: '#5E81F4'
                    },
                    {
                      name: 'openCaseCount',
                      label: 'Open cases',
                      color: '#7CE7AC'
                    }
                  ]}
                />
              </Stack>

              <Stack
                direction="vertical"
                gap={30}
                align="flex-start"
                className="line-graph-container"
              >
                <LineGraph
                  title="MTTR"
                  distribution={mttrBins}
                  yAxisLabel="in min"
                  height={260}
                  lines={[
                    {
                      name: 'mttr',
                      label: 'MTTR',
                      color: '#5E81F4',
                      unit: ' min'
                    }
                  ]}
                />
              </Stack>
            </Stack>
          </div>
        </Stack>
      )}
    </>
  );

  // function onFiltersChange(items: SelectedItem[]) {
  //   const newAttributeFilter: any = {};
  //   items.map((item) => {
  //     newAttributeFilter[item.key] = item.values;
  //     return item;
  //   });
  //   setSupportMetricsFilter(newAttributeFilter);
  // }
};
