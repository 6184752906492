import React, {Component} from 'react';
import './LoginZendesk.scss';
import IrisPortalService from '../../services/IrisPortalService';
import Utils from '../../utils/utils';
import {FormControl} from '@material-ui/core';
import {logEvent} from '../../utils/amplitude';
import {IrisAgentTextField} from "../../components/input/IrisAgentTextField";
import _ from "lodash";
import {PlatformConnectButtons, PlatformConnectFormControlText} from "../PlatformConnect";

interface Props {
    onClick: any;
}

interface State {
    subdomainValue?: string;
    error?: string;
}

class LoginZendesk extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    loginZendesk = async () => {
        let subdomainValue = this.state.subdomainValue;
        if (!subdomainValue) {
            this.setState({error: 'Cannot be empty'});
            return;
        }
        let user = Utils.getObjectItem('user');
        // At the moment that the user wants to login with google (First Time)
        logEvent('Connect with Zendesk button', {
            email: user.data.email,
            isNewUser: user.isNewUser,
            subdomainValue: subdomainValue
        });
        Utils.saveObjectItem('zendeskSubdomain', subdomainValue);
        await IrisPortalService.oauthZendesk(subdomainValue);
    };

    handleChange = (e: any) => {
        this.setState({error: undefined});
        this.setState({subdomainValue: e.target.value});
    };

    render() {
        const {onClick} = this.props;
        return (
            <div>
                <FormControl
                    classes={{
                        root: "w-100"
                    }}
                >
                    <IrisAgentTextField
                        label="Subdomain" color="secondary" variant="outlined" size={"small"} required
                        onChange={this.handleChange}
                        InputProps={{
                            endAdornment: <strong>.zendesk.com</strong>
                        }}
                    />
                    <PlatformConnectFormControlText error={!_.isEmpty(this.state.error)} message={this.state.error}/>
                </FormControl>

                <PlatformConnectButtons onCancel={onClick} onConnect={this.loginZendesk}/>
            </div>
        );
    }
}

export default LoginZendesk;
