import amplitude from 'amplitude-js';
import Utils from './utils';

const ENABLE_AMPLITUDE = process.env.REACT_APP_ENVIRONMENT !== 'localhost';
export const initAmplitude = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE);
};

export const logEvent = async (eventType, eventProperties) => {
  if (ENABLE_AMPLITUDE) {
    try {
      await amplitude.getInstance().logEvent(eventType, eventProperties);
    } catch (err) {
      console.log('error: ', err);
    }
  }
};

export const logEventWithUserInfo = async (eventType, eventProperties) => {
  const user = Utils.getObjectItem('user');
  const properties = {
    ...eventProperties,
    ...(user
      ? {
          email: user.email,
          sourceName: user.sourceName,
          organizationId: user.sourceOrganizationId
        }
      : undefined)
  };
  logEvent(eventType, properties);
};
