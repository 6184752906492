import React, { FC, useState } from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import { DateRangePicker } from '../../incidents/date-picker-component/DatePickerComponent';
import { MenuListComposition } from '../../customerHealth/components/MenuListComposition';
import { Stack, Text } from '../../../components/common';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import { AgentAnalyticsTable } from './AgentAnalyticsTable';

import _ from 'lodash';
import './Agent.scss';
import { useDateRangeQueryForAgentData } from '../utils';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../../utils/constants';
import {DropdownSearch} from "../../../components/DropdownSearch";

const NO_TEAM_FILTER_STRING = 'All teams';
export const AgentsTotalSection: FC<{
  title?: string;
  isDashboard?: boolean;
}> = (props) => {
  const { title, isDashboard } = props;
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE
  ]);

  let [currentTeamName, setCurrentTeamName] = useState<String | undefined>(
    undefined
  );
  let allTeamNames = [];

  const { data: agentAnalyticsData } = useDateRangeQueryForAgentData(
    dateRange,
    currentTeamName
  );

  allTeamNames = agentAnalyticsData ? agentAnalyticsData.allTeamNames : [];

  const handleDateRangeChange = (date: [Date, Date]) => {
    logEventWithUserInfo('Agent Analytics Date Filter Change', {
      startDate: date[0].toString(),
      endDate: date[1].toString()
    }).then();
    setDateRange([startOfDay(date[0]), endOfDay(date[1])]);
  };

  const MenuListAndDatePicker = () => {
    return (
      <Stack align="center" gap={20}>
        <DropdownSearch
            placeholder={NO_TEAM_FILTER_STRING}
            value={currentTeamName}
            onChange={(_, newValue) => {
                if (newValue) {
                    setCurrentTeamName(newValue);
                } else {
                    setCurrentTeamName(undefined);
                }
            }}
          options={allTeamNames}
        />
        <DateRangePicker
          compact={isDashboard}
          value={dateRange}
          onChange={handleDateRangeChange}
        />
      </Stack>
    );
  };

  return (
    <Stack direction="vertical">
      <Stack justify="space-between">
        <Stack align="center">
          <Text
            weight="semi-bolder"
            variant="p4"
            color="dark"
            style={{
              // height: '7vh',
              marginTop: '16px',
              marginLeft: '16px',
              marginBottom: '0px',
              marginRight: '0px'
            }}
          >
            {title ? title : 'Agents'}
          </Text>
          {!isDashboard && (
            <Text style={{ margin: '20px 0px 0px 8px' }}>
              TOTAL {agentAnalyticsData?.agents.length}
            </Text>
          )}
        </Stack>
        <div style={{ marginTop: '16px', marginRight: '16px' }}>
          <MenuListAndDatePicker />
        </div>
      </Stack>
      <AgentAnalyticsTable
        agentsData={agentAnalyticsData?.agents}
        isDashboard={isDashboard}
      />
    </Stack>
  );
};
