import 'react-datepicker/dist/react-datepicker.css';
import React, { FC } from 'react';

import './FilterSelectionDisplay.scss';
import { Button } from '../../../../components/home/Button';
import {
  Badge,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem
} from '@material-ui/core';
import { Text } from '../../../../components/common';
import { Selector } from './Selector';
import { CategoryFilter, FilterChecked } from '../../types';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import produce from 'immer';

export interface AdvancedFilterClasses {
  root?: string;
}

type FilterSelectionProps = {
  onFiltersChange: (items: SelectedItem[]) => void;
  filters: CategoryFilter[];
  classes?: AdvancedFilterClasses;
  draggable?: boolean;
  width?: number | string;
};
export type SelectedItem = {
  key: string;
  values: string[];
};
export const AdvancedFilter: FC<FilterSelectionProps> = (props) => {
  const [state, setState] = React.useState({
    showDrawer: false
  });
  const [filtersCount, setFiltersCount] = React.useState(0);

  const { filters, onFiltersChange, width, draggable } = props;

  const [selectedItems, setSelectedItems] = React.useState<
    _.Dictionary<string[]>
  >(
    _.chain(filters)
      .reject((filter) =>
        _.chain(filter.values).filter('isChecked').isEmpty().value()
      )
      .map((filter) => {
        return {
          ...filter,
          values: _.chain(filter.values).filter('isChecked').value()
        };
      })
      .keyBy('key')
      .mapValues((filter) => filter.values.map((value) => value.value))
      .value()
  );

  const hideChat = (open: boolean) => {
    let chat: HTMLDivElement = document?.getElementById(
      'hubspot-messages-iframe-container'
    ) as HTMLDivElement;
    if (chat) {
      chat.style.visibility = open ? 'hidden' : 'visible';
    }
  };

  const toggleDrawer = React.useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState({ showDrawer: open });
      hideChat(open);
    },
    []
  );

  const cancelFilters = React.useCallback(() => {
    setState({ showDrawer: false });
    hideChat(false);
  }, []);

  const applyFilters = React.useCallback(() => {
    setState({ showDrawer: false });
    hideChat(false);

    const correctSelectedItems: SelectedItem[] = _.chain(selectedItems)
      .pickBy((values) => !_.isEmpty(values))
      .toPairs()
      .map((pair) => {
        return {
          key: _.first(pair),
          values: _.last(pair)
        } as SelectedItem;
      })
      .value();

    const currentFiltersCount = _.chain(correctSelectedItems)
      .map('values')
      .flatten()
      .size()
      .value();

    setFiltersCount(currentFiltersCount);
    onFiltersChange(correctSelectedItems);
  }, [onFiltersChange, selectedItems]);

  const onOptionsChange = React.useCallback((key: string) => {
    return (options: FilterChecked[]) => {
      setSelectedItems(
        produce((draft) => {
          draft[key] = options.map((option) => option.value);
        })
      );
    };
  }, []);

  const list = React.useMemo(() => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          style={{
            width: width || 'fit-content',
            alignSelf: 'flex-end',
            marginBottom: '64px'
          }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <div className="advanced-filter ">
            <Text className="heading">Advanced Filters</Text>
            <IconButton
              style={{ top: 0, position: 'fixed', right: 0 }}
              onClick={cancelFilters}
            >
              <CloseIcon style={{ color: '#F6F6F6' }} />
            </IconButton>
            <br />
            <Text className="sub-heading">
              Make table custom view as you want.
            </Text>
          </div>
          <List style={{ padding: 0 }}>
            {filters.map((filter) => {
              return (
                <ListItem className="filter-options">
                  <Selector
                    heading={filter.label}
                    options={filter.values}
                    id={filter.key}
                    draggable={draggable}
                    onOptionsChange={onOptionsChange}
                  />
                </ListItem>
              );
            })}
            {/* <ListItem className="filter-options">
                        <ConditionSelector heading="Apply rule based conditions" options={companyCategoryOptions}></ConditionSelector>
                    </ListItem> */}
          </List>
        </Box>
        <div className="footer width-fill-available">
          <Button className="cancel-button" onClick={cancelFilters}>
            Cancel
          </Button>
          <Button className="ok-button" onClick={applyFilters}>
            Apply
          </Button>
        </div>
      </div>
    );
  }, [
    filters,
    applyFilters,
    cancelFilters,
    onOptionsChange,
    toggleDrawer,
    draggable,
    width
  ]);

  const { classes: { root: rootClass = '' } = {} } = props;

  return (
    <div className={rootClass}>
      <React.Fragment key="right">
        <div
          onClick={toggleDrawer(true)}
          style={{
            cursor: 'pointer',
            width: 'fit-content'
          }}
        >
          {props.children ? (
            props.children
          ) : (
            <Badge
              badgeContent={filtersCount}
              color="primary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <Button variant="outlined">{'Advanced Filter'}</Button>
            </Badge>
          )}
        </div>

        <Drawer
          anchor="right"
          open={state['showDrawer']}
          onClose={toggleDrawer(false)}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
};
