import { startOfDay } from 'date-fns';
import { CausedCase, GraphData } from './types';

export function normalizeTimeOfCreation(timeOfCreation: string) {
  return startOfDay(new Date(timeOfCreation)).toISOString();
}

export function createGraphData(causedCases: CausedCase[]) {
  const graphData: GraphData = {};

  for (let caseItem of causedCases) {
    const normalizedTimeOfOccurrence = normalizeTimeOfCreation(
      caseItem.timeOfCreation
    );

    if (graphData[normalizedTimeOfOccurrence]) {
      graphData[normalizedTimeOfOccurrence].causedCasesCount += 1;
    } else {
      graphData[normalizedTimeOfOccurrence] = {
        causedCasesCount: 1
      };
    }
  }

  return Object.keys(graphData).map((timeOfCreation) => ({
    timeOfCreation,
    causedCasesCount: graphData[timeOfCreation].causedCasesCount
  }));
}

export function getTimeRange(times: string[]) {
  const sortedTimes = times.sort((time1, time2) => {
    return new Date(time1).getTime() > new Date(time2).getTime() ? 1 : -1;
  });

  if (!sortedTimes.length) {
    return null;
  }

  return {
    startTime: sortedTimes[0],
    endTime: sortedTimes[sortedTimes.length - 1]
  };
}
