import React, { FC } from 'react';
import {
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow as MUITableRow,
  Tooltip,
  withStyles
} from '@material-ui/core';
import { EntityWithNoMatchingArticles } from '../../types/automation';
import { TablePaper } from '../../components/common/blocks/TablePaper';
import TableHeadSorting from '../../components/table-head-sorting/TableHeadSorting';
import _ from 'lodash';
import { Stack, Text, useTableSorters } from '../../components/common';
import Utils from '../../utils/utils';

type Props = {
  entitiesWithNoMatchingArticles: EntityWithNoMatchingArticles[];
};

const title = 'Queries with no matching articles';

export const EntitiesWithNoMatchingArticles: FC<Props> = (props) => {
  const { entitiesWithNoMatchingArticles } = props;

  const headCells = [
    { id: 'query', label: 'Query', width: '50%' },
    { id: 'category', label: 'Category', width: '50%' }
  ];

  const {
    onSort,
    filterData,
    sortColumn,
    sortOrder,
    onPageChange,
    currentPage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    pageSize,
    ROWS_PER_PAGE_OPTIONS,
  } = useTableSorters({});

  return (
    <Stack direction="vertical" gap={20}>
      <Text
        weight="semi-bolder"
        variant="p3"
        color="dark"
        // style={{ padding: '2% 0 0 4%' }}
        style={{
          marginLeft: '16px'
        }}
      >
        {title}
      </Text>

      <TablePaper>
        <TableContainer>
          <Table aria-label={title} size="small">
            <TableHeadSorting
              order={sortOrder}
              orderByColumn={sortColumn}
              onRequestSort={(_, column) => onSort(column)}
              headCells={headCells}
            />

            <TableBody>
              {_.isEmpty(entitiesWithNoMatchingArticles) ? (
                <TableRow>
                  <TableCell
                    colSpan={100}
                    align="center"
                    style={{ border: 'none' }}
                  >
                    <Text variant="p3" weight="semi-bolder" color="dark">
                      No Data
                    </Text>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {filterData(entitiesWithNoMatchingArticles).map(
                    (
                      entityWithNoMatchingArticles: EntityWithNoMatchingArticles,
                      index: number
                    ) => (
                      <TableRow key={index} className="agent-row">
                        <TableCell
                          variant="body"
                          style={{ borderBottom: 'none' }}
                          onClick={() => {
                            if (
                              _.isEqual(
                                entityWithNoMatchingArticles.type,
                                'case'
                              )
                            ) {
                              Utils.openInNewTab(
                                entityWithNoMatchingArticles.InstanceURL || ''
                              );
                            }
                          }}
                        >
                          <Text
                            variant="p1"
                            weight="semi-bolder"
                            style={{ color: '#5E81F4' }}
                            lineClamp={1}
                          >
                            {_.isEqual(
                              entityWithNoMatchingArticles.type,
                              'case'
                            )
                              ? entityWithNoMatchingArticles.CaseNumber
                              : entityWithNoMatchingArticles.query}
                          </Text>
                          {_.isEqual(
                            entityWithNoMatchingArticles.type,
                            'case'
                          ) && (
                            <Tooltip
                              title={entityWithNoMatchingArticles.Subject || ''}
                              placement="top"
                            >
                              <Text color="dark" variant="p1" lineClamp={1}>
                                {entityWithNoMatchingArticles.Subject}
                              </Text>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell
                          variant="body"
                          style={{ borderBottom: 'none' }}
                        >
                          <Text lineClamp={1}>
                            {_.chain(entityWithNoMatchingArticles.categories)
                              .join(',')
                              .value()}
                          </Text>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </>
              )}
            </TableBody>
          </Table>

          {!_.isEmpty(entitiesWithNoMatchingArticles) ? (
            <TablePagination
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={entitiesWithNoMatchingArticles.length}
              rowsPerPage={pageSize}
              page={currentPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelDisplayedRows={labelDisplayedRows}
              onChangePage={(_, page) => onPageChange(page)}
            />
          ) : null}
        </TableContainer>
      </TablePaper>
    </Stack>
  );
};

const TableRow = withStyles(
  createStyles({
    root: {
      '&:hover': {
        background: '#FBFBFD'
      }
    }
  })
)(MUITableRow);
