/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React, { forwardRef } from 'react';

type direction = 'vertical' | 'horizontal';
type gap = number | string;

export type StackProps = React.HTMLAttributes<HTMLDivElement> & {
  wrap?: React.CSSProperties['flexWrap'];
  inline?: boolean;
  reverse?: boolean;
  justify?: React.CSSProperties['justifyContent'];
  align?: React.CSSProperties['alignItems'];
  direction?: direction;
  gap?: gap;
  as?: string;
} & React.RefAttributes<HTMLDivElement>;

const createDirection = (direction: any, reverse: boolean) => {
  const prefix = direction === 'vertical' ? 'column' : 'row';
  const suffix = reverse ? '-reverse' : '';
  return `${prefix}${suffix}`;
};

const Stack = forwardRef<HTMLDivElement, StackProps>(function Stack(
  {
    inline = false,
    reverse = false,
    justify,
    align,
    direction = 'horizontal',
    gap = 0,
    className,
    as = 'div',
    wrap = 'unset',
    ...props
  },
  ref
) {
  return (
    <ClassNames>
      {({ css, cx }) =>
        jsx(as, {
          ref,
          // TODO: Check what if I just use string concatenation?
          className: cx(
            css`
              flex-wrap: ${wrap};
              height: fit-content;
              display: ${inline ? 'inline-flex' : 'flex'};
              flex-direction: ${createDirection(direction, reverse)};
              justify-content: ${justify};
              align-items: ${align};
              /* box-sizing: border-box; */
              > * + * {
                margin-top: ${direction === 'vertical' ? gap : 0}px;
                margin-inline-start: ${direction === 'vertical'
                  ? 0
                  : reverse
                  ? 0
                  : gap}px;
                margin-inline-end: ${reverse ? gap : 0}px;
              }
            `,
            className
          ),
          ...props
        })
      }
    </ClassNames>
  );
});

export { Stack };
