import React, { memo } from 'react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { Tooltip } from '../../Tooltip';

// import { ReactComponent as ArrowSvg } from '../../../assets/keyboardArrowUp.svg';
import './styles.scss';

type PortalSidebarItemProps = {
  children?: React.ReactNode;
  icon?: React.ComponentType<{
    htmlColor?: string;
    style?: React.CSSProperties;
  }>;
  isSidebarCollapsed: boolean;
  paths?: Array<string>;
  title: string;
  to: string;
  haveSubItems?: boolean;
};

const SidebarItem: React.FC<PortalSidebarItemProps> = ({
  children,
  icon: Icon,
  isSidebarCollapsed = false,
  paths = [],
  title,
  to,
  haveSubItems
}) => {
  const location = useLocation();
  const match = useRouteMatch(to);

  let isActive = match || paths.includes(location.pathname);
  let internalActive = false;
  return (
    <div
      className={[
        'app-portal-sidebar-item',
        // 'children-active'
        isActive && (children ? 'children-active' : 'active')
      ]
        .filter(Boolean)
        .join(' ')}
    >
      <Tooltip
        darkMode
        title={isSidebarCollapsed ? title : ''}
        placement="right"
      >
        <NavLink
          to={to}
          className={[
            isActive && (children ? 'children-active' : ''),
            isSidebarCollapsed && 'collapsed'
          ]
            .filter(Boolean)
            .join(' ')}
          style={{
            backgroundColor: internalActive && !children ? '#5e81f41a' : '',
            justifyContent: 'space-between',
            pointerEvents: 'auto'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {Icon && (
              <Icon
                style={{
                  marginLeft: '11px',
                  width: '24px',
                  fill: isActive ? '#5E81F4' : '#8181A5',
                  stroke: isActive ? '#5E81F4' : '#8181A5'
                }}
              />
            )}

            {!isSidebarCollapsed && (
              <p className={isActive ? 'active' : ''}>{title}</p>
            )}
          </div>

          {/* {haveSubItems && !isSidebarCollapsed && (
            <ArrowSvg
              style={{
                marginRight: '12.5px',
                transform: isActive ? '' : 'rotate(180deg)'
              }}
            />
          )} */}
        </NavLink>
      </Tooltip>

      {children && !isSidebarCollapsed && (
        <div
          className="app-portal-sidebar-item-content"
          style={{ display: isActive ? 'contents' : 'none' }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export const PortalSidebarItem = memo(SidebarItem);
