import {TextField, withStyles} from "@material-ui/core";

export const IrisAgentTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'var(--template-color)',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'var(--template-color)',
        },
        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            //   borderColor: 'red',
            // },
            '&:hover fieldset': {
                borderColor: 'var(--template-color)',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--template-color)',
            },
        },
    },
})(TextField);
