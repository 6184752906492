import Axios, {AxiosInstance} from "axios";
import Utils from "../../utils/utils";
import {
    AgentForRoutingUpdateRequest,
    AgentsForRoutingResponse,
    CustomerProvidedTag,
    CreateCustomerProvidedTagRequest
} from "../types";
import MockData from "../../utils/mockData";

type _OktaTokenResponse = {
    token: string;
}

export default class FrontendApiServer {
    private static _instance: AxiosInstance;

    private static _getInstance(): AxiosInstance {
        if (!FrontendApiServer._instance) {
            FrontendApiServer._instance = Axios.create({
                baseURL: process.env.REACT_APP_APISERVER_URL,
                headers: {
                    Authorization: `Bearer ${Utils.getObjectItem('token_express')}`
                },
            });
        }
        return FrontendApiServer._instance;
    }

    public static async getAgentsForRouting(): Promise<AgentsForRoutingResponse> {
        if ( Utils.isDemo() ) {
            return MockData.getAgentsForRouting();
        }
        const axiosInstance = FrontendApiServer._getInstance();
        const axiosResponse = await axiosInstance.get<AgentsForRoutingResponse>(
            "/v1/routing/agents");
        return axiosResponse.data;
    }

    public static async putAgentForRouting(agentId: string, patch: AgentForRoutingUpdateRequest): Promise<void> {
        if ( Utils.isDemo() ) {
            throw new Error("Not implemented");
        }
        const axiosInstance = FrontendApiServer._getInstance();
        await axiosInstance.put(`/v1/routing/agents/${agentId}`, patch);
    }

    public static async getCustomerProvidedTags(): Promise<CustomerProvidedTag[]> {
        if ( Utils.isDemo() ) {
            return MockData.getCustomerProvidedTags();
        }
        const axiosInstance = FrontendApiServer._getInstance();
        const response = await axiosInstance.get(`/v1/provided-tags`);
        return response.data['customer_provided_tags'];
    }

    public static async createCustomerProvidedTag(request: CreateCustomerProvidedTagRequest): Promise<void> {
        if ( Utils.isDemo() ) {
            throw new Error("Not implemented");
        }
        const axiosInstance = FrontendApiServer._getInstance();
        await axiosInstance.post(`/v1/provided-tags`, request);
    }

    public static async getTokenAfterOktaAuth(): Promise<string> {
        const url = `${process.env.REACT_APP_APISERVER_URL}/v1/okta/token`
        const response = await Axios.get<_OktaTokenResponse>(url, {
            withCredentials: true,
        })
        return response.data.token;
    }
}
