import React, { Component } from 'react';
import './Dashboard.scss';
import IrisPortalService from '../../services/IrisPortalService';
import Utils from '../../utils/utils';
import produce from 'immer';
import {LinearProgressBarWithLabel} from '../../components/progress-bar/ProgressBar';
import _ from 'lodash';
import moment from 'moment';
import Constants, { Source } from '../../utils/constants';
import { Customer } from '../../services/types';

interface Props {
  location: any;
  history: any;
}

const isMocKData = process.env.REACT_APP_MOCKDATA === 'true' ? true : false;

const SELF_SERVE_CHECK_INTERVAL = moment.duration(5, 'minutes').asMilliseconds();

class Dashboard extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    Utils.saveObjectItem('isLoginView', false);
    this.state = {
      isLoading: false,
      showFirstTimeSetupMessage: false,
      isIrisPortal: false,
      interval: null,
      value: 0,
      createdAt: null
    };
  }

  componentDidMount() {
    let url = this.props.location.search;
    let params = new URLSearchParams(url);
    let user = Utils.getObjectItem('user');
    if (isMocKData) {
      // Load Mock Data for Iris PortalFlow
      this.getAccountDashboard(true);
    } else if (
      !user.isIrisPortal ||
      (params.get('sourceName') && params.get('organizationId'))
    ) {
      let sourceName = params.get('sourceName');
      let organizationId = params.get('organizationId');
      let tokenExpress = params.get('token_express');
      let customerId = params.get('customerId');
      let data = {
        sourceName: sourceName,
        sourceOrganizationId: organizationId,
        customerId: customerId,
        isIrisPortal: false
      };
      Utils.saveObjectItem('user', data);
      Utils.saveObjectItem('token_express', tokenExpress);
      // this.props.history.push('/dashboard'); //clean URL removing parameters
      this.getAccountDashboard(false);
    } else {
      this.getAccountDashboard(true);
    }

    const interval = setInterval(() => {
      if (_.isNil(this.state.createdAt)) {
        return;
      }

      const minutes = moment
        .utc()
        .diff(
          moment(this.state.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
          'minutes'
        );
      let value = minutes;
      if (value > 95) {
        value = 95;
      }

      this.setState(
        produce((draft: any) => {
          draft['value'] = value;
        })
      );

      IrisPortalService.getCustomer()
        .then((customer) => {
          if (customer.backfillDone) {
            this.clearSelfServeInterval();
            window.location.reload();
          }
        })
        .catch((e) => console.error(`API Error: ${e}`));
    }, SELF_SERVE_CHECK_INTERVAL);
    this.setState(
      produce((draft: any) => {
        draft['interval'] = interval;
      })
    );
  }

  componentWillUnmount() {
    this.clearSelfServeInterval();
  }

  clearSelfServeInterval() {
    clearInterval(this.state.interval);
  }

  async getAccountDashboard(isIrisPortal: boolean) {
    this.setState(
      produce((draft: any) => {
        draft['isLoading'] = true;
      })
    );

    let customer: Customer = {} as Customer;
    try {
      customer = await IrisPortalService.getCustomer();
    } catch (e) {
      console.error(`getAccountDashboard: API Error: ${e}`);
      return;
    }
    const isSelfServeEnabled = customer.selfServe?.enabled;

    if (customer.backfillDone) {
      this.clearSelfServeInterval();
    }

    this.setState(
      produce((draft: any) => {
        draft['isSelfServeEnabled'] = isSelfServeEnabled;
        draft['createdAt'] = customer.createdAt;
      })
    );

    if (isIrisPortal && !Utils.getObjectItem('statusBackfillDone').current) {
      const response = await IrisPortalService.getFirstTimeSetupIrisPortal();
      if (response['firstTimeSetupComplete']) {
        this.goHome(customer);
        Utils.saveObjectItem('backfillDone', { current: true });
      } else {
        this.setState(
          produce((draft: any) => {
            draft['showFirstTimeSetupMessage'] = true;
            draft['isLoading'] = false;
          })
        );
        Utils.saveObjectItem('backfillDone', { current: false });
      }
    } else if (
      !Utils.getObjectItem('backfillDone').current &&
      Utils.getObjectItem('statusBackfillDone').current
    ) {
      this.setState(
        produce((draft: any) => {
          draft['showFirstTimeSetupMessage'] = true;
          draft['isLoading'] = false;
        })
      );
    } else {
      Utils.saveObjectItem('backfillDone', { current: true });
      this.goHome(customer);
    }
  }

  goHome(customer: Customer) {
    if (_.isEqual(customer.sourceName, Source.IRIS_GPT)) {
      this.props.history.push(Constants.routes.IRIS_GPT);
    } else {
      this.props.history.push(Constants.routes.NEEDS_ATTENTION);
    }
  }

  render() {
    let isLoading = this.state.isLoading;
    let showFirstTimeSetupMessage = this.state.showFirstTimeSetupMessage;
    return (
      <div>
        {showFirstTimeSetupMessage ? (
          <div style={{ marginTop: '15vh' }}>
            <img
              width="500px"
              style={{ marginBottom: 20 }}
              src="https://res.cloudinary.com/irisagent/image/upload/c_scale,w_500/v1629898831/shutterstock_1695590932_1_xjbote.png"
              alt="setup message"
              loading="eager"
            />
            <div
              className="text-spinner-container"
              style={{
                maxWidth: '800px',
                marginLeft: 'auto',
                marginRight: 'auto',
                fontWeight: 'normal',
              }}
            >
              <React.Fragment>
                Hang tight, we are setting up your account and we will email you as soon as it's ready. <br />
                This typically takes a few hours. <a
                  href="https://calendly.com/palak-iris/irisagent-setup"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule
                </a>{' '}
                a call with us to get a personalized demo.
                <br /><br />
                <LinearProgressBarWithLabel value={this.state.value} />
              </React.Fragment>
            </div>
          </div>
        ) : (
          ''
        )}
        {isLoading && !showFirstTimeSetupMessage && (
          <div>
            <img
              className="loading"
              alt=""
              width="55px"
              src="/images/spinner.gif"
            />
          </div>
        )}
      </div>
    );
  }
}

export default Dashboard;
