import React, { ReactElement } from 'react';

import { RulesRowManager } from '../../../utils';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as AlertTriangle } from '../../../../../assets/alertTriangle.svg';

import '../styles.scss';
import { FormControl, Input } from '@material-ui/core';

/**
 * Row manager for TrendingIncidents
 */
export class TrendingIncidentsRowManager extends RulesRowManager {
  constructor(options: Option[], actionValue: any, actionOperator: any) {
    super(options, actionValue, actionOperator);
    this.action.type = 'trending_incident_case_count';
    this.action.operator =
      actionOperator === undefined ? 'geq' : actionOperator;
  }

  setDefaultActionValue(): void {
    this.action.value = '';
  }
  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    if (this.action.value === undefined) this.setDefaultActionValue();

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <AlertTriangle />
          <p className="title">Trending Incident</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <p className="operator-text">
            New incident with number of cases more than
          </p>
          <div style={{ width: '50px' }}>
            <FormControl>
              <Input
                onChange={(e) => {
                  this.action.value = e.target.value;
                  handleRowComponent(this.action);
                }}
                defaultValue={this.action.value}
                type="number"
              ></Input>
            </FormControl>
          </div>
        </div>
      </div>
    );
  }
}
