import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Page } from '../Page';
import { TriggersImpactNumbers } from '../../components/home/TriggersImpactNumbers';
import { AutomationTrend } from './AutomationTrend';
import { ArticleMetrics } from './ArticleMetrics';
import { KnowledgeArticlesAndBugs } from '../categories/components/KnowledgeArticlesAndBugs';

export const AutomationImpact: React.FC<RouteComponentProps> = () => {
  return (
    <Page>
      <TriggersImpactNumbers invertColor={true} size={'sm'} />

      <AutomationTrend />

      <ArticleMetrics />
      <KnowledgeArticlesAndBugs />
    </Page>
  );
};
