import React, { FC, useEffect, useState } from 'react';
import { DateRangePicker } from '../incidents/date-picker-component/DatePickerComponent';
import Utils from '../../utils/utils';
import { Stack } from '../../components/common';
import { Loader } from '../../components/common/blocks/Loader';
import { LineGraph } from '../../components/home/components/Graphs/LineGraph';
import '../../components/home/components/ImportantSupportMetrics/ImportantSupportMetrics.scss';
import { Paper } from '@material-ui/core';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../utils/constants';

export const ImportantHealthSupportMetrics: FC = () => {
  const [dateRangeForMetrics, setDateRangeForMetrics] = useState<[Date, Date]>(
    () => {
      return [DEFAULT_START_DATE, DEFAULT_END_DATE];
    }
  );

  const [sentimentDataBins, setSentimentDataBins] = useState<any[]>([]);
  const [csatBins, setCsatBins] = useState<any[]>([]);

  const {
    importantSupportMetrics,
    isLoading
  } = Utils.getImportantSupportMetrics(dateRangeForMetrics);

  useEffect(() => {
    if (importantSupportMetrics) {
      const newSentimentDataBins: any = [],
        newCsatBins: any = [];
      importantSupportMetrics?.importantSupportMetrics.map((ism) => {
        newSentimentDataBins.push({
          date: ism.startTime,
          sentimentScore: Math.round(
            ism.data.averageSentimentScoreData.score * 100
          )
        });
        newCsatBins.push({
          date: ism.startTime,
          csatScore: ism.data.csat.score ? ism.data.csat.score : 0,
          surveyCount: Math.round(
            ism.data.csat.surveyCount ? ism.data.csat.surveyCount : 0
          )
        });
      });
      setSentimentDataBins(newSentimentDataBins);
      setCsatBins(newCsatBins);
    }
  }, [importantSupportMetrics]);

  return (
    <>
      {isLoading || !importantSupportMetrics ? (
        <Paper style={{ flex: 1, marginBottom: '40px' }}>
          <Loader center />
        </Paper>
      ) : (
        <Stack className={'w-100'}>
          <div className="ism-container">
            {/* <div> */}
            <Stack
              direction="horizontal"
              justify="space-between"
              align="center"
              style={{ marginBottom: '16px' }}
            >
              <h3 className="heading">Customer Health Overview</h3>
              <DateRangePicker
                value={dateRangeForMetrics}
                onChange={setDateRangeForMetrics}
              />
            </Stack>

            <Stack direction="horizontal" className={'gap'}>
              <Stack
                direction="vertical"
                gap={30}
                align="flex-start"
                className="line-graph-container"
              >
                <LineGraph
                  title="Case Sentiment"
                  distribution={sentimentDataBins}
                  yAxisUnit="%"
                  height={260}
                  lines={[
                    {
                      name: 'sentimentScore',
                      label: 'Avg. Sentiment Score',
                      color: '#5E81F4',
                      unit: '%'
                    }
                  ]}
                />
              </Stack>
              <Stack
                direction="vertical"
                gap={30}
                align="flex-start"
                className="line-graph-container"
              >
                <LineGraph
                  customTooltip={true}
                  title="CSAT Score"
                  distribution={csatBins}
                  yAxisUnit="%"
                  height={260}
                  lines={[
                    {
                      name: 'csatScore',
                      label: 'CSAT Score',
                      color: '#5E81F4',
                      unit: '%'
                    },
                    {
                      name: 'surveyCount',
                      label: 'Survey Count',
                      color: '#6FCF97',
                      hide: true
                    }
                  ]}
                />
              </Stack>
              <Stack
                direction="vertical"
                gap={30}
                align="flex-start"
                className="line-graph-container-empty"
              ></Stack>
            </Stack>
          </div>
        </Stack>
      )}
    </>
  );

  // function onFiltersChange(items: SelectedItem[]) {
  //   const newAttributeFilter: any = {};
  //   items.map((item) => {
  //     newAttributeFilter[item.key] = item.values;
  //     return item;
  //   });
  //   setSupportMetricsFilter(newAttributeFilter);
  // }
};
