import React, { FC, useState } from 'react';
import { Stack, Text } from '../common';
import { DateRangePicker } from '../../routes/incidents/date-picker-component/DatePickerComponent';
import { ProgressCell } from '../common/blocks/ProgressCell';
import { ChangeIndicator } from '../../routes/categories/components/ChangeIndicator';
import { Loader } from '../common/blocks/Loader';
import { Paper } from './Paper';
import { Button } from './Button';
import { useDateRangeQuery } from '../../routes/categories/utils';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../utils/constants';

export const CategoriesByMostCases: FC<any> = (props) => {
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE
  ]);
  const { data: categoriesDataBMC, status: statusLocal } = useDateRangeQuery(
    dateRange
  );

  const top5Categories = categoriesDataBMC?.caseCategoriesData
    ?.filter((category: any) => category.name !== 'Uncategorized')
    ?.sort((cat1, cat2) => (cat1.count - cat2.count > 0 ? -1 : 1))
    .slice(0, 5);
  const maxNoOfCases = top5Categories
    ? Math.max(...top5Categories.map((category) => category.count))
    : 100;

  const showNoCategoriesMessage = () => {
    return !top5Categories?.length;
  };
  return (
    <Paper style={{ flex: 1 }}>
      <Stack
        direction="vertical"
        gap={16}
        justify="space-between"
        style={{ height: '100%' }}
      >
        <Stack direction="vertical" gap={16}>
          <Stack direction="horizontal" justify="space-between" align="center">
            <Text variant="p4" weight="bold" color="dark">
              Top 5 case drivers
            </Text>
            <DateRangePicker value={dateRange} onChange={setDateRange} />
          </Stack>
          <Stack direction="vertical" gap={16}>
            <Stack
              direction="vertical"
              style={{ position: 'relative', height: 50 }}
            >
              <Stack
                style={{
                  background: 'rgba(245, 245, 250, 0.4)',
                  position: 'absolute',
                  left: -24,
                  right: -24,
                  padding: '15px 24px'
                }}
                direction="horizontal"
                justify="space-between"
                gap={15}
              >
                <Text
                  style={{ flexBasis: '80%', color: '#8181A5' }}
                  variant="p0"
                  weight="semi-bolder"
                >
                  Top 5 categories
                </Text>

                <Text
                  style={{ flexBasis: '20%', color: '#8181A5' }}
                  variant="p0"
                  weight="semi-bolder"
                >
                  No of Cases
                </Text>
              </Stack>
            </Stack>
            {statusLocal === 'loading' ? <Loader center /> : null}
            <Stack direction="vertical" gap={20}>
              {showNoCategoriesMessage() ? <NoCategoriesFoundCmp /> : null}
              {top5Categories?.map((category: any, index) => (
                <Stack
                  direction="horizontal"
                  gap={15}
                  align="center"
                  justify="space-between"
                >
                  <Stack
                    style={{ flexBasis: '90%' }}
                    gap={8}
                    direction="vertical"
                  >
                    <Text>{category.name}</Text>
                    <ProgressCell
                      value={(category.count / maxNoOfCases) * 100}
                      color={CATEGORIES_COLORS[index]}
                      height={3}
                    />
                  </Stack>
                  <Stack
                    style={{ flexBasis: '10%' }}
                    direction="horizontal"
                    gap={7}
                    align="center"
                  >
                    <Text>{category.count}</Text>
                    <ChangeIndicator
                      dateRange={dateRange}
                      value={category.casesChangePercent}
                    />
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          style={{ marginTop: 25 }}
          direction="horizontal"
          align="center"
          justify="center"
        >
          <Button href="/overview" variant="outlined">
            View all Categories
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
const CATEGORIES_COLORS = [
  'linear-gradient(0deg, #F4BE5E 0%, #857c6b 100%)',
  'linear-gradient(180deg, #FF808B 0%, #FC5E6C 100%)',
  '#5E81F4',
  'linear-gradient(92.31deg, #2BCDFF 0.71%, #22D6BA 100%)',
  '#7CE7AC'
];

export const NoCategoriesFoundCmp: FC = (props) => {
  return (
    <Text style={{ textAlign: 'center' }}>
      Please schedule an{' '}
      <a
        href="https://calendly.com/palak-iris/irisagent-setup"
        target="_blank"
        rel="noreferrer noopener"
        style={{ color: '#0000EE', textDecoration: 'underline' }}
      >
        onboarding call
      </a>{' '}
      to set up categories and automatic tagging
    </Text>
  );
};
