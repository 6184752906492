import React, { FC, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles, TextField } from '@material-ui/core';
import { Stack, Text } from '../../../components/common';
import { debounce } from 'debounce';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { CategoryMetrics } from '../../categories/components/CategoryMetrics';
import InfiniteScroll from 'react-infinite-scroll-component';

const useInputStyles = makeStyles({
  root: {
    borderRadius: 1,
    border: '0.75px solid rgba(56, 56, 56, 0.3)',
    background: 'transparent'
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  },
  resize: {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontFamily: 'DM Sans'
  }
});

type Search = {
  value?: string;
  placeholder?: string;
  dataList?: string[];
  dataListSearch?: any[];
  isCustom?: boolean;
  deactivateDropDown?: boolean;
  deactivateTitle?: boolean;
  withoutBorder?: boolean;
  withoutBorderInput?: boolean;
  showListSearch?: boolean;
  onlyTextItems?: boolean;
  redirectWithId?: boolean;
  getOptionSelected?: (optionSelected: string) => void;
  getClickCardMetrics?: (optionSelected: string) => void;
  defaultSelection?: string;
};

const AccountSearch: FC<Search> = (props) => {
  const {
    defaultSelection,
    value,
    placeholder,
    dataList = [],
    dataListSearch = [],
    isCustom = false,
    withoutBorder = false,
    withoutBorderInput = false,
    deactivateDropDown = false,
    deactivateTitle = false,
    showListSearch = false,
    onlyTextItems = false,
    redirectWithId = false,
    getOptionSelected = () => {},
    getClickCardMetrics = () => {}
  } = props;
  const inputClasses = useInputStyles();
  const [state, setState] = useState({
    count: { prev: 0, next: 10 },
    countFilterCategories: -1,
    filterWord: '',
    filterCategories: dataListSearch.slice(0, 10) || [],
    hasMore: dataListSearch.length > 0,
    isFiltering: false
  });
  useEffect(() => {
    if (dataListSearch.length > 0 && state.filterCategories.length === 0) {
      setState({ ...state, filterCategories: dataListSearch.slice(0, 10) });
    }
  }, [dataListSearch, state]);
  const [globalFiltering, setGlobalFiltering] = useState<any>([]);

  const handleOptionSelected = (optionSelected: string) => {
    let optionBy = '';
    if (redirectWithId) {
      optionBy = dataListSearch.find((value) => value.name === optionSelected)
        .id;
    } else {
      optionBy = optionSelected;
    }
    getOptionSelected(optionBy);
  };

  const getEventClickCardMetrics = (optionSelected: any) => {
    getClickCardMetrics(
      redirectWithId ? optionSelected.id : optionSelected.name
    );
  };

  const preFetchFilterData = debounce((_: any, filterName: string | null) => {
    if (filterName) {
      const wordsInput = filterName.split(/_|-| /).filter((val: any) => val);
      const filterData = dataListSearch.filter(
        (category: any) =>
          wordsInput.filter(
            (word: any) =>
              category.name
                .split('_')
                .join('')
                .toLowerCase()
                ?.indexOf(word.toLowerCase().trim()) > -1
          ).length === wordsInput.length
      );
      setGlobalFiltering(filterData);
      setState((prevState) => ({
        ...prevState,
        countFilterCategories: filterData.length,
        filterWord: filterName,
        filterCategories: filterData.slice(0, 10),
        isFiltering: true,
        count: { prev: 0, next: 10 },
        hasMore: filterData.length > 0
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        countFilterCategories: -1,
        filterCategories: dataListSearch.slice(0, 10),
        isFiltering: false,
        count: { prev: 0, next: 10 }
      }));
    }
  }, 500);

  const getMoreData = () => {
    if (
      state.filterCategories.length === dataListSearch.length ||
      state.filterCategories.length === globalFiltering.length
    ) {
      setState((prevState) => ({
        ...prevState,
        hasMore: false
      }));
    }
    setTimeout(() => {
      if (state.isFiltering) {
        setState((prevState) => ({
          ...prevState,
          filterCategories: state.filterCategories.concat(
            globalFiltering.slice(state.count.prev + 10, state.count.next + 10)
          )
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          filterCategories: state.filterCategories.concat(
            dataListSearch.slice(state.count.prev + 10, state.count.next + 10)
          )
        }));
      }
    }, 1500);
    setState((prevState) => ({
      ...prevState,
      count: {
        prev: prevState.count.prev + 10,
        next: prevState.count.next + 10
      }
    }));
  };

  return (
    <div>
      <Stack direction="vertical" gap={10} align="flex-start">
        {!deactivateTitle && (
          <Text color="dark" variant="p3" weight="medium">
            Search by customer name
          </Text>
        )}
        <Autocomplete
          size="small"
          options={dataList}
          style={{
            width: isCustom ? '90%' : 500,
            backgroundColor: isCustom ? 'white' : ''
          }}
          onChange={(_, newValue) => {
            if (newValue) {
              handleOptionSelected(newValue);
            }
          }}
          freeSolo={deactivateDropDown}
          onInputChange={isCustom ? preFetchFilterData : undefined}
          value={value ? decodeURI(value) : ''}
          renderInput={(params) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: isCustom ? '100%' : 'auto'
              }}
            >
              <TextField
                {...params}
                placeholder={isCustom ? placeholder : 'Type here...'}
                variant={withoutBorderInput ? 'standard' : 'outlined'}
                InputProps={{
                  ...params.InputProps,
                  classes: withoutBorderInput
                    ? { input: inputClasses.resize }
                    : withoutBorder
                    ? undefined
                    : {
                        root: inputClasses.root
                      },
                  startAdornment: isCustom && (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          )}
        />
      </Stack>
      {showListSearch && (
        <div>
          <div
            style={{
              fontFamily: 'DM Sans',
              fontStyle: 'normal',
              fontSize: 16,
              fontWeight: 500
            }}
          >
            {dataListSearch.length === 0 && (
              <div style={{ marginTop: '5%', textAlign: 'left' }}>
                No data for search
              </div>
            )}
            {state.countFilterCategories === 0 ? (
              <div style={{ marginTop: '5%' }}>
                <img src="/images/search_alt.png" alt="users" />
                <div>Couldn’t find anything for “{state.filterWord}”</div>
                <div
                  style={{
                    fontSize: 12,
                    color: '#808080',
                    fontWeight: 'normal'
                  }}
                >
                  Suggesting similar searches
                </div>
              </div>
            ) : (
              state.countFilterCategories > 0 && (
                <div
                  style={{ marginTop: '5%', textAlign: 'left' }}
                >{`${state.countFilterCategories} Results Found`}</div>
              )
            )}
          </div>
          <div>
            <InfiniteScroll
              dataLength={state.filterCategories.length}
              next={getMoreData}
              hasMore={state.hasMore}
              height="90vh"
              loader={<h4>Loading...</h4>}
            >
              {state.filterCategories.map((category: any, index) => (
                <CategoryMetrics
                  selected={defaultSelection === category.name}
                  getOptionClick={() => getEventClickCardMetrics(category)}
                  categoryData={category}
                  toSearch={true}
                  key={index}
                  onlyText={onlyTextItems}
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountSearch;
