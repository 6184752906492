import React, { Component } from 'react';
import './ConnectOrganization.scss';
import Utils from '../../utils/utils';
import history from '../../utils/history';
import { Button, Input } from '@material-ui/core';
import IrisPortalService from '../../services/IrisPortalService';
import { PageContainer } from '../../components/common/blocks/PageContainer';
import { Heading, Stack } from '../../components/common';
import { Paper } from '../../components/home/Paper';
import { store } from 'state-pool';
import Constants from '../../utils/constants';

class ConnectOrganization extends Component<{}, any> {
  constructor(props: any) {
    super(props);
    this.state = { errors: {} };
  }

  async connectOrganization() {
    let customerId = this.state.customerId;
    if (!customerId) {
      let customerIdError = !customerId ? 'Cannot be empty' : '';
      let errors = { customerIdError };
      this.setState({ errors: errors });
      return;
    }
    let newUser = await IrisPortalService.getChangeIrisportalviewUserData(
      customerId
    );
    if (Object.keys(newUser).length === 0) {
      // empty dict
      return;
    }
    let user = Utils.getObjectItem('user');
    store.setState(Constants.STARTER_CUSTOMER_ID, user.customerId, {
      persist: true
    });
    user.sourceName = newUser.sourceName;
    user.sourceOrganizationId = newUser.organizationId;
    user.customerId = newUser.customerId;
    Utils.saveObjectItem('user', user);
    Utils.saveCustomerItem(newUser.customer);
    const response = await IrisPortalService.getFirstTimeSetupIrisPortal();
    Utils.saveObjectItem('backfillDone', {
      current: response.firstTimeSetupComplete
    });
    Utils.saveObjectItem('statusBackfillDone', { current: true });
    history.push('/dashboard');
    window.location.reload();
  }

  handleChange = (e: any) => {
    this.setState({ errors: {} });
    let value: any = {};
    value[e.target.id] = e.target.value;
    this.setState(value);
  };

  render() {
    return (
      <PageContainer>
        <Paper>
          <Stack style={{ padding: 16 }}>
            <Heading>Connect Organization</Heading>
          </Stack>
          <div className="connect-organization-container">
            <Input
              id="customerId"
              fullWidth
              placeholder="Customer ID"
              onChange={this.handleChange}
            />
            <span className="error">{this.state.errors.customerIdError}</span>
            <Button
              className="connect-button"
              fullWidth
              onClick={async () => {
                await this.connectOrganization();
              }}
            >
              Connect
            </Button>
          </div>
        </Paper>
      </PageContainer>
    );
  }
}

export default ConnectOrganization;
