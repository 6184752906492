import React, { forwardRef } from 'react';
import { Text } from './Text';

export const Subtitle = forwardRef<HTMLSpanElement, { children: any }>(
  (props, ref) => {
    return (
      <Text {...props} ref={ref} variant="p1" color="light" weight="medium" />
    );
  }
);
