import React, { FC, useEffect, useState } from 'react';

import { Stack } from '../../../../components/common';
import { Paper } from '../../../../components/home/Paper';
import { ConditionContainer, Option } from '../../types';
import { RulesManager } from '../RulesManager';
import { ReactComponent as ActionIcon } from '../../../../assets/mousePointer.svg';
import Utils from '../../../../utils/utils';

export const RulesAction: FC<{
  actions: any;
  options: Option[]; // constants options (found in ../constants.ts)
  handleActionsChange: (
    operator: string,
    actionRowWrappers: ConditionContainer[]
  ) => void; // parent handle function
  conditionToolClicked: string;
  triggersEmpty: boolean;
  isEditable: boolean;
}> = ({
  actions,
  options,
  handleActionsChange,
  conditionToolClicked,
  isEditable
}) => {
  const [filteredActionRules, setFilteredActionRules] = useState(options);

  useEffect(() => {
    const user = Utils.getObjectItem('user');
    if (user) {
      setFilteredActionRules(
        options.filter((rule) => {
          if (rule.hidden && rule.hidden.length > 0) {
            if (rule.hidden?.includes(user.sourceName) === false) {
              return rule;
            }
            return null;
          } else {
            return rule;
          }
        })
      );
    }
  }, [options]);
  return (
    <Paper>
      <Stack direction="vertical" gap={12}>
        <RulesManager
          beforeButtonTitle="Add Actions"
          afterButtonTitle="Actions"
          values={actions}
          options={filteredActionRules}
          handleActionsChange={handleActionsChange}
          firstOperand="and"
          buttonIcon={<ActionIcon stroke="#EF5DA8" />}
          buttonColor="#EF5DA8"
          type="action"
          conditionToolClicked={conditionToolClicked}
          isEditable={isEditable}
        />
      </Stack>
    </Paper>
  );
};
