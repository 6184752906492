import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { handleError, isMocKData } from './utils';
import {
  Trigger,
  TriggersOverallImpactTrendData
} from '../../routes/rules/types';
import { TriggerActionCasesArray } from '../../routes/rules/components/TriggerActionCases';
import _ from 'lodash';

export const getTrigger = async (ruleId: any) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getTriggersMockData();
  }

  if (!ruleId) {
    throw new Error('ruleId is required to get trigger details.');
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/triggers/${ruleId}?customerId=${user.customerId}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`
      })
    }
  );
  handleError(response.status);

  if (response.status === 403) {
    Utils.showError('There was an error while fetching the trigger details');
    return;
  }

  if (!response.ok) {
    throw new Error(
      `getRule: Unhandled error status code!: ${response.status}`
    );
  }

  return response.json();
};

export const getTriggers = async () => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getTriggersMockData();
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/triggers?customerId=${user.customerId}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`
      })
    }
  );
  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error(
      `getRules: Unhandled error status code!: ${response.status}`
    );
  }
  return response.json();
};

export const getTriggersPrerequisites = async () => {
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getTriggersPrerequisitesMockData();
  }

  const response = await fetch(
      `${process.env.REACT_APP_APISERVER_URL}/v1/automation/triggers/prerequisites`,
      {
        method: 'GET',
        headers: new Headers({Authorization: `Bearer ${tokenExpress}`}),
      },
  );
  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error(`get Triggers prerequisites: Unhandled error status code!: ${response.status}`);
  }

  return response.json();
};

export const createTrigger = async (triggerNew: Trigger) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({ trigger: triggerNew })
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/triggers?customerId=${user.customerId}`,
    requestOptions
  );

  handleError(response.status);

  const body = await response.json();
  if (response.status !== 200) {
    console.log('body:', JSON.stringify(triggerNew, null, 2));
    console.log('response:', response.statusText);
    throw Error(body.message);
  }
  return { body, response };
};

export const editTrigger = async (triggerUpdate: Trigger) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  const requestOptions = {
    method: 'PUT',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({ trigger: triggerUpdate })
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/triggers/${triggerUpdate.id}/?customerId=${user.customerId}`,
    requestOptions
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }

  return response;
};

export const updateStatusTrigger = async (triggerId: string, patch: any) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  const requestOptions = {
    method: 'PATCH',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({ patch })
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/triggers/${triggerId}?customerId=${user.customerId}`,
    requestOptions
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }
  return response;
};

export const deleteTrigger = async (triggerId: string) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  const requestOptions = {
    method: 'DELETE',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    })
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/triggers/${triggerId}?customerId=${user.customerId}`,
    requestOptions
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }

  return response;
};

export const getTriggersOverallImpactData = async () => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getTriggersOverallImpactData();
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/triggers/overallImpact?customerId=${user.customerId}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`
      })
    }
  );
  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error(
      `get Triggers Overall Impact Data: Unhandled error status code!: ${response.status}`
    );
  }

  return response.json();
};

export const getTriggersOverallImpactTrendData = async (
  startTime: Date,
  endTime: Date
): Promise<TriggersOverallImpactTrendData> => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  const startTimeUTC = Utils.formatDateToUTC(startTime);
  const endTimeUTC = Utils.formatDateToUTC(endTime);
  if (isMocKData) {
    return MockData.getTriggersOverallImpactTrendData();
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/triggers/overallImpactTrend?customerId=${user.customerId}&startTime=${startTimeUTC}&endTime=${endTimeUTC}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`
      })
    }
  );
  handleError(response.status);
  if (!response.ok && !_.isEqual(response.status, 403)) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }

  return response.json();
};

export const getTriggerImpactData = async (
  triggerId: string,
  startTime: any,
  endTime: any
) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  const startTimeUTC = Utils.formatDateToUTC(startTime);
  const endTimeUTC = Utils.formatDateToUTC(endTime);
  if (isMocKData) {
    return MockData.getTriggersOverallImpactData();
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/triggers/Impact?customerId=${user.customerId}&triggerId=${triggerId}&startTime=${startTimeUTC}&endTime=${endTimeUTC}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`
      })
    }
  );
  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error(
      `get Trigger Impact Data: Unhandled error status code!: ${response.status}`
    );
  }

  return response.json();
};

export const getTriggerActionCases = async (triggerId: string) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getTriggerActionCases();
  }

  const endpoint = `${process.env.REACT_APP_APISERVER_URL}/v1/automation/triggers/${triggerId}/cases`;
  const response = await fetch(
      endpoint,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${tokenExpress}`,
        }),
      },
  );
  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error(
      `get Trigger Auto Respond Cases: Unhandled error status code!: ${response.status}`
    );
  }
  const body: TriggerActionCasesArray = await response.json();
  return body;
};

export const createOrUpdateCaseDeflectionTrigger = async (enable: boolean) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/triggers/system/case-deflection?customerId=${user.customerId}`,
    {
      method: 'PUT',
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        action: enable ? 'enable' : 'disable'
      }),
      credentials: 'include'
    } as RequestInit
  );

  handleError(response.status);
  if (response.status === 403) {
    return;
  }

  if (!response.ok) {
    throw new Error(`Something went wrong! Please try again`);
  }
  return await response.json();
};
