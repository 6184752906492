import React, { forwardRef } from 'react';
import { DarkTooltip } from '../../../routes/incidents/incident-list/DarkTooltip';
import { Text, TextProps } from './Text';
import { ReactComponent as HelpSvg } from '../../../assets/Help.svg';

interface HeadingProps extends Omit<TextProps, 'ref'> {}

export const Heading = forwardRef<HTMLSpanElement, HeadingProps>(
  (props, ref) => {
    return <Text variant="p4" weight="semi-bolder" color="dark" {...props} />;
  }
);

export const HeadingWithDarkTooltip: React.FC<{
  label: string;
  tooltipLabel: string;
}> = ({ label, tooltipLabel }) => (
  <Heading>
    {label}{' '}
    <DarkTooltip title={tooltipLabel}>
      <HelpSvg />
    </DarkTooltip>
  </Heading>
);
