import Utils from "../../utils/utils";
import {handleError, isMocKData} from "./utils";
import {User} from "../types";
import MockData from "../../utils/mockData";

export const getUserData = async (): Promise<User> => {
    const token_express = Utils.getObjectItem('token_express');
    if (isMocKData || !Utils.isOriginalCustomer()) {
        return MockData.getIrisPortalUserMockData();
    }

    let endpoint = `${process.env.REACT_APP_APISERVER_URL}/v1/users/me`;

    const response = await fetch(endpoint, {
        redirect: 'follow',
        method: 'GET',
        headers: new Headers({
            Authorization: `Bearer ${token_express}`,
        }),
    });

    handleError(response.status);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
};


export const logoutUser = async ():Promise<void> => {
    const token_express = Utils.getObjectItem('token_express');
    if (isMocKData) {
        return;
    }

    let endpoint = `${process.env.REACT_APP_APISERVER_URL}/v1/users/token`;
    const response = await fetch(endpoint, {
        redirect: 'follow',
        method: 'DELETE',
        headers: new Headers({
            Authorization: `Bearer ${token_express}`,
        }),
    });

    handleError(response.status);
    if (response.status > 300) {
        const body = await response.json();
        throw Error(body.message);
    }
};
