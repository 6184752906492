import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { handleError, isMocKData } from './utils';

export const getChatbotSetting = async () => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getChatbotSettingsMockData();
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/chatbot-setting?customerId=${user.customerId}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`
      })
    }
  );
  handleError(response.status);
  if (response.status === 403) {
    return {};
  }
  if (!response.ok) {
    throw new Error(
      `getChatbotSettings: Unhandled error status code!: ${response.status}`
    );
  }
  return response.json();
};

export const createChatbotSetting = async (
  brandLogo: File,
  bannerColor: string
) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  const formData = new FormData();
  formData.append('brandLogo', brandLogo);
  formData.append('bannerColor', bannerColor);

  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`
    }),
    body: formData
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/chatbot-setting?customerId=${user.customerId}`,
    requestOptions
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }

  return response;
};

export const editChatbotSetting = async (updateDict: any) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  const formData = new FormData();
  if (updateDict['brandLogo']) {
    formData.append('brandLogo', updateDict['brandLogo']);
  }
  if (updateDict['bannerColor']) {
    formData.append('bannerColor', updateDict['bannerColor']);
  }

  const requestOptions = {
    method: 'PUT',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`
    }),
    body: formData
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/chatbot-setting?customerId=${user.customerId}`,
    requestOptions
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }

  return response;
};
