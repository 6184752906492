import { Paper } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { DateRangePicker } from '../../../../routes/incidents/date-picker-component/DatePickerComponent';
import IrisPortalService from '../../../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../../../utils/cacheUtils';
import { Stack } from '../../../common';
import { Loader } from '../../../common/blocks/Loader';
import './Summary.scss';
import { SummaryItem } from './SummaryItem';
import {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE
} from '../../../../utils/constants';

export const Summary: FC = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE
  ]);

  const { data: incidentsSummary, isLoading } = useQuery(
    [CACHE_KEYS.DASHBOARD_SUMMARY, ...dateRange],
    () => IrisPortalService.getDashboardSummary(...dateRange),
    {
      ...CACHE_TIME[CACHE_KEYS.DASHBOARD_SUMMARY]
    }
  );

  if (isLoading) {
    return (
      <Paper style={{ flex: 1, marginBottom: '40px' }}>
        <Loader center />
      </Paper>
    );
  }
  return (
    <Stack style={{ width: '100%' }}>
      <div className="summary-container">
        {/* <div> */}
        <Stack
          direction="horizontal"
          justify="space-between"
          align="center"
          style={{ marginBottom: '16px' }}
        >
          <h3 className="heading">Summary</h3>
          <DateRangePicker value={dateRange} onChange={setDateRange} />
        </Stack>

        <SummaryItem data={incidentsSummary} />
        {/* </div> */}
      </div>
    </Stack>
  );
};
