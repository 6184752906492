import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const HtmlTooltip = withStyles(() => ({
  arrow: {
    color: '#303030'
  },
  tooltip: {
    backgroundColor: '#303030',
    color: 'white',
    width: 230,
    border: '1px solid #303030',
    borderRadius: 4
  }
}))(Tooltip);
