import React from 'react';

import { MenuItem } from '@material-ui/core';
import { style } from 'typestyle';
import { Tooltip } from '../../../Tooltip';

import './styles.scss';

type PortalUserMenuItem = {
  icon: React.ComponentType;
  onClick: () => void;
  title: string;
};

export const PortalUserMenuItem: React.FC<PortalUserMenuItem> = ({
  icon: Icon,
  onClick,
  title
}) => {
  const hoverColor = style({
    $nest: {
      '&:hover': {
        backgroundColor: '#5e81f41a'
      }
    }
  });

  return (
    <Tooltip
      title={title}
      placement="top"
      containerStyles={{ backgroundColor: 'red', padding: '30px' }}
    >
      <MenuItem
        disableRipple
        onClick={onClick}
        className={hoverColor}
        style={{ paddingRight: '0', height: '40px' }}
      >
        <div className="app-portal-user-menu-item" style={{ width: '100%' }}>
          <Icon />

          <p
            style={{
              overflow: 'hidden',
              flex: '1',
              textOverflow: 'ellipsis'
            }}
          >
            {title}
          </p>
        </div>
      </MenuItem>
    </Tooltip>
  );
};
