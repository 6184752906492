import { PageContainer } from '../components/common/blocks/PageContainer';
import { Stack } from '../components/common';
import React from 'react';

export interface PageProps extends React.ComponentProps<any> {
  classes?: {
    root?: string;
  };
}

export const Page: React.FC<PageProps> = (props) => {
  const { classes: { root = '' } = {} } = props;

  return (
    <React.Fragment>
      <PageContainer className={root}>
        <Stack
          direction={'vertical'}
          gap={40}
          style={{
            marginBottom: '3em'
          }}
        >
          {props.children}
        </Stack>
      </PageContainer>
    </React.Fragment>
  );
};
