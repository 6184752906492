import React, {Component} from 'react';
import './Login.scss';
import LoginButton from '../../components/login-button/LoginButton';
import Footer from '../../components/footer/Footer';
import IrisPortalService from '../../services/IrisPortalService';
import Utils from '../../utils/utils';
import {logEvent} from '../../utils/amplitude';
import {Loader} from '../../components/common/blocks/Loader';
import {Stack} from '../../components/common';
import Constants from '../../utils/constants';

interface Props {
  location: any;
  history: any;
}

interface State {
    loading: boolean;
}

class UnAuthorizedError extends Error {
}

class Login extends Component<Props, State> {

  constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  async componentDidMount() {
    if (Utils.isDemo()) {
      // Load Mock Data
      this.props.history.push('/dashboard');
      return;
    }

    let url = this.props.location.search;
    let params = new URLSearchParams(url);

    //redirect to dashboard if the user is logged
    let user = Utils.getObjectItem('user');
    if (user.email) {
      this.props.history.push('/dashboard');
    }

    if (params.get('sourceName')) {
      await this.getUserInfo(params.get('sourceName'));
    }

    // Login Zendesk
    if (params.get('zendesk_authorization_code')) {
      let authorizationCode = params.get('zendesk_authorization_code') || '';
      this.setState({loading: true});
      try {
        let data = await IrisPortalService.onLoginZendesk(authorizationCode);
        await this.getUserInfo(data.sourceName);
      } catch (e) {
        this.setState({loading: false});
        Utils.showError((e as Error).toString());
        this.props.history.push('/platforms');
      }
    }
  }

  async getUserInfo(sourceName: any) {
    if (!sourceName) return;

    this.setState({loading: true});
    try {
      const data = await IrisPortalService.getUserInfo(sourceName);
      let userInfo = data.user;
      if (userInfo) {
        //handle if the user enter the first time
        userInfo.isIrisPortal = true;
        Utils.saveObjectItem('user', userInfo);
        console.log('isNewUser ', userInfo.isNewUser);
        const onboarded = data.onboarded;
        Utils.saveObjectItem('onboarded', onboarded);
        const accessEnabled = data.accessEnabled;
        Utils.saveObjectItem('accessEnabled', {current: accessEnabled});
        const backfillDone = userInfo.backfillDone;
        Utils.saveObjectItem('backfillDone', {current: backfillDone});

        if (userInfo.isNewUser) {
          this.props.history.push('/platforms');
        } else {
          let tokenExpress = data.token_express;
          Utils.saveObjectItem('token_express', tokenExpress);
          // When user is logged succesfully
          logEvent('User logged', {
            email: userInfo.email,
            sourceName: userInfo.sourceName,
            organizationId: userInfo.sourceOrganizationId
          });

          try {
            const customer = await IrisPortalService.getCustomer();
            Utils.saveCustomerItem(customer);
          } catch (e) {
            console.error(`getUserInfo: API Error: ${e}`);
          }

          const nextURL = Utils.getObjectItem('meta');
          if (!backfillDone) {
            this.props.history.push('/dashboard');
          } else if (accessEnabled && nextURL?.next === '/free-trial-expired') {
            window.location.pathname = '/dashboard';
          } else if (accessEnabled && nextURL?.next) {
            window.location.href = window.location.origin + nextURL.next;
          } else if (accessEnabled) {
            window.location.pathname = Constants.routes.DASHBOARD;
          } else if (!accessEnabled) {
            this.props.history.push('/free-trial-expired');
          }
        }
      } else {
        throw new UnAuthorizedError('Please login');
      }
    } catch (e: unknown) {
      this.setState({loading: false});
      const err = Utils.ensureError(e);
      if ( err instanceof UnAuthorizedError ) {
        Utils.showNotify(err.message)
        return;
      }

      Utils.showError(err.message);
    }
  }

  loginSalesforce() {
    IrisPortalService.onLoginSalesforce(false);
  }

  loginSalesforceSandbox() {
    IrisPortalService.onLoginSalesforce(true);
  }

  loginGoogle() {
    IrisPortalService.onLoginGoogle();
  }

  loginMicrosoft() {
    IrisPortalService.onLoginMicrosoft();
  }

  render() {
    if ( this.state.loading ) {
      return <React.Fragment>
        <div className={"d-flex align-items-center minh-100"}>
          <Stack direction={"vertical"} gap={20} className={"my-0 mx-auto"}>
            <div>Logging you in...</div>
            <Loader center={true}/>
          </Stack>
        </div>
      </React.Fragment>;
    }

    return (
      <div className="app-login">
        {/* <header className="signin-header"></header> */}
        <main>
          <div className="signin-row">
            <div>
              <div className="logo">
                <img alt="" width="200px" src="/images/irisagent.svg" />
              </div>
              Welcome to IrisAgent! Delight your customers and supercharge your customer support with AI.
              <br/><br/>
              <form>
                <LoginButton
                  svgIcon="/images/google.svg"
                  account="Google"
                  title="Continue with Google"
                  onClick={this.loginGoogle}
                />
                <LoginButton
                  svgIcon="/images/salesforce.svg"
                  account="Salesforce"
                  title="Continue with Salesforce"
                  onClick={this.loginSalesforce}
                />
                <LoginButton
                  svgIcon="/images/salesforce.svg"
                  account="Salesforce"
                  title="Continue with Salesforce Sandbox"
                  onClick={this.loginSalesforceSandbox}
                />
                <LoginButton
                  svgIcon="/images/microsoft.svg"
                  account="Salesforce"
                  title="Continue with Microsoft"
                  onClick={this.loginMicrosoft}
                />
                <br />
                {/*<div className="sub-text">
                  First time user? Sign in to get started.
                </div>*/}
              </form>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default Login;
