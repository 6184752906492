import React, { FC } from 'react';
import {
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow as MUITableRow,
  withStyles
} from '@material-ui/core';
import { Stack, Text, useTableSorters } from '../../../components/common';
import { formatDistance } from 'date-fns';
import Constants from '../../../utils/constants';
import { useHistory } from 'react-router';
import './Agent.scss';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import { TablePaper } from '../../../components/common/blocks/TablePaper';

type Props = {
  data: any;
  filterKey: any;
  filterLabel: any;
};

export const TopAgentByCategoriesTable: FC<Props> = (props) => {
  const { data, filterKey, filterLabel } = props;

  const headCells = [
    { id: 'rank', label: 'Rank', width: '15%' },
    { id: 'name', label: 'Agent', width: '40%' },
    { id: filterKey, label: filterLabel, width: '45%' }
  ];
  const { onSort, sortColumn, sortOrder } = useTableSorters({ pageSize: 15 });

  const history = useHistory();
  const handleRowClick = (agentId: any) => {
    history.push(
      `${Constants.routes.AGENT_DETAILS}?${Constants.AGENT_ID_SEARCH_PARAM}=${agentId}`
    );
  };

  return (
    <TablePaper>
      <TableContainer>
        <Table aria-label="Negative Sentiment Table" size="small">
          <TableHeadSorting
            order={sortOrder}
            orderByColumn={sortColumn}
            onRequestSort={(_, column) => onSort(column)}
            headCells={headCells}
          />

          <TableBody>
            {data?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={100}
                  align="center"
                  style={{ border: 'none' }}
                >
                  <Text variant="p3" weight="semi-bolder" color="dark">
                    No Data
                  </Text>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {data?.map((agent: any, index: any) => (
                  <TableRow
                    key={index}
                    className="agent-row"
                    onClick={() => handleRowClick(agent.id)}
                  >
                    <TableCell variant="body" style={{ borderBottom: 'none' }}>
                      <Text variant="p1" weight="semi-bolder">
                        {index + 1}
                      </Text>
                    </TableCell>
                    <TableCell variant="body" style={{ borderBottom: 'none' }}>
                      <Text
                        variant="p1"
                        weight="semi-bolder"
                        style={{ color: '#5E81F4' }}
                      >
                        {agent.name === null ? '' : agent.name}
                      </Text>
                    </TableCell>
                    <TableCell variant="body" style={{ borderBottom: 'none' }}>
                      <Stack justify="space-between" align="center">
                        <Text variant="p1" weight="semi-bolder">
                          {filterKey === 'mttrInSecs' ? (
                            <>
                              {agent.mttrInSecs
                                ? `${formatDistance(
                                    0,
                                    agent.mttrInSecs * 1000
                                  )}`
                                : 0}
                            </>
                          ) : (
                            <>{agent.numClosedCases}</>
                          )}
                        </Text>
                        <img
                          onClick={() => handleRowClick(agent.id)}
                          alt=""
                          src="/images/goRedirect.png"
                          width="auto"
                          height="40px"
                          className="incidents-summary-go"
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TablePaper>
  );
};

const TableRow = withStyles(
  createStyles({
    root: {
      '&:hover': {
        background: '#FBFBFD'
      }
    }
  })
)(MUITableRow);
