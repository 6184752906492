import React from "react";
import {useQuery} from "react-query";
import FrontendApiServer from "../services/IrisPortalService/frontendApiServer";
import {Stack} from "../components/common";
import {Loader} from "../components/common/blocks/Loader";
import Utils from "../utils/utils";
import Constants from "../utils/constants";
import {Redirect} from "react-router-dom";

export function OktaAuthRedirect() {
    const {
        data: tokenAfterOktaAuth,
        isLoading,
        isSuccess,
    } = useQuery(
        [],
        () => FrontendApiServer.getTokenAfterOktaAuth(),
        {
            onError: (e) => {
                Utils.showUnknownError(e);
            },
        }
    );

    React.useEffect(
        () => {
            if ( !isSuccess ) {
                return;
            }

            window.location.href = `${process.env.REACT_APP_BACKEND_URL}/okta/auth/redirect?token=${tokenAfterOktaAuth}`;
        },
        [isSuccess],
    )

    if ( isLoading ) {
        // Show loading screen until the query is resolved
        return getLoader();
    }

    if (!isSuccess) {
        // Redirect to login page if the query fails
        return <Redirect
            to={{
                pathname: Constants.routes.LOGIN,
            }}
        />;
    }

    function getLoader() {
        return (
            <div className={"d-flex align-items-center minh-100"} style={{minHeight: "90vh"}}>
                <Stack direction={"vertical"} gap={20} className={"my-0 mx-auto"}>
                    {/*<div>Logging you in...</div>*/}
                    <Loader center={true}/>
                </Stack>
            </div>
        );
    }

    return <></>
}
