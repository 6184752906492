import { useQuery } from 'react-query';
import IrisPortalService from '../../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../../utils/cacheUtils';
import Utils from '../../../utils/utils';

import { getCurrentLastTriggers } from './getCurrentLastTriggers';

export function useTriggers() {
  const { data: triggersData, isFetching, refetch } = useQuery(
    [CACHE_KEYS.TRIGGERS_DATA, getCurrentLastTriggers()],
    () => IrisPortalService.getTriggers(),
    {
      refetchOnWindowFocus: false,
      onError: (e) => {
        Utils.showError(String(e));
      },
      onSuccess: () => {},
      ...CACHE_TIME.get(CACHE_KEYS.TRIGGERS_DATA)
    }
  );

  return { triggersData, isFetching, refetch };
}
