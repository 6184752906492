import React, { ReactElement } from 'react';

import { RulesNativeSelect } from '../../RulesNativeSelect';

import {
  formatPrerequisitesData,
  RulesRowManager,
  useTriggersPrerequisites
} from '../../../utils';

import { HandleRowComponentType, Option } from '../../../types';
import '../styles.scss';
import { ReactComponent as Jira } from '../../../../../assets/Jira.svg';

const COMMENT_ADDED = 'comment_added';
const VALUE_CHANGED_TO = 'value_changed_to';

/**
 * Row manager for LinkedJiraIssue
 */
export class LinkedJiraIssueRowManager extends RulesRowManager {
  linkedJiraIssuesOperator: Option[] = [
    { name: VALUE_CHANGED_TO, label: 'status' },
    // { name: 'value_changed_to', label: 'Changes to' },
    { name: COMMENT_ADDED, label: 'has a new comment' }
  ];
  constructor(
    options: Option[],
    actionValue: any,
    actionOperator: any,
    type: string
  ) {
    super(options, actionValue, actionOperator, type);
    this.action.type = 'linked_jira_status';
    this.action.operator =
      actionOperator === undefined ? 'value_changed_to' : actionOperator;
  }

  setDefaultActionValue(): void {
    this.action.value = '';
  }
  isMockData(): boolean {
    return process.env.REACT_APP_MOCKDATA === 'true';
  }

  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    const prerequesitesData = useTriggersPrerequisites();
    const linkedJiraIssueOptions = formatPrerequisitesData(
      prerequesitesData.triggersPrerequesites?.jiraStatuses || []
    );
    if (this.action.value === undefined) this.setDefaultActionValue();

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Jira />
          <p className="title">Linked jira issue</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <div>
            <RulesNativeSelect
              options={this.linkedJiraIssuesOperator.filter(
                (opr) =>
                  (opr.name !== 'comment_added' &&
                    this.isMockData() === false) ||
                  this.isMockData() === true
              )}
              defaultValue={this.action.operator}
              onChange={(e) => {
                this.action.operator = e.target.value;
                // if (this.action.operator === 'status') {
                //   this.action.value = this.action.operator;
                // }
                handleRowComponent(this.action);
              }}
            />
          </div>
          {this.action.operator === 'value_changed_to' && (
            <div style={{ marginLeft: '20px' }}>
              <RulesNativeSelect
                options={linkedJiraIssueOptions}
                defaultValue={this.action.value}
                onChange={(e) => {
                  this.action.value = e.target.value;
                  handleRowComponent(this.action);
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
