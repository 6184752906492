import { Paper } from '@material-ui/core';
import _ from 'lodash';
import React, { FC } from 'react';
import { Stack, Text } from '../../../components/common';
import { DataMetric } from '../types';

export const FormattedMetric: FC<{
  data: DataMetric;
  numberMetricStyleVariant: 'p1' | 'p2' | 'p3' | 'p4' | 'p5';
}> = ({ data, numberMetricStyleVariant }) => {
  if (_.isArray(data.metric)) {
    return (
      <Stack direction="horizontal" align="center" gap={10}>
        <Text
          variant={numberMetricStyleVariant}
          weight="semi-bolder"
          style={{ color: '#1C1D21' }}
        >
          {data.metric[0]}
        </Text>
        <Text variant="p0" weight="regular" color="green">
          {data.metric[1]}
        </Text>
      </Stack>
    );
  } else {
    return (
      <Text variant="p5" weight="semi-bolder" style={{ color: '#1C1D21' }}>
        {data.metric}
      </Text>
    );
  }
};

export const MetricsRow: FC<{
  data: DataMetric[];
  numberMetricStyleVariant?: 'p1' | 'p2' | 'p3' | 'p4' | 'p5';
}> = ({ data, numberMetricStyleVariant = 'p5' }) => {
  return (
    <Stack direction="horizontal" gap={30}>
      <Paper
        style={{
          textAlign: 'left',
          cursor: 'default',
          width: 'auto',
          boxShadow: 'none'
        }}
      >
        <Stack
          direction="horizontal"
          justify="flex-start"
          gap={20}
          align="center"
          style={{ width: 'minmax(302px, 1fr)', paddingBottom: '30px' }}
        >
          {data.map((data, index) => {
            return (
              <Stack
                style={{
                  padding: '20px 40px',
                  borderRadius: 10,
                  flex: 'initial',
                  background:
                    'linear-gradient(0deg, rgba(94, 129, 244, 0.05), rgba(94, 129, 244, 0.05)), #FFFFFF'
                }}
                direction="vertical"
                key={index}
              >
                <FormattedMetric
                  data={data}
                  numberMetricStyleVariant={numberMetricStyleVariant}
                />
                <Text
                  variant="p1"
                  weight="regular"
                  style={{ color: '#8181A5' }}
                >
                  {data.description}
                </Text>
              </Stack>
            );
          })}
        </Stack>
      </Paper>
    </Stack>
  );
};
