import React, { FC } from 'react';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import { DateTuple, formatDateRange } from './utils';
import './DateDisplay.scss';

export const DateDisplay: FC<{
  dateRange?: DateTuple;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = (props) => {
  const { dateRange: value, onClick } = props;
  return (
    <>
      <div
        className="root"
        onClick={onClick}
        style={{
          border: value ? '2px solid #ECECF2' : 'none',
          padding: value ? 5 : 10
        }}
      >
        <DateRangeOutlinedIcon className="calendar-icon" />
        {value ? formatDateRange(value) : ''}
      </div>
      {props.children}
    </>
  );
};
