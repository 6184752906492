import { Paper } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import IrisPortalService from '../../../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../../../utils/cacheUtils';
import Utils from '../../../../utils/utils';
import { Stack } from '../../../common';
import { Loader } from '../../../common/blocks/Loader';
import { StackedBarGraph } from '../Graphs/StackedBarGraph';
import './RevenueImpacted.scss';
import '../Graphs/Graph.scss';
import LegendItem from '../Graphs/LegendItem';

export const CaseStatus: FC<any> = (props) => {
  const [caseStatusBin, setCaseStatusBin] = useState<any[]>([]);
  const [cumulativeBin, setCumulativeBin] = useState<any[]>([]);
  const bars = [
    {
      name: 'total',
      label: 'Revenue Impact',
      color: '#8181A5',
      type: 'currency',
      hide: true
    },
    {
      name: 'closed',
      label: 'Closed',
      color: '#219653',
      type: 'currency'
    },
    {
      name: 'open',
      label: 'Open',
      color: ' #F2C94C',
      type: 'currency'
    }
  ];

  const { data: revenueImpactData, status: statusRevenue } = useQuery(
    [CACHE_KEYS.REVENUE_IMPACT_CASE_STATUS_DATA, ...props.dateRange],
    () =>
      IrisPortalService.getRevenueImpact(
        props.dateRange[0],
        props.dateRange[1],
        'status'
      ),
    {
      ...CACHE_TIME.get(CACHE_KEYS.REVENUE_IMPACT_CASE_STATUS_DATA),
      refetchOnWindowFocus: false,
      onError: (e) => {
        Utils.showError(String(e));
      },
      onSuccess: (CaseStatusData) => {}
    }
  );
  useEffect(() => {
    if (revenueImpactData && statusRevenue === 'success') {
      revenueImpactData.impact.graph.sort(
        (a: any, b: any) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );
      let open =
        revenueImpactData?.impact.cumulative.topAcvStatuses[0]?.item === 'Open'
          ? revenueImpactData?.impact.cumulative.topAcvStatuses[0]?.acv
          : revenueImpactData?.impact.cumulative.topAcvStatuses[1]?.acv;
      let closed =
        revenueImpactData?.impact.cumulative.topAcvStatuses[0]?.item ===
        'Closed'
          ? revenueImpactData?.impact.cumulative.topAcvStatuses[0]?.acv
          : revenueImpactData?.impact.cumulative.topAcvStatuses[1]?.acv;
      if (!open) {
        open = 0;
      }
      if (!closed) {
        closed = 0;
      }
      const newCumulativeBin = [
        {
          name: 'total',
          value: open + closed
        },
        {
          name: 'open',
          value: open
        },
        {
          name: 'closed',
          value: closed
        }
      ];

      const newCaseStatusBin: any = [];
      revenueImpactData.impact.graph.map((imp: any) => {
        console.log('herehereherehere', imp);
        let open =
          imp.data.topAcvStatuses[0]?.item === 'Open'
            ? imp.data.topAcvStatuses[0]?.acv
            : imp.data.topAcvStatuses[1]?.acv;
        let closed =
          imp.data.topAcvStatuses[0]?.item === 'Closed'
            ? imp.data.topAcvStatuses[0]?.acv
            : imp.data.topAcvStatuses[1]?.acv;
        if (!open) {
          open = 0;
        }
        if (!closed) {
          closed = 0;
        }
        newCaseStatusBin.push({
          date: imp.startTime,
          open: open,

          closed: closed,

          total: open + closed
        });
        return imp;
      });
      setCumulativeBin(newCumulativeBin);
      setCaseStatusBin(newCaseStatusBin);
    }
  }, [revenueImpactData, statusRevenue]);

  return (
    <Stack style={{ width: '100%' }} direction="horizontal">
      {statusRevenue === 'loading' ? (
        <Paper style={{ flex: 1, marginBottom: '40px' }}>
          <Loader center />
        </Paper>
      ) : (
        <>
          <Stack
            direction="vertical"
            gap={30}
            align="flex-start"
            style={{ width: '100%' }}
          >
            <Stack direction="horizontal">
              {cumulativeBin.map((item, index: number) => {
                return (
                  <Stack direction="horizontal" style={{ marginLeft: '40px' }}>
                    <LegendItem
                      value={Utils.getCurrencyValue(item.value)}
                      label={
                        bars.filter((bar) => bar.name === item.name)[0].label
                      }
                      color={
                        bars.filter((bar) => bar.name === item.name)[0].color
                      }
                    />
                    {cumulativeBin.length > 1 && index === 0 && (
                      <div
                        style={{
                          width: '4px',
                          height: '47px',
                          background: '#5E81F4',
                          marginLeft: '40px'
                        }}
                      ></div>
                    )}
                  </Stack>
                );
              })}
            </Stack>
            <StackedBarGraph
              customTooltip={true}
              title=""
              distribution={caseStatusBin}
              additionalTipOrder={0}
              bars={bars}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
