import React, { useMemo } from 'react';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { Stack, Text, useTableSorters } from '../../../components/common';
import { format } from 'date-fns';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import { useQuery } from 'react-query';
import IrisPortalService from '../../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../../utils/cacheUtils';
import { Loader } from '../../../components/common/blocks/Loader';
import { Button } from '../../../components/home/Button';
import { CSVLink } from 'react-csv';
import _ from 'lodash';

type TriggerActionCase = {
  CaseNumber: string;
  Subject: string;
  Description: string;
  timeOfCreation: string;
  InstanceURL: string;
};
export type TriggerActionCasesArray = {
  arCases: TriggerActionCase[];
};
type Props = {
  triggerId: string;
};

export const TriggerActionCases: React.FC<Props> = (props) => {
  const { triggerId } = props;
  const { data: arCases, isLoading } = useQuery(
    CACHE_KEYS.HOME_PAGE_STATS,
    () => IrisPortalService.getTriggerActionCases(triggerId),
    {
      ...CACHE_TIME[CACHE_KEYS.TRIGGER_AR_CASES]
    }
  );

  const {
    onSort,
    filterData,
    sortColumn,
    sortOrder,
    onPageChange,
    currentPage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    pageSize,
    ROWS_PER_PAGE_OPTIONS,
  } = useTableSorters({});
  const headCells = [
    { id: 'CaseNumber', label: 'Ticket ID', width: '20%', hasFilter: true },
    { id: 'Subject', label: 'Subject', width: '23%', hasFilter: true },
    { id: 'Description', label: 'Description', width: '30%', hasFilter: true },
    {
      id: 'timeOfCreation',
      label: 'Created On',
      width: '15%',
      hasFilter: true
    },
    { id: 'action', label: 'Action', width: '12%', hasFilter: true }
  ];

  const exportData = useMemo(() => {
    if (isLoading) {
      return [];
    }

    const headers = _.chain(headCells).map('label').without('Action').value();
    const data = [headers];

    data.push(
      ..._.map(arCases?.arCases, (arCase: TriggerActionCase) => {
        return [
          arCase.CaseNumber,
          _.escape(arCase.Subject),
          _.escape(arCase.Description),
          format(new Date(arCase.timeOfCreation), 'MMM dd, yyyy')
        ];
      })
    );

    return data;
  }, [headCells, arCases, isLoading]);

  return (
    <TablePaper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        <Text
          weight="semi-bolder"
          // variant="p4"
          color="dark"
          style={{
            width: '100%',
            float: 'left',
            margin: '2%',
            marginLeft: '0px',
            fontSize: 22
          }}
        >
          List of matching cases
        </Text>

        {!isLoading && !_.isEmpty(arCases?.arCases) && (
          <CSVLink
            data={exportData}
            filename={`irisagent_trigger_${triggerId}_matching_cases.csv`}
            style={{
              marginLeft: 'auto',
              width: '150px'
            }}
          >
            <Button variant="outlined">Export to CSV</Button>
          </CSVLink>
        )}
      </div>

      {isLoading && <Loader center />}
      {!isLoading && (
        <TableContainer>
          <Table size="small">
            <TableHeadSorting
              order={sortOrder}
              orderByColumn={sortColumn}
              onRequestSort={(_, column) => onSort(column)}
              headCells={headCells}
            />

            <TableBody>
              {arCases === undefined || arCases?.arCases.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={100}
                    align="center"
                    style={{ border: 'none' }}
                  >
                    <Text variant="p3" weight="semi-bolder" color="dark">
                      No Data
                    </Text>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {arCases &&
                    filterData(arCases?.arCases).map(
                      (arCase: TriggerActionCase, index: number) => (
                        <TableRow
                          key={index}
                          className="agent-row"
                          //   onClick={() => handleRowClick(agent.id)}
                        >
                          <TableCell
                            variant="body"
                            style={{ borderBottom: 'none' }}
                          >
                            <Text
                              variant="p1"
                              weight="semi-bolder"
                              style={{ color: '#5E81F4' }}
                            >
                              {arCase.CaseNumber}
                            </Text>
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ borderBottom: 'none' }}
                          >
                            <Text variant="p1" weight="semi-bolder">
                              <Tooltip title={arCase.Subject} placement="top">
                                <Text lineClamp={2}>{arCase.Subject}</Text>
                              </Tooltip>
                            </Text>
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ borderBottom: 'none' }}
                          >
                            <Text variant="p1" weight="semi-bolder">
                              <Tooltip
                                title={arCase.Description}
                                placement="top"
                              >
                                <Text lineClamp={2}>{arCase.Description}</Text>
                              </Tooltip>
                            </Text>
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ borderBottom: 'none' }}
                          >
                            <Text variant="p1" weight="semi-bolder">
                              {format(
                                new Date(arCase.timeOfCreation),
                                'MMM dd, yyyy'
                              )}
                            </Text>
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ borderBottom: 'none' }}
                          >
                            <Stack justify="space-between" align="center">
                              <Text variant="p1" weight="semi-bolder"></Text>
                              <a
                                href={arCase.InstanceURL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  style={{ justifyContent: 'center' }}
                                  alt=""
                                  src="/images/goRedirect.png"
                                  width="auto"
                                  height="40px"
                                  className="incidents-summary-go"
                                />
                              </a>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </>
              )}
            </TableBody>
          </Table>
          {arCases && arCases?.arCases.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={arCases?.arCases.length}
              rowsPerPage={pageSize}
              page={currentPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelDisplayedRows={labelDisplayedRows}
              onChangePage={(_, page) => onPageChange(page)}
            />
          ) : null}
        </TableContainer>
      )}
    </TablePaper>
  );
};
