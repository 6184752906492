import React from 'react';

import { Stack } from '../../../../components/common';
import { Paper } from '../../../../components/home/Paper';
import { RulesManager } from '../RulesManager';
import { ReactComponent as Condition } from '../../../../assets/gitMerge.svg';

import type { ConditionContainer, Option } from '../../types';

export const RulesTrigger: React.FC<{
  triggers: any;
  options: Option[]; // constants options (found in ../constants.ts)
  handleActionsChange: (
    operator: string,
    actions: ConditionContainer[]
  ) => void; // parent handle function,
  conditionToolClicked: string;
  onTriggersEmpty: () => void;
  isEditable: boolean;
}> = ({
  triggers,
  options,
  handleActionsChange,
  conditionToolClicked,
  isEditable,
  onTriggersEmpty
}) => {
  return (
    <Paper>
      <Stack direction="vertical" gap={12}>
        <RulesManager
          beforeButtonTitle="Add conditions"
          afterButtonTitle="Conditions"
          values={
            triggers?.operands[0].operands.length > 0
              ? triggers?.operands[0].operands
              : triggers?.operands[1].operands
          }
          firstOperand={
            triggers?.operands[1]?.operands?.length > 0 ? 'or' : 'and'
          }
          options={options}
          handleActionsChange={(operator, val) =>
            handleActionsChange(operator, val)
          }
          buttonIcon={<Condition stroke="#5D5FEF" />}
          buttonColor="#5d5fef"
          type="trigger"
          conditionToolClicked={conditionToolClicked}
          onTriggersEmpty={onTriggersEmpty}
          isEditable={isEditable}
        />
      </Stack>
    </Paper>
  );
};
