import React, {Component} from 'react';
import './LoginIrisGPT.scss';
import IrisPortalService from '../../services/IrisPortalService';
import Utils from '../../utils/utils';
import {FormControl} from '@material-ui/core';
import {logEvent} from '../../utils/amplitude';
import {IrisAgentTextField} from "../../components/input/IrisAgentTextField";
import _ from "lodash";
import {PlatformConnectButtons, PlatformConnectFormControlText} from "../PlatformConnect";


const HTTP_URL_REGEXP = /^http(s)?:\/\//;

interface Props {
    onClick: any;
}

interface State {
    helpCenterUrl?: string;
    error?: string;
}

class LoginIrisGPT extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    loginIrisGPT = async () => {
        let helpCenterUrl = this.state.helpCenterUrl;

        // console.log(this.state);
        if (!helpCenterUrl) {
            let error = 'Cannot be empty';
            this.setState({error: error});
            return;
        }

        try {
            new URL(helpCenterUrl);
            if (!HTTP_URL_REGEXP.test(helpCenterUrl)) {
                throw new Error("Invalid Scheme");
            }
        } catch (e) {
            let error = 'URL is invalid. Please enter a valid URL. Ensure that the url starts with https:// or http://';
            this.setState({error: error});
            return;
        }

        let user = Utils.getObjectItem('user');

        // At the moment that the user wants to login with google (First Time)
        logEvent('Connect with IrisGPT button', {
            email: user.data.email,
            isNewUser: user.isNewUser,
            helpCenterUrl: helpCenterUrl
        });
        await IrisPortalService.signupIrisGpt(helpCenterUrl);
    };

    handleChangeHelpCenterUrl = (e: any) => {
        this.setState({error: ""});
        this.setState({helpCenterUrl: e.target.value});
    };

    render() {
        const {onClick} = this.props;
        return (
            <div>
                <FormControl
                    classes={{
                        root: "w-100"
                    }}
                >
                    <IrisAgentTextField
                        label="Help Center Url" color="secondary" variant="outlined" size={"small"} required
                        onChange={this.handleChangeHelpCenterUrl}
                    />
                    <PlatformConnectFormControlText error={!_.isEmpty(this.state.error)} message={this.state.error}/>
                </FormControl>
                <PlatformConnectButtons onCancel={onClick} onConnect={this.loginIrisGPT}/>
            </div>
        );
    }
}

export default LoginIrisGPT;
