import React from 'react';
import { RouteComponentProps } from 'react-router';
import { RevenueImpacted } from '../../components/home/components/RevenueImpacted/RevenueImpacted';
import { CustomerAccountSearch } from './CustomerAccountSearch';
import { CustomerHealthScoreAndCases } from './CustomerHealthScoreAndCases';
import { ImportantHealthSupportMetrics } from './ImportantHealthSupportMetrics';
import { Page } from '../Page';

export const HealthOverview: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <Page>
        <CustomerAccountSearch />

        <ImportantHealthSupportMetrics />

        <div>
          <RevenueImpacted />
        </div>

        <CustomerHealthScoreAndCases />
      </Page>
    </React.Fragment>
  );
};
