import React, { ReactElement } from 'react';

import { RulesNativeSelect } from '../../RulesNativeSelect';
import { RulesRowManager } from '../../../utils/RulesRowManager';

import { HandleRowComponentType, Option } from '../../../types';
import '../styles.scss';
import { ReactComponent as Trello } from '../../../../../assets/trello.svg';
import {
  formatPrerequisitesData,
  useTriggersPrerequisites
} from '../../../utils';

/**
 * Row manager for ChangeCaseStatus
 */
export class ChangeCaseStatusRowManager extends RulesRowManager {
  constructor(
    options: Option[],
    actionValue: any,
    actionOperator: any,
    type: string
  ) {
    super(options, actionValue, actionOperator, type);
    this.action.type = 'change_case_status';
  }
  setDefaultActionValue(): void {
    this.action.value = '';
  }
  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    const prerequesitesData = useTriggersPrerequisites();
    const change_case_status = formatPrerequisitesData(
      prerequesitesData.triggersPrerequesites?.caseStatuses || []
    );
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Trello />
          <p className="title">Change case status</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <p className="operator-text">to</p>
          <div>
            <RulesNativeSelect
              options={change_case_status}
              defaultValue={this.action.value}
              onChange={(e) => {
                this.action.value = e.target.value;
                handleRowComponent(this.action);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
