import React, { FC, useEffect, useMemo, useState } from 'react';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import {
  Heading,
  Stack,
  Text,
  useTableSorters
} from '../../../components/common';
import { CaseWithPriority, HighPriorityCase } from '../types';
import { PriorityTooltip } from '../../../routes/incidents/incident-list/types';
import Utils from '../../../utils/utils';
import _ from 'lodash';
import {
  MenuListComposition,
  MenuListCompositionValue
} from './MenuListComposition';
import { PriorityCell } from '../../incidents/incident-list/PriorityCell';
import moment from 'moment';
import { useCustomerHealthDataExtras } from '../hooks/useCustomerHealthDataExtras';
import { Loader } from '../../../components/common/blocks/Loader';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import {MultiSelectInput, MultiSelectInputOption} from "../../../components/MultiSelectInput";

const TableHeaderCells = [
  { id: 'CaseNumber', label: 'Case & Subject', width: '25%', hasFilter: true },
  // { id: 'Subject', label: 'Subject', width: '25%', hasFilter: true },
  {
    id: 'priorityScore.score',
    label: 'Priority Score',
    width: '10%',
    hasFilter: true
  },
  {
    id: 'openTimeSecs',
    label: 'Wait Time',
    width: '10%',
    hasFilter: true
  },
  {
    id: 'priority',
    label: 'Case Priority',
    width: '10%',
    hasFilter: true
  },
  {
    id: 'numberOfComments',
    label: 'Number of comments',
    width: '10%',
    hasFilter: true
  },
  {
    id: 'caseSentimentBucket.title',
    label: 'Sentiment Score',
    width: '10%',
    hasFilter: true
  },
  {
    id: 'Status',
    label: 'Status',
    width: '10%',
    hasFilter: true
  },
  {
    id: 'AccountName',
    label: 'Customer',
    width: '15%',
    hasFilter: true
  }
];


export const HighPriorityScoreCases: FC<{
  setHighPriorityCasesCount?: (count: number) => void;
}> = (props) => {
  const {
    dataCustomerHealthExtras,
    isFetchingCustomerHealthExtras
  } = useCustomerHealthDataExtras();
  // @ts-expect-error
  const highPriorityCases: HighPriorityCase[] =
    dataCustomerHealthExtras?.customerHealth.highPriorityCaseList;
  const { setHighPriorityCasesCount } = props;
  const {
    onSort,
    filterData,
    sortColumn,
    sortOrder,
    onPageChange,
    currentPage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    pageSize,
    ROWS_PER_PAGE_OPTIONS,
  } = useTableSorters({});

  const title = 'Cases that need attention';

  const allAgentNames = _.chain(highPriorityCases)
    .map('agentNames')
    .flatten()
    .uniq()
    .reject(_.isEmpty)
    .value();

  const agentNameMenuValues: MultiSelectInputOption[] = allAgentNames.map((agentName) => {
    return {
      label: agentName,
    };
  });

  const [selectedAgents, setSelectedAgents] = useState<MultiSelectInputOption[]>([]);

  const data = useMemo(() => {
    if ( _.isEmpty(selectedAgents) ) {
      return highPriorityCases;
    }

    const selectedAgentNames = _.map(selectedAgents, 'label');
    return highPriorityCases.filter((doc) =>
        _.chain(selectedAgentNames)
            .intersection(doc.agentNames || [])
            .isEmpty()
            .thru((isEmpty) => !isEmpty)
            .value()
    );
  }, [selectedAgents, highPriorityCases]);

  useEffect(() => {
    if (highPriorityCases.length > 0) {
      if (!_.isNil(setHighPriorityCasesCount)) {
        setHighPriorityCasesCount(highPriorityCases.length);
      }
    }
  }, [highPriorityCases, setHighPriorityCasesCount]);

  return (
    <TablePaper style={{ marginTop: '2%' }}>
      <Stack direction="vertical" gap={20}>
        <Stack
          direction={'horizontal'}
          style={{
            alignItems: 'center',
            paddingTop: 16
          }}
        >
          <Heading>{title}</Heading>

          {!_.isEmpty(data) && (
            <div
              style={{
                marginLeft: 'auto'
              }}
            >
              <MultiSelectInput
                  displayValue="label"
                  placeholder="All Agents"
                  onKeyPressFn={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  valuesSelected={selectedAgents}
                  onRemove={setSelectedAgents}
                  onChange={setSelectedAgents}
                  options={agentNameMenuValues}
              />
            </div>
          )}
        </Stack>
        {!isFetchingCustomerHealthExtras ? (
          <TableContainer
            component={Paper}
            elevation={0}
            // style={{ overflow: 'hidden' }}
          >
            <Table aria-label="High Priority Score Table">
              <TableHeadSorting
                order={sortOrder}
                orderByColumn={sortColumn}
                onRequestSort={(_, column) => onSort(column)}
                headCells={TableHeaderCells}
              />
              <TableBody>
                {filterData(data).length === 0 && (
                  <TableRow>
                    <TableCell colSpan={100} align="center">
                      <Text>No Data</Text>
                    </TableCell>
                  </TableRow>
                )}
                {filterData(data).map((row: CaseWithPriority, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <a
                          href={row.InstanceURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() =>
                            logEventWithUserInfo(
                              `Customer Health High Priority - ${title}`
                            )
                          }
                        >
                          <p
                            style={{
                              color: 'rgb(94, 129, 244)',
                              fontSize: '16px',
                              fontWeight: 700
                            }}
                          >
                            {row.CaseNumber}
                          </p>
                        </a>
                        <Tooltip title={row.Subject} placement="top">
                          <Text color="dark" variant="p1" lineClamp={2}>
                            {row.Subject}
                          </Text>
                        </Tooltip>
                      </TableCell>

                      <TableCell
                        style={{ paddingRight: '0px', paddingLeft: '0px' }}
                      >
                        <div
                          style={{
                            background: 'rgb(165, 166, 246,0.1)',
                            // opacity: '0.1',
                            borderTopLeftRadius: '4px',
                            borderBottomLeftRadius: '4px',
                            height: '45px',
                            paddingLeft: '10px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <LocalPriorityCell item={row}></LocalPriorityCell>
                        </div>
                      </TableCell>

                      <TableCell
                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                      >
                        <div
                          style={{
                            background: 'rgb(165, 166, 246,0.1)',
                            // opacity: '0.1',

                            height: '45px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Text variant="p1">
                            {Utils.durationFormatReadable(
                              moment.duration(row.openTimeSecs, 'seconds')
                            )}
                          </Text>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                      >
                        <div
                          style={{
                            background: 'rgb(165, 166, 246,0.1)',
                            // opacity: '0.1',

                            height: '45px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Text variant="p1">{row.priority}</Text>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{ paddingLeft: '0px', paddingRight: '0px', alignItems: 'center' }}
                      >
                        <div
                          style={{
                            background: 'rgb(165, 166, 246,0.1)',
                            // opacity: '0.1',

                            height: '45px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Text variant="p1" className={'d-flex'} style={{
                            paddingLeft: '20px',
                          }}>
                            {row.commentsLength ? row.commentsLength : 0}
                          </Text>
                        </div>
                      </TableCell>
                      <TableCell style={{ paddingLeft: '0px' }}>
                        <div
                          style={{
                            background: 'rgb(165, 166, 246,0.1)',
                            // opacity: '0.1',

                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                            height: '45px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '10px'
                          }}
                        >
                          <Stack
                            direction="horizontal"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <Text variant="p1">
                              {row.caseSentimentBucket?.title}
                            </Text>
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end'
                              }}
                            >
                              <PriorityCellInfoTooltip item={row} />
                            </div>
                          </Stack>
                        </div>
                      </TableCell>
                      {/* </div> */}

                      <TableCell>
                        <Text variant="p1">{Utils.toCapital(row.Status)}</Text>
                      </TableCell>
                      <TableCell>
                        <Text variant="p1">{row.AccountName}</Text>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {data?.length ? (
              <TablePagination
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  component="div"
                  count={data.length}
                  rowsPerPage={pageSize}
                  page={currentPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelDisplayedRows={labelDisplayedRows}
                onChangePage={(_, page) => onPageChange(page)}
              />
            ) : null}
          </TableContainer>
        ) : (
          <Loader center />
        )}
      </Stack>
    </TablePaper>
  );
};

export const CreatePriority = (caseItem: CaseWithPriority) => {
  let priorityTooltipItem: PriorityTooltip = {
    Priority: caseItem.priority,
    priorityScore: caseItem.priorityScore,
    caseSentimentBucket: caseItem.caseSentimentBucket,
    openTimeSecs: caseItem.openTimeSecs,
    commentsLength: caseItem.commentsLength,
    annualContractValue: caseItem.annualContractValue
  };
  return priorityTooltipItem;
};

const LocalPriorityCell: FC<{ item: CaseWithPriority }> = (props) => {
  return (
    <Stack gap={7} align="center">
      <Text variant="p3" weight="semi-bold" style={{ color: '#FF6361' }}>
        {_.floor(props.item.priorityScore.score)}
        <Text variant={'p0'}>/100</Text>
      </Text>

      {/* <PriorityCell
        priorityTooltipItem={priorityTooltipItem}
        type="info"
      ></PriorityCell> */}
    </Stack>
  );
};

const PriorityCellInfoTooltip: FC<{ item: CaseWithPriority }> = (props) => {
  const priorityTooltipItem = CreatePriority(props.item);
  return (
    <PriorityCell
      placement="right"
      priorityTooltipItem={priorityTooltipItem}
      type="info"
    ></PriorityCell>
  );
};
