import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {logoutUser} from "../../../services/IrisPortalService/user";

import { Menu } from '@material-ui/core';
import { ReactComponent as CallIcon } from '../../../assets/PhoneCall.svg';
import { ReactComponent as ExitToAppIcon } from '../../../assets/Logout.svg';
import { ReactComponent as StorageIcon } from '../../../assets/Columns.svg';
import { ReactComponent as ArrowSvg } from '../../../assets/keyboardArrowUp.svg';
import MonetizationOnIconOutlined from '@material-ui/icons/MonetizationOnOutlined';

import { PortalUserMenuItem } from './PortalUserMenuItem';
import { PortalUserMenuSwitch } from './PortalUserMenuSwitch';

import { logEventWithUserInfo } from '../../../utils/amplitude';
import { isMocKData } from '../../../services/IrisPortalService/utils';
import StripeService from '../../../services/StripeService';
import Utils from '../../../utils/utils';

import './styles.scss';

type UserMenuItemsProps = {
  user: string | any;
  history: any;
};

const UserMenuItems: React.FC<UserMenuItemsProps> = ({user, history}) => {
  const handleLogout = useCallback(() => {
    logEventWithUserInfo('Logout');
    if (isMocKData) {
      Utils.saveObjectItem('user', user);
      return;
    }

    logoutUser()
        .finally(() => {
          Utils.clearStorage();
          window.location.reload();
        });
  }, [user]);

  const handleCompleteOnboarding = useCallback(() => {
    logEventWithUserInfo('Complete Onboarding');
    window.open('https://calendly.com/palak-iris/irisagent-setup', '_blank');
  }, []);

  const handleManageOrganization = useCallback(() => {
    history.push('/manage-organization');
  }, [history]);

  const handleBillingDetails = useCallback(() => {
    // When the user selects the option "Billing Details" from the menu (Takes the user to stripe)
    logEventWithUserInfo('Go to Billing details');
    StripeService.goToCustomerPortal(window.location.href);
  }, []);

  const isDemo = true;
  return (
    <>
      {user.showBillingDetails &&
        process.env.REACT_APP_ENVIRONMENT !== 'localhost' && (
          <PortalUserMenuItem
            title="Billing"
            icon={MonetizationOnIconOutlined}
            onClick={handleBillingDetails}
          />
        )}

      <PortalUserMenuItem
        title="Finish Onboarding & Setup"
        icon={CallIcon}
        onClick={handleCompleteOnboarding}
      />

      {process.env.REACT_APP_ENVIRONMENT === 'localhost' && (
        <PortalUserMenuItem
          title="Change Iris Portal View"
          icon={StorageIcon}
          onClick={handleManageOrganization}
        />
      )}

      {process.env.REACT_APP_ENVIRONMENT === 'localhost' && isDemo !== true && (
        <PortalUserMenuSwitch title="Enable Demo view" />
      )}

      <PortalUserMenuItem
        title="Logout"
        icon={ExitToAppIcon}
        onClick={handleLogout}
      />
    </>
  );
};

type PortalUserMenuProps = {
  isSidebarCollapsed: boolean;
};

export const PortalUserMenu: React.FC<PortalUserMenuProps> = ({
  isSidebarCollapsed
}) => {
  const user = Utils.getObjectItem('user');
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isActiveUserMenu, setIsActiveUserMenu] = React.useState<boolean>(
    false
  );

  const handleClick = () => {
    setIsActiveUserMenu((isActive) => !isActive);
  };

  const handleClickMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = () => {
    setAnchorEl(null);
    setIsActiveUserMenu(false);
  };

  return (
    <>
      <div
        className="app-portal-sidebar-user-menu"
        onClick={isSidebarCollapsed ? handleClickMenu : handleClick}
      >
        <span style={{ margin: '0 11px' }}>
          {(user && user.email ? user.email.charAt(0) : 'u').toUpperCase()}
        </span>
        {!isSidebarCollapsed && (
          <p style={{ marginLeft: '0', color: '#8181a5' }}>
            {user.email ?? 'user@user.com'}
          </p>
        )}

        {!isSidebarCollapsed && (
          <ArrowSvg
            style={{
              marginRight: '22.5px',
              marginLeft: '12.5px',
              transform: isActiveUserMenu ? '' : 'rotate(180deg)'
            }}
          />
        )}
      </div>

      {!isSidebarCollapsed && (
        <div
          style={{
            display: isActiveUserMenu ? 'block' : 'none'
          }}
        >
          <UserMenuItems user={user} history={history} />
        </div>
      )}

      {isSidebarCollapsed && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <div style={{ padding: '10px 20px 10px 15px' }}>
            <UserMenuItems user={user} history={history} />
          </div>
        </Menu>
      )}
    </>
  );
};
