import { HealthParameter } from '../../routes/customerHealth/types';

export const customerHealthMockData = {
  customerHealth: {
    customerLowestHealthScores: [
      {
        name: 'Customer 1',
        health: {
          _id: '62e7259466e2a2d82cd38fe6',
          parameters: [
            {
              _id: '62e7259466e2a24e62d38fe7',
              type: HealthParameter.TTR,
              score: 43,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a231dbd38fe8',
              type: HealthParameter.NUM_CASES,
              score: 9,
              value: 7,
              subtitle: '7 Cases'
            },
            {
              _id: '62e7259466e2a2af54d38fe9',
              type: HealthParameter.PRIORITY,
              score: 39,
              value: -0.21428571428571427,
              subtitle: 'Moderate Negative'
            },
            {
              _id: '62e7259466e2a2213ad38fea',
              type: HealthParameter.PRIORITY,
              score: 64,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a2150ed38feb',
              type: HealthParameter.ACV,
              score: 6,
              value: 2125250,
              subtitle: '$2.13M'
            },
            {
              _id: '62e7259466e2a237bbd38fec',
              type: HealthParameter.NUM_ESCALATED_CASES,
              score: 25,
              value: 2,
              subtitle: '2 Escalated Cases'
            }
          ],
          score: 31,
          healthBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      },
      {
        name: 'Customer 2',
        health: {
          _id: '62e7259466e2a28daed38fa7',
          parameters: [
            {
              _id: '62e7259466e2a257ebd38fa8',
              type: HealthParameter.TTR,
              score: 65,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a206edd38fa9',
              type: HealthParameter.NUM_CASES,
              score: 1,
              value: 14,
              subtitle: '14 Cases'
            },
            {
              _id: '62e7259466e2a26375d38faa',
              type: HealthParameter.CASE_SENTIMENT_SCORE,
              score: 39,
              value: -0.21428571428571427,
              subtitle: 'Moderate Negative'
            },
            {
              _id: '62e7259466e2a2cefbd38fab',
              type: HealthParameter.PRIORITY,
              score: 53,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a28a86d38fac',
              type: HealthParameter.ACV,
              score: 1,
              value: 14334810.4,
              subtitle: '$14.33M'
            }
          ],
          score: 31,
          healthBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      },
      {
        name: 'Customer 3',
        health: {
          _id: '62e7259466e2a2a78fd38ff8',
          parameters: [
            {
              _id: '62e7259466e2a23d57d38ff9',
              type: HealthParameter.TTR,
              score: 56,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a2898cd38ffa',
              type: HealthParameter.NUM_CASES,
              score: 21,
              value: 5,
              subtitle: '5 Cases'
            },
            {
              _id: '62e7259466e2a2fd90d38ffb',
              type: HealthParameter.CASE_SENTIMENT_SCORE,
              score: 30,
              value: -0.4,
              subtitle: 'Moderate Negative'
            },
            {
              _id: '62e7259466e2a27cddd38ffc',
              type: HealthParameter.PRIORITY,
              score: 50,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a20e5bd38ffd',
              type: HealthParameter.ACV,
              score: 3,
              value: 4722654,
              subtitle: '$4.72M'
            }
          ],
          score: 32,
          healthBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      },
      {
        name: 'Customer 4',
        health: {
          _id: '62e7259466e2a2e3abd38fd3',
          parameters: [
            {
              _id: '62e7259466e2a2aedbd38fd4',
              type: HealthParameter.TTR,
              score: 75,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a2e10dd38fd5',
              type: HealthParameter.NUM_CASES,
              score: 6,
              value: 8,
              subtitle: '8 Cases'
            },
            {
              _id: '62e7259466e2a25ba8d38fd6',
              type: HealthParameter.CASE_SENTIMENT_SCORE,
              score: 43,
              value: -0.125,
              subtitle: 'Moderate Negative'
            },
            {
              _id: '62e7259466e2a22da3d38fd7',
              type: HealthParameter.PRIORITY,
              score: 56,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a26b88d38fd8',
              type: HealthParameter.ACV,
              score: 11,
              value: 1523200,
              subtitle: '$1.52M'
            },
            {
              _id: '62e7259466e2a26b28d38fd9',
              type: HealthParameter.NUM_ESCALATED_CASES,
              score: 25,
              value: 2,
              subtitle: '2 Escalated Cases'
            }
          ],
          score: 36,
          healthBucket: {
            id: 'moderate',
            title: 'Moderate'
          }
        }
      },
      {
        name: 'Customer 5',
        health: {
          _id: '62e7259466e2a2b1c5d38fc1',
          parameters: [
            {
              _id: '62e7259466e2a2c14cd38fc2',
              type: HealthParameter.TTR,
              score: 68,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a23ed9d38fc3',
              type: HealthParameter.NUM_CASES,
              score: 1,
              value: 15,
              subtitle: '15 Cases'
            },
            {
              _id: '62e7259466e2a20fd0d38fc4',
              type: HealthParameter.CASE_SENTIMENT_SCORE,
              score: 36,
              value: -0.26666666666666666,
              subtitle: 'Moderate Negative'
            },
            {
              _id: '62e7259466e2a20761d38fc5',
              type: HealthParameter.PRIORITY,
              score: 76,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a20d90d38fc6',
              type: HealthParameter.ACV,
              score: 32,
              value: 518430.62,
              subtitle: '$518.43K'
            },
            {
              _id: '62e7259466e2a2750bd38fc7',
              type: HealthParameter.NUM_ESCALATED_CASES,
              score: 6,
              value: 3,
              subtitle: '3 Escalated Cases'
            }
          ],
          score: 36,
          healthBucket: {
            id: 'moderate',
            title: 'Moderate'
          }
        }
      },
      {
        name: 'Customer 6',
        health: {
          _id: '62e7259466e2a2fd6dd39090',
          parameters: [
            {
              _id: '62e7259466e2a227ccd39091',
              type: HealthParameter.TTR,
              score: 75,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a22d79d39092',
              type: HealthParameter.NUM_CASES,
              score: 21,
              value: 5,
              subtitle: '5 Cases'
            },
            {
              _id: '62e7259466e2a214d2d39093',
              type: HealthParameter.CASE_SENTIMENT_SCORE,
              score: 35,
              value: -0.3,
              subtitle: 'Moderate Negative'
            },
            {
              _id: '62e7259466e2a22d7ad39094',
              type: HealthParameter.PRIORITY,
              score: 50,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a2790ad39095',
              type: HealthParameter.ACV,
              score: 8,
              value: 1908384,
              subtitle: '$1.91M'
            }
          ],
          score: 37,
          healthBucket: {
            id: 'moderate',
            title: 'Moderate'
          }
        }
      },
      {
        name: 'Customer 7',
        health: {
          _id: '62e7259466e2a2b884d390a8',
          parameters: [
            {
              _id: '62e7259466e2a260a9d390a9',
              type: HealthParameter.TTR,
              score: 100,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a20362d390aa',
              type: HealthParameter.NUM_CASES,
              score: 3,
              value: 10,
              subtitle: '10 Cases'
            },
            {
              _id: '62e7259466e2a23d10d390ab',
              type: HealthParameter.CASE_SENTIMENT_SCORE,
              score: 30,
              value: -0.4,
              subtitle: 'Moderate Negative'
            },
            {
              _id: '62e7259466e2a26e1dd390ac',
              type: HealthParameter.PRIORITY,
              score: 55,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a2cd3bd390ad',
              type: HealthParameter.ACV,
              score: 1,
              value: 5345691.91,
              subtitle: '$5.35M'
            }
          ],
          score: 37,
          healthBucket: {
            id: 'moderate',
            title: 'Moderate'
          }
        }
      },
      {
        name: 'Customer 8',
        health: {
          _id: '62e7259466e2a27e82d38fa1',
          parameters: [
            {
              _id: '62e7259466e2a24a98d38fa2',
              type: HealthParameter.TTR,
              score: 58,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a2818bd38fa3',
              type: HealthParameter.NUM_CASES,
              score: 14,
              value: 6,
              subtitle: '6 Cases'
            },
            {
              _id: '62e7259466e2a2466dd38fa4',
              type: HealthParameter.CASE_SENTIMENT_SCORE,
              score: 41,
              value: -0.16666666666666666,
              subtitle: 'Moderate Negative'
            },
            {
              _id: '62e7259466e2a2997fd38fa5',
              type: HealthParameter.PRIORITY,
              score: 58,
              subtitle: ''
            },
            {
              _id: '62e7259466e2a27703d38fa6',
              type: HealthParameter.ACV,
              score: 18,
              value: 953783.6,
              subtitle: '$953.78K'
            }
          ],
          score: 38,
          healthBucket: {
            id: 'moderate',
            title: 'Moderate'
          }
        }
      }
    ],
    customerNegativeSentimentScores: [
      {
        _id: {
          AccountName: 'IrisAgent'
        },
        sentimentScore: -0.5800000052154064,
        numCases: 16
      },
      {
        _id: {
          AccountName: 'New Co.'
        },
        sentimentScore: -0.4400000052154064,
        numCases: 20
      },
      {
        _id: {
          AccountName: 'Tech Ltd.'
        },
        sentimentScore: -0.4000000052154064,
        numCases: 12
      }
    ],
    customerPositiveSentimentScores: [
      {
        _id: {
          AccountName: 'SpaceY'
        },
        sentimentScore: 0.5800000052154064,
        numCases: 12
      },
      {
        _id: {
          AccountName: 'United Ltd.'
        },
        sentimentScore: 0.2400000052154064,
        numCases: 3
      }
    ],
    customerWithNumCases: [
      {
        _id: {
          AccountName: 'SpaceY'
        },
        numCases: 12
      },
      {
        _id: {
          AccountName: 'United Ltd.'
        },
        numCases: 3
      }
    ],
    negativeCaseList: [
      {
        Assignees: [],
        Tags: [],
        _id: '5f7913116ef107c4ec4701c3',
        Id: '2970',
        organizationId: '360004463454',
        sourceName: 'Zendesk',
        AccountId: '370342904294',
        AccountName: 'IrisAgent',
        CaseNumber: '2970',
        timeOfCreation: '2020-07-28T06:03:56Z',
        Description: 'error message',
        InstanceURL: 'https://d3v-irisagent.zendesk.com/agent/tickets/2970',
        Reason: 'incident',
        Status: 'new',
        Subject: 'another error ticket for test',
        caseSentimentMagnitude: 1.600000023841858,
        caseSentimentScore: -99,
        customReason: 'error',
        FieldHistory: []
      },
      {
        Assignees: ['407784451554'],
        Tags: ['add', 'card', 'issue', 'payment'],
        _id: '5f7913116ef107c4ec4701c4',
        Id: '2972',
        organizationId: '360004463454',
        sourceName: 'Zendesk',
        AccountId: '370342904294',
        AccountName: 'IrisAgent',
        CaseNumber: '2972',
        timeOfCreation: '2020-07-31T18:26:38Z',
        Description: 'I am trying add a payment card but it returns an error',
        InstanceURL: 'https://d3v-irisagent.zendesk.com/agent/tickets/2972',
        Reason: 'incident',
        Status: 'open',
        Subject: 'Issue with adding payment card',
        caseSentimentMagnitude: 1.600000023841858,
        caseSentimentScore: -99,
        customReason: 'error',
        FieldHistory: [],
        UpdatedDate: '2020-08-27T23:50:10Z'
      },
      {
        Assignees: [null],
        Tags: [],
        _id: '5f7913116ef107c4ec4701ca',
        Id: '2979',
        organizationId: '360004463454',
        sourceName: 'Zendesk',
        AccountId: '370342904294',
        AccountName: 'IrisAgent',
        CaseNumber: '2979',
        timeOfCreation: '2020-08-25T02:30:41Z',
        Description: "I can't access the reporting page",
        InstanceURL: 'https://d3v-irisagent.zendesk.com/agent/tickets/2979',
        Reason: 'incident',
        Status: 'new',
        Subject: 'Unable to create new reports',
        caseSentimentMagnitude: 1.7000000476837158,
        caseSentimentScore: -99,
        customReason: '',
        FieldHistory: [],
        UpdatedDate: '2020-08-27T23:42:25Z'
      },
      {
        Assignees: [null],
        Tags: [],
        _id: '5f7913116ef107c4ec4701cb',
        Id: '2980',
        organizationId: '360004463454',
        sourceName: 'Zendesk',
        AccountId: '370342904294',
        AccountName: 'IrisAgent',
        CaseNumber: '2980',
        timeOfCreation: '2020-08-25T02:32:18Z',
        Description:
          'When will this issue be resolved? It is pretty frustrating to wait for such crucial item.',
        InstanceURL: 'https://d3v-irisagent.zendesk.com/agent/tickets/2980',
        Reason: 'incident',
        Status: 'new',
        Subject: 'Reporting page is loading very slowly',
        caseSentimentMagnitude: 1.600000023841858,
        caseSentimentScore: -98,
        customReason: 'performance',
        FieldHistory: [],
        UpdatedDate: '2020-08-28T00:01:07Z'
      },
      {
        Assignees: [null],
        Tags: [],
        _id: '5f7913116ef107c4ec4701cf',
        Id: '2984',
        organizationId: '360004463454',
        sourceName: 'Zendesk',
        AccountId: '370342904294',
        AccountName: 'IrisAgent',
        CaseNumber: '2984',
        timeOfCreation: '2020-09-24T19:44:53Z',
        Description:
          'I get an error when trying to add my credit card for payment',
        InstanceURL: 'https://d3v-irisagent.zendesk.com/agent/tickets/2984',
        Reason: 'incident',
        Status: 'new',
        Subject: 'Unable to add payment card on the profiles page',
        caseSentimentMagnitude: 1.7000000476837158,
        caseSentimentScore: -98,
        customReason: 'error',
        FieldHistory: [],
        UpdatedDate: '2020-10-12T18:45:40Z'
      },
      {
        Assignees: [null],
        Tags: [],
        _id: '5f853a3a8821701323cc4637',
        Id: '2986',
        organizationId: '360004463454',
        sourceName: 'Zendesk',
        AccountId: '370342904294',
        AccountName: 'IrisAgent',
        CaseNumber: '2986',
        timeOfCreation: '2020-10-11T21:36:45Z',
        Description: 'Unable to create new charts',
        InstanceURL: 'https://d3v-irisagent.zendesk.com/agent/tickets/2986',
        Reason: null,
        Status: 'new',
        Subject: 'Charts are not working',
        caseSentimentMagnitude: 1.7000000476837158,
        caseSentimentScore: -98,
        customReason: '',
        FieldHistory: [],
        UpdatedDate: '2020-10-11T21:36:45Z'
      },
      {
        Assignees: [null],
        Tags: [],
        _id: '5f99876e305b628b4f7119e6',
        Id: '2992',
        organizationId: '360004463454',
        sourceName: 'Zendesk',
        AccountId: null,
        AccountName: null,
        CaseNumber: '2992',
        timeOfCreation: '2020-10-23T22:16:44Z',
        Description: 'Cannot access the database',
        FieldHistory: [
          {
            _id: '5f99876e6a43fd335aea693d',
            fieldName: 'Status',
            fieldValue: 'new',
            timeStamp: '2020-10-23T22:16:44Z'
          }
        ],
        InstanceURL: 'https://d3v-irisagent.zendesk.com/agent/tickets/2992',
        Reason: 'problem',
        Status: 'new',
        Subject: 'Service is down!',
        UpdatedDate: '2020-10-23T22:16:44Z',
        caseSentimentMagnitude: 1.7000000476837158,
        caseSentimentScore: -97,
        customReason: '',
      },
      {
        Assignees: [null],
        Tags: [],
        _id: '5f99876e305b628b4f7119eb',
        Id: '2997',
        organizationId: '360004463454',
        sourceName: 'Zendesk',
        AccountId: null,
        AccountName: null,
        CaseNumber: '2997',
        timeOfCreation: '2020-10-26T20:11:21Z',
        Description: 'Cannot access the database',
        FieldHistory: [
          {
            _id: '5f99876e6a43fd335aea6941',
            fieldName: 'Status',
            fieldValue: 'new',
            timeStamp: '2020-10-26T20:11:21Z'
          }
        ],
        InstanceURL: 'https://d3v-irisagent.zendesk.com/agent/tickets/2997',
        Reason: 'problem',
        Status: 'new',
        Subject: 'Service is down!',
        UpdatedDate: '2020-10-26T20:11:21Z',
        caseSentimentMagnitude: 1.7000000476837158,
        caseSentimentScore: -97,
        customReason: '',
      },
      {
        Assignees: [],
        Tags: [],
        _id: '5fa099edb6b32905a641c3f3',
        Id: '3006',
        organizationId: '360004463454',
        sourceName: 'Zendesk',
        AccountId: '370342904294',
        AccountName: 'IrisAgent',
        CaseNumber: '3006',
        timeOfCreation: '2020-10-31T00:27:45Z',
        Description: 'I cannot view reporting charts.',
        FieldHistory: [
          {
            _id: '5fa099ed7d41f8430aec5ad2',
            fieldName: 'Status',
            fieldValue: 'new',
            timeStamp: '2020-10-31T00:27:45Z'
          }
        ],
        InstanceURL: 'https://d3v-irisagent.zendesk.com/agent/tickets/3006',
        Reason: null,
        Status: 'new',
        Subject: 'Unable to load reporting page.',
        UpdatedDate: '2020-10-31T00:27:45Z',
        caseSentimentMagnitude: 0.800000011920929,
        caseSentimentScore: -97,
        customReason: 'performance',
      },
      {
        Assignees: ['407784451554'],
        Tags: ['access', 'page', 'reporting'],
        _id: '5f7913116ef107c4ec4701c7',
        Id: '2976',
        organizationId: '360004463454',
        sourceName: 'Zendesk',
        AccountId: '370342904294',
        AccountName: 'IrisAgent',
        CaseNumber: '2976',
        timeOfCreation: '2020-08-25T02:29:37Z',
        Description:
          'I was able to view the reports until yesterday, but unable to do so today.',
        InstanceURL: 'https://d3v-irisagent.zendesk.com/agent/tickets/2976',
        Reason: 'incident',
        Status: 'open',
        Subject: 'Unable to access Reporting page',
        caseSentimentMagnitude: 0.699999988079071,
        caseSentimentScore: 95,
        customReason: '',
        FieldHistory: [],
        UpdatedDate: '2020-09-21T17:48:55Z'
      }
    ],
    highPriorityCaseList: [
      {
        Id: '11770',
        sourceName: 'Freshdesk',
        AccountName: 'Iris Inc.',
        CaseNumber: '1',
        InstanceURL:
          'https://newaccount1607167888597.freshdesk.com/a/tickets/1',
        Status: 'Waiting on Third Party/Ins',
        Subject: 'Payment Failed?',
        priority: 'Low',
        timeOfCreation: '2021-09-24T13:12:12.000Z',
        openTimeSecs: 518400,
        agentNames: ['Sheldon Cooper', 'Leonard Hoffstader'],
        caseSentimentBucket: {
          id: 5,
          title: 'Negative',
          color: '#E8E36E'
        },
        annualContractValue: 857230000,
        commentsLength: 10,
        priorityScore: {
          score: 81,
          parameters: [
            {
              type: 'openTime',
              score: 99,
              scoreBucket: {
                id: 'bad',
                title: 'Bad'
              }
            },
            {
              type: 'sourcePriority',
              score: 40,
              scoreBucket: {
                id: 'good',
                title: 'Good'
              }
            },
            {
              type: HealthParameter.CASE_SENTIMENT_SCORE,
              score: 70,
              scoreBucket: {
                id: 'bad',
                title: 'Bad'
              }
            },
            {
              type: 'comments',
              score: 99,
              scoreBucket: {
                id: 'bad',
                title: 'Bad'
              }
            },
            {
              type: HealthParameter.ACV,
              score: 99,
              scoreBucket: {
                id: 'bad',
                title: 'Bad'
              }
            }
          ]
        }
      },
      {
        Id: '11783',
        sourceName: 'Freshdesk',
        AccountName: 'TSI',
        CaseNumber: '12',
        InstanceURL:
          'https://newaccount1607167888597.freshdesk.com/a/tickets/12',
        Status: 'Open',
        Subject: 'login not working',
        priority: 'Medium',
        timeOfCreation: '2021-09-24T19:03:18.000Z',
        openTimeSecs: 259200,
        commentsLength: 6,
        agentNames: ['Sheldon Cooper'],
        caseSentimentBucket: {
          id: 5,
          title: 'Negative',
          color: '#E8E36E'
        },
        priorityScore: {
          score: 79.9754058042302,
          parameters: [
            {
              type: 'openTime',
              score: 99.9508116084604,
              scoreBucket: {
                id: 'bad',
                title: 'Bad'
              }
            },
            {
              type: 'sourcePriority',
              score: 60,
              scoreBucket: {
                id: 'moderate',
                title: 'Moderate'
              }
            }
          ]
        }
      },
      {
        Id: '11781',
        sourceName: 'Freshdesk',
        AccountName: 'IrisAgent',
        CaseNumber: '18',
        InstanceURL:
          'https://newaccount1607167888597.freshdesk.com/a/tickets/18',
        Status: 'Open',
        Subject: 'login not working',
        priority: 'High',
        commentsLength: 3,
        timeOfCreation: '2021-09-24T19:03:18.000Z',
        openTimeSecs: 86400,
        agentNames: ['Howard Wolowitz'],
        caseSentimentBucket: {
          id: 5,
          title: 'Negative',
          color: '#E8E36E'
        },
        priorityScore: {
          score: 72,
          parameters: [
            {
              type: 'openTime',
              score: 68,
              scoreBucket: {
                id: 'bad',
                title: 'Bad'
              }
            },
            {
              type: 'sourcePriority',
              score: 80,
              scoreBucket: {
                id: 'bad',
                title: 'Bad'
              }
            }
          ]
        }
      },
      {
        Id: '11774',
        sourceName: 'Freshdesk',
        AccountName: 'Acme Inc.',
        CaseNumber: '7',
        InstanceURL:
          'https://newaccount1607167888597.freshdesk.com/a/tickets/7',
        Status: 'Waiting on Third Party/Ins',
        Subject: 'Re: [EXTERNAL] - 1263886, Amit  Burman . CAS-127695-D7T3V1',
        priority: 'Medium',
        timeOfCreation: '2021-09-24T13:12:12.000Z',
        openTimeSecs: 172800,
        agentNames: ['Howard Wolowitz', 'Rajesh Koothrapalli'],
        caseSentimentBucket: {
          id: 5,
          title: 'Positive',
          color: '#75E44E'
        },
        annualContractValue: 214000000,
        commentsLength: 2,
        priorityScore: {
          score: 54.6,
          parameters: [
            {
              type: 'openTime',
              score: 98,
              scoreBucket: {
                id: 'bad',
                title: 'Bad'
              }
            },
            {
              type: 'sourcePriority',
              score: 60,
              scoreBucket: {
                id: 'moderate',
                title: 'Moderate'
              }
            },
            {
              type: HealthParameter.CASE_SENTIMENT_SCORE,
              score: 25,
              scoreBucket: {
                id: 'good',
                title: 'Good'
              }
            },
            {
              type: 'comments',
              score: 10,
              scoreBucket: {
                id: 'good',
                title: 'Good'
              }
            },
            {
              type: HealthParameter.ACV,
              score: 78,
              scoreBucket: {
                id: 'bad',
                title: 'Bad'
              }
            }
          ]
        }
      }
    ],
    binnedSentimentCategory: {
      product_issue: {
        'Wed Sep 29 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Thu Sep 30 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 01 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Tue Oct 05 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Wed Oct 06 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 08 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Mon Oct 11 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Tue Oct 12 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Wed Oct 13 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Thu Oct 14 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 15 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Sat Oct 16 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Tue Oct 19 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Thu Oct 21 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Fri Oct 22 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Sat Oct 23 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Mon Oct 25 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Tue Oct 26 2021 05:30:00 GMT+0530 (India Standard Time)': 2
      },
      critical_issue: {
        'Thu Sep 30 2021 05:30:00 GMT+0530 (India Standard Time)': 1
      },
      feature_request: {
        'Thu Oct 07 2021 05:30:00 GMT+0530 (India Standard Time)': 1
      },
      discontinue_service: {
        'Thu Oct 02 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 03 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Sat Oct 04 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Tue Oct 20 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Thu Oct 31 2021 05:30:00 GMT+0530 (India Standard Time)': 1
      },
      escalation_request: {
        'Fri Oct 01 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Tue Oct 05 2021 05:30:00 GMT+0530 (India Standard Time)': 5,
        'Wed Oct 06 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Fri Oct 08 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Mon Oct 11 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Tue Oct 12 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Wed Oct 13 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Thu Oct 14 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Fri Oct 15 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Sat Oct 16 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Tue Oct 19 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Thu Oct 21 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 22 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Sat Oct 23 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Mon Oct 25 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Tue Oct 26 2021 05:30:00 GMT+0530 (India Standard Time)': 2
      },
      confusion: {
        'Wed Sep 29 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Thu Sep 30 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 01 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Thu Oct 02 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Fri Oct 03 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Sat Oct 04 2021 05:30:00 GMT+0530 (India Standard Time)': 5,
        'Thu Oct 14 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 15 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Sat Oct 16 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Tue Oct 20 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Thu Oct 31 2021 05:30:00 GMT+0530 (India Standard Time)': 2
      },
      performance_issue: {
        'Wed Sep 29 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Thu Sep 30 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 01 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Thu Oct 02 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Fri Oct 03 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Sat Oct 04 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Thu Oct 14 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Tue Oct 20 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Thu Oct 31 2021 05:30:00 GMT+0530 (India Standard Time)': 2
      },
      product_bug: {
        'Wed Sep 29 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Thu Sep 30 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 01 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Thu Oct 02 2021 05:30:00 GMT+0530 (India Standard Time)': 4,
        'Fri Oct 03 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Sat Oct 04 2021 05:30:00 GMT+0530 (India Standard Time)': 4,
        'Tue Oct 20 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Thu Oct 31 2021 05:30:00 GMT+0530 (India Standard Time)': 1
      },
      question: {
        'Wed Sep 29 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Thu Sep 30 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Fri Oct 01 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Thu Oct 02 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 03 2021 05:30:00 GMT+0530 (India Standard Time)': 5,
        'Sat Oct 04 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Tue Oct 20 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Thu Oct 31 2021 05:30:00 GMT+0530 (India Standard Time)': 3
      },
      urgent_issue: {
        'Wed Sep 29 2021 05:30:00 GMT+0530 (India Standard Time)': 4,
        'Thu Sep 30 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Fri Oct 01 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Thu Oct 02 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Fri Oct 03 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Sat Oct 04 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Tue Oct 20 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Thu Oct 31 2021 05:30:00 GMT+0530 (India Standard Time)': 2
      }
    },
    perDayCustomerHealthCounts: {
      totalCustomersPerDay: {
        'Wed Sep 29 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Thu Sep 30 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 01 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Tue Oct 05 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Wed Oct 06 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 08 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Mon Oct 11 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Tue Oct 12 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Wed Oct 13 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Thu Oct 14 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Fri Oct 15 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Sat Oct 16 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Tue Oct 19 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Thu Oct 21 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Fri Oct 22 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Sat Oct 23 2021 05:30:00 GMT+0530 (India Standard Time)': 2,
        'Mon Oct 25 2021 05:30:00 GMT+0530 (India Standard Time)': 1,
        'Tue Oct 26 2021 05:30:00 GMT+0530 (India Standard Time)': 2
      },
      totalCasesPerDay: {
        'Wed Sep 29 2021 05:30:00 GMT+0530 (India Standard Time)': 9,
        'Thu Sep 30 2021 05:30:00 GMT+0530 (India Standard Time)': 4,
        'Fri Oct 01 2021 05:30:00 GMT+0530 (India Standard Time)': 11,
        'Tue Oct 05 2021 05:30:00 GMT+0530 (India Standard Time)': 17,
        'Wed Oct 06 2021 05:30:00 GMT+0530 (India Standard Time)': 18,
        'Fri Oct 08 2021 05:30:00 GMT+0530 (India Standard Time)': 15,
        'Mon Oct 11 2021 05:30:00 GMT+0530 (India Standard Time)': 13,
        'Tue Oct 12 2021 05:30:00 GMT+0530 (India Standard Time)': 12,
        'Wed Oct 13 2021 05:30:00 GMT+0530 (India Standard Time)': 11,
        'Thu Oct 14 2021 05:30:00 GMT+0530 (India Standard Time)': 20,
        'Fri Oct 15 2021 05:30:00 GMT+0530 (India Standard Time)': 3,
        'Sat Oct 16 2021 05:30:00 GMT+0530 (India Standard Time)': 12,
        'Tue Oct 19 2021 05:30:00 GMT+0530 (India Standard Time)': 16,
        'Thu Oct 21 2021 05:30:00 GMT+0530 (India Standard Time)': 19,
        'Fri Oct 22 2021 05:30:00 GMT+0530 (India Standard Time)': 8,
        'Sat Oct 23 2021 05:30:00 GMT+0530 (India Standard Time)': 6,
        'Mon Oct 25 2021 05:30:00 GMT+0530 (India Standard Time)': 4,
        'Tue Oct 26 2021 05:30:00 GMT+0530 (India Standard Time)': 2
      }
    }
  }
};
