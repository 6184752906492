import Constants from './constants';

export default class Routing {
  static getTriggerDetailsPageUrl(triggerId: string): string {
    return `${Constants.routes.TRIGGERS_DETAILS}?${Constants.TRIGGER_ID_SEARCH_PARAM}=${triggerId}`;
  }

  static reload() {
    window.location.reload();
  }
}
