import React from "react";
import {IconButton} from "@material-ui/core";
import "./CustomerProvidedTagPanel.scss";
import {Stack, Text} from "../../../../components/common";
import {CustomerProvidedTagForm} from "./CustomerProvidedTagForm";
import CloseIcon from "@material-ui/icons/Close";
import {CustomerProvidedTagTable, CustomerProvidedTagTableRef} from "./CustomerProvidedTagTable";

export interface CustomerProvidedTagPanelProps {
    closeDrawer: () => void;
}

export const CustomerProvidedTagPanel: React.FC<CustomerProvidedTagPanelProps> = (props) => {
    const {closeDrawer} = props;
    const tableRef = React.useRef<CustomerProvidedTagTableRef>(null);

    return <React.Fragment>
        <Stack direction={"vertical"} gap={20}>
            <div className="advanced-filter ">
                <Text className="heading">Add ML Category</Text>
                <IconButton
                    style={{top: 0, position: 'fixed', right: 0}}
                    onClick={closeDrawer}
                >
                    <CloseIcon style={{color: '#F6F6F6'}}/>
                </IconButton>
                <br/>
                <Text className="sub-heading">
                    Add category details
                </Text>
            </div>
            <div className={"customer-provided-tag-panel-root"}>
                <Stack direction={"vertical"} gap={40}>
                    <CustomerProvidedTagForm onCreate={onCustomerProvidedTagCreate}/>
                    <CustomerProvidedTagTable ref={tableRef}/>
                </Stack>
            </div>
        </Stack>
    </React.Fragment>;

    function onCustomerProvidedTagCreate() {
        tableRef.current?.refetch();
    }
}

