import React from 'react';
import { TablePaper } from './blocks/TablePaper';
import { Heading, Stack } from './blocks';
import { DateRangePicker } from '../../routes/incidents/date-picker-component/DatePickerComponent';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../utils/constants';
import _ from 'lodash';

export interface WidgetProps {
  title: string;
  hasDateRange?: boolean;
  onDateRangeChange?: (dateRange: [Date, Date]) => void;
  left?: React.ReactElement;
  right?: React.ReactElement;
}

export const Widget: React.FC<WidgetProps> = (props) => {
  const { title, hasDateRange, onDateRangeChange } = props;

  const [dateRange, setDateRange] = React.useState<[Date, Date]>([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE
  ]);

  const updateDateRange = React.useCallback(() => {
    if (!hasDateRange || _.isNil(onDateRangeChange)) {
      return;
    }

    onDateRangeChange(dateRange);
  }, [dateRange, hasDateRange, onDateRangeChange]);

  React.useEffect(() => {
    updateDateRange();
  }, [updateDateRange]);

  return (
    <React.Fragment>
      <TablePaper>
        <Stack direction="vertical">
          <Stack
            direction={'horizontal'}
            style={{
              paddingTop: 16
            }}
            className={'mb-1o5 align-items-center'}
          >
            <Heading>{title}</Heading>

            {props.left}

            <Stack
              direction={'horizontal'}
              className={'ml-auto align-items-center'}
            >
              {props.right}

              {hasDateRange && (
                <DateRangePicker value={dateRange} onChange={setDateRange} />
              )}
            </Stack>
          </Stack>

          {props.children}
        </Stack>
      </TablePaper>
    </React.Fragment>
  );
};
