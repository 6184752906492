import React, { FC, useState } from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import { DateRangePicker } from '../../incidents/date-picker-component/DatePickerComponent';
import { MenuListComposition } from '../../customerHealth/components/MenuListComposition';
import { Stack, Text } from '../../../components/common';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import { TopAgentByCategoriesTable } from './TopAgentByCategoriesTable';
import './Agent.scss';
import { Loader } from '../../../components/common/blocks/Loader';
import { useDateRangeQueryForAgentData } from '../utils';
import _ from 'lodash';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../../utils/constants';
import {DropdownSearch} from "../../../components/DropdownSearch";

const NO_CATEGORY_FILTER_STRING = 'All categories';
export const TopAgentsSection: FC = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE
  ]);

  let [currentCategory, setCategory] = useState<String | undefined>(undefined);

  let allCategories = [];
  const { data: agentAnalyticsData } = useDateRangeQueryForAgentData(
    dateRange,
    undefined,
    currentCategory
  );

  allCategories = agentAnalyticsData ? agentAnalyticsData.allCategories : [];

  const handleDateRangeChange = (date: [Date, Date]) => {
    logEventWithUserInfo('Agent Analytics Date Filter Change', {
      startDate: date[0].toString(),
      endDate: date[1].toString()
    }).then();
    setDateRange([startOfDay(date[0]), endOfDay(date[1])]);
  };

  // const [currentPage, setCurrentPage] = useState(0);
  const sortAgentsArray = (arr: any, key: string) => {
    const filteredArr = arr?.filter(
      (a: any) => a[key] !== 0 && a[key] !== null
    );
    return filteredArr?.sort((a: any, b: any) => a[key] - b[key]);
  };

  const getTop5AgentsByMttr = (arr: any) => {
    return sortAgentsArray(arr, 'mttrInSecs')?.slice(0, 5);
  };

  const getTop5AgentsByResolvedCases = (arr: any) => {
    return sortAgentsArray(arr, 'numClosedCases')?.slice(-5).reverse();
  };

  const MenuListAndDatePicker = () => {
    return (
      <Stack align="center" gap={20}>
        <DropdownSearch
            placeholder={NO_CATEGORY_FILTER_STRING}
            value={currentCategory}
            onChange={(_, newValue) => {
              if (newValue) {
                setCategory(newValue);
              } else {
                setCategory(undefined);
              }
            }}
            options={allCategories}
        />
        <DateRangePicker value={dateRange} onChange={handleDateRangeChange} />
      </Stack>
    );
  };

  return (
    <Stack direction="vertical" gap={10}>
      <Stack justify="space-between" style={{ marginTop: 16 }}>
        <Text weight="semi-bolder" variant="p4" color="dark">
          Top Agents by Categories
        </Text>

        <MenuListAndDatePicker />
      </Stack>
      <Stack justify="space-evenly">
        {agentAnalyticsData === undefined ? (
          <div className="centered-loader">
            <Loader />
          </div>
        ) : (
          <>
            <Stack className="top-agents-box" direction="vertical" gap={20}>
              <Text
                weight="medium"
                variant="p3"
                color="dark"
                style={{ padding: '2% 0 0 4%' }}
              >
                Ranked by average resolution time
              </Text>
              <TopAgentByCategoriesTable
                data={getTop5AgentsByMttr(agentAnalyticsData?.agents)}
                filterKey="mttrInSecs"
                filterLabel="AVG. Resolution Time"
              />
            </Stack>

            <Stack
              className="top-agents-box"
              direction="vertical"
              gap={20}
              style={{ marginLeft: '2%' }}
            >
              <Text
                weight="medium"
                variant="p3"
                color="dark"
                style={{ padding: '2% 0 0 4%' }}
              >
                Ranked by resolved cases
              </Text>
              <TopAgentByCategoriesTable
                data={getTop5AgentsByResolvedCases(agentAnalyticsData?.agents)}
                filterKey="numClosedCases"
                filterLabel="Resolved Cases"
              />
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};
