import React, { FC, ReactElement, useCallback, useState } from 'react';

import { Button, FormControl, MenuItem, Select } from '@material-ui/core';

import { Option } from '../../types';

import './styles.scss';
import { useSelectStyles } from '../../hooks/useSelectStyles';

export const RulesDropdownButton: FC<{
  onChange: (value: string) => void;
  options: Option[];
  startIcon: ReactElement;
  children: string | ReactElement | ReactElement[];
  initialState: boolean;
  type: string;
}> = ({ onChange, startIcon, options, children, initialState, type }) => {
  const selectClasses = useSelectStyles();
  const [showSelect, setShowSelect] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setShowSelect(false);
  }, []);

  const handleOpen = useCallback(() => {
    setShowSelect(true);
  }, []);

  return (
    <div style={{ marginBottom: '20px ' }}>
      {showSelect ? (
        <FormControl
          style={{
            minWidth: 120,
            marginBottom: '20px '
          }}
        >
          <Select
            className={`${selectClasses.select} ${selectClasses.placeholder}`}
            label={typeof children === 'string' ? children : 'Options'}
            variant="outlined"
            value=""
            defaultValue=""
            open={showSelect}
            onOpen={handleOpen}
            onClose={handleClose}
            onChange={(e) => {
              onChange(e.target.value as string);
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
          >
            {options.map(({ name, label }, index) => (
              <MenuItem
                value={name}
                key={index}
                disabled={options[index].disabled}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Button
          style={{ backgroundColor: 'white' }}
          className={
            initialState
              ? 'button-details-condition'
              : type === 'action'
              ? 'button-details-condition action'
              : 'button-details-condition trigger'
          }
          startIcon={startIcon}
          onClick={handleOpen}
        >
          {children}
        </Button>
      )}
    </div>
  );
};
