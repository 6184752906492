import React from "react";
import Utils from "../../utils/utils";
import {Loader} from "../../components/common/blocks/Loader";
import _ from "lodash";
import Constants, {Source} from "../../utils/constants";
import {Redirect} from "react-router-dom";
import {PageContainer} from "../../components/common/blocks/PageContainer";
import {TablePaper} from "../../components/common/blocks/TablePaper";
import {Stack} from "../../components/common";
import {ITableStateFilter, TableHeader, TableWithTabs} from "../../components/table-with-tabs/TableWithTabs";
import {useQuery} from "react-query";
import {CACHE_KEYS, CACHE_TIME} from "../../utils/cacheUtils";
import FrontendApiServer from "../../services/IrisPortalService/frontendApiServer";
import {AgentForRouting, Customer} from "../../services/types";
import "./Routing.scss"
import {RoutingAgentRow} from "./RoutingAgentRow";


const TABS = ["All", "Enabled", "Disabled"];
const TABLE_HEADERS: TableHeader[] = [
    {
        "name": "NAME",
        "width": "14%"
    },
    {
        "name": "TIMEZONE",
        "width": "11%"
    },
    {
        "name": "WORKING DAYS",
        "width": "11%"
    },
    {
        "name": "WORKING HOURS",
        "width": "11%"
    },
    {
        "name": "OUT OF OFFICE DAYS",
        "width": "11%"
    },
    {
        "name": "SKILLS WITH LEVELS",
        "width": "16%"
    },
    {
        "name": "ENABLED",
        "width": "10%"
    },
    {
        "name": "EDIT",
        "width": "12%"
    }
];

type EditableAgentForRouting = {
    agent: AgentForRouting;
    isEditing: boolean
}

export const Routing: React.FC = () => {
    const {isLoading, data: customer} = Utils.useCustomerQuery(true);
    const {isLoading: isDataLoading, data, isFetching} = useQuery(
        [CACHE_KEYS.ROUTING_AGENTS],
        () =>
            FrontendApiServer.getAgentsForRouting(),
        {
            enabled: !isLoading && !_.isNil(customer) && canServe(customer),
            refetchOnWindowFocus: false,
            onError: (e) => {
                Utils.showError(String(e));
            },
            ...CACHE_TIME.get(CACHE_KEYS.ROUTING_AGENTS)
        }
    );

    const [sortedAgents, setSortedAgents] = React.useState<AgentForRouting[]>([]);
    const [canModify, setModify] = React.useState<boolean>(true);

    React.useEffect(
        () => {
            if ( isFetching || _.isNil(data) ) {
                return;
            }

            setAgents(data.agents);
        },
        [isFetching, data],
    );

    if ( !_.isNil(customer) && !canServe(customer) ) {
        return <Redirect to={{pathname: Constants.routes.LOGIN}}/>;
    } else if ( isLoading || isDataLoading || _.isNil(data) ) {
        return <Loader/>;
    }

    return <PageContainer className="tab-container">
        <TablePaper
            style={{
                borderBottomLeftRadius: '0 !important',
                borderBottomRightRadius: '0 !important'
            }}
        >
            <Stack gap={20} direction={'vertical'} style={{padding: "1.7em" }}>
                <TableWithTabs
                    data={sortedAgents}
                    getSearchableValue={getAgentRoutingSearchableValue}
                    getTableStatePredicate={getTabFilter}
                    header={"Routing Agents"}
                    tableHeaders={TABLE_HEADERS}
                    tabs={TABS}
                    renderDatum={getAgentRow}
                />
            </Stack>
        </TablePaper>
    </PageContainer>;

    function getAgentRoutingSearchableValue(agent: AgentForRouting): string {
        return agent.name;
    }

    function getTabFilter(state: ITableStateFilter<AgentForRouting>, action: string): ITableStateFilter<AgentForRouting> {
        switch (action) {
            default:
            case "All":
                return (agent) => true;
            case "Enabled":
                return (agent) => agent.isEnabled;
            case "Disabled":
                return (agent) => !agent.isEnabled;
        }
    }

    function canServe(customer: Customer): boolean {
        return _.isEqual(customer?.sourceName, Source.ZENDESK)
    }

    function getAgentRow(agent: AgentForRouting) {
        return <RoutingAgentRow
            agent={agent}
            canModify={canModify}
            onUpdateSuccessful={onAgentUpdateSuccessful}
            key={agent.id}
            onEdit={() => setModify(false)}
            onCancel={() => setModify(true)}
        />
    }

    function setAgents(agents: AgentForRouting[]) {
        // Sort agents by enabled, onboarded and name
        const iteratees = [
            (agent: AgentForRouting) => agent.isEnabled, (agent: AgentForRouting) => agent.isOnboarded,
            (agent: AgentForRouting) => agent.name.toLowerCase()
        ];
        agents = _.orderBy(agents, iteratees, ["desc", "desc", "asc"]);
        setSortedAgents(agents);
    }

    async function onAgentUpdateSuccessful() {
        // await refetchAgentsForRouting();
        setModify(true);
        // set agents again to restore the order
        setAgents(sortedAgents);
    }
}
