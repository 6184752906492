import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Text } from '../../../../components/common';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Selector.scss';
import { FilterChecked } from '../../types';
import { SortableList } from '../../../../components/common/sortable-list/SortableList';
import produce from 'immer';
import _ from 'lodash';
import {
  DragHandle,
  SortableItem
} from '../../../../components/common/sortable-list/components/SortableItem/SortableItem';

interface Props {
  heading: string;
  options: FilterChecked[];
  id: string;
  checked?: boolean;
  draggable?: boolean;
  onOptionsChange: (key: string) => (options: FilterChecked[]) => void;
}

export interface checkboxProps {
  option: FilterChecked;
}

export const Selector = (props: Props) => {
  const { id, onOptionsChange } = props;

  const CustomColorCheckbox = withStyles({
    root: {
      color: '#6F787E',
      '&$checked': {
        color: '#22A5F7'
      }
    },
    checked: {}
  })((prop: checkboxProps) => (
    <Checkbox
      color="default"
      {...prop}
      name={prop.option.value}
      size="small"
      onChange={(e) => handleChange(prop.option)}
      defaultChecked={prop.option.isChecked}
    />
  ));

  const [options, setOptions] = React.useState(props.options);

  const onSelectedOptionsChange = React.useCallback(onOptionsChange(id), []);

  const handleChange = (option: FilterChecked) => {
    setOptions(
      produce((draft) => {
        _.chain(draft)
          .find((opt) => _.isEqual(opt.value, option.value))
          .thru((opt) => (opt.isChecked = !opt.isChecked))
          .value();
      })
    );
  };

  React.useEffect(() => {
    const checkedOptions = options.filter((opt) => opt.isChecked);
    onSelectedOptionsChange(checkedOptions);
  }, [options, onSelectedOptionsChange]);

  return (
    <div className="main w-100">
      <Accordion defaultExpanded={true} style={{ boxShadow: 'none' }}>
        <AccordionSummary
          className="accordian"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ minHeight: '34px' }}
          classes={{
            root: 'w-100'
          }}
        >
          <Text className="accordian-heading">{props.heading}</Text>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup
            classes={{
              root: 'w-100'
            }}
          >
            {props.draggable ? (
              <SortableList<FilterChecked>
                items={options}
                onChange={setOptions}
                renderItem={(item) => (
                  <SortableItem id={item.id}>
                    <FormControlLabel
                      control={<CustomColorCheckbox option={item} />}
                      label={item.value}
                    />
                    <DragHandle />
                  </SortableItem>
                )}
              />
            ) : (
              options.map((option, index) => (
                <FormControlLabel
                  control={<CustomColorCheckbox option={option} />}
                  label={option.value}
                  style={{ height: '32px' }}
                />
              ))
            )}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
