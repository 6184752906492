import React, {FC} from 'react';
import {Tooltip} from '@material-ui/core';
import Utils from '../../../utils/utils';
import Constants from '../../../utils/constants';
import {Stack, Text} from '../../../components/common';
import './MaxSupportImpactIncidents.scss';
import {ReactComponent as CheckBoxSvg} from '../../../assets/CheckBox.svg';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {withStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router';
import IrisPortalService from '../../../services/IrisPortalService';
import {queryClient} from '../../../utils/queryCache';

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: '#303030'
  },
  tooltip: {
    backgroundColor: '#303030',
    border: '1px solid #dadde9',
    pointerEvents: 'auto'
  }
}))(Tooltip);

export const IncidentsNotifications: FC = (props) => {
  const user = Utils.getObjectItem('user');
  const { data } = Utils.useUserQuery();
  const notificationStatus = data?.isSubscribedToDetectedIncidents;
  const updateNotificationStatus = () => {
    if (!notificationStatus) {
      try {
        queryClient.fetchQuery('subscribe-to-detect-incidents', () =>
          IrisPortalService.subscribeToDetectedIncidents()
        );
      } catch (error) {
        console.log('Sending email notification', error);
      }
    }
  };
  const notifyTitle = 'Get Notified';
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const history = useHistory();
  const goToManageAccounts = () => {
    history.push(Constants.routes.MANAGE_ACCOUNTS);
  };

  return (
    <div>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <HtmlTooltip
          arrow
          placement="bottom"
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <React.Fragment>
              <div style={{ padding: 15 }}>
                <div>
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: '#4A154B',
                      textTransform: 'inherit',
                      width: '220px',
                      height: '56px'
                    }}
                    onClick={goToManageAccounts}
                  >
                    <img
                      alt=""
                      style={{ order: 1 }}
                      src="/images/slack_notification.png"
                    />
                    <Text
                      variant="p1"
                      weight="medium"
                      style={{ color: '#F2F2F2' }}
                    >
                      Integrate with
                    </Text>
                  </Button>
                </div>
                <div className="dash-line"></div>
                <div>
                  <Button
                    onClick={updateNotificationStatus}
                    style={{
                      display: 'inline-grid',
                      alignItems: 'center',
                      background: '#FFFFFF1A',
                      textTransform: 'inherit',
                      width: '100%'
                    }}
                  >
                    {!notificationStatus ? (
                      <>
                        <Text
                          variant="p1"
                          weight="regular"
                          style={{
                            color: '#FFFFFF',
                            textAlign: 'center'
                          }}
                        >
                          Notify me over
                        </Text>
                        <Text variant="p1" weight="regular" color="gray">
                          {user.email}
                        </Text>{' '}
                      </>
                    ) : (
                      <Stack align="center" gap={25}>
                        <CheckBoxSvg></CheckBoxSvg>
                        <Stack direction="vertical">
                          <Text
                            variant="p1"
                            weight="regular"
                            style={{
                              color: '#FFFFFF',
                              textAlign: 'center'
                            }}
                          >
                            You got notified over
                          </Text>
                          <Text variant="p1" weight="regular" color="gray">
                            {user.email}
                          </Text>
                        </Stack>
                      </Stack>
                    )}
                  </Button>
                </div>
              </div>
            </React.Fragment>
          }
        >
          <Button
            variant="text"
            onClick={handleTooltipOpen}
            style={{
              background: '#5D5FEF',
              textTransform: 'inherit',
              height: '6vh',
              width: '18vh'
            }}
          >
            <Text variant="p1" weight="medium" style={{ color: '#F2F2F2' }}>
              {notifyTitle}
            </Text>
          </Button>
        </HtmlTooltip>
      </ClickAwayListener>
    </div>
  );
};
