import React, { useCallback, useState } from 'react';
import Utils from '../../../utils/utils';
import Constants from '../../../utils/constants';
import _ from "lodash";

const PAGE_SIZE = 8;
const ROWS_PER_PAGE_OPTIONS = _.sortedUniq([PAGE_SIZE, 10, 25, 50, 100]);
export const useTableSorters = (
  config?: Partial<{
    defaultCurrentPage?: number;
    pageSize: number;
    turnOffPagination: boolean;
    defaultSortColumn: string;
    defaultSortOrder: string;
  }>
) => {
  const [pageSize, setPageSize] = useState(config?.pageSize || PAGE_SIZE);
  const [sortColumn, setSortColumn] = useState(config?.defaultSortColumn || '');
  const [sortOrder, setSortOrder] = useState(
    config?.defaultSortOrder || Constants.ASCENDING_SORTING
  );
  const [page, setPage] = useState(config?.defaultCurrentPage || 0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(1);

  const handleSortRequest = useCallback(
    (column: string) => {
      // Always set page to 0 when sorting is clicked!
      setPage(0);
      if (sortColumn === column && sortOrder === Constants.ASCENDING_SORTING) {
        setSortOrder(Constants.DESCENDENT_SORTING);
      } else if (
        sortColumn === column &&
        sortOrder === Constants.DESCENDENT_SORTING
      ) {
        setSortOrder(Constants.ASCENDING_SORTING);
      } else {
        setSortColumn(column);
        setSortOrder(Constants.DESCENDENT_SORTING);
      }
    },
    [sortOrder, sortColumn]
  );

  const filterData = useCallback(
    <T extends unknown>(data: T[]) => {
      const sortedData = Utils.sortTableData(
        data || [],
        sortOrder,
        sortColumn
      ) as T[];

      if (config?.turnOffPagination) {
        return sortedData;
      }

      return sortedData.slice(page * pageSize, (page + 1) * pageSize);
    },
    [sortOrder, sortColumn, pageSize, page, config]
  );

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setPage(0);
        setPageSize(parseInt(event.target.value, 10));
    };
    
    const labelDisplayedRows = (page: any) => {
      return `${page.from}-${page.to} of ${page.count}`;
    };
    

  return {
    onSort: handleSortRequest,
    filterData,
    sortColumn,
    sortOrder,
    onPageChange: setPage,
    currentPage: page,
    handleChangeRowsPerPage, 
    labelDisplayedRows,
    pageSize,
    ROWS_PER_PAGE_OPTIONS,
  };
};
