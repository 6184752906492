import { RouteComponentProps } from 'react-router';
import React from 'react';
import { HighPriorityScoreCases } from '../customerHealth/components/HighPriorityScoreCases';

import Utils from '../../utils/utils';
import { CustomerAccountHealthScoreTable } from '../customerHealth/components/CustomerAccountHealthScoreTable';
import { PrioritizedEngineeringEscalations } from '../../components/PrioritizedEngineeringEscalations';
import { Page } from '../Page';

export const NeedsAttention: React.FC<RouteComponentProps> = () => {
  const { dataCustomerHealth } = Utils.useCustomerWithLowestHealthScore();

  return (
    <React.Fragment>
      <Page>
        <HighPriorityScoreCases />

        {dataCustomerHealth && (
          <CustomerAccountHealthScoreTable
            data={dataCustomerHealth.customerAccounts}
          />
        )}

        <PrioritizedEngineeringEscalations
          title={'Engineering escalations that need attention'}
        />
      </Page>
    </React.Fragment>
  );
};
