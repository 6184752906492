import Utils from '../../utils/utils';
import {handleError} from './utils';

export interface IndexData {
  subtitle: string;
}

export interface QueryResponse {
  id: string;
  answer?: string;
}

export interface IrisGPTQueryResponse {
  result: QueryResponse;
}

export const sendIrisGPTMessage = async (query: string): Promise<IrisGPTQueryResponse> => {
  let token_express = Utils.getObjectItem('token_express');

  const controller = new AbortController();
  const timer = setTimeout(() => controller.abort(), 60 * 1000);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "X-Tenant-Id": `irisportal`,
      Authorization: `Bearer ${token_express}`,
    },
    body: JSON.stringify({
      query
    }),
    signal: controller.signal,
  } as RequestInit;

  const response = await fetch(
      `${process.env.REACT_APP_APISERVER_URL}/v1/portal/chatbot`,
      requestOptions
  );
  clearTimeout(timer);

  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }

  const responseJson = await response.json();

  // Botonic chat supports to send multiple messages per single request.
  // This UI doesn't support it yet, so we expect a single response.
  if (!Array.isArray(responseJson) || responseJson.length === 0) {
    throw new Error('Expected a non-empty array in response');
  }

  const responseTextObj = responseJson[0];

  // Botonic chat has strange logic for Rasa-based requests, they are not string, but an object with 'answer' field.
  let answer;
  if (typeof responseTextObj?.data === 'string') {
    answer = responseTextObj?.data;
  } else {
    answer = responseTextObj?.plaintext;
  }

  const queryResponseAsIrisGPTQueryResponse = {
    id: String(response.headers.get('Chat-Identifier')),
    answer: answer,
  };

  return {
    result: queryResponseAsIrisGPTQueryResponse,
  }
};

export const getIndexData = async (): Promise<IndexData> => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token_express}`
    },
    credentials: 'include'
  } as RequestInit;

  const path = Utils.isDemo() ? '/demo/chatbot' : '/chatbot';
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}${path}?customerId=${user.customerId}`,
    requestOptions
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }

  return await response.json();
};
