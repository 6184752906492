import detailsAccountMockData from './mock-data-files/detailsAccountMockData.json';
import userMockData from './mock-data-files/userMockData.json';
import incidentSourcesMockData from './mock-data-files/incidentSourcesMockData.json';
import {customerHealthMockData} from './mock-data-files/customerHealthMockData';
import {aiRecommendedCases} from './mock-data-files/aiRecommendedCases';
import caseCategoriesMockData from './mock-data-files/caseCategoriesMockData.json';
import knowledgeBaseArticlesMockData from './mock-data-files/knowledgeBaseArticlesMockData.json';
import jiraIssuesMockData from './mock-data-files/jiraIssuesMockData.json';
import accountHealth from './mock-data-files/accountHealth.json';
import invitedUsersMockData from './mock-data-files/invitedUsersMockData.json';
import caseCategoryFiltersMockData from './mock-data-files/caseCategoryFiltersMockData.json';
import agentAnalyticsData from './mock-data-files/agentAnalyticsData.json';
import singleAgentAnalytics from './mock-data-files/getSingleAgentAnalytics.json';
import getTriggers from './mock-data-files/getTriggersMockData.json';
import getTriggersPrerequisitesMockData from './mock-data-files/getTriggersPrerequisitesMockData.json';
import getDetectedIncidentsSettingsPrerequisitesMockData
  from './mock-data-files/getDetectedIncidentsSettingsPrerequisitesMockData.json';
import getChatbotActivityMockData from './mock-data-files/getChatbotActivityMockData.json';
import geDetectedIncidentSettingsMockData from './mock-data-files/geDetectedIncidentSettingsMockData.json';
import getChatbotSettingsMockData from './mock-data-files/getChatbotSettingsMockData.json';
import ruleDetails from './mock-data-files/ruleDetailsMockData.json';
import customer from './mock-data-files/customer.json';
import importantSupportMetrics from './mock-data-files/importantSupportMetrics.json';
import revenueImpactCategories from './mock-data-files/revenueImpactCategories.json';
import revenueImpactOverall from './mock-data-files/revenueImpactOverall.json';
import revenueImpactEscalations from './mock-data-files/revenueImpactEscalations.json';
import revenueImpactStatus from './mock-data-files/revenueImpactStatus.json';
import revenueImpactPriority from './mock-data-files/revenueImpactPriority.json';
import dashboardSummary from './mock-data-files/dashboardSummary.json';
import customersWithLowestHealthScoresMockData from './mock-data-files/customersWithLowestHealthScoresMockData.json';
import {
  AgentsForRoutingResponse,
  AiRecommendedCases,
  AtlassianConfigResponse,
  CustomerProvidedTag,
  User
} from '../services/types';
import customerEngagement from './mock-data-files/customerEngagement.json';
import triggersOverallImpactData from './mock-data-files/triggersOverallImpactData.json';
import triggersOverallImpactTrendData from './mock-data-files/triggersOverallImpactTrend.json';
import triggerActionCases from './mock-data-files/triggerActionCases.json';
import articleMetrics from './mock-data-files/articleMetrics.json';
import atlassianConfig from './mock-data-files/atlassianConfig.json';
import irisPortalUser from './mock-data-files/irisPortalUser';
import agentsForRoutingResponse from './mock-data-files/agentsForRoutingResponse.json';
import customerProvidedTags from './mock-data-files/customerProvidedTags.json';
import {CustomersWithLowestHealthScores, RevenueImpact} from '../components/home/types';
import {TriggersOverallImpactData, TriggersOverallImpactTrendData} from '../routes/rules/types';
import {TriggerActionCasesArray} from '../routes/rules/components/TriggerActionCases';
import {
  DetectedIncidentsSettingsPrerequisites
} from '../routes/incidents/trending-incidents-management/DetectedIncidentsManagementTable/types';
import {
  DetectedIncidentsManagementTableData
} from '../routes/incidents/trending-incidents-management/DetectedIncidentsManagementTable';
import {ChatbotSetting} from '../routes/manage-chatbot/types';
import {ArticleMetricsData} from '../types/automation';
import {ChatbotActivity} from '../routes/chatbot-activity/types';

class MockData {
  public static getDetailsAccountDashboardMockData() {
    return detailsAccountMockData;
  }

  public static getAllAccountNames() {
    return { allAccountNames: ['Iris', 'IrisAgent'] };
  }

  public static getUserMockData() {
    return userMockData;
  }

  public static isSlackTokenAvailableMockData() {
    return { isToken: false };
  }

  public static isGenericTokenAvailableMockData() {
    return { isToken: false };
  }

  public static getSaveTokenMockData() {
    return { codeMessage: 'tokenInserted' };
  }

  public static getSaveIrisPortalUserMockData() {
    return { inserted: true, messageCode: 'userInserted' };
  }

  public static getIrisPortalInvitedUsersMockData() {
    return invitedUsersMockData;
  }

  public static getCustomerHealthsMockData() {
    return customerHealthMockData;
  }

  public static getIncidentSourcesMockData() {
    return incidentSourcesMockData;
  }

  public static getCaseCategoriesMockData() {
    return caseCategoriesMockData;
  }

  public static getCaseCategoryFiltersMockData() {
    return caseCategoryFiltersMockData;
  }

  public static getKnowledgeBaseArticlesMockData() {
    return knowledgeBaseArticlesMockData;
  }

  public static getJiraIssuesMockData() {
    return jiraIssuesMockData;
  }

  public static getAccountHealth() {
    return accountHealth;
  }

  public static getAiRecommendedCases(): AiRecommendedCases[] {
    return aiRecommendedCases;
  }

  public static getAgentAnalyticsMockData() {
    return agentAnalyticsData;
  }

  public static getSingleAgentAnalyticsMockData() {
    return singleAgentAnalytics;
  }

  public static getTriggersMockData() {
    return getTriggers;
  }

  public static getTriggersPrerequisitesMockData() {
    return getTriggersPrerequisitesMockData;
  }

  public static getRuleDetailsMockData() {
    return ruleDetails;
  }

  static getCustomerMockData() {
    return customer;
  }

  static getDashboardSummaryMockData() {
    return dashboardSummary;
  }

  static getImportantSupportMetricsMockData() {
    return importantSupportMetrics;
  }
  static getCustomersWithLowestHealthScoresMockData() {
    return customersWithLowestHealthScoresMockData as CustomersWithLowestHealthScores;
  }

  static getRevenueImpactMockData(order: string): RevenueImpact {
    switch (order) {
      case 'overall':
        return revenueImpactOverall;
      case 'escalations':
        return revenueImpactEscalations;
      case 'categories':
        return revenueImpactCategories;
      case 'priority':
        return revenueImpactPriority;
      case 'status':
        return revenueImpactStatus;
      default:
        return revenueImpactOverall;
    }
  }

  static getTriggersOverallImpactData() {
    return triggersOverallImpactData as TriggersOverallImpactData;
  }

  static getArticleMetricsData() {
    return articleMetrics as ArticleMetricsData;
  }

  static getTriggersOverallImpactTrendData() {
    return triggersOverallImpactTrendData as TriggersOverallImpactTrendData;
  }

  static getCustomerEngagementMockData() {
    return customerEngagement;
  }
  static getTriggerActionCases() {
    return triggerActionCases as TriggerActionCasesArray;
  }

  static getDetectedIncidentsSettingsPrerequisitesMockData() {
    return getDetectedIncidentsSettingsPrerequisitesMockData as DetectedIncidentsSettingsPrerequisites;
  }

  static geDetectedIncidentSettingsMockData() {
    return geDetectedIncidentSettingsMockData as DetectedIncidentsManagementTableData;
  }

  static getChatbotSettingsMockData() {
    return getChatbotSettingsMockData as ChatbotSetting;
  }

  static getChatbotActivityMockData() {
    return getChatbotActivityMockData as ChatbotActivity[];
  }

  static getAtlassianConfigMockData(): AtlassianConfigResponse {
    return atlassianConfig as AtlassianConfigResponse;
  }

  static getIrisPortalUserMockData(): User {
    return irisPortalUser as User;
  }

  static getAgentsForRouting(): AgentsForRoutingResponse {
    return agentsForRoutingResponse as AgentsForRoutingResponse;
  }

  static getCustomerProvidedTags(): CustomerProvidedTag[] {
    return customerProvidedTags as CustomerProvidedTag[];
  }
}

export default MockData;
