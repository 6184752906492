import React, { FC, useCallback, useEffect, useState } from 'react';
import { Stack, Text } from '../common';
import { ReactComponent as AlertImpact } from '../../assets/AlertImpact.svg';

import { useQuery } from 'react-query';
import { CACHE_KEYS, CACHE_TIME } from '../../utils/cacheUtils';
import IrisPortalService from '../../services/IrisPortalService';
import { Loader } from '../common/blocks/Loader';
import { endOfDay, startOfDay } from 'date-fns';
import { DateRangePicker } from '../../routes/incidents/date-picker-component/DatePickerComponent';
import Constants, {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE
} from '../../utils/constants';
import _ from 'lodash';
import Utils from '../../utils/utils';
import { ChangeIndicator } from '../../routes/categories/components/ChangeIndicator';
import { IrisCard, IrisCardHighlightText } from '../IrisCard';

type Props = {
  invertColor?: boolean;
  size?: string;
  title?: string;
  triggerId?: string;
  titleSize?: string;
  isDashboard?: boolean;
  isDetails?: boolean;
};

export const TriggersImpactNumbers: FC<Props> = (props) => {
  const {
    invertColor,
    size,
    title = 'Automation impact summary',
    triggerId,
    titleSize,
    isDashboard,
    isDetails
  } = props;
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE
  ]);
  const [showData, setShowData] = useState(false);
  const { data: triggerStats, isLoading } = useQuery(
    [dateRange, triggerId, 'TriggerImpact'],
    () =>
      isDetails
        ? IrisPortalService.getTriggerImpactData(
            triggerId ? triggerId : '',
            ...dateRange
          )
        : IrisPortalService.getTriggersOverallImpactData(),
    {
      ...CACHE_TIME[CACHE_KEYS.TRIGGERS_OVERALL_IMPACT]
    }
  );

  const getImpact = (triggerType: string) => {
    const value = triggerStats?.impactData?.filter((type: any) => {
      return type[0] === triggerType;
    });
    return value?.length > 0 ? value[0][1] : null;
  };

  const getImpactValue = (triggerType: string) => {
    const impact = getImpact(triggerType);
    if (_.isNil(impact)) {
      return null;
    }

    return impact['count'];
  };

  const getImpactPreviousValue = (triggerType: string) => {
    const impact = getImpact(triggerType);
    if (_.isNil(impact)) {
      return 0;
    }

    return impact['previousCount'] || 0;
  };

  const handleDateRangeChange = (date: [Date, Date]) => {
    setDateRange([startOfDay(date[0]), endOfDay(date[1])]);
  };
  const impactNumbers: Array<{
    hasImpact: boolean;
    number: number | string;
    percentChange: number | null;
    description: string;
    icon: typeof AlertImpact;
    subtitle?: string;
  }> = _.map(Constants.TRIGGER_ACTION_GROUPS, (group) => {
    return {
      hasImpact: _.some(group.actions, (action) => {
        return !_.isNil(getImpact(action));
      }),
      number: _.reduce(
        group.actions,
        (accumulator, action) => {
          const impactValue = getImpactValue(action);
          if (_.isNil(impactValue)) {
            return accumulator;
          }

          return accumulator + impactValue;
        },
        0
      ),
      percentChange: Utils.calculatePercentChange(
        _.reduce(
          group.actions,
          (accumulator, action) => {
            return (getImpactValue(action) || 0) + accumulator;
          },
          0
        ),

        _.reduce(
          group.actions,
          (accumulator, action) => {
            return (getImpactPreviousValue(action) || 0) + accumulator;
          },
          0
        )
      ),
      ..._.pick(group, 'icon', 'description')
    };
  });

  const showImpactData = useCallback(() => {
    setShowData(
      triggerStats?.impactData?.length > 0
      // &&
      //   !(
      //     triggerStats?.impactData?.length === 1 &&
      //     triggerStats?.impactData[0][0] === 'suggested_resolution'
      //   )
    );
  }, [triggerStats]);
  useEffect(() => {
    showImpactData();
  }, [triggerStats, showImpactData]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: '-5%',
            marginBottom: '3%',
            width: '100%'
          }}
        >
          <Loader center />
        </div>
      ) : (
        showData && (
          <div
            style={{
              padding: 16,
              background: isDashboard ? '#F7F9FE' : 'white',
              borderRadius: 12,
              width: '100%'
              // marginBottom: 20
            }}
          >
            <Stack
              direction="vertical"
              gap={30}
              justify="space-between"
              align="stretch"
            >
              <Stack direction="horizontal">
                <Text
                  variant={titleSize === 'sm' ? 'p3' : 'p4'}
                  weight="semi-bold"
                  style={{ color: '#000', minWidth: 'fit-content' }}
                >
                  {title}
                </Text>
                {isDetails && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <DateRangePicker
                      compact={isDashboard}
                      value={dateRange}
                      onChange={handleDateRangeChange}
                    />
                  </div>
                )}
                {isDashboard && (
                  <a
                    href="/triggers"
                    style={{
                      width: '100%',
                      textAlign: 'right',
                      color: '#5D5FEF'
                    }}
                  >
                    View {'>'}
                  </a>
                )}
                {!isDashboard && !isDetails && (
                  <Text
                    variant="p1"
                    style={{
                      width: '100%',
                      textAlign: 'right',
                      color: '#8181A5'
                    }}
                  >
                    Last 1 Month Data
                  </Text>
                )}
              </Stack>
              <Stack
                direction="horizontal"
                // gap={16}
                justify="space-between"
                align="stretch"
                style={{
                  flexFlow: 'wrap',
                  rowGap: 16,
                  columnGap: 16,
                  justifyContent: 'flex-start'
                }}
              >
                {impactNumbers
                  .filter((imp) => imp.hasImpact)
                  .map((impactNumber) => (
                    <IrisCard
                      background={invertColor ? undefined : 'white'}
                      style={{
                        minWidth: 188,
                        maxWidth:
                          size === 'sm' ? 'calc(100%/4)' : 'calc(100%/2)'
                      }}
                      description={impactNumber.description}
                    >
                      <Stack
                        direction="horizontal"
                        gap={16}
                        justify="space-between"
                      >
                        <Stack style={{ width: 32, height: 32 }}>
                          <impactNumber.icon />
                        </Stack>
                        {/*<Stack direction="horizontal">*/}
                        <IrisCardHighlightText text={impactNumber.number} />

                        <span
                          className={'d-flex'}
                          style={{
                            marginLeft: '1em'
                          }}
                        >
                          <ChangeIndicator
                            value={impactNumber.percentChange}
                            growth={'increment'}
                          />
                        </span>

                        {!_.isNil(impactNumber.percentChange) && (
                          <Text
                            color="dark"
                            variant="p0"
                            style={{ alignSelf: 'center', marginLeft: 4 }}
                          >
                            {`of cases`}
                          </Text>
                        )}
                      </Stack>
                    </IrisCard>
                  ))}
              </Stack>
            </Stack>
          </div>
        )
      )}
    </>
  );
};
