import React, { FC } from 'react';
import { format } from 'date-fns';
import { Stack, Text } from '../common';
import { ReactComponent as Calendar } from '../../assets/Calendar.svg';

export const TimeRange: FC<{ startTime: string; endTime: string }> = (
  props
) => {
  const { startTime, endTime } = props;
  return (
    <Stack gap={10} align="center" justify="center">
      <Calendar />
      <Text variant="p1" weight="semi-bold" color="dark">
        {format(new Date(startTime), 'dd/MM/yyyy')} -{' '}
        {format(new Date(endTime), 'dd/MM/yyyy')}
      </Text>
    </Stack>
  );
};
