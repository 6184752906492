import React, { ReactElement } from 'react';

import { MultiSelectInput } from '../../../../../components/MultiSelectInput';
import { RulesNativeSelect } from '../../RulesNativeSelect';

import {
  formatPrerequisitesData,
  RulesRowManager,
  useTriggersPrerequisites
} from '../../../utils';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as Tag } from '../../../../../assets/tag.svg';
import '../styles.scss';

/**
 * Row manager for Tags
 */
export class TagsRowManager extends RulesRowManager {
  tagsOptionsOperator: Option[] = [
    { name: 'in', label: 'Contains at least one of the following' },
    { name: 'nin', label: 'Contains none of the following' }
  ];

  constructor(options: Option[], actionValue: any, actionOperator: any) {
    super(options, actionValue, actionOperator);
    this.action.type = 'tags';
    this.action.operator = actionOperator === undefined ? 'in' : actionOperator;
  }

  setDefaultActionValue(): void {
    this.action.value = [];
  }
  tagsSelected = this.action.value.map((val: any) => {
    return { name: val, label: val };
  });
  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    const { triggersPrerequesites } = useTriggersPrerequisites();
    const tagsOptions = formatPrerequisitesData(
      triggersPrerequesites?.tags || []
    );
    if (this.action.value === undefined) this.setDefaultActionValue();

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Tag />
          <p className="title">Tags</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <RulesNativeSelect
            options={this.tagsOptionsOperator}
            defaultValue={this.action.operator}
            onChange={(e) => {
              this.action.operator = e.target.value;
              handleRowComponent(this.action);
            }}
          />
          <div>
            <MultiSelectInput
              displayValue="label"
              placeholder="Search Tags"
              onKeyPressFn={function noRefCheck() {}}
              onRemove={(e) => {
                this.action.value = e.map((val: any) => val.name) || [];
                handleRowComponent(this.action);
              }}
              onSearch={function noRefCheck() {}}
              valuesSelected={this.tagsSelected}
              onChange={(e) => {
                this.action.value = e.map((val) => val.name);
                handleRowComponent(this.action);
              }}
              options={tagsOptions}
            />
          </div>
        </div>
      </div>
    );
  }
}
