import React from 'react';
import { Stack } from '../../../common';

import './Graph.scss';

const LegendItem = ({ label, value, color }: any) => {
  return (
    <Stack
      direction="vertical"
      style={{ width: '120px', maxWidth: '120px', minWidth: '120px' }}
    >
      <Stack direction="horizontal">
        <div
          style={{
            width: 18,
            height: 18,
            borderRadius: '50%',
            backgroundColor: color,
            alignSelf: 'center'
          }}
        />
        <span style={{ marginLeft: 5, fontSize: '22px', fontWeight: 500 }}>
          {value}
        </span>
      </Stack>
      <span
        style={{
          color: '#808080',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          textAlign: 'left'
        }}
      >
        {label}
      </span>
    </Stack>
  );
};
export default LegendItem;
