import React, { FC, useEffect } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { format } from 'date-fns';
import './Graph.scss';
import AdditionalDataTooltip from './AdditionalDataTooltip';

export const LineGraph: FC<{
  distribution: any[];
  lines: any[];
  title: string;
  yAxisUnit?: string;
  customTooltip?: boolean;
  yAxisLabel?: string;
  yaxisFormatter?: (tick: any) => string;
  height?: number;
}> = (props) => {
  const {
    distribution,
    lines,
    title,
    customTooltip,
    yaxisFormatter,
    yAxisUnit,
    yAxisLabel,
    height
  } = props;
  useEffect(() => {
    if (distribution.length > 0) {
      distribution.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );
    }
  }, [distribution]);

  return (
    <>
      <h2
        style={{
          marginTop: '25px',
          marginLeft: '25px',
          textAlign: 'left',
          height: '42px'
        }}
      >
        {title}
      </h2>
      {distribution.length > 0 && (
        <ResponsiveContainer
          className="graph-container"
          height={height ? height : 350}
          width="100%"
        >
          <LineChart
            data={distribution}
            margin={{ top: yAxisLabel ? 25 : 8, left: 5, right: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="2 2" horizontal={false} />
            <XAxis
              dataKey="date"
              tickFormatter={(value: string) =>
                format(new Date(value), 'dd MMM')
              }
              tickSize={12}
            />
            <YAxis
              width={40}
              unit={yAxisUnit}
              // label={yAxisLabel && <CustomizedLabelB />}
              tickFormatter={(tick) => {
                return yaxisFormatter ? yaxisFormatter(tick) : tick;
              }}
              label={{
                value: yAxisLabel,
                // angle: -90,
                position: 'top',
                offset: 15,
                fill: 'rgb(135, 152, 173)'
              }}
            />
            <Legend
              payload={lines
                .filter((it) => {
                  return it.hide !== true ? it : null;
                })
                .map((item, index) => ({
                  id: item.name,
                  type: 'circle',
                  value: item.label,
                  color: item.color,
                  formatter: (value, entry, index) => {
                    return (
                      <span
                        style={{
                          color: '#8798AD',
                          fontSize: '12px',
                          lineHeight: '10px',
                          font: 'DM Sans'
                        }}
                      >
                        {value}
                      </span>
                    );
                  }
                }))}
            />
            {lines.map((val: any) => {
              return (
                <Line
                  type="monotone"
                  dataKey={val.name}
                  stroke={val.color}
                  activeDot={{ r: 8 }}
                  dot={false}
                  strokeWidth={2}
                  hide={val.hide ? val.hide : false}
                />
              );
            })}

            {customTooltip && (
              <Tooltip
                cursor={{ fill: 'transparent' }}
                content={
                  <AdditionalDataTooltip lines={lines} additionalTipOrder={1} />
                }
              />
            )}

            {!customTooltip && (
              <Tooltip
                cursor={{ fill: 'transparent' }}
                labelFormatter={(label) => {
                  return <span>{format(new Date(label), 'dd MMM')}</span>;
                }}
                formatter={(value: string, name: string) => {
                  const line = lines.filter((line) => {
                    return line.name === name;
                  })[0];
                  if (line.unit) {
                    return [value + line.unit, line.label];
                  }
                  return [value, line.label];
                }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
