import { Paper } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import IrisPortalService from '../../../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../../../utils/cacheUtils';
import Utils from '../../../../utils/utils';
import { Stack } from '../../../common';
import { Loader } from '../../../common/blocks/Loader';
import { BarGraph } from '../Graphs/BarGraph';
import './RevenueImpacted.scss';
import '../Graphs/Graph.scss';
import LegendItem from '../Graphs/LegendItem';

export const Escalations: FC<any> = (props) => {
  const [escalationsBin, setEscalationsBin] = useState<any[]>([]);
  const [cumulativeBin, setCumulativeBin] = useState<any[]>([]);
  const bars = [
    {
      name: 'escalationsAcv',
      label: 'Revenue Impacted',
      color: '#FF808B',
      type: 'currency'
    }
  ];

  const { data: revenueImpactData, status: statusRevenue } = useQuery(
    [CACHE_KEYS.REVENUE_IMPACT_ESCALATIONS_DATA, ...props.dateRange],
    () =>
      IrisPortalService.getRevenueImpact(
        props.dateRange[0],
        props.dateRange[1],
        'escalations'
      ),
    {
      ...CACHE_TIME.get(CACHE_KEYS.REVENUE_IMPACT_ESCALATIONS_DATA),
      refetchOnWindowFocus: false,
      onError: (e) => {
        Utils.showError(String(e));
      },
      onSuccess: (EscalationsImpactData) => {}
    }
  );
  useEffect(() => {
    if (revenueImpactData && statusRevenue === 'success') {
      revenueImpactData.impact.graph.sort(
        (a: any, b: any) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );

      const newCumulativeBin = [
        {
          name: 'escalationsAcv',
          value: revenueImpactData?.impact.cumulative.escalationsAcv
        }
      ];
      const newEscalationsBin: any = [];
      revenueImpactData.impact.graph.map((imp: any) => {
        newEscalationsBin.push({
          date: imp.startTime,
          escalationsAcv: imp.data.escalationsAcv
        });
        return imp;
      });
      setCumulativeBin(newCumulativeBin);
      setEscalationsBin(newEscalationsBin);
    }
  }, [revenueImpactData, statusRevenue]);

  return (
    <Stack style={{ width: '100%' }} direction="horizontal">
      {statusRevenue === 'loading' ? (
        <Paper style={{ flex: 1, marginBottom: '40px' }}>
          <Loader center />
        </Paper>
      ) : (
        <>
          {escalationsBin.length > 0 && (
            <Stack
              direction="vertical"
              gap={30}
              align="flex-start"
              style={{ width: '100%' }}
            >
              <Stack direction="horizontal" style={{ marginLeft: '40px' }}>
                {cumulativeBin.map((item) => {
                  return (
                    <LegendItem
                      value={Utils.getCurrencyValue(item.value)}
                      label={
                        bars.filter((bar) => bar.name === item.name)[0].label
                      }
                      color={
                        bars.filter((bar) => bar.name === item.name)[0].color
                      }
                    />
                  );
                })}
              </Stack>
              <BarGraph
                customTooltip={true}
                title=""
                distribution={escalationsBin}
                bars={bars}
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};
