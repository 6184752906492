import React, { FC } from 'react';
import { Stack, Text } from '../../../components/common';
import { HtmlTooltip } from '../../../components/common/blocks/Tooltip';

type Props = {
  value?: string | number;
  bucket?: string;
  label: string;
  additionalValue?: string | number;
  type?: string;
  score?: number;
};

export const StatItem: FC<Props> = (props: Props) => {
  const { value, bucket, label, additionalValue, type, score } = props;
  return (
    <>
      {(value || bucket) && (
        <Stack direction="vertical" style={{ width: '180px' }}>
          <Stack direction="horizontal">
            {value && (
              <p
                style={{
                  fontWeight: 900,
                  fontSize: '24px',
                  lineHeight: '32px',
                  minWidth: 'fit-content',
                  marginRight: '6px'
                }}
              >
                {value}
              </p>
            )}
            {additionalValue && (
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '10px',
                  lineHeight: '21px',
                  alignSelf: 'center',
                  marginRight: '6px',
                  color:
                    type === 'csat'
                      ? '#6FCF97'
                      : getScoreColor(bucket ? bucket : '', 1)
                  // color: {type==='csat'?'#6FCF97':getScoreColor(1)}
                }}
              >
                {additionalValue}
              </p>
            )}
            {bucket && (
              <div
                style={{
                  background: getScoreColor(bucket, 0.1),
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  paddingLeft: '14px',
                  paddingRight: '14px'
                }}
              >
                <HtmlTooltip
                  classes={{
                    tooltip: 'width-mc max-width-mc'
                  }}
                  arrow
                  placement="top"
                  title={
                    <React.Fragment>
                      <div
                        style={{
                          textAlign: 'center',
                          marginLeft: '4%',
                          marginTop: '3%'
                        }}
                      >
                        <Text
                          variant="p4"
                          weight="bold"
                          style={{ color: '#FFFFFF' }}
                        >
                          {score}
                        </Text>
                        <br />
                        <Text
                          variant="p0"
                          weight="bold"
                          style={{ color: '#FFFFFF' }}
                        >
                          Out of 100
                        </Text>
                      </div>
                    </React.Fragment>
                  }
                >
                  <p
                    style={{
                      color: getScoreColor(bucket, 1),
                      fontWeight: 500,
                      fontSize: '12px',
                      lineHeight: '16px'
                    }}
                  >
                    {bucket}
                  </p>
                </HtmlTooltip>
              </div>
            )}
          </Stack>
          <p
            style={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '12px',
              color: '#808080',
              marginTop: '4px',
              textAlign: 'left',
              minWidth: 'max-content'
            }}
          >
            {label}
          </p>
        </Stack>
      )}
    </>
  );
};

export const getScoreColor = (bucket: string, opacity: number) => {
  if (bucket === 'Bad') {
    return 'rgb(235, 87, 87,' + opacity + ')';
  } else if (bucket === 'Average' || bucket === 'Moderate') {
    return 'rgb(244, 190, 94,' + opacity + ')';
  } else {
    return 'rgb(3, 187, 115,' + opacity + ')';
  }
};
